import React, { useState, useCallback, useEffect, useRef } from 'react';
import ContractorInfoView from './contractor-info.view';
import ContractorUpdateForm from '../contractor-update/contractor-update.component';
import { useContractorContext } from 'src/contexts/contractor.context';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { getUserID, isAdmin, isPrime } from 'src/libraries/users.library';
import XLSX from 'xlsx-js-style';
import {
    border,
    leftAlignment,
    rightAlignment,
    centerAlignment,
    reportHeaderBackground,
    reportCellFont,
    reportHeaderFont,
} from 'src/constants/common.constant';

type IProps = {
    onReload?: () => void;
};

const ContractorInfo: React.FC<IProps> = ({ onReload }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state, actions } = useContractorContext();
    const [clientWidth, setClientWidth] = useState(0);
    const formRef = useRef<HTMLFormElement>();
    const dataRef = useRef<any>();
    const [primedata, setPrimeData] = useState();
    const fetchData = useCallback(async () => {
        setLoading(true);
        const userId = getUserID();
        const params = { vendor_id: userId };
        const response = await actions.listGET(params);
        console.log('response',response)
        dataRef.current = response.payload?.data;
        if (response.payload?.data.company_name != '') {
            setLoading(false);
        }
    }, [actions]);
    const isUserPrime = isPrime();
    const isUserAdmin = isAdmin();

    useEffect(() => {
        // check user is this prime
        const clientWdth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        setClientWidth(clientWdth);
        fetchData();
    }, [actions, setClientWidth, clientWidth]);

    const handleEdit = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleDiscard = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const saveData = (function () {
        const a = document.createElement('a');
        document.body.appendChild(a);
        return function (data, fileName) {
            const blob = new Blob([data], { type: 'octet/stream' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            ToastSuccess('Export Completed Successfully');
        };
    })();

    const handleDownload = async () => {
        
        const wscolsPrime = [
            { wch: 30 },
            { wch: 40 },
            { wch: 20 },
            { wch: 20 },
            { wch: 25 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
        ]; 
    
        const wscolsNonPrime = [
            { wch: 30 },
            { wch: 40 },
            { wch: 20 },
            { wch: 20 },
            { wch: 25 },
            { wch: 35 },
            { wch: 35 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
        ];
    
        const dataPrime: any = [
            {
                'Prime Name': dataRef.current.company_name,
                Address: `${dataRef.current.vendoradd}\n${dataRef.current.city}, ${dataRef.current.state}  ${dataRef.current.zip}`,
                'SAP ID': dataRef.current.sapId,
                'Supplier Code': dataRef.current.supplier_code,
                'Agreements Allowed': dataRef.current.allow_agreements
                    ? 'Yes'
                    : 'No',
                'Supplier Diversity Contact (SDG&E)':
                    dataRef.current.supplier_diversity_contact,
                'Supplier Diversity Contact (SoCalGas)':
                    dataRef.current.supplier_diversity_contact_socal,
                'Primary Contact Name': `${dataRef.current.contactName ?? '-'}`,
                'Primary Contact Phone': `${dataRef.current.contactPhone ?? '-'}`,
                'Primary Contact Email': `${dataRef.current.contactEmail ?? '-'}`,
                'Secondary Contact Name': `${dataRef.current.contactName2 ?? '-'}`,
                'Secondary Contact Phone': `${dataRef.current.contactPhone2 ?? '-'}`,
                'Secondary Contact Email': `${dataRef.current.contactEmail2 ?? '-'}`,
                'Optional Contact Name': `${dataRef.current.contactName3 ?? '-'}`,
                'Optional Contact Phone': `${dataRef.current.contactPhone3 ?? '-'}`,
                'Optional Contact Email': `${dataRef.current.contactEmail3 ?? '-'}`,
                'Enabled for Tier III Reporting': `${dataRef.current.isToggleAllow ? 'Yes' : 'No'}`,
            },
        ];
        const dataNonPrime: any = [
            {
                'Prime Name': dataRef.current.company_name,
                Address: `${dataRef.current.vendoradd}\n${dataRef.current.city}, ${dataRef.current.state}  ${dataRef.current.zip}`,
                'SAP ID': dataRef.current.sapId,
                'Supplier Code': dataRef.current.supplier_code,
                'Agreements Allowed': dataRef.current.allow_agreements
                    ? 'Yes'
                    : 'No',
                'Supplier Diversity Contact (SDG&E)':
                    dataRef.current.supplier_diversity_contact,
                'Supplier Diversity Contact (SoCalGas)':
                    dataRef.current.supplier_diversity_contact_socal,
                'Contact 1': dataRef.current.contact1_name ?? '-',
                'Contact 2': dataRef.current.contact2_name ?? '-',
                'Contact 3': dataRef.current.contact3_name ?? '-',
                'Primary Contact Name': `${dataRef.current.contactName ?? '-'}`,
                'Primary Contact Phone': `${dataRef.current.contactPhone ?? '-'}`,
                'Primary Contact Email': `${dataRef.current.contactEmail ?? '-'}`,
                'Secondary Contact Name': `${dataRef.current.contactName2 ?? '-'}`,
                'Secondary Contact Phone': `${dataRef.current.contactPhone2 ?? '-'}`,
                'Secondary Contact Email': `${dataRef.current.contactEmail2 ?? '-'}`,
                'Optional Contact Name': `${dataRef.current.contactName3 ?? '-'}`,
                'Optional Contact Phone': `${dataRef.current.contactPhone3 ?? '-'}`,
                'Optional Contact Email': `${dataRef.current.contactEmail3 ?? '-'}`,
                'Enabled for Tier III Reporting': `${dataRef.current.isToggleAllow ? 'Yes' : 'No'}`,
            },
        ];
        
        let primeInfoData: any = null;
        try {
            
            if(isUserPrime) {
                primeInfoData = XLSX.utils.json_to_sheet(dataPrime);
            } else {
                primeInfoData = XLSX.utils.json_to_sheet(dataNonPrime);
            }
            for (const key in primeInfoData) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    const alignment = { ...centerAlignment, wrapText: true };
                    primeInfoData[key].s = {
                        fill: reportHeaderBackground,
                        alignment: alignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = leftAlignment;
                    

                    if(isUserPrime) {
                        if (
                            key.charAt(0) === 'C' ||
                            key.charAt(0) === 'D' ||
                            key.charAt(0) === 'E'
                        ) {
                            alignment = centerAlignment;
                        }
                    } else {
                        if (
                            key.charAt(0) === 'C' ||
                            key.charAt(0) === 'D' ||
                            key.charAt(0) === 'E' ||
                            key.charAt(0) === 'I' ||
                            key.charAt(0) === 'J' ||
                            key.charAt(0) === 'H'
                        ) {
                            alignment = centerAlignment;
                        }
                    }

                    primeInfoData[key].s = {
                        font: reportCellFont,
                        border: border,
                        alignment: alignment,
                    };
                }
            }
        } catch (e) {}
        if(isUserPrime) {
            primeInfoData['!cols'] = wscolsPrime;
        } else {
            primeInfoData['!cols'] = wscolsNonPrime;
        }

        const workbook = {
            Sheets: {
                prime_contact_info: primeInfoData,
            },
            SheetNames: ['prime_contact_info'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(excelBuffer, 'prime_contact_info.xlsx');
    };

    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setOpen(false);
            ToastSuccess(msg);
            await fetchData();
            setLoading(false);
            onReload && onReload();
        },
        [setOpen, setLoading]
    );

    const handleOpen = useCallback(async () => {
        const userId = localStorage.getItem('prime_id');
        const params = { vendor_id: userId };
        const datann = await actions.adminPrimeListGET(params);
        console.log('datann', datann)
        setOpen(true);
    }, [setOpen]);

    return (
        <>
            <ContractorInfoView
                handleEdit={handleEdit}
                handleOpen={handleOpen}
                isAdmin={isUserAdmin}
                infoData={state?.list?.data || { data: {} }}
                loading={loading}
                isPrime={isUserPrime}
                handleDownload={handleDownload}
            />
            {open && (
                <ContractorUpdateForm
                    isOpen={open}
                    handleClose={handleClose}
                    handleSuccess={handleSuccess}
                    formRef={formRef}
                    handleDiscard={handleDiscard}
                    contractorData={
                        isUserAdmin ? { list: state.adminPrimeList } : state
                    }
                />
            )}
        </>
    );
};

export default ContractorInfo;

import yup from '../libraries/validator.library';

export const POCreationValidationMonthSchema = yup.object({
    id: yup.number(),
    validationMonth: yup.number(),
    activeMonth: yup.boolean()

})

export const POCreationValidationMonthPayloadSchema = yup
    .object({
        data: yup.array().of(POCreationValidationMonthSchema).defined(),
        status: yup.boolean(),
    })
    .defined();

export const POGuardRailssAuditLogPayloadSchema = yup.object({
    validationMonth: yup.number(),
    loggedAt: yup.date(),
    endDate: yup.date(),
    emailSendStatus: yup.boolean(),
    emailBody: yup.string(),
    emailSubject: yup.string().nullable(),
    firstName: yup.string(),
    lastName: yup.string(),
});

export const POGuardRailsAuditLogResponseSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(POGuardRailssAuditLogPayloadSchema),
    status: yup.boolean(),
    total_rows: yup.number(),
    total_pages: yup.number(),
})

export const POValidationBatchJobStatusSchema = yup.object({
    data: yup.object({
        isInProgress: yup.boolean(),
        isRollBackNOverTriggered:yup.boolean(),
        isPoAdhocTriggered:yup.boolean()
    }),
    status: yup.boolean(),
    message: yup.string(),
});

export type IPOValidationBatchJobStatusSchema = yup.InferType<
typeof POValidationBatchJobStatusSchema
>;
import LocalizedStrings from 'react-localization';
import DictionaryLang from './en/dictionary.language';
import LabelLang from './en/label.language';
import MessageLang from './en/message.language';
import TitleLang from './en/title.language';
import NoteLang from './en/note.language';

const LangConsts = {
  en: {
    ...DictionaryLang,
    ...LabelLang,
    ...MessageLang,
    ...TitleLang,
    ...NoteLang,
  } as const,
} as const;

const Lang = new LocalizedStrings(LangConsts);

export default Lang;

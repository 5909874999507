import React from 'react';
import { ATMContainer } from 'shared-it-appmod-ui';
import ResetPasswordView from './reset-password.view';

const ResetPassword: React.FC = () => {
    return (
        <ATMContainer className="fluid">
            <ResetPasswordView />
        </ATMContainer>
    );
};

export default ResetPassword;

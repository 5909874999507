import React, { useCallback, useEffect, useState } from 'react';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { ATMButton, ATMSelect } from 'shared-it-appmod-ui';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import Lang from 'src/libraries/language';
import { FunctionalityDisableText } from '../../constant/message-constant.component';
import POGuardrailsAuditLog from './audit-log/po-guardrails-audit-log.component';

const POGuardrailsConfig: React.FC = () => {

  const { state, actions } = usePOGuardrailsContext();
  const [isConfirm, setIsConfirm] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [fetchedSelectedMonth, setFetchedSelectedMonth] = useState<number>(0);
  const [poMonthList, setPOMonthList] = useState<any>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [openAuditLogs, setOpenAuditLogs] = useState<boolean>(false);

  const { state: schFileState, actions: schFileActions } = useContractorContext();
  const { state: poState, actions: poActions } = usePOGuardrailsContext();

  const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
  const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
  const [isDisableFunctionality, setDisableFunctionality] = useState(false);

  const checkSCHFileStatus = async () => {
    const response = await schFileActions.schFileStatusGet();
    if (response.payload?.status) {
      setSCHFileRunnig(response.payload?.data.isInProgress)
    }
  };

  const checkPOValidationBatchJobStatus = async () => {
    const response = await poActions.getPOValidationBatchJobStatus();
    if (response.payload?.status) {
      setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
    }
  };

  useEffect(() => {
    if (isSCHFileRunning || isPOValidationBatchJobRunning) {
      setDisableFunctionality(true)
    } else {
      setDisableFunctionality(false)
    }
  }, [isSCHFileRunning, isPOValidationBatchJobRunning])


  useEffect(() => {
    checkSCHFileStatus();
    checkPOValidationBatchJobStatus();
    fetchData();
  }, [])

  useEffect(() => {
    if (selectedMonth === fetchedSelectedMonth) {
      setDisableSave(true)
    } else {
      setDisableSave(false)
    }
  }, [fetchedSelectedMonth])

  const fetchData = async () => {
    setLoading(true);
    const response = await actions.getPOCreationValidationMonth();
    const data = response?.payload?.data;


    const poMonthListOption = data.map((poMonth, index) => {
      return { key: index, text: poMonth.validationMonth, value: poMonth.id };
    });

    // Set the PO month list
    setPOMonthList(poMonthListOption);

    // Find the active month from the data
    const activeMonth = data?.filter((poMonth) => poMonth.activeMonth === true)[0];
    setSelectedMonth(activeMonth.id);
    setFetchedSelectedMonth(activeMonth.id)
    setLoading(false);
  }

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const params = {
      "id": selectedMonth
    }
    await actions.updatePOCreationValidationMonth(params);
    setLoading(false)
    setIsConfirm(false);
    ToastSuccess(Lang.LBL_PO_CREATION_MONTH_UPDATE_SUCCESSFUL)
    fetchData();
  }, [actions, selectedMonth]);


  const handleChange = (value) => {
    setSelectedMonth(value)
    if (value === fetchedSelectedMonth) {
      setDisableSave(true)
    } else {
      setDisableSave(false)
    }
  }

  return isDisableFunctionality ? (<div style={{
    display: 'flex',
    width: '100%',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center'
  }
  } >
    <FunctionalityDisableText />
  </div >) : (<div>
    {openAuditLogs && <POGuardrailsAuditLog openAuditLogs={openAuditLogs} handleOnDiscardAuditLogs={() => setOpenAuditLogs(false)} />}
    {isConfirm && (
      <Confirm

        open={isConfirm}
        size="tiny"
        content={Lang.LBL_CONFIRM_CHANGE_CONFIG}
        loading={loading}
        btn_text={Lang.LBL_CONFIRM_BTN}
        header={Lang.TTL_CONFIRMATION}
        onCancel={() => setIsConfirm(false)}
        onConfirm={() => handleSubmit()}
      />
    )}

    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <ATMButton
        className="ui primary button"
        onClick={() => setOpenAuditLogs(true)}
      >
        {Lang.LBL_AUDIT_LOG}
      </ATMButton>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', paddingTop: 10 }}>
      <div>
        {Lang.LBL_SELECT_PO_CREATION_MONTH}</div>
      <ATMSelect
        disabled={loading}
        value={selectedMonth}
        options={poMonthList}
        onChange={(e, v) => handleChange(v?.value)}
      />
      <ATMButton
        className="ui primary button"
        loading={loading}
        onClick={() => setIsConfirm(true)}
        disabled={disableSave}
      >
        {Lang.LBL_SAVE}
      </ATMButton>
    </div>
  </div>);


}

export default POGuardrailsConfig
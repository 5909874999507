import React, { useEffect, useState, useCallback } from 'react';
import UpdateSpendView from './update-spend.view';
import { useSpendContext } from 'src/contexts/spend.context';
import { ATMContainer } from 'shared-it-appmod-ui';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import * as _ from 'lodash';
import { getSpendStatus } from 'src/selectors/spend.selector';
import { actionTypes } from 'src/ducks/spend.duck';
import UpdateSpendReportModalComponent from './update-spend-report-modal/update-spend-report-modal.component'
import {
    getUserID,
    isAdmin,
    isContact,
    isPrime,
} from 'src/libraries/users.library';
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { Card } from 'semantic-ui-react';
import { FunctionalityDisableText } from '../constant/message-constant.component';

const userId = getUserID();
const isJanuary = new Date().getMonth();
//const isJanuary = Math.floor(Math.random() * 10) + 1;

export const defaultParams = {
    year: Number(localStorage.getItem('reportYear')),
    //year: new Date().getMonth() == 0 ? (new Date().getFullYear() - 1) : new Date().getFullYear(),
    vendor_id: userId,
    month: new Date().getMonth() == 0 ? 12 : new Date().getMonth(),
    page: 1,
    contracts: '',
    subcontractors: '',
    utility: '',
    spend_amount_filter: '',
    isTier3SubContractorType: false,
};

export const handleReset: any = async (setParams, setLoading, setChanges) => {
    await setParams((prev) => {
        prev.year = Number(localStorage.getItem('reportYear'));
        prev.vendor_id = userId;
        return prev;
    });
    await setLoading(true);
    await setChanges([]);
    await setLoading(false);
    return;
};

export const handleSave: any = async (changes, updateData, setChanges) => {
    updateData(changes);
    setChanges([]);
    return;
};

export const handleSearch: any = async (
    setChanges,
    params,
    fetchData,
    utility,
    fetchContracts,
    setSearch,
    utility_id,
    tier
) => {
    setChanges([]);
    setSearch(true);
    params.page = 1;
    params.vendor_id = userId;
    params.utility = utility_id;
    let isTier3SubContractorType = false;
    if (tier === 'tier2') {
        isTier3SubContractorType = false;
    }
    else {
        isTier3SubContractorType = true;
    }
    params.isTier3SubContractorType = isTier3SubContractorType;
    fetchData(params);
    fetchContracts(utility_id);
    return;
};

export const handleSubcontractorChange: any = async (subs, setParams) => {
    await setParams((prev) => {
        prev.subcontractors = subs;
        return prev;
    });
};

export const handlePOChange: any = async (contracts, setParams) => {
    await setParams((prev) => {
        prev.contracts = contracts;
        return prev;
    });
};


export const handleTierChange: any = async (tier, setParams) => {
    await setParams((prev) => {
        let isTier3SubContractorType = false;
        if (tier === 'tier2') {
            isTier3SubContractorType = false;
        } else {
            isTier3SubContractorType = true;
        }
        prev.isTier3SubContractorType = isTier3SubContractorType;
        return prev;
    });
};



export const handleUtilsChange: any = async (utils, setParams, setUtChanges, currentApiYear) => {
    await setParams((prev) => {
        prev.utility = utils;
        if (isJanuary == 0) {
            // prev.month = 12;
            // prev.year = (new Date().getFullYear() - 1);
            if (currentApiYear == new Date().getFullYear()) {
                prev.month = 1;
                prev.year = (new Date().getFullYear());
            } else {
                prev.month = 12;
                prev.year = (new Date().getFullYear() - 1);
            }
        } else {
            prev.month = new Date().getMonth();
        }
        return prev;
    });
};
export const handleSpendAmtChange: any = async (spend, setParams) => {
    await setParams((prev) => {
        prev.spend_amount_filter = spend;
        return prev;
    });
};

export const handleMonthChange: any = async (
    month,
    setParams,
    setChanges,
    fetchData,
    params
) => {
    await setParams((prev) => {
        prev.month = month;
        return prev;
    });
    await setChanges([]);
    params.page = 1;
    params.vendor_id = userId;
    await fetchData(params);
};

export const handleYearChange: any = async (
    year,
    setParams,
    setChanges,
    fetchData,
    params
) => {
    await setParams((prev) => {
        prev.year = year;
        return prev;
    });
    await setChanges([]);
    params.page = 1;
    params.vendor_id = userId;
    await fetchData(params);
};

export const handlePageChange: any = async (
    page,
    setParams,
    fetchData,
    params
) => {
    if (page === 'prev') {
        await setParams((prev) => {
            prev.page = prev.page - 1;
            return prev;
        });
    } else if (page === 'next') {
        await setParams((prev) => {
            prev.page = prev.page + 1;
            return prev;
        });
    }
    params.vendor_id = userId;
    await fetchData(params);
};

export const changeObject: any = (obj, change) => {
    return {
        contract_number: String(obj?.j?.contract_number),
        new_spend_amount: Number(change),
        new_spend_amount_new: (change),
        subcontractor_id: String(obj?.i?.subcontractor_id),
    };
};

export const handleCellChange: any = async (
    obj,
    change,
    changes,
    setChanges
) => {
    let found = false;
    const changes1 = JSON.parse(JSON.stringify(changes));
    changes1.forEach((i) => {
        if (
            i['contract_number'] === String(obj?.j?.contract_number) &&
            i['subcontractor_id'] === String(obj?.i?.subcontractor_id)
        ) {
            i['new_spend_amount'] = Number(change);
            i['new_spend_amount_new'] = change;
            found = true;
        }
    });

    if (!found) {
        await setChanges([...changes, changeObject(obj, change)]);
    } else {
        setChanges([...changes1]);
    }
    return;
};

interface IChanges {
    contract_number: string;
    subcontractor_id: string;
    new_spend_amount: string;
}

const UpdateSpend: React.FC = () => {
    const { state, actions } = useSpendContext();
    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const [params, setParams] = useState(_.cloneDeep(defaultParams));
    const [changes, setChanges] = useState<IChanges[]>([]);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setShowToast] = useState(false);
    const [utChanges, setUtChanges] = useState(true);
    const [utility, setUtility] = useState('ALL');

    const [isTierChange, setIsTierChange] = useState(false);
    const [isTier2, setIsTier2] = useState(true);
    const [tier, setTier] = useState('');
    const [open, setOpen] = useState(false);
    const [isBlockContent, setIsBlockContent] = useState(false);
    const [currentApiYear, setCurrentApiYear] = useState(Number(localStorage.getItem('reportYear')));
    const isUserPrime = isPrime();

    useEffect(() => {
        isTier2 ? setTier('tier2') : setTier('');
    }, [isTier2])

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])



    const fetchData = useCallback(
        async (newParams) => {
            fetchYear();
            checkTier();
            const userId = getUserID();
            console.log('tier', tier)
            if (newParams) {
                newParams.vendor_id = userId;
                newParams.year = currentApiYear;
                newParams.year = currentApiYear;
                let isTier3SubContractorType = false;
                if (tier === '' || tier === 'tier2') {
                    isTier3SubContractorType = false;
                } else {
                    isTier3SubContractorType = true;
                }
                newParams.isTier3SubContractorType = isTier3SubContractorType;

                await actions.listGET(newParams);
            } else {
                params.vendor_id = userId;
                params.year = currentApiYear;
                await actions.listGET(params);
            }
        },
        [actions, currentApiYear, tier]
    );

    const checkTier = async () => {
        const userId = getUserID();
        const params = { vendor_id: userId };
        const data = await actions.primeContractorGet(params);
        //isTier2, setIsTier2
        //if(data.payload.data[0])
        if (data.payload.data.isToggleAllow) {
            setIsTier2(false)
        }
        return data;
    }

    const fetchYear = async () => {
        const data = await actions.reportingyearsGet();
        localStorage.setItem('reportYearUpdate', data.payload.data[0].report_year)
        if (localStorage.getItem('reportYear') != localStorage.getItem('reportYearUpdate')) {
            localStorage.setItem('reportYear', data.payload.data[0].report_year)
            setCurrentApiYear(data.payload.data[0].report_year);
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    }

    const updateData = useCallback(
        async (obj) => {
            const userId = getUserID();
            if (obj.length > 0) {
                let isTier3SubContractorType = false;
                if (tier === 'tier2') {
                    isTier3SubContractorType = false;
                } else {
                    isTier3SubContractorType = true;
                }
                const updateObj = {
                    common: {
                        vendor_id: userId,
                        spend_year: params.year,
                        month: params.month,
                        isTier3SubContractorType: isTier3SubContractorType
                    },
                    update_data: obj,
                };
                const response: any = await actions.listUPDATE(updateObj);
                if (response?.payload?.status) {
                    ToastSuccess('Spend data has been saved successfully.');
                    fetchData(params);
                }
            } else {
                ToastSuccess('Spend data has been saved successfully.');
            }
        },
        [actions, params, tier]
    );

    const handleOnDiscard = () => {
        setOpen(false);
    };

    const fetchSubcontractors = useCallback(async () => {
        const userId = getUserID();
        let isTier3SubContractorType = false;
        if (tier === 'tier2') {
            isTier3SubContractorType = false;
        } else {
            isTier3SubContractorType = true;
        }
        await actions.listDistinctSUBCONTRACTORS({
            vendor_id: userId,
            isTier3SubContractorType: isTier3SubContractorType

        });
    }, [tier, actions]);

    const fetchContracts = useCallback(
        async (utility) => {
            const userId = getUserID();
            await actions.listCONTRACTS({
                vendor_id: userId,
                utility: utility,
                hide: 0
            });
        },
        [actions, tier]
    );
    const checkIsBlock = useCallback(
        async () => {
            const data = await actions.blockAndUnblockExternalUserGet();
            setIsBlockContent(data.payload?.data[0]?.isBlockedExternalUser)
        },
        [actions]
    );

    useEffect(() => {

        defaultParams.vendor_id = getUserID();
        if (isJanuary == 0) {
            if (currentApiYear == new Date().getFullYear()) {
                defaultParams.month = 1;
                defaultParams.year = (new Date().getFullYear());
            } else {
                defaultParams.month = 12;
                defaultParams.year = (new Date().getFullYear() - 1);
            }
        }
        checkIsBlock();
        fetchData(defaultParams);
        // fetchSubcontractors();
        if (!isUserPrime) {
            fetchContracts(utility);
        }
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus()
    }, [actions]);

    useEffect(() => {
        const isUserPrime = isPrime();
        if (isUserPrime && typeof utility === 'number') {
            defaultParams.utility = utility;
            if (isJanuary == 0) {
                if (currentApiYear == new Date().getFullYear()) {
                    defaultParams.month = 1;
                    defaultParams.year = (new Date().getFullYear());
                } else {
                    defaultParams.month = 12;
                    defaultParams.year = (new Date().getFullYear() - 1);
                }
            }
            fetchData(defaultParams);
            fetchContracts(utility);
        }
    }, [utility]);



    const handleNoSpendReportModal = () => {
        setOpen(true);
    };

    const handleResetCall = () =>
        handleReset(setParams, setLoading, setChanges);

    const handleSaveCall = () => handleSave(changes, updateData, setChanges);

    const handleSearchCall = (utility_id) => {
        setUtility(utility_id)
        handleSearch(setChanges, params, fetchData, utility, fetchContracts, setSearch, utility_id, tier);
    }

    const handleSubcontractorChangeCall = (subs) =>
        handleSubcontractorChange(subs, setParams);

    const handlePOChangeCall = (contracts) =>
        handlePOChange(contracts, setParams);

    const handleUtilsChangeCall = (utils) => {
        fetchSubcontractors();
        setUtility(utils)
        handleUtilsChange(utils, setParams, setUtChanges, currentApiYear);
        setIsTierChange(false)
    };

    const handleTierChangeCall = (tier) => {
        setTier(tier)
        handleTierChange(tier, setParams);
        setIsTierChange(true)
    };

    const handleSpendAmtChangeCall = (spend) => {
        handleSpendAmtChange(spend, setParams);
    };
    const handleMonthChangeCall = (month) =>
        handleMonthChange(month, setParams, setChanges, fetchData, params);

    const handleYearChangeCall = (year) =>
        handleYearChange(year, setParams, setChanges, fetchData, params);


    const handlePageChangeCall = (page) =>
        handlePageChange(page, setParams, fetchData, params);

    const handleCellChangeCall = (obj, change) =>
        handleCellChange(obj, change, changes, setChanges);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    return isDisableFunctionality && isUserPrime ? (<Card style={{
        display: 'flex',
        marginTop: '100px',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </Card >) : (
        <>
        <ATMContainer fluid>
            <UpdateSpendView
                tier={tier}
                toastOpen={toastOpen}
                success={false}
                toastMessage={''}
                error={false}
                setShowToast={setShowToast}
                handleReset={handleResetCall}
                handleSave={handleSaveCall}
                handleSearch={handleSearchCall}
                handleSubcontractorChange={handleSubcontractorChangeCall}
                handlePOChange={handlePOChangeCall}
                handleUtilsChange={handleUtilsChangeCall}
                handleTierChange={handleTierChangeCall}
                handleSpendAmtChange={handleSpendAmtChangeCall}
                handleMonthChange={handleMonthChangeCall}
                handlePageChange={handlePageChangeCall}
                handleCellChange={handleCellChangeCall}
                handleNoSpendReportModal={handleNoSpendReportModal}
                changes={changes}
                utChanges={utChanges}
                page={params.page}
                search={search}
                loading={
                    loading ||
                    getSpendStatus(state, actionTypes.SPEND_LIST_FETCH)
                        .fetching
                }
                handleYearChange={handleYearChangeCall}
                isJanuary={isJanuary}
                isBlockContent={isBlockContent}
                currentApiYear={currentApiYear}
                isTier2={isTier2}
                isTierChange={isTierChange}
            />
        </ATMContainer>
        {open && (
            <UpdateSpendReportModalComponent
                open={open}
                data={changes}
                handleOnDiscard={handleOnDiscard}

            />
        )}
    </>);

};

export default UpdateSpend;

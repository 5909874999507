export default {
    PLACEHOLDER: {
        SUBCONTRACTOR_ENTER_NUMBER: 'Enter Number',
        SUBCONTRACTOR_ENTER_NAME: 'Enter Name',
        SUBCONTRACTOR_ENTER_ADDRESS: 'Enter Address',
        SUBCONTRACTOR_ENTER_CITY: 'Enter City',
        SUBCONTRACTOR_ENTER_STATE: 'Enter State',
        SUBCONTRACTOR_ENTER_ZIP: 'Enter Zip',
        SUBCONTRACTOR_ENTER_PHONE_NUMBER: 'Enter Phone Number',
        SUBCONTRACTOR_SELECT_GENDER: 'Select Gender',
        SUBCONTRACTOR_SELECT_NATIONALITY: 'Select Nationality',
        SUBCONTRACTOR_SELECT_ETHNICITY: 'Select Ethnicity',
        SUBCONTRACTOR_SELECT_CATEGORY: 'Select Diversity Category',
        SUBCONTRACTOR_SELECT_AGENCY_NAME: 'Select Agency Name',
        SUBCONTRACTOR_SELECT_DATE: 'Select Date',
        SUBCONTRACTOR_ENTER_CONTACT_NAME: 'Enter Contact Name',
        SUBCONTRACTOR_ENTER_EMAIL: 'Enter Email',
        VIEW_ALL_SUBS_ENTER_VON: 'Enter VON',
        VIEW_ALL_SUBS_ENTER_SUBCONTRACTOR_NAME: 'Enter Subcontractor Name',
        VIEW_ALL_SUBS_ENTER_PRIME_NAME: 'Enter Prime Name',
        VIEW_ALL_SUBS_ENTER_SAP_ID: 'Enter SAP ID',
    },
    LBL_DELETE_COLUMN: 'Delete',
    LBL_ACTION: 'Action',
    LBL_ACTIONS: 'Actions',
    LBL_ADMIN_ADD: 'Add Admin User',
    LBL_ADMIN_EMPLOYEE_EMAIL: 'Email',
    LBL_ADMIN_EMPLOYEE_NAME: 'Name',
    LBL_ADMIN_GRANTED_ADMIN_ACCESS_BY: 'Granted Admin Access By',
    LBL_ADMIN_GRANTED_ADMIN_ON: 'Granted Admin On',
    LBL_BCC: 'BCC',
    LBL_BODY: 'Body',
    LBL_CANCEL: 'Cancel',
    LBL_CC: 'CC',
    LBL_CLEAR: 'Clear',
    LBL_NOTE: 'Note',
    LBL_DOWNLOAD: 'Download',
    LBL_CLOSE: 'Close',
    LBL_COMPLETE: 'Complete',
    LBL_DONE: 'Done',
    LBL_DATE_RANGE: 'Date Range',
    LBL_EDIT: 'Edit',
    LBL_EMAIL: 'Email',
    LBL_EMAIL_SENT: 'Email Sent',
    LBL_ENTER: 'Enter',
    LBL_INACTIVE: 'Inactive',
    LBL_ACTIVE: 'Active',
    LBL_OKAY: 'OK',
    LBL_REMOVE_ADMIN_ACCESS: 'Remove Admin Access',
    LBL_RESULT: 'Result',
    LBL_RUN_NOW: 'Run now',
    LBL_RUNNING_TASKS: 'Running Task..',
    LBL_GENERATE: 'Generate',
    LBL_SAVE: 'Save',
    LBL_RESET: 'Reset',
    LBL_NO_SPEND_TO_REPORT: 'No spend to report?',
    LBL_UPDATE: 'Update',
    LBL_SAVE_CUSTOM_EMAIL: 'Save Custom Email',
    LBL_SEARCH: 'Search',
    LBL_SEARCH_PERSON: 'Lookup person',
    LBL_SEND: 'Send',
    LBL_RESEND: 'Resend',
    LBL_SEND_CUSTOM_EMAIL: 'Send Custom Email',
    LBL_SUBJECT: 'Subject',
    LBL_SUBMIT: 'Submit',
    LBL_EMAIL_ADDRESS: 'Email Address',
    LBL_LOGIN_ID: 'Login ID',
    LBL_PASSWORD: 'Password',
    LBL_NEW_PASSWORD: 'New Password',
    LBL_MASS_MAIL_TO: 'TO:',
    LBL_MASS_MAIL_MISSING_DATA_FOR: 'Missing Data For',
    LBL_MASS_MAIL_STATE: 'State',
    LBL_MASS_MAIL_CITY: 'City',
    LBL_MASS_MAIL_BY_SUPPLIER: 'Select Supplier Diversity Contacts',
    LBL_MASS_MAIL_PLACEHOLDER_SELECT: 'Select',
    LBL_MASS_MAIL_FROM: 'FROM:',
    LBL_MASS_MAIL_CC: 'CC',
    LBL_MASS_MAIL_SUBJECT: 'SUBJECT',
    LBL_MASS_MAIL_BODY: 'BODY',
    LBL_MASS_MAIL_ATTACH_FILE: 'Attach File',
    LBL_CONFIRM_PASSWORD: 'Confirm Password',
    LBL_PLACEHOLDER_EMAIL_ADDRESS: 'Enter Email Address',
    LBL_PLACEHOLDER_LOGIN_ID: 'Login ID',
    LBL_PLACEHOLDER_NEW_PASSWORD: 'Enter New Password',
    LBL_PLACEHOLDER_RE_ENTER_PASSWORD: 'Re-enter Password',
    LBL_BTN_SIGNIN: 'Sign In',
    LBL_BTN_LOGIN: 'Login',
    LBL_BTN_RESET_PASSWORD: 'Reset Password',
    LBL_RESET_PASSWORD_FOR: 'Reset Password for',
    LBL_MONDATORY_FIELD: 'Indicates mandatory field',
    LBL_MASS_UPDATE_HEADER: 'Mass Update',
    LBL_UPDATES_CONFIRM:
        'Please confirm updates with cancel and confirm options',
    LBL_PLACEHOLDER_PASSWORD: 'Password',
    LBL_SIGN_DESC:
        'Please review the subcontracting reporting documentation before proceeding to ensure you have the required data available and you understand our subcontracting reporting criteria.',
    LBL_PROVIDED_INFO_TRUE:
        'I hereby certify that the information provided is true and correct',
    LBL_FORGOT_PASSWORD: 'Forgot Password?',
    LBL_SIGN_FOOTER_FIRST: 'Use "Utility Login" if you are an employee of',
    LBL_SIGN_FOOTER_SECOND:
        'San Diego Gas & Electric or Southern California Gas Company',
    LBL_SPEND_SUMMARY_SUBCONTRACTOR: 'Subcontractor',
    LBL_SPEND_SUMMARY_YEAR: 'Year :',
    LBL_SPEND_SUMMARY_VERIFIED_SPEND: 'Verified Spend',
    LBL_SPEND_SUMMARY_UNVERIFIED_SPEND: 'Non Verified Spend',
    LBL_SPEND_VERIFIED_SPEND: 'Verified Spend',
    LBL_SPEND_UNVERIFIED_SPEND: 'Unverified Spend',
    LBL_CONTRACTOR_INFO_ACCESS_GENERAL_CONTRACTING_INC:
        'ACCESS GENERAL CONTRACTING INC',
    LBL_EDIT_UPDATE_CONTRACT: 'Edit / Update Contract',
    LBL_ADD_CONTRACT: 'Add Contract',
    LBL_NEXT: 'Next',
    LBL_PREV: 'Previous',
    SPEND_CELL_FLOAT_ERROR:
        'Please enter figures as whole numbers only. Do not use any formatting characters, including dollar signs,decimal points or commas.',

    LBL_CONTRACTOR_COMPANY_NAME: 'Company Name',
    LBL_CONTRACTOR_PRIME_NAME: 'Prime Name',
    LBL_SUBCONTRACTOR_CREATE_SUBCONTRACTOR_NAME: 'Subcontractor Name',

    LBL_CONTRACTOR_LOGIN_ID: 'Login ID',
    LBL_CONTRACTOR_SAP_ID: 'SAP ID',
    LBL_CONTRACTOR_SUPPLIER_CODE: 'Supplier Code',
    LBL_CONTRACTOR_TIER_III_ENABLED: 'Enabled for Tier III Reporting',
    LBL_CONTRACTOR_ADDRESS: 'Address',
    LBL_CONTRACTOR_CITY: 'City',
    LBL_CONTRACTOR_STATE: 'State',
    LBL_CONTRACTOR_ZIP: 'Zip',
    LBL_CONTRACTOR_PRIMARY_NAME: 'Primary Name',
    LBL_CONTRACTOR_PRIMARY_PHONE: 'Primary Phone',
    LBL_CONTRACTOR_PRIMARY_EMAIL: 'Primary Email',
    LBL_CONTRACTS_PURCHASE_RELEASE_ORDER_NUMBER:
        'Purchase/Release Order Number',
    CONTRACTS_ENTER_PROJECT_DESCRIPTION: 'Enter Project Description',

    LBL_CONTRACTOR_ALTERNAME_NAME: 'Secondary Name',
    LBL_CONTRACTOR_ALTERNAME_PHONE: 'Secondary Phone',
    LBL_CONTRACTOR_ALTERNAME_EMAIL: 'Secondary Email',

    LBL_CONTRACTOR_ADDITIONAL_NAME: 'Optional Name',
    LBL_CONTRACTOR_ADDITIONAL_PHONE: 'Optional Phone',
    LBL_CONTRACTOR_ADDITIONAL_EMAIL: 'Optional Email',
    LBL_CONFIRM_BTN: 'Confirm',
    LBL_CONFIRMATION: 'Confirmation',
    LBL_DELETE: 'Yes, Delete',

    LBL_CONTRACTS: 'Contracts',
    LBL_CONTRACTS_PURCHASE_RELEASE_ORDER: 'Purchase/Release Order',
    LBL_CONTRACTS_NAME: 'Company Name',
    LBL_CONTRACTS_UTILITY_COMPANY_NAME: 'Utility Company Name',
    LBL_CONTRACTS_PROJECT_DESC: 'Project Description',
    LBL_CONTRACTS_ADDED_ON: 'Added On',
    LBL_CONTRACTS_STATUS: 'Status',
    LBL_CONTRACTS_EDIT: 'Edit',
    LBL_CONTRACTS_Activae: 'Edit',
    LBL_CONTRACTS_ACTIVATE_CONTRACT: 'Activate Contract',
    LBL_CONTRACTS_INACTIVATE_CONTRACT: 'View on Update Spend Screen?',

    LBL_SUBCONTRACTORS: 'Subcontractors',
    LBL_SUBCONTRACTOR: 'Subcontractor',
    LBL_SUBCONTRACTOR_ADD: 'Add Subcontractor',
    LBL_SUBCONTRACTOR_ENTER_NEW_SUBCONTRACTOR: 'Enter a New Subcontractor',
    LBL_SUBCONTRACTORS_CITY: 'City',
    LBL_SUBCONTRACTOR_STATE: 'State',
    LBL_SUBCONTRACTOR_ZIP: 'Zip',
    LBL_SUBCONTRACTOR_VERIFICATION_EXPIRATION_STATUS:
        'Verification {0} Expiration Status',
    LBL_SUBCONTRACTOR_STATUS: 'Status',
    LBL_SUBCONTRACTOR_REASON: 'Inactivation Reason',
    LBL_SUBCONTRACTOR_TIER_II: 'Tier II',
    LBL_SUBCONTRACTOR_TIER_III: 'Tier III',
    LBL_SUBCONTRACTOR_BOTH_TIER_II_III: 'Both Tier II & Tier III',
    LBL_SUBCONTRACTOR_INACTIVATION_REASON: 'Reason',
    LBL_SUBCONTRACTOR_ACTION: 'Action',
    LBL_SUBCONTRACTOR_ACTIVATE: 'Activate Subcontractor',
    LBL_SUBCONTRACTOR_INACTIVATE: 'Inactivate Subcontractor',
    LBL_SUBCONTRACTOR_SELECT: 'Select',
    LBL_SUBCONTRACTOR_ADDRESS: 'Address',
    LBL_SUBCONTRACTOR_VIEW: 'Edit',
    LBL_SUBCONTRACTOR_ADD_SELECTED_VIEW: 'View',
    LBL_SUBCONTRACTOR_FILTER_NAME_BEGIN_WITH: 'Subcontractor Name Begins With',
    LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS: 'Subcontractor Name Contains',
    LBL_SUBCONTRACTOR_FILTER_LOCATED_IN_THE_STATE: 'Located In This State',
    LBL_SUBCONTRACTOR_FILTER_VERIFICATION_EXP_STATUS:
        'Verification Expiration Status',
    LBL_SUBCONTRACTOR_FILTER_SHOW_ALL_SUBCONTRACTORS: 'Show All Subcontractors',
    LBL_SUBCONTRACTOR_FILTER_SELECT: 'Select',
    LBL_SUBCONTRACTOR_FILTER_ENTER: 'Enter',
    LBL_SUBCONTRACTOR_CONTACT_NAME: 'Contact Name',
    LBL_SUBCONTRACTOR_CONTACT_NUMBER: 'Contact Number',
    LBL_SUBCONTRACTOR_CREATE_CONTACT_PHONE_NUMBER: 'Contact Phone Number',
    LBL_SUBCONTRACTOR_CONTACT_EMAIL: 'Contact Email',
    LBL_SUBCONTRACTOR_SAP_ID: 'SAP ID',
    LBL_SUBCONTRACTOR_OWNER_NAME: 'Owner Name',
    LBL_SUBCONTRACTOR_VERIFICATION_AGENCY: 'Verification Agency',
    LBL_SUBCONTRACTOR_PHONE_NUMBER: 'Phone Number',
    LBL_SUBCONTRACTOR_VERIFICATION_NUMBER: 'Verification Number',
    LBL_SUBCONTRACTOR_TOTAL_SPEND_AT_RISK: 'Total Spend at Risk',
    LBL_SUBCONTRACTOR_ETHINICITY: 'Ethnicity',
    LBL_SUBCONTRACTOR_CATEGORY: 'Diversity Category',
    LBL_SUBCONTRACTOR_VERIFICATION_EXP_DATE: 'Verification Exp. Date',
    LBL_SUBCONTRACTOR_GENDER: 'Gender',
    LBL_SUBCONTRACTOR_LOOKUP: 'Lookup',
    LBL_SUBCONTRACTOR_AUTOFILL: 'Auto Fill',
    LBL_SUBCONTRACTOR_ON: 'On',
    LBL_SUBCONTRACTOR_OFF: 'Off',
    LBL_SUBCONTRACTOR_NATIONALITY_FEDARAL_DESIGNATION:
        'Nationality / Federal Designation',
    LBL_SUBCONTRACTOR_ENABLE_MANUAL_ENTRY: 'Enable Manual Entry',
    LBL_SUBCONTRACTOR_REASON_FOR: 'Reason for Inactivating Subcontractor',
    LBL_PRIME_HEADING: 'Primes and Contacts',
    LBL_PRIME_NAME_BEGINS_WITH: 'Prime Name begins with',
    LBL_PRIME_NAME_CONTAINS: 'Prime Name Contains',
    LBL_CONTACT_NAME_ADVISOR: 'Advisor Name Contains',
    LBL_CONTACT_NAME_CONTAINS: 'Contact Name Contains',
    LBL_PRIME_SDGE_ADVISER: 'Supplier Diversity Contact',
    LBL_PRIME_SOCALGAS_ADVISER: 'SoCalGas Adviser Contains',
    LBL_PRIME_STATUS: 'Prime Status',
    LBL_PRIME_ENTER: 'Enter',
    LBL_PRIME_SELECT: 'Select',
    LBL_CONTACT_PRIMES: 'Primes',
    LBL_ADD_PRIME: 'Add Prime',
    LBL_CONTACT_PRIMES_SAP_ID: 'SAP ID',
    LBL_CONTACT_PRIMES_BUSINESS_NAME: 'Prime',
    LBL_CONTACT_PRIMES_ADDRESS: 'Address',
    LBL_CONTACT_PRIMES_CITY: 'City',
    LBL_CONTACT_PRIMES_STATE: 'State',
    LBL_CONTACT_PRIMES_FAVOURITES: 'Favorites',
    LBL_CONTACT_PRIMES_STATUS: 'Status',
    LBL_CONTACT_PRIMES_FILTER_NAME_BEGIN_WITH: 'Prime Name Begins With',
    LBL_CONTACT_PRIMES_FILTER_SUBCONTRACTOR_TYPE: 'Subcontractor Type',
    LBL_CONTACT_PRIMES_FILTER_NAME_CONTAINS: 'Prime Name Contains',
    LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE: 'Located In This State',
    LBL_SUBCONTRACTOR_VERIFICATION_STATUS: 'Verification Expiration Status',
    LBL_CONTACT_PRIMES_FILTER_SAP_ID_CONTAINS: 'SAP ID Contains',
    LBL_CONTACT_PRIMES_DETAIL: 'Prime Details',
    LBL_EDIT_UPDATE_SAP_ID: 'SAP ID',
    LBL_EDIT_UPDATE_PRIME: 'Prime',
    LBL_EDIT_UPDATE_ADDRESS: 'Address',
    LBL_EDIT_UPDATE_CITY: 'City',
    LBL_EDIT_UPDATE_STATE: 'State',
    LBL_EDIT_UPDATE_FAVOURITE: 'Favorite',
    LBL_CONTACT_DATE_ADDED: 'Date Added to System',
    LBL_OLD_PASSWORD: 'Old Password',
    LBL_PLACEHOLDER_OLD_PASSWORD: 'Enter Old Password',
    LBL_MASS_UPDATE: 'Mass Update',

    LBL_ADMIN_ALLOW_AGREEMENTS: 'Allow Agreements',
    LBL_ADMIN_COMPANY_STATUS: 'Company Status',
    LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG:
        'Supplier Diversity Contact (SDG&E)',
    LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG:
        'Supplier Diversity Contact (SoCalGas)',
    LBL_CURRENT_SUPPLIER_DIVERSITY_CONTACT_SDG:
        'Current Supplier Diversity Contact (SDG&E)',
    LBL_NEW_SUPPLIER_DIVERSITY_CONTACT_SDG:
        'New Supplier Diversity Contact (SDG&E)',
    LBL_CURRENT_SUPPLIER_DIVERSITY_CONTACT_SCG:
        'Current Supplier Diversity Contact (SoCalGas)',
    LBL_NEW_SUPPLIER_DIVERSITY_CONTACT_SCG:
        'New Supplier Diversity Contact (SoCalGas)',
    LBL_CURRENT_CONTACT: 'Current Contact',
    LBL_NEW_CONTACT: 'New Contact',
    LBL_ADMIN_CONTACT1: 'Contact 1',
    LBL_ADMIN_CONTACT2: 'Contact 2',
    LBL_ADMIN_CONTACT3: 'Contact 3',
    LBL_SUPPLIER_DIVERSITY_CONTACT_UPDATE: 'Supplier Diversity Contact Update',
    LBL_CONTACT_UPDATE: 'Contact Update',
    LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_DETAILS:
        'Supplier Diversity Contact Details',
    LBL_UPDATE_SUBCONTRACTOR_DATA: 'Update Subcontractor Data',
    LBL_UPLOAD_SCH_FILE: 'Upload SCH File',
    LBL_CLEANING_IN_PROGRESS: 'Cleaning In Progress...',
    LBL_LOGIN_UTILITY_LOGIN: 'Utility Login',
    LBL_LOGIN_PRIME_LOGIN: 'Prime Login',
    LBL_LOGIN_LOGIN_WITH_COMPANY_ACCOUNT: 'Or Login with Company Account',
    LBL_LOGIN_LOGIN_WITH_EXTERNAL_ACCOUNT: 'Or Login with External Account',
    LBL_UPDATE_SAPID_SUBCONTRACTOR_NAME: 'Subcontractor',
    LBL_UPDATE_SAPID_VON: 'VON',
    LBL_UPDATE_SAPID_EXPIRY_DATE: 'Expiration Date',
    LBL_UPDATE_SAPID_SELECTEDBY_PRIME: 'Selected By Prime',
    LBL_UPDATE_SAPID_SPEND: 'Sub $ reported by Prime',
    LBL_UPDATE_SAPID: 'SAP ID',
    LBL_RESET_PASSWORD: 'Reset Password',
    LBL_RESET_MUST_MEET_PASSWORD_REQUIREMENT_PASSWORD:
        'Password must meet Password Requirements.',
    LBL_RESET_PASSWORD_REQUIREMENT_PASSWORD: 'Password Requirements',
    LBL_RESET_PASSWORD_CAN_NOT_BE_SAME_AS_LOGIN_ID:
        'Cannot be the same as the Login ID.',
    LBL_RESET_PASSWORD_ONE_UPPER_CASE:
        'Must contain at least one (1) uppercase letter.',
    LBL_RESET_PASSWORD_ONE_SPECIAL_CASE:
        'Must contain at least one (1) special character.',
    LBL_RESET_PASSWORD_ONE_NUMBER_CASE: 'Must contain at least one (1) number.',
    LBL_RESET_PASSWORD_MIN_EIGHT: 'Minimum length of eight (8) characters.',
    LBL_RESET_PASSWORD_CAN_NOT_BE_SAME_AS_LAST_FIVE_PASS:
        'New password cannot be the same as your last five (5) used passwords.',
    LBL_FORGOT_PASS_DESC:
        'Please enter your email address. We will send you password along with your login ID will be sent to your email address.',
    LBL_FORGOT_PASS_FOOTER_DESC:
        'If your email address has changed or if you are assuming the reporting responsibilities from another individual, please send an email to',
    LBL_SEND_NEW_NOTIFICATION: 'Send New Notification',

    LBL_MASS_MAIL_BY_SUPPLIER_CHECK: 'Supplier Diversity Contacts',
    LBL_MASS_MAIL_CONTACT_CHECK: 'Contacts',
    LBL_MASS_MAIL_ALL_CHECK: 'All',

    LBL_ADMIN_USER_CONFIG_ADD_NEW_USER: 'Add New User',
    LBL_ADMIN_USER_CONFIG_FIRST_NAME: 'First Name',
    LBL_ADMIN_USER_CONFIG_LAST_NAME: 'Last Name',
    LBL_ADMIN_USER_CONFIG_USER_TYPE: 'User Type',
    LBL_ADMIN_USER_CONFIG_ALIAS: 'Alias',
    LBL_ADMIN_USER_CONFIG_UTILITY: 'Utility',
    LBL_ADMIN_USER_CONFIG_INFO_USER_CONFIG: 'User Configuration',
    LBL_REPORT_BY_PURCHASE_ORDER_CONTRACT: 'Contract',
    LBL_CHANGE_TO_SPEND_AMOUNT_DIFFERENCE: 'Difference',
    LBL_PURCHASE_RELEASE_ORDER: 'Puchase/ Release Order',
    LBL_MASTER_AGGREMENT: 'Master Agreement',

    LBL_SUBCONTRACTOR_ADD_SELECTED_ALL: 'All',
    LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS: 'Next 60 days',
    LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE: '1-180 days past due',
    LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED: 'Verified',
    LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFICATION: 'Verification ',
    LBL_SUBCONTRACTOR_ADD_SELECTED_EXP_STATUS: 'Expiration Status',
    LBL_SUBCONTRACTOR_DUE_STATUS: 'Due Status',

    LBL_ADMIN_AGGREGATE_EXPORT_REPORT_YEAR: 'Report Year',
    LBL_ADMIN_AGGREGATE_EXPORT_MONTH_START: 'Month Start',
    LBL_ADMIN_AGGREGATE_EXPORT_MONTH_END: 'Month End',
    LBL_ADMIN_AGGREGATE_EXPORT_PRIME_NAME_BETWEEN: 'Prime Name Between',
    LBL_ADMIN_AGGREGATE_EXPORT_DOWNLOAD_REVIEW_EXCEL: 'Download Review Excel',
    LBL_ADMIN_AGGREGATE_EXPORT_DOWNLOAD_FINAL_EXCEL: 'Download Final Excel',


    LBL_ADMIN_ORIGINAL_AGGREGATE_DOWNLOAD_EXCEL: 'Download Original Aggregate Excel',
    LBL_ADMIN_LOAD_READY_DETAIL_DOWNLOAD_EXCEL: 'Download Load Ready Detail Excel',
    LBL_ADMIN_LOAD_READY_FINAL_DOWNLOAD_EXCEL: 'Download Load Ready Final Excel',
    LBL_ADMIN_LOAD_WORKING_DOWNLOAD_EXCEL: 'Download Load Working Excel',


    LBL_SPEND_SUMMARY_TOTAL_YTD: 'Total YTD',
    LBL_SPEND_SUMMARY_CURRENT: 'Current*',
    LBL_SPEND_SUMMARY_TOTAL: 'TOTAL',
    LBL_SPEND_SUMMARY_GTOTALVERIFIED: '$',

    LBL_SUBCONTRACTOR_ADD_SUBCONTRACTOR: 'Select view',

    LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE:
        'More than 180 days past due',
    LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH: 'Not Found in SCH',

    LBL_REPORT_MONTH_TOTALS: 'Month Totals',

    LBL_ROW_PER_PAGE: 'Rows per page:',

    LBL_REPORT_SELECT_REPORT: 'Select Report',
    LBL_REPORT_SUBCONTRACTORS_NEEDING_VERIFICATION_FOLLOW_UP:
        'Subcontractors Needing Verification Follow Up',
    LBL_REPORT_CHANGES_TO_SPEND_AMOUNTS: 'Changes to Spend Amounts',
    LBL_REPORT_SUBCONTRACTING_SPEND_REPORTING: 'Subcontracting Spend Reporting',
    LBL_REPORT_SUBCONTRACTING_BY_MONTH_BY_PRIME_CONTRACT_DETAILS:
        'Subcontracting by Month by Prime Contract Details',
    LBL_REPORT_SUBCONTRACTING_BY_MONTH_SUMMARY_REPORT:
        'Subcontracting by Month Summary Report',
    LBL_REPORT_SUBCONTRACTING_BY_MONTH_SUMMARY_REPORT_TIER_THREE:
        'Subcontracting by Month Summary Report Tier III',
    LBL_REPORT_SUBCONTRACTING_BY_CONTRACT: 'Subcontracting by Contract',
    LBL_REPORT_PRIME_SUBCONTRACTING_SUMMARY: 'Prime Subcontracting Summary',
    LBL_REPORT_PRIME_SUBCONTRACTING_SUMMARY_TIER_III: 'Prime Subcontracting Summary Tier III',
    LBL_REPORT_PRIME_SUBCONTRACTING_DETAILS: 'Prime Subcontracting Details',

    LBL_SUBCONTRATOR_VERIFICATION_EXP_DATE_REQUIRED_FIELD:
        'Verification Exp. Date is required field',
    LBL_EMAIL_TEMPLATES: 'Email Templates',
    LBL_ADD: 'Add',
    LBL_VIEW_ALL_SUBS_VON: 'VON',
    LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME: 'Subcontractor Name',
    LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS:
        'Subcontractor Name Contains',
    LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_STATUS: 'Subcontractor Status',
    LBL_VIEW_ALL_SUBS_STATUS: 'Status',
    LBL_ZIP: 'Zip',
    LBL_GENDER: 'Gender',
    LBL_DOING_BUSINESS_AS: 'Doing Business As',
    LBL_VERIFICATION_TYPE_DESCRIPTION: 'Verification Type Description',
    LBL_PHONE_NUMBER: 'Phone Number',
    LBL_CONFIRM_REMOVAL: 'Confirm Removal',
    LBL_STATE: 'State',
    LBL_CITY: 'City',
    LBL_CONTRACT_NAME: 'Contract Name',
    LBL_NEW_ANNOUNCEMENT: 'New Announcement',
    LBL_MANAGE_ANNOUNCEMENT: 'Manage Announcements',
    LBL_ANNOUCEMENT_TYPE: 'Announcement Type',
    LBL_ANNOUCEMENT_DATED_POSTED: 'Dated Posted/Sent',
    LBL_ANNOUNCEMENT_SENT_TO: 'Sent To',
    LBL_ANNOUNCEMENT_DURATION: 'Duration',
    LBL_ANNOUNCEMENT: 'Announcement',
    LBL_SEND_ANNOUNCEMENTS: 'Send Announcements',
    LBL_TO: 'To',
    LBL_DURATION: 'Duration',
    LBL_SELECT: 'Select',
    LBL_ADMIN_USER_CONFIG_INFO_FIRST_NAME: 'First Name Contains',
    LBL_ADMIN_USER_CONFIG_INFO_LAST_NAME: 'Last Name Contains',
    LBL_EXCEPTION_ADDED: 'Exception Added',
    LBL_EXCEPTION_REMOVED: 'Exception Removed',
    LBL_ADD_BOOKMARK: 'Add Bookmark',
    LBL_PRIME_LOGIN_PAGE_BOOKMARK: 'Bookmark this page on your browser',
    LBL_AUDIT_LOG: 'Audit Logs',
    LBL_MASS_EMAIL_SENT: 'Mass Email Sent',
    LBL_DISABLE: 'Disable',
    LBL_ENABLE: 'Enable',
    LBL_AUTO_SPEND_FILE_GENERATION: 'Automated Generation of Spend File',
    LBL_SELECT_PO_CREATION_MONTH: 'Select the number of months to use for PO Creation Date',
    LBL_CONFIRM_CHANGE_CONFIG: 'Are you sure you want to update the current configuration? Please note, changing the number of months will impact the total  verified and non verified spend on the reports.',
    LBL_PO_CREATION_MONTH_UPDATE_SUCCESSFUL: 'Number of months to use for PO Creation Date Updated Successfully',
    LBL_SDGNE: 'SDG&E',
    LBL_SOCALGAS: 'SoCalGas',
    LBL_TOTAL_PAYMENTS_YTD: 'Total Payments to the Prime YTD $',
    LBL_TOTAL_VERIFIED_SUBCONTRACTING: 'Total Verified Subcontracting $',
    LBL_VERIFIED_SUBCONTRACTING_YTD: 'Verified Subcontracting % YTD',
    LBL_OVERALL_GOALS: 'Overall Goal %',
    LBL_GOAL_SUBCONTRACTING: 'Goal Subcontracting $',
    LBL_SUBCONTRACTING_DIFFERENCE: 'Subcontracting Difference',
    LBL_SUBCONTRACTING_SUMMARY_DASHBOARD_TITLE: 'Subcontracting Goals vs Actual Results Summary',
    LBL_VIEW_INDIVIDUAL_CONTRACT_INFO: 'View Individual PO Info',
    LBL_PREVIOUS_MONTH: 'Previous Month',
    LBL_UTILITY: 'Utility',
    LBL_SELECT_INDIVIDUAL_PRIME: 'Select Individual Prime',
    LBL_PRIMES: 'Primes',
    LBL_INDIVIDUAL_PRIME: 'Individual Prime',
    LBL_ALL: 'All',
    LBL_ALL_CAPS: 'ALL',
    LBL_PRIME_S: 'Prime(s)',
    LBL_SUBCONTRACTING_GOALS_VS_ACTUAL_SUMMARY_REPORT: 'Subcontracting Goals vs Actual Results Summary Report',
    LBL_PO: 'PO',
    LBL_PRIME: 'Prime',
    LBL_PO_DESCRIPTION: 'PO Description',
    LBL_PO_YTD: 'PO $ YTD',
    LBL_PO_GOAL_PERCENTAGE: 'PO Goal %',
    LBL_PO_GOAL: 'PO Goal $',
    LBL_VERIFIED_SUB: 'Verified Sub $',
    LBL_ACTUAL_SUB: 'Actual Sub %',
    LBL_ACTUAL_VS_SUB_GOAL_DIFFERENCE: 'Actual vs Sub Goal Difference',
    LBL_SELECT_PRIME:'Select Prime',
    LBL_BACK:'Back',
    LBL_SELECT_PRIME_STATUS: 'Select Prime Status',
    LBL_TOTAL:'Total'
};

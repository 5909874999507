import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SummaryReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/summary.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(SummaryReducer, defaultState, asyncActions);
};

type ISummaryContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const SummaryContext = createContext<Partial<ISummaryContext>>({
  state: defaultState,
}) as React.Context<ISummaryContext>;

const SummaryProvider: React.FC = ({ children }) => {
  const reducer = useReducer();
  return (
    <SummaryContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useSummaryContext: () => ISummaryContext = () => useContext(SummaryContext);
export type IUseSummaryContext = ReturnType<typeof useSummaryContext>;

export { SummaryContext, SummaryProvider };

import React, { useState, useCallback, useRef } from 'react';
import Lang from 'src/libraries/language';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useUserConfigContext } from 'src/contexts/user-config.context';
import UpdateSubcontractorView from './user-config-view.component';
import type { LIST_TYPE } from '../user-configuration-info/user-config-info.component';

type Iprops = {
    open: boolean;
    data: any;
    handleOnDiscard: () => void;
    handleSuccess: (msg, bool) => void;
    userType: LIST_TYPE[];
    entities: LIST_TYPE[];
};

const UpdateSubcontrctorComponent: React.FC<Iprops> = ({
    open,
    data,
    handleOnDiscard,
    handleSuccess,
    userType,
    entities,
}) => {
    const { actions } = useUserConfigContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const [clearButtonDisable, setclearButtonDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const discardStatusRef = useRef<string>();
    const userDataRef = useRef<any>(data);
    const handleClose = () => {
        setConfirm(false);
    };

    const handleCancel = useCallback(() => {
        if (JSON.stringify(data) === JSON.stringify(userDataRef.current)) {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        } else {
            discardStatusRef.current = 'close';
            setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_DISCARD);
            setConfirm(true);
        }
    }, [data, userDataRef]);

    const createOrUpdateUser = useCallback(
        async (params) => {
            setLoading(true);
            const response = userDataRef.current?.userID
                ? await actions.update(params)
                : await actions.create(params);
            if (response?.payload) {
                handleSuccess(
                    userDataRef.current?.userID
                        ? 'User has been updated successfully'
                        : 'User has been added successfully',
                    true
                );
                setButtonDisable(true);
                setConfirm(false);
            } else if (!response?.error?.status) {
                ToastError(response?.error?.message);
                setConfirm(false);
            }
            setLoading(false);
        },
        [setLoading]
    );
    const handleSubmitConfirm = async () => {
        if (discardStatusRef.current === 'open') {
            const params = {
                user_name: userDataRef.current?.username,
                user_id: userDataRef.current?.userID,
                first_name: userDataRef.current?.firstName,
                last_name: userDataRef.current?.lastName,
                email: userDataRef.current?.eMailAddress,
                user_type_id: userDataRef.current?.userTypeID,
                entity_id: userDataRef.current?.entityId,
            };
            await createOrUpdateUser(params);
            setLoading(false);
        } else {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        }
    };

    const disableClearBtn = useCallback(() => {
        if (!userDataRef.current?.userID) {
            userDataRef.current.username = '';
            userDataRef.current.firstName = '';
            userDataRef.current.lastName = '';
            userDataRef.current.eMailAddress = '';
            userDataRef.current.userTypeID = '';
            userDataRef.current.entityId = '';
        } else {
            userDataRef.current.username = data.username;
            userDataRef.current.firstName = data.firstName;
            userDataRef.current.lastName = data.lastName;
            userDataRef.current.eMailAddress = data.eMailAddress;
            userDataRef.current.userTypeID = data.userTypeID;
            userDataRef.current.entityId = data.entityId;
        }
        setclearButtonDisable(true);
        setButtonDisable(true);
    }, [clearButtonDisable]);

    const handleChange = (key, value) => {
        const newData = { ...userDataRef.current, ...{ [key]: value } };
        userDataRef.current = newData;
        const notEmpty = Object.values(userDataRef.current).every(
            (x) => x !== ''
        );
        const empty = Object.values(userDataRef.current).find((x) => x);
        notEmpty ? setButtonDisable(false) : setButtonDisable(true);
        empty ? setclearButtonDisable(false) : setclearButtonDisable(true);
    };

    const handleClick = useCallback(() => {
        if (formRef && formRef.current) {
            formRef.current.handleSubmit(); // doesn't work
            const notEmpty = Object.values(userDataRef.current).every(
                (value) => !!value
            );
            notEmpty && handleSubmit();
        }
    }, [setConfirm, formRef]);

    const handleSubmit = () => {
        discardStatusRef.current = 'open';
        setConfirm(true);
        setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_UPDATE);
    };

    return (
        <>
            <UpdateSubcontractorView
                data={userDataRef.current}
                open={open}
                handleClose={handleCancel}
                handleClick={handleClick}
                handleChange={handleChange}
                disableClearBtn={disableClearBtn}
                handleSubmit={handleSubmit}
                formRef={formRef}
                buttonDisable={buttonDisable}
                clearButtonDisable={clearButtonDisable}
                userType={userType}
                entities={entities}
            />

            {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={confirmmsg}
                    loading={loading}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleClose}
                    onConfirm={handleSubmitConfirm}
                />
            )}
        </>
    );
};

export default UpdateSubcontrctorComponent;

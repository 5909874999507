import React, { useState, useCallback, useRef, useEffect } from 'react';
import UpdateSubcontractorVonAuditLogView from './update-subcontractor-von-audit-log.view';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const UpdateSubcontractorVonAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [loadinglog, setLoadinglog] = useState(true);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();


    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
                const da =await actions?.subsWithoutVonAuditLog(param);
            setLoadinglog(false);
        },
        [actions]
    );
    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);
    return (
        <>
            <UpdateSubcontractorVonAuditLogView
                data={state?.vonAuditLogs?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.vonAuditLogs?.total_rows || 0}
            />
            

        </>
    );
};

export default UpdateSubcontractorVonAuditLog;

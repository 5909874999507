import services from 'src/services/contractor.service';
import { services as manageSubcontractorService } from 'src/services/manage-subcontractor.service';
import {
    IContractor,
    IAdvisorAndContactUser,
    IAdminPrime,
    IReportingyearsGetSchema,
} from 'src/models/contractor.model';
import {
    IschFileStatusSchema
} from '../models/manage-subcontractor.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    CONTRACTOR_LIST_FETCH: 'CONTRACTOR_LIST_FETCH',
    EMPLOYEE_REPLACE_UPDATE: 'EMPLOYEE_REPLACE_UPDATE',
    ADMIN_PRIME_LIST_FETCH: 'ADMIN_PRIME_LIST_FETCH',
    ADVISOR_AND_CONTACT_USER_GET: 'ADVISOR_AND_CONTACT_USER_GET',
    ADMIN_PRIME_REPLACE_UPDATE: 'ADMIN_PRIME_REPLACE_UPDATE',
    ADMIN_PRIME_REPLACE_CREATE: 'ADMIN_PRIME_REPLACE_CREATE',
    ADMIN_CONTACT_MASS_UPDATE: 'ADMIN_CONTACT_MASS_UPDATE',
    PRIME_ADVISOR_AND_CONTACT_USER_GET: 'PRIME_ADVISOR_AND_CONTACT_USER_GET',
    REPORTING_YEAR: 'REPORTING_YEAR',
    SCH_FILE_STATUS : 'SCH_FILE_STATUS'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    replacePUT: {
        type: actionTypes.EMPLOYEE_REPLACE_UPDATE,
        service: services.replacePUT,
        status: {} as never,
    },
    listGET: {
        type: actionTypes.CONTRACTOR_LIST_FETCH,
        service: services.listGET,
        status: {} as never,
    },
    adminPrimeListGET: {
        type: actionTypes.ADMIN_PRIME_LIST_FETCH,
        service: services.adminPrimeListGET,
        status: {} as never,
    },
    advisorAndContactUserGET: {
        type: actionTypes.ADVISOR_AND_CONTACT_USER_GET,
        service: services.advisorAndContactUserGET,
        status: {} as never,
    },
    adminPrimeReplacePUT: {
        type: actionTypes.ADMIN_PRIME_REPLACE_UPDATE,
        service: services.adminPrimeReplacePUT,
        status: {} as never,
    },
    adminPrimeReplacePOST: {
        type: actionTypes.ADMIN_PRIME_REPLACE_UPDATE,
        service: services.adminPrimeReplacePOST,
        status: {} as never,
    },
    adminContactMassUpdatePut: {
        type: actionTypes.ADMIN_CONTACT_MASS_UPDATE,
        service: services.adminContactMassUpdatePut,
        status: {} as never,
    },
    primeAdvisorAndContactUserGET: {
        type: actionTypes.PRIME_ADVISOR_AND_CONTACT_USER_GET,
        service: services.primeAdvisorAndContactUserGET,
        status: {} as never,
    }, 
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: services.reportingyearsGet,
        status: {} as never,
    },
    schFileStatusGet: {
        type: actionTypes.SCH_FILE_STATUS,
        service: manageSubcontractorService.schFileStatus,
        status: {} as never,
    } 
};
export type IContractorAsync = typeof asyncActions;

export type IContractorReducerAction = IAsyncActions<IContractorAsync>;
export interface IContractorState
    extends ICommonState<IContractorReducerAction> {
    list: Partial<IContractor>;
    adminPrimeList: Partial<IAdminPrime>;
    advisorAndContactUserList: Partial<IAdvisorAndContactUser>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
    schFileStatusGet?: Partial<IschFileStatusSchema>;
}

export const defaultState: IContractorState = {
    status: {},
    list: {},
    adminPrimeList: {},
    advisorAndContactUserList: {},
    reportingyearsGet: {},
    schFileStatusGet: {},
};

const ContractorReducer = (
    state: IContractorState = defaultState,
    action: ICommonAction<IContractorReducerAction>
): IContractorState => {
    switch (action.type) {
        case actionTypes.CONTRACTOR_LIST_FETCH: {
            return {
                ...state,
                list: action.payload || {},
            };
        }
        case actionTypes.ADMIN_PRIME_LIST_FETCH: {
            return {
                ...state,
                adminPrimeList: action.payload || {},
            };
        }
        case actionTypes.ADVISOR_AND_CONTACT_USER_GET: {
            return {
                ...state,
                advisorAndContactUserList: action.payload || {},
            };
        }
        case actionTypes.ADMIN_CONTACT_MASS_UPDATE: {
            return {
                ...state,
                advisorAndContactUserList: action.payload || {},
            };
        }
        case actionTypes.PRIME_ADVISOR_AND_CONTACT_USER_GET: {
            return {
                ...state,
                advisorAndContactUserList: action.payload || {},
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        case actionTypes.SCH_FILE_STATUS: {
            return {
                ...state,
                schFileStatusGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default ContractorReducer;

import React from 'react';
import VerificationFollowUp from './verification-follow-up.view';

const VerificationFollowUpComponent: React.FC<any> = ({
    data,
    onVerificationPageChange,
    loading,
    setResetPagination,
    resetPagination,
}) => {
    return (
        <VerificationFollowUp
            data={data?.data || []}
            onVerificationPageChange={onVerificationPageChange}
            total={data?.total_rows}
            loading={loading}
        />
    );
};

export default VerificationFollowUpComponent;

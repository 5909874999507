export const changePO: any = (value: string, setPoNumber, state:{contracts}, setPoError, setPoErrorMessage): void => {
    const reg = /^\d+$/;
    setPoNumber(value);
    const lenValue = value?.length ?? 0;
    if(lenValue != 10){
        setPoError(true);
        setPoErrorMessage('Contract # should be of 10 characters');  
    } else 
    if (reg.test(value)) {
        if (
            state?.contracts?.data?.find(
                (i) => i?.contract_number === value
            )
        ) {
            setPoError(true);
            setPoErrorMessage('Contract # Already Exists');
        } else {
            setPoError(false);
            setPoErrorMessage('');
        }
    } else {
        setPoError(true);
        setPoErrorMessage('Not a valid Contract #');
    }
};

import services from 'src/services/dashboard.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import {
    IFetchDashboardStatusSchema,
} from 'src/models/dashboard.model';
import {
    IReportingyearsGetSchema
} from 'src/models/spend.model';
export const actionTypes = {
    DASHBOARD_STATUS: 'DASHBOARD_STATUS',
    REPORTING_YEAR: 'REPORTING_YEAR',
    GET_SUBCONTRACTING_SPEND_REPORT_DROPDOWN: 'GET_SUBCONTRACTING_SPEND_REPORT_DROPDOWN',
    GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD: 'GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD',
    GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD_PRIME: 'GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD_PRIME'
} as const;

export const asyncActions = {
    getDashboardStatus: {
        type: actionTypes.DASHBOARD_STATUS,
        service: services.getDashboardStatus,
        status: {} as never,
    },
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: services.reportingyearsGet,
        status: {} as never,
    },
    getSubcontractingSpendReportDropdown: {
        type: actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DROPDOWN,
        service: services.getSubcontractingSpendReportDropdown,
        status: {} as never,
    },
    getSubcontractingSpendReportDashboard: {
        type: actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD,
        service: services.getSubcontractingSpendReportDashboard,
        status: {} as never,
    },
    getSubcontractingSpendReportDashboardPrime: {
        type: actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD_PRIME,
        service: services.getSubcontractingSpendReportDashboardPrime,
        status: {} as never,
    }
};


export type IDashboardAsync = typeof asyncActions;

export type IDashboardReducerAction = IAsyncActions<IDashboardAsync>;

export interface IDashboardState
    extends ICommonState<IDashboardReducerAction> {
    dashboardStatus: Partial<IFetchDashboardStatusSchema>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
    subcontractingSpendReportDropdown: any;
    subcontractingSpendReportDashboard:any
}

export const defaultState: IDashboardState = {
    status: {},
    dashboardStatus: {},
    reportingyearsGet: {},
    subcontractingSpendReportDropdown:{},
    subcontractingSpendReportDashboard:{}
};

const DashboardReducer = (
    state: IDashboardState = defaultState,
    action: ICommonAction<IDashboardReducerAction>
): IDashboardState => {
    switch (action.type) {
        case actionTypes.DASHBOARD_STATUS: {
            return {
                ...state,
                dashboardStatus: action.payload || {},
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        case actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DROPDOWN: {
            return {
                ...state,
                subcontractingSpendReportDropdown: action.payload || {},
            };
        }
        case actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD: {
            return {
                ...state,
                subcontractingSpendReportDashboard: action.payload || {},
            };
        }
        case actionTypes.GET_SUBCONTRACTING_SPEND_REPORT_DASHBOARD_PRIME: {
            return {
                ...state,
                subcontractingSpendReportDashboard: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default DashboardReducer;

import React, { useState } from 'react';
import {
    ORGDataTable,
    ATMCheckbox, 
    ATMIcon,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
    ATMGrid,
    ATMSelect,
} from 'shared-it-appmod-ui';
import * as _ from 'lodash';
import Lang from 'src/libraries/language';
import moment from 'moment';
import { Container } from 'semantic-ui-react';
const ContractExpirationStatusView: any = ({
    data,
    fetchData,
    loading,
    handleSetChange,
    downloadContractStatus
}) => {

    const tableData = data['data'] ? data['data'] : [];

    const columns = [
        {
            title: 'Contracts',
            index: 'contract_number',
            sortable: true,
            render: (_, value) => (
                <span style={{color: 'rgb(33, 133, 208)',fontWeight: '500'}}>
                    {value.contract_number}<p style={{color: '#878383'}}>{value.description}</p>
                </span>
            )
        },
        {
            title: 'Expiration Date',
            index: 'expiryDate',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${ value?.expiryDate == null ? '-' : (new Date(value?.expiryDate).toISOString().split('T')[0] !='1900-01-01' ? moment(value?.expiryDate).zone(0).format('M/D/YYYY') : '-')}`}</span>
            ),
        },
        {
            title: 'Status',
            index: 'expiryStatus',
            sortable: true,
            render: (_, value) => (
                <span>
                    {value.expiryStatus}
                </span>
            )
        },
    ];

    const utilityData = data?.utilityData;
    if(utilityData?.length == 2) {
        utilityData.unshift({
            Sempra_Company_ID: '0',
            Sempra_Company_Name: 'ALL'
        });
    }
    

    return (
        <>  
            <Container fluid>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ fontWeight: 'bold',textAlign: 'center',paddingTop: '20px' }}>Contract Summary</div>
                    {true && (
                    <div>
                        <div>
                       
                            <span style={{paddingRight: '25px'}}>Utility</span>
                            <ATMSelect
                                placeholder="Select Utility"
                                options={utilityData?.map(
                                    (type) => ({
                                        key: type?.Sempra_Company_ID,
                                        value: type?.Sempra_Company_ID,
                                        text: type?.Sempra_Company_Name,
                                    })
                                )}
                                defaultValue='0'
                                compact
                                style={{
                                    width: '257px',
                                    height: '30px',
                                    paddingTop: '11px'
                                }}
                                onChange={(i, v) =>{
                                    handleSetChange(v.value,'utility')
                                }
                                }
                            />
                        </div>
                        <div style={{ color: '#000000A5',marginTop: '10px' }}>

                            <ATMCheckbox
                                defaultChecked={false}
                                label={'Exception'}
                                onChange={(_, val) => {
                                    if(val.checked) {
                                        handleSetChange(1,'contractSummaryException')
                                    } else {
                                        handleSetChange(0,'contractSummaryException')
                                    }
                                    return val.checked;
                                }}
                                style={{
                                    marginRight: '10px'
                                }}
                            />

                            <ATMCheckbox
                                defaultChecked={false}
                                label={'Expired'}
                                onChange={(_, val) => {
                                    if(val.checked) {
                                        handleSetChange(1,'contractSummaryExpired')
                                    } else {
                                        handleSetChange(0,'contractSummaryExpired')
                                    }
                                    return val.checked;
                                }}
                                style={{
                                    marginRight: '10px'
                                }}
                            />
                            <ATMCheckbox
                                defaultChecked={false}
                                label={'Expiring In Reporting Year'}
                                onChange={(_, val) => {
                                    if(val.checked) {
                                        handleSetChange(1,'contractSummaryExpiringInReportingYear')
                                    } else {
                                        handleSetChange(0,'contractSummaryExpiringInReportingYear')
                                    }
                                    return val.checked;
                                }}
                                style={{
                                    marginRight: '10px'
                                }}
                            />

                            <div
                                style={{
                                    display: 'initial',
                                    padding: '5px 5px 5px 8px',
                                    border: '1px solid rgb(33, 133, 208)',
                                    borderRadius: '5px',
                                    color: 'rgb(33, 133, 208)',
                                    cursor: 'pointer'
                                }}
                                onClick={downloadContractStatus}
                            >
                                <ATMIcon color="blue" name="download" />
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </Container>
            <div
                style={{
                    position: 'relative',
                    top: '15px',
                    maxHeight: '310px',
                    overflowY: 'auto'
                }}
            >  
                <ORGDataTable
                    celled={false}
                    data={tableData}
                    columns={columns}
                    total={data?.total_rows}
                    history
                    counter={false}
                    // onChange={fetchData}
                    loading={loading}
                    tableHeight={'310px'}
                    sortable
                    filters={false}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}    
                    key={data?.total_rows}
                    showPagination={false}
                />
            </div>
        </>
    );
};
export default ContractExpirationStatusView;

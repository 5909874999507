import React, { useEffect } from 'react';
import { login } from 'src/constants/login.constant';

const CDCLoginProxy: React.FC = () => {
useEffect(() => {
const proxyURL = window.location.pathname;
const apiKey = process.env.REACT_APP_CDC_API_KEY;

if (proxyURL === '/proxy') {
    const hostName = window.location.origin;
    const script = document.createElement('script');
    script.src = `https://cdns.gigya.com/js/gigya.saml.js?apiKey=${apiKey}`;
    script.textContent = `{
    loginURL: '${hostName}/login',
    logoutURL: "${hostName}/logout"
    }`;
    script.async = true;
    document.body.appendChild(script);
} else if (proxyURL === '/login') {
    document.write(login);
    //document.body.insertAdjacentHTML('afterbegin', login);
}
}, []);



return <>{/* <CDCLoginProxyView /> */}</>;
};
export default CDCLoginProxy;


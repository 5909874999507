import services from 'src/services/manage-subcontractor.service';
import { services as subcontractorService } from 'src/services/subcontractor.service';
import { services as manageSubcontractorService } from 'src/services/manage-subcontractor.service';
import {
    IUpdateSubcontractorDB,
    IUpdateSubcontractorVonsListPayload,
    IUpdateSubcontractorSapId,
    IUpdateViewAllSubcontractor,
    IUpdateSapIdPost,
    ISubcontractorReasonGet,
    ISubsWithoutSapIdAuditLogSchema,
    ISubsWithoutVonAuditLogSchema,
    IViewAllSubAuditLogSchema,
    IBlockAndUnblockExternalUserSchema,
    IBlockAndUnblockExternalUserGetSchema,
    IBlockAndUnblockExternalUserAuditLogGetSchema,
    IReportingyearsGetSchema,
    IReportYearRoleBackAndRoleOverPostSchema,
    IReportYearRoleBackAndRoleOverGetSchema,
    IYearEndRolloverAuditLogGetSchema,
    IschFileStatusSchema,
    IschFileProcessAndUpdateSubcontractorsSchema
} from '../models/manage-subcontractor.model';
import {
    IspendRecordSapIdZeroSchema,
} from '../models/subcontractor.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    UPDATE_SUBCONTRACTOR_DB: 'UPDATE_SUBCONTRACTOR_DB',
   
    UPDATE_SUBCONTRACOTR_VON: 'UPDATE_SUBCONTRACOTR_VON',
    UPDATE_SUBCONTRACOTR_VON_POST: 'UPDATE_SUBCONTRACOTR_VON_POST',
    LIST_VON_NUMBER: 'LIST_VON_NUMBER',
    LIST_SUBCONTRACTOR_SAPID: 'LIST_SUBCONTRACTOR_SAPID',
    LIST_VIEW_ALL_SUB: 'LIST_VIEW_ALL_SUB',
    UPDATE_SAPID_POST: 'UPDATE_SAPID_POST',
    SUBCONTRACTOR_REASON_LIST: 'SUBCONTRACTOR_REASON_LIST',
    DELETE_SUBCONTRACTOR: 'DELETE_SUBCONTRACTOR',
    SAP_ID_AUDIT_LOG: 'SAP_ID_AUDIT_LOG',
    VON_AUDIT_LOG: 'VON_AUDIT_LOG',
    VIEW_ALL_SUB_AUDIT_LOG: 'VIEW_ALL_SUB_AUDIT_LOG',
    SPEND_RECORD_SAP_ZEERO: 'SPEND_RECORD_SAP_ZEERO',
    BLOCK_UNBLOCK_EXTERNAL_USERS:'BLOCK_UNBLOCK_EXTERNAL_USERS',
    BLOCK_UNBLOCK_EXTERNAL_USERS_GET:'BLOCK_UNBLOCK_EXTERNAL_USERS_GET',
    BLOCK_AND_UNBLOCK_EXTERNAL_USER_AUDIT_LOG_GET: 'BLOCK_AND_UNBLOCK_EXTERNAL_USER_AUDIT_LOG_GET',
    REPORT_YEAR_RBACK_ROVER_GET: 'REPORT_YEAR_RBACK_ROVER_GET',
    REPORT_YEAR_RBACK_ROVER_POST: 'REPORT_YEAR_RBACK_ROVER_POST',
    REPORTING_YEAR: 'REPORTING_YEAR',
    YEAR_END_ROLL_OVER_AUDIT_LOG: 'YEAR_END_ROLL_OVER_AUDIT_LOG',
    SCH_FILE_STATUS : 'SCH_FILE_STATUS',
    SCH_FILE_PROCESS_AND_UPDATE_SUBCONTRACTOR : 'SCH_FILE_PROCESS_AND_UPDATE_SUBCONTRACTOR'  

} as const;

export const asyncActions = {
    updateSubcontractorDBPOST: {
        type: actionTypes.UPDATE_SUBCONTRACTOR_DB,
        service: services.updateSubcontractorDatabase,
        status: {} as never,
    },
    
    UpdateSubcontractorVonGET: {
        type: actionTypes.UPDATE_SUBCONTRACOTR_VON,
        service: services.UpdateSubcontractorVonGet,
        status: {} as never,
    },
    UpdateSubcontractorVonPOST: {
        type: actionTypes.UPDATE_SUBCONTRACOTR_VON_POST,
        service: services.UpdateSubcontractorVonPost,
        status: {} as never,
    },
    listVonNumberGET: {
        type: actionTypes.LIST_VON_NUMBER,
        service: services.listVonNumberGET,
        status: {} as never,
    },
    updateSubsSapId: {
        type: actionTypes.LIST_SUBCONTRACTOR_SAPID,
        service: services.UpdateSubcontractorSapIdGet,
        status: {} as never,
    },
    viewAllSubcontractor: {
        type: actionTypes.LIST_VIEW_ALL_SUB,
        service: services.ViewAllSubConstractor,
        status: {} as never,
    },
    updateSubsSapIdPost: {
        type: actionTypes.UPDATE_SAPID_POST,
        service: services.UpdateSapIdPost,
        status: {} as never,
    },
    Reason_getList: {
        type: actionTypes.SUBCONTRACTOR_REASON_LIST,
        service: subcontractorService.ReasonList,
        status: {} as never,
    },
    spendRecordSapIdZero: {
        type: actionTypes.SPEND_RECORD_SAP_ZEERO,
        service: subcontractorService.spendRecordSapIdZero,
        status: {} as never,
    },

    deleteSubcontractor: {
        type: actionTypes.DELETE_SUBCONTRACTOR,
        service: subcontractorService.deleteSubcontractor,
        status: {} as never,
    },
    subsWithoutSapIdAuditLog: {
        type: actionTypes.SAP_ID_AUDIT_LOG,
        service: manageSubcontractorService.subsWithoutSapIdAuditLog,
        status: {} as never,
    },
    subsWithoutVonAuditLog: {
        type: actionTypes.VON_AUDIT_LOG,
        service: manageSubcontractorService.subsWithoutVonAuditLog,
        status: {} as never,
    },  
    viewAllSubAuditLog: {
        type: actionTypes.VIEW_ALL_SUB_AUDIT_LOG,
        service: manageSubcontractorService.viewAllSubAuditLog,
        status: {} as never,
    }, 
    blockAndUnblockExternalUser: {
        type: actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS,
        service: manageSubcontractorService.blockAndUnblockExternalUser,
        status: {} as never,
    },   
    blockAndUnblockExternalUserGet: {
        type: actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS_GET,
        service: manageSubcontractorService.blockAndUnblockExternalUserGet,
        status: {} as never,
    },
    blockAndUnblockExternalUserAuditLogGet: {
        type: actionTypes.BLOCK_AND_UNBLOCK_EXTERNAL_USER_AUDIT_LOG_GET,
        service: manageSubcontractorService.blockAndUnblockExternalUserAuditLog,
        status: {} as never,
    }, 
    reportYearRoleBackAndRoleOverGet: {
        type: actionTypes.REPORT_YEAR_RBACK_ROVER_GET,
        service: manageSubcontractorService.reportYearRoleBackAndRoleOverGet,
        status: {} as never,
    }, 
    reportYearRoleBackAndRoleOverPost: {
        type: actionTypes.REPORT_YEAR_RBACK_ROVER_POST,
        service: manageSubcontractorService.reportYearRoleBackAndRoleOverPost,
        status: {} as never,
    }, 
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: manageSubcontractorService.reportingyearsGet,
        status: {} as never,
    },
    yearEndRolloverAuditLogGet: {
        type: actionTypes.YEAR_END_ROLL_OVER_AUDIT_LOG,
        service: manageSubcontractorService.yearEndRolloverAuditLogGet,
        status: {} as never,
    },
    schFileStatusGet: {
        type: actionTypes.SCH_FILE_STATUS,
        service: manageSubcontractorService.schFileStatus,
        status: {} as never,
    },
    schFileProcessAndUpdateSubcontractors: {
        type: actionTypes.SCH_FILE_PROCESS_AND_UPDATE_SUBCONTRACTOR,
        service: manageSubcontractorService.schFileProcessAndUpdateSubcontractors,
        status: {} as never,
    }
    
};
export type IUpdateSubcontractorDBAsync = typeof asyncActions;
export type IUpdateSubcontractorDBReducerAction =
    IAsyncActions<IUpdateSubcontractorDBAsync>;

export interface IUpdateSubcontractorDBState
    extends ICommonState<IUpdateSubcontractorDBReducerAction> {
    data: Partial<IUpdateSubcontractorDB>;
    updateSubcontractorVonList: Partial<IUpdateSubcontractorVonsListPayload>;
    listVon: Partial<IUpdateSubcontractorVonsListPayload>;
    subsSapList: Partial<IUpdateSubcontractorSapId>;
    allSubList: Partial<IUpdateViewAllSubcontractor>;
    updateSubsSapId: Partial<IUpdateSapIdPost>;
    reasons?: ISubcontractorReasonGet;
    sapIdauditLogs?: Partial<ISubsWithoutSapIdAuditLogSchema>;
    vonAuditLogs?: Partial<ISubsWithoutVonAuditLogSchema>;
    viewAllSubAuditLogs?: Partial<IViewAllSubAuditLogSchema>;
    spendRecordSapIdZerors?: Partial<IspendRecordSapIdZeroSchema>;
    blockAndUnblockExternalUser?: Partial<IBlockAndUnblockExternalUserSchema>;
    blockAndUnblockExternalUserGet?: Partial<IBlockAndUnblockExternalUserGetSchema>;
    blockAndUnblockExternalUserAuditLogGet?: Partial<IBlockAndUnblockExternalUserAuditLogGetSchema>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
    reportYearRoleBackAndRoleOverPost?: Partial<IReportYearRoleBackAndRoleOverPostSchema>;
    reportYearRoleBackAndRoleOverGet?: Partial<IReportYearRoleBackAndRoleOverGetSchema>;
    yearEndRolloverAuditLogGet?: Partial<IYearEndRolloverAuditLogGetSchema>;
    schFileStatusGet?: Partial<IschFileStatusSchema>;
    schFileProcessAndUpdateSubcontractorsGet?: Partial<IschFileProcessAndUpdateSubcontractorsSchema>;
}
export const defaultState: IUpdateSubcontractorDBState = {
    data: {},
    status: {},
    updateSubcontractorVonList: {},
    listVon: {},
    subsSapList: {},
    allSubList: {},
    updateSubsSapId: {},
    reasons: { data: [] },
    sapIdauditLogs: {},
    vonAuditLogs: {},
    viewAllSubAuditLogs: {},
    spendRecordSapIdZerors: {},
    blockAndUnblockExternalUser:{},
    blockAndUnblockExternalUserGet:{},
    blockAndUnblockExternalUserAuditLogGet:{},
    reportingyearsGet:{},
    reportYearRoleBackAndRoleOverPost:{},
    reportYearRoleBackAndRoleOverGet:{},
    yearEndRolloverAuditLogGet:{},
    schFileStatusGet:{},
    schFileProcessAndUpdateSubcontractorsGet:{}
};
const UpdateSubcontractorDBReducer = (
    state: IUpdateSubcontractorDBState = defaultState,
    action: ICommonAction<IUpdateSubcontractorDBReducerAction>
): IUpdateSubcontractorDBState => {
    switch (action.type) {
        case actionTypes.UPDATE_SUBCONTRACTOR_DB: {
            return {
                ...state,
                data: action.payload || {},
            };
        }
        case actionTypes.UPDATE_SUBCONTRACOTR_VON: {
            return {
                ...state,
                updateSubcontractorVonList: action.payload || {},
            };
        }

        case actionTypes.LIST_VON_NUMBER: {
            return {
                ...state,
                listVon: action.payload || {},
            };
        }
        case actionTypes.LIST_SUBCONTRACTOR_SAPID: {
            return {
                ...state,
                subsSapList: action.payload || {},
            };
        }
        case actionTypes.LIST_VIEW_ALL_SUB: {
            return {
                ...state,
                allSubList: action.payload || {},
            };
        }
        case actionTypes.UPDATE_SAPID_POST: {
            return {
                ...state,
                updateSubsSapId: action.payload || {},
            };
        }

        case actionTypes.SUBCONTRACTOR_REASON_LIST: {
            return {
                ...state,
                reasons: action.payload || {},
            };
        }

        case actionTypes.SAP_ID_AUDIT_LOG: {
            return {
                ...state,
                sapIdauditLogs: action.payload || {},
            };
        }

        case actionTypes.VON_AUDIT_LOG: {
            return {
                ...state,
                vonAuditLogs: action.payload  || {},
            };
        }

        case actionTypes.VIEW_ALL_SUB_AUDIT_LOG: {
            return {
                ...state,
                viewAllSubAuditLogs: action.payload || {},
            };
        }
        case actionTypes.SPEND_RECORD_SAP_ZEERO: {
            return {
                ...state,
                spendRecordSapIdZerors: action.payload || {},
            };
        }
        case actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS: {
            return {
                ...state,
                blockAndUnblockExternalUser: action.payload || {},
            };
        }
        case actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS_GET: {
            return {
                ...state,
                blockAndUnblockExternalUserGet: action.payload.data || {},
            };
        }
        case actionTypes.BLOCK_AND_UNBLOCK_EXTERNAL_USER_AUDIT_LOG_GET: {
            return {
                ...state,
                blockAndUnblockExternalUserAuditLogGet: action.payload || {},
            };
        }
        case actionTypes.REPORT_YEAR_RBACK_ROVER_GET: {
            return {
                ...state,
                reportYearRoleBackAndRoleOverGet: action.payload || {},
            };
        }
        case actionTypes.REPORT_YEAR_RBACK_ROVER_POST: {
            return {
                ...state,
                reportYearRoleBackAndRoleOverPost: action.payload || {},
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }

        case actionTypes.YEAR_END_ROLL_OVER_AUDIT_LOG: {
            return {
                ...state,
                yearEndRolloverAuditLogGet: action.payload || {},
            };
        }

        case actionTypes.SCH_FILE_STATUS: {
            return {
                ...state,
                schFileStatusGet: action.payload || {},
            };
        }
        case actionTypes.SCH_FILE_PROCESS_AND_UPDATE_SUBCONTRACTOR: {
            return {
                ...state,
                schFileProcessAndUpdateSubcontractorsGet: action.payload || {},
            };
        }
    {}
        
        

    
        default: {
            return state;
        }
    }
};

export default UpdateSubcontractorDBReducer;

import React from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import UpdateSapIdView from './sapId-update.view';

export type IProps = {
    isOpen: boolean;
    fdata?: any;
    editData?: any;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    handleChange: (key, data) => void;
    handleSubmit: () => void;
    // handleSuccess: (params: IORGDataTableQueryState) => void;
    // setValue: (fieldName, value) => void;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    reasonList: any;
};

const SapIdUpdateForm: React.FC<IProps> = ({
    formRef,
    // reasonList,
    editData,
    fdata,
    handleClick,
    handleChange,
    // setValue,
    // handleSuccess,
    handleSubmit,
    reasonList,
    ...props
}) => {
    const search = window?.location?.search || '?page=1&limit=10';

    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');
    return (
        <>
            <UpdateSapIdView
                {...props}
                fdata={fdata}
                editData={editData}
                loading={false}
                formRef={formRef}
                handleChange={handleChange}
                handleClick={handleClick}
                // setValue={setValue}
                page={page ?? '1'}
                limit={limit ?? '10'}
                handleSubmit={handleSubmit}
                reasonList={reasonList}
            />
        </>
    );
};

export default SapIdUpdateForm;

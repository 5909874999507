import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import AdminConsoleReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/admin-console.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(AdminConsoleReducer, defaultState, asyncActions);
};

type IAdminConsoleContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const AdminConsoleContext = createContext<Partial<IAdminConsoleContext>>({
  state: defaultState,
}) as React.Context<IAdminConsoleContext>;

const AdminConsoleProvider: React.FC = ({ children }) => {
  const reducer = useReducer();
  return (
    <AdminConsoleContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </AdminConsoleContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useAdminConsoleContext: () => IAdminConsoleContext = () => useContext(AdminConsoleContext);
export type IUseAdminConsoleContext = ReturnType<typeof useAdminConsoleContext>;

export { AdminConsoleContext, AdminConsoleProvider };

import React from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { IContactPrimesTesting } from 'src/models/contact-primes.model';
import UpdateFormView from './primes-update.view';

export type IProps = {
    isOpen: boolean;
    fdata?: Partial<IContactPrimesTesting>;
    editData?: Partial<IContactPrimesTesting>;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    handleSubmit: () => void;
    handleSuccess: (params: IORGDataTableQueryState) => void;
    setValue: (fieldName, value) => void;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
};

const PrimesUpdateForm: React.FC<IProps> = ({
    formRef,
    editData,
    fdata,
    handleClick,
    setValue,
    handleSuccess,
    handleSubmit,
    ...props
}) => {
    const search = window?.location?.search || '?page=1&limit=10';

    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');

    return (
        <>
            <UpdateFormView
                {...props}
                fdata={fdata || {}}
                editData={editData}
                loading={false}
                formRef={formRef}
                handleClick={handleClick}
                setValue={setValue}
                page={page ?? '1'}
                limit={limit ?? '10'}
                handleSubmit={handleSubmit}
            />
        </>
    );
};

export default PrimesUpdateForm;

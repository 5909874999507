import { IAsyncActions } from 'src/libraries/thunk.library';
import services from 'src/services/po-guardrails.service';

import { ICommonAction, ICommonState } from '.';
import { IPOValidationBatchJobStatusSchema } from 'src/models/po-guardrails.model';

export const actionTypes = {
    GET_PO_CREATION_VALIDATION_MONTH: 'GET_PO_CREATION_VALIDATION_MONTH',
    UPDATE_PO_CREATION_VALIDATION_MONTH: 'UPDATE_PO_CREATION_VALIDATION_MONTH',
    GET_PO_GUARDRAILS_AUDIT_LOG: 'GET_PO_GUARDRAILS_AUDIT_LOG',
    GET_PO_VALIDATION_BATCH_JOB_STATUS: 'GET_PO_VALIDATION_BATCH_JOB_STATUS'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    getPOCreationValidationMonth: {
        type: actionTypes.GET_PO_CREATION_VALIDATION_MONTH,
        service: services.getPOCreationValidationMonth,
        status: {} as never,
    },
    updatePOCreationValidationMonth: {
        type: actionTypes.UPDATE_PO_CREATION_VALIDATION_MONTH,
        service: services.updatePOCreationValidationMonth,
        status: {} as never,
    },

    getInvalidContractAuditLog: {
        type: actionTypes.GET_PO_GUARDRAILS_AUDIT_LOG,
        service: services.getPOGuardrailsAuditLog,
        status: {} as never,
    },

    getPOValidationBatchJobStatus: {
        type: actionTypes.GET_PO_VALIDATION_BATCH_JOB_STATUS,
        service: services.getPOValidationBatchJobStatus,
        status: {} as never,
    },
};
export type IPOGuardrailsAsync = typeof asyncActions;

export type POGuardrailsAction = IAsyncActions<IPOGuardrailsAsync>;
export interface IPOGuardrailsState
    extends ICommonState<POGuardrailsAction> {
    status: any;
    data: any;
    poGuardrailsAuditLog: any;
    poValidationBatchJobStatus?: Partial<IPOValidationBatchJobStatusSchema>;
}

export const defaultState: IPOGuardrailsState = {
    status: {},
    data: [],
    poGuardrailsAuditLog: {},
    poValidationBatchJobStatus:{}
};

const POGuardrailsReducer = (
    state: IPOGuardrailsState = defaultState,
    action: ICommonAction<POGuardrailsAction>
): IPOGuardrailsState => {
    switch (action.type) {
        case actionTypes.GET_PO_CREATION_VALIDATION_MONTH: {
            return {
                ...state,
                data: action.payload,
            };
        }

        case actionTypes.UPDATE_PO_CREATION_VALIDATION_MONTH: {
            return {
                ...state,
            };
        }
        case actionTypes.GET_PO_GUARDRAILS_AUDIT_LOG: {
            return {
                ...state,
                poGuardrailsAuditLog: action.payload ?? {},
            };
        }

        case actionTypes.GET_PO_VALIDATION_BATCH_JOB_STATUS: {
            return {
                ...state,
                poValidationBatchJobStatus: action.payload || {},
            };
        }
        
        default: {
            return state;
        }
    }
};

export default POGuardrailsReducer;

import React, { useCallback, useState } from 'react';
import MassMailAuditLogView from './block-spend-file-generation-audit-log.view';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useMassMailContext } from 'src/contexts/mass-mail.context';
import BlockSpendFileGenerationAuditLogView from './block-spend-file-generation-audit-log.view';
import { useAdHocSpendFileContext } from 'src/contexts/ad-hoc-spend-file.context';

interface IProps {
    open: boolean;
    handleClose: any
}

const BlockSpendFileGenerationAuditLog: React.FC<IProps> = ({ open, handleClose }) => {
    const { state, actions } = useAdHocSpendFileContext();
    const [loading, setloading] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setloading(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.getSpendFileAutoGenerationActivationAuditLog(param);
            setloading(false);
        },
        []
    );


    return (
        <>
            <BlockSpendFileGenerationAuditLogView
                open={open}
                handleClose={handleClose}
                data={state?.spendFileAutoGenerationActivationAuditLogList?.data || []}
                loading={loading}
                fetchData={fetchData}
                totalRecord={
                    state?.spendFileAutoGenerationActivationAuditLogList?.total_rows ||
                    0}
            />
        </>
    )
}

export default BlockSpendFileGenerationAuditLog;
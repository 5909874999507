import React from 'react';
import { Button } from 'semantic-ui-react';
import { ATMMessage } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './ad-hoc-sch-file-upload.module.scss';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';

export type IProps = {
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    sp3Loading: boolean;
    loadEvent: boolean;
    handleClick: () => void;
};

const AdHocSCHFileUploadView: React.FC<IProps> = ({
    sp3Loading,
    handleFileChange,
    loadEvent,
    handleClick
}) => {
    return (
        <div>
            {sp3Loading && (<div>
                    <ATMMessage error>
                        <ATMMessage.Header>{Lang.LBL_NOTE}</ATMMessage.Header>
                        <p style={{ marginTop: '16px' }}>
                        AD-Hoc SCH File Run cannot be accessed right now, as SCH file is being processed in the background. Please return to this page after some time and try again
                        </p>
                    </ATMMessage>
                </div>)}
            {/* <ATMMessage
                style={{
                    height: '86px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: '#F8FFFF',
                }}
            >
                

                <ATMMessage.Header
                    style={{ fontSize: '14px', color: '#276F86' }}
                >
                    Ad-hoc Updation of Subcontractor Data
                </ATMMessage.Header>
                <Button
                    disabled={sp3Loading}
                    loading={loadEvent}
                    primary
                    as="label"
                    htmlFor="file"
                    type="button"
                >
                    <Button.Content visible>
                        <i
                            className={`icon upload ${styles.uploadIcon}`}
                            style={{ color: 'white' }}
                        ></i>
                        {Lang.LBL_UPLOAD_SCH_FILE}
                    </Button.Content>
                </Button>
                <input
                    type="file"
                    id="file"
                    value=""
                    hidden
                />
            </ATMMessage> */}

            <ATMGrid columns={1}>
                    <ATMGrid.Row >
                        
                        <ATMGrid.Column style={{ textAlign: 'center',paddingTop: '25px',paddingBottom: '25px' }}>
                            <ATMButton
                                size="small"
                                style={{ marginRight: '20px' }}
                                primary
                                onClick={() => handleClick()}
                                loading={loadEvent}
                                disabled={loadEvent ? true :sp3Loading}

                            >
                                Run Ad-hoc SCH File
                            </ATMButton>
                            
                            {/* <ATMButton
                                disabled={!showGenerate}
                                size="small"
                                style={{ margiLeft: '20px' }}
                                primary
                            >
                                {Lang.LBL_DOWNLOAD}
                            </ATMButton> */}
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
        </div>
    );
};

export default AdHocSCHFileUploadView;

import { AccessRole } from 'src/constants/access.constant';
import { getUserType } from 'src/libraries/access.library';

export const isAdmin = () => AccessRole.ADMIN === getUserType();

export const isContact = () =>
    AccessRole.CONTACT === getUserType() ||
    AccessRole.DBE_ADVISOR === getUserType();

export const isPrime = () => AccessRole.PRIME === getUserType();

export const getUserID = () =>
    isAdmin() || isContact()
        ? localStorage.getItem('prime_id')
        : localStorage.getItem('loginCredential');

import services from 'src/services/user-config.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import { IUSer } from '../models/user-config.model';

export const actionTypes = {
    USER_LIST_FETCH: 'USER_LIST_FETCH',
    USER_DELETE: 'USER_DELETE',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET: {
        type: actionTypes.USER_LIST_FETCH,
        service: services.listGET,
        status: {} as never,
    },
    delete: {
        type: actionTypes.USER_DELETE,
        service: services.delete,
        status: {} as never,
    },
    create: {
        type: actionTypes.CREATE_USER,
        service: services.create,
        status: {} as never,
    },
    update: {
        type: actionTypes.UPDATE_USER,
        service: services.update,
        status: {} as never,
    },
};
export type IUserConfigAsync = typeof asyncActions;

export type IUserConfigReducerAction = IAsyncActions<IUserConfigAsync>;
export interface IUserConfigState
    extends ICommonState<IUserConfigReducerAction> {
    list: Partial<IUSer>;
}

export const defaultState: IUserConfigState = {
    list: {},
    status: {},
};
const UserConfigReducer = (
    state: IUserConfigState = defaultState,
    action: ICommonAction<IUserConfigReducerAction>
): IUserConfigState => {
    switch (action.type) {
        case actionTypes.USER_LIST_FETCH: {
            return {
                ...state,
                list: action?.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default UserConfigReducer;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    ChangeSpendAmountReportPayloadSchema,
    PurchaseOrderReportPayloadSchema,
    subByMonthReportAdminPayloadSchema,
    subByMonthReportPayloadSchema,
    verificationFollowUpPayloadSchema,
    subByPrimeContractReportSchema,
    primeInformationReportSchema,
    verificationFollowUpPayloadSchemaAdmin,
    AdminChangesSpendAmountReportPayloadSchema
} from '../models/report.model';

const client = httpClient();

export const services = {
    listMonthSummaryGET: async (params) => {
        return client.get(
            '/subByMonthReport',
            params,
            subByMonthReportPayloadSchema
        );
    },
    listMonthSummaryTierIIIGET: async (params) => {
        return client.get(
            '/subByMonthReportTierIII',
            params,
            subByMonthReportPayloadSchema
        );
    },
    listMonthSummaryAdminGET: async (params) => {
        return client.get(
            '/inSubByMonthReport',
            params,
            subByMonthReportAdminPayloadSchema
        );
    },
    listMonthReportByPrimeContractGET: async (params) => {
        return client.get(
            '/inSubcontractByPurchaseOrderByPrime',
            params,
            subByPrimeContractReportSchema
        );
    },
    listMonthSummaryDOWNLOAD: async (params) => {
        return client.get(
            '/subByMonthReport',
            params,
            subByMonthReportPayloadSchema
        );
    },
    listMonthSummaryTierIIIDOWNLOAD: async (params) => {
        return client.get(
            '/subByMonthReportTierIII',
            params,
            subByMonthReportPayloadSchema
        );
    },
    listMonthSummaryAdminDOWNLOAD: async (params) => {
        return client.get(
            '/inSubByMonthReport',
            params,
            subByMonthReportAdminPayloadSchema
        );
    },listMonthSummaryAdminTierIIIDOWNLOAD: async (params) => {
        return client.get(
            '/inSubByMonthReportTierIII',
            params,
            subByMonthReportAdminPayloadSchema
        );
    },
    listMonthReportByPrimeContractDOWNLOAD: async (params) => {
        return client.get(
            '/inSubcontractByPurchaseOrderByPrime',
            params,
            subByPrimeContractReportSchema
        );
    },
    listNeedingVerificationFollowUpGET: async (params) => {
        return client.get(
            '/subcontractorsNeedVerificationFollowUpReport',
            params,
            verificationFollowUpPayloadSchema
        );
    },
    listNeedingVerificationFollowUpAdminGET: async (params) => {
        return client.get(
            '/subcontractorNeedVerificationFollowUpForAdmin',
            params,
            verificationFollowUpPayloadSchemaAdmin
        );
    },
    listNeedingVerificationFollowUpDOWNLOAD: async (params) => {
        return client.get(
            '/subcontractorsNeedVerificationFollowUpReport',
            params,
            verificationFollowUpPayloadSchema
        );
    },
    listChangesToSpendAmountsGET: async (params) => {
        return client.get(
            '/changeSpendAmountReport',
            params,
            ChangeSpendAmountReportPayloadSchema
        );
    },
    listPrimeInformationGET: async (params) => {
        return client.get(
            '/getPrimeInformationReport',
            params,
            primeInformationReportSchema
        );
    },
    listChangesToSpendAmountsDOWNLOAD: async (params) => {
        return client.get(
            '/changeSpendAmountReport',
            params,
            ChangeSpendAmountReportPayloadSchema
        );
    },

    listAdminChangesToSpendAmountsGET:async(params)=>{
     return client.get(
         '/changeSpendAmountReportAdmin',
         params,
         AdminChangesSpendAmountReportPayloadSchema         
     ); 
    },

    listAdminChangesToSpendAmountsDOWNLOAD:async(params)=>{
        return client.get(
            '/changeSpendAmountReportAdmin',
            params,
            AdminChangesSpendAmountReportPayloadSchema
        );
    },
    listByPurchaseOrderGET: async (params) => {
        return client.get(
            '/getSubcontractByPurchaseOrder',
            params,
            PurchaseOrderReportPayloadSchema
        );
    },
    listByPurchaseOrderDOWNLOAD: async (params) => {
        return client.get(
            '/getSubcontractByPurchaseOrder',
            params,
            PurchaseOrderReportPayloadSchema
        );
    },
    listPrimeInformationDOWNLOAD: async (params) => {
        return client.get('/getPrimeInformationReport', 
            params,
            primeInformationReportSchema
        );
    },
    listNeedingVerificationFollowUpAdminDOWNLOAD: async (params) => {
        return client.get(
            '/subcontractorNeedVerificationFollowUpForAdmin',
            params,
            verificationFollowUpPayloadSchemaAdmin
        );
    },
    listMonthSummaryAdminTierIIIGET: async (params) => {
        return client.get(
            '/inSubByMonthReportTierIII',
            params,
            subByMonthReportAdminPayloadSchema
        );
    },

};
export default services;

import services from 'src/services/contactuser.service';
import {
    IContactUserListPayload,
} from 'src/models/contactuser.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    CONTACTUSER_LIST_FETCH: 'CONTACTUSER_LIST_FETCH',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET: {
        type: actionTypes.CONTACTUSER_LIST_FETCH,
        service: services.ContactUserList,
        status: {} as never,
    },
};
export type IContactUserAsync = typeof asyncActions;

export type IContactUserReducerAction = IAsyncActions<IContactUserAsync>;
export interface IContactUserState
    extends ICommonState<IContactUserReducerAction> {
    list: IContactUserListPayload;
}

export const defaultState: IContactUserState = {
    status: {},
    list: { data: [], total_pages: 0, total_rows: 0 },
};

const ContactUserReducer = (
    state: IContactUserState = defaultState,
    action: ICommonAction<IContactUserReducerAction>
): IContactUserState => {
    switch (action.type) {
        case actionTypes.CONTACTUSER_LIST_FETCH: {
            return {
                ...state,
                list: action.payload ?? {
                    data: [],
                    total_pages: 0,
                    total_rows: 0,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default ContactUserReducer;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { actionTypes } from 'src/ducks/manage-contractor.duck';
import Lang from 'src/libraries/language';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import UpdateSubcontractorSapIdView from './update-subcontractor-sapId.view';
import { getSPStatus } from 'src/selectors/manage-subcontractor.selector';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IUpdateSubsSapIdTesting } from 'src/models/manage-subcontractor.model';
import SapIdUpdateForm from './sapId-update/sapId-update.component';
import UpdateSubcontractorSapIdAuditLog from './update-subcontractor-sapid-audit-log/update-subcontractor-sapid-audit-log.component';
import XLSX from 'xlsx-js-style';
import {
    border,
    leftAlignment,
    rightAlignment,
    centerAlignment,
    reportHeaderBackground,
    reportCellFont,
    reportHeaderFont,
} from 'src/constants/common.constant';
import { each } from 'lodash';
import { useSummaryContext } from 'src/contexts/summary.context';
import { async } from 'q';

const UpdateSubcontractorSapId: React.FC = () => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const { state: summaryState, actions: summaryAction } = useSummaryContext();
    const [loading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [sapId, setSapId] = useState<string>('');
    const [editData, setEditData] = useState<IUpdateSubsSapIdTesting>();
    const [updateConfirm, setUpdateConfirm] = useState(false);
    const [refreshView, setRefreshView] = useState(false);
    const search: string = window?.location?.search || '?page=1&limit=20';
    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const dialogRef = useRef<string>();
    const dataRef = useRef<any>();
    const searchRef = useRef<any>();
    const formRef = useRef<HTMLFormElement>();
    const [hidden, setHidden] = useState<boolean>(false);
    const [reasonId, setReasonId] = useState<number>(0);
    const [SubContractName, setSubContractName] = useState();
    const [VerificationNumber, setVerificationNumber] = useState();
    const [PrimeName, setPrimeName] = useState();
    const [SapID, setSapID] = useState();
    const [zeroReport, setZeroReport] = useState(false);
    const [currentYear, setCurrentYear] = useState(Number(localStorage.getItem('reportYear')));

    const [openAuditLogs, setOpenAuditLogs] = useState(false);

    const fetchReportData = useCallback(async () => {
        const data = await summaryAction.reportingyearsGet();
        localStorage.setItem('reportYearUpdate', data.payload.data[0].report_year)
        if (localStorage.getItem('reportYear') != localStorage.getItem('reportYearUpdate')) {
            localStorage.setItem('reportYear', data.payload.data[0].report_year)
            setCurrentYear(data.payload.data[0].report_year);
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    }, []);

    useEffect(() => {
        fetchReportData();
    }, [actions]);

    useEffect(() => {

        setSubContractName(searchRef.current?.subcontractor_name);
        setVerificationNumber(searchRef.current?.verification_number);
        setPrimeName(searchRef.current?.prime_name);
        setSapID(searchRef.current?.sap_id);
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
    }, []);

    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }

    const displayZeroSpend = useCallback(async (data) => {
        if (data) {
            searchRef.current = [];
            setZeroReport(false)
        } else {
            searchRef.current = [];
            setZeroReport(true)
        }
        // const newData = { ...searchRef.current, ...{ [key]: value } };
        // searchRef.current = newData;
    }, []);

    const generateReport = useCallback(async () => {
        await callGenerateReport();
    }, [currentYear]);





    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            state.reasons?.data.length == 0 && (await actions.Reason_getList());
            const { page, limit, sort, order } = params;
            tableParamsRef.current = params;
            const param =
                {
                    reportYear: currentYear,
                    sort: sort || '',
                    order: order || '',
                    page: page,
                    limit: limit,
                    subcontractorName: searchRef.current?.subcontractor_name || '',
                    verificationNumber: searchRef.current?.verification_number || '',
                    primeName: searchRef.current?.prime_name || '',
                    sapID: searchRef.current?.sap_id || '',
                    isToggled: zeroReport,
                } || queryParams;
            //if (currentYear !== '') {
                await actions?.updateSubsSapId(param);
            //}
        },
        [actions, zeroReport, currentYear]
    );

    const handleEdit = useCallback(
        async (data) => {
            dataRef.current = data;
            setEditData(data);
            setSapId(data.sap_id);
            setHidden(data.hidden);
            setReasonId(data.reasonId);
            setConfirm(true);
        },
        [setConfirm]
    );

    const handleSubmit = () => {
        return;
    };

    const handleSubmitConfirm = useCallback(
        async (params: IORGDataTableQueryState) => {
            if (dialogRef.current === 'open') {
                if (sapId || hidden != editData?.hidden) {
                    let isHiddenChanged = false;
                    if (reasonId != dataRef.current.reasonId) {
                        isHiddenChanged = true;
                    }
                    const payload = {
                        subcontractor_id: editData?.subcontractor_id,
                        sap_id: sapId,
                        verification_number: editData?.verification_number || '0',
                        hidden: hidden,
                        reasonId: reasonId,
                        VSID: editData?.VSID || '0',
                        prevSapId: editData?.sap_id,
                        subcontractor_name: editData?.subcontractor_name,
                        isHiddenChanged: isHiddenChanged,
                        prime_name: editData?.prime_name,
                    };

                    const response: any = await actions.updateSubsSapIdPost(
                        payload
                    );

                    if (response?.payload) {
                        ToastSuccess('SAP ID has been updated successfully.');
                        setConfirm(false);
                        fetchData(params);
                    } else if (!response?.error?.status) {
                        ToastError(response.error?.message);
                        setConfirm(true);
                    }
                }
                setUpdateConfirm(false);
            } else {
                setUpdateConfirm(false);
                setConfirm(false);
            }
        },
        [sapId, hidden, actions, reasonId]
    );
    const handleClosePopUp = useCallback(() => {
        // close popup form for update form
        if (sapId === editData?.sap_id && hidden === editData?.hidden) {
            setConfirm(false);
        } else {
            setUpdateConfirm(true);
            dialogRef.current = 'close';
            if (!!editData) {
                const data = editData;
                data['sap_id'] = sapId;
                data['hidden'] = hidden;
                data['reasonId'] = reasonId;
                setEditData(data);
            }
        }
    }, [setConfirm, editData, sapId, hidden, reasonId]);

    const handleClick = useCallback(() => {
        dialogRef.current = 'open';
        if (formRef && formRef.current) {
            formRef.current.handleSubmit();
        }
        setUpdateConfirm(true);
        setConfirm(true);
    }, [setUpdateConfirm, sapId, editData, hidden, reasonId]);

    const handleChange = (key, val) => {
        if (key === 'sap_id') {
            setSapId(val);
        }
        if (key === 'hidden') {
            setHidden(val);
        }
        if (key === 'reasonId') {
            setReasonId(val);
        }
    };

    const handleOnClose = useCallback(async () => {
        if (sapId !== editData?.sap_id || hidden !== editData?.hidden) {
            setUpdateConfirm(false);
            setRefreshView(false);
            dialogRef.current = 'close';
        }
        await setRefreshView(true);
        setUpdateConfirm(false);
        await setRefreshView(false);
    }, [sapId, editData, hidden, reasonId]);

    const handleChangeSearch = (key, value: any) => {
        const newData = { ...searchRef.current, ...{ [key]: value } };
        searchRef.current = newData;
    };
    const handleClear = useCallback(async () => {
        searchRef.current = [];
        const param =
            {
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
    }, []);

    const handleSearch = useCallback(async () => {
        const param =
            {
                subcontractor_name: searchRef.current?.subcontractor_name || '',
                verification_number: searchRef.current?.verification_number || '',
                prime_name: searchRef.current?.prime_name || '',
                sap_id: searchRef.current?.sap_id || '',
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
    }, []);



    const callGenerateReport = useCallback(
        async () => {
            const param = { reportYear: currentYear };
            console.log(param);
            //if (currentYear != '') {
                const response = await actions?.spendRecordSapIdZero(param);
                if (response.payload.status) {
                    const activeData: any = [];
                    const inActiveData: any = [];
                    response.payload.data.flatMap((j) => {
                        if (j.STATUS === 'Inactive') {

                            inActiveData.push({
                                'CONTRACT NUMBER': j.contractNumber,
                                'SEMPRA COMPANY ID': j.sempraCompanyId,
                                'SEMPRA COMPANY NAME': j.sempraCompanyName,
                                'EXPIRY DATE': j.expiryDate,
                                'VENDOR ID': j.vendorId,
                                'SPEND YEAR': j.spendYear,
                                'SPEND MONTH': j.spendMonth,
                                'SPEND AMOUNT': j.spendAmount,
                                'VENDOR SAP ID': j.venSapId,
                                'VENDOR NAME': j.vandorName,
                                'SUBCONTRACTOR SAP ID': j.subSapId,
                                'SUBCONTRACTOR NAME': j.subName,
                                'STATUS': j.STATUS,

                            })
                        } else {
                            activeData.push({
                                'CONTRACT NUMBER': j.contractNumber,
                                'SEMPRA COMPANY ID': j.sempraCompanyId,
                                'SEMPRA COMPANY NAME': j.sempraCompanyName,
                                'EXPIRY DATE': j.expiryDate,
                                'VENDOR ID': j.vendorId,
                                'SPEND YEAR': j.spendYear,
                                'SPEND MONTH': j.spendMonth,
                                'SPEND AMOUNT': j.spendAmount,
                                'VENDOR SAP ID': j.venSapId,
                                'VENDOR NAME': j.vandorName,
                                'SUBCONTRACTOR SAP ID': j.subSapId,
                                'SUBCONTRACTOR NAME': j.subName,
                                'STATUS': j.STATUS,

                            })
                        }
                    });
                    // SEMPRA COMPANY NAME	EXPIRY_DATE	CONTRACT_TABLE_VENDOR_ID    SPEND_YEAR	SPEND_MONTH	SPEND_AMOUNT	VENDOR_TABLE_SAP_ID	VENDOR_TABLE_VENDOR_NAME	SUBCONTRACTOR_SAP_ID	SUBCON_TABLE_SUBCONTRACTOR_NAME	STATUS
                    const wscols = [
                        { wch: 20 },
                        { wch: 25 },
                        { wch: 30 },
                        { wch: 20 },
                        { wch: 30 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 25 },
                        { wch: 40 },
                        { wch: 25 },
                        { wch: 40 },
                        { wch: 20 }
                    ];

                    let active: any = null;
                    let inactive: any = null;
                    try {
                        active = XLSX.utils.json_to_sheet(activeData);
                        //Format Excel
                        for (const key in active) {
                            if (key.replace(/[^0-9]/gi, '') === '1') {
                                const alignment = { ...centerAlignment, wrapText: true };
                                active[key].s = {
                                    fill: reportHeaderBackground,
                                    alignment: alignment,
                                    font: reportHeaderFont,
                                    border: border,
                                };
                            } else {
                                const alignment = leftAlignment;
                                active[key].s = {
                                    font: reportCellFont,
                                    border: border,
                                    alignment: alignment,
                                };
                            }
                        }
                    } catch (e) { }

                    try {
                        inactive = XLSX.utils.json_to_sheet(inActiveData);
                        //Format Excel
                        for (const key in inactive) {
                            if (key.replace(/[^0-9]/gi, '') === '1') {
                                const alignment = { ...centerAlignment, wrapText: true };
                                inactive[key].s = {
                                    fill: reportHeaderBackground,
                                    alignment: alignment,
                                    font: reportHeaderFont,
                                    border: border,
                                };
                            } else {
                                const alignment = leftAlignment;
                                inactive[key].s = {
                                    font: reportCellFont,
                                    border: border,
                                    alignment: alignment,
                                };
                            }
                        }
                    } catch (e) { }

                    active['!cols'] = wscols;
                    //unverified['!cols'] = wscols;
                    inactive['!cols'] = wscols;

                    const workbook = {
                        Sheets: {
                            'Active': active,
                            'Inactive': inactive,
                        },
                        SheetNames: ['Active', 'Inactive'],
                    };

                    const excelBuffer = XLSX.write(workbook, {
                        bookType: 'xlsx',
                        type: 'array',
                    });

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    saveData(excelBuffer, 'spends_recorded_against_subcontractors_with_zero_sap_id.xlsx');

                }
            //}
        },
        [actions, currentYear]
    );

    return (
        <>
            <UpdateSubcontractorSapIdView
                data={state?.subsSapList.data || []}
                fetchData={fetchData}
                totalRecord={state.subsSapList.total_rows || 0}
                handleEdit={handleEdit}
                loading={
                    loading ||
                    getSPStatus(state, actionTypes.LIST_SUBCONTRACTOR_SAPID)
                        .fetching
                }
                handleChangeSearch={handleChangeSearch}
                searchRef={searchRef}
                handleSearch={handleSearch}
                handleClear={handleClear}
                setSubContractName={setSubContractName}
                setVerificationNumber={setVerificationNumber}
                setPrimeName={setPrimeName}
                setSapID={setSapID}
                handleOpenAuditLogs={handleOpenAuditLogs}
                displayZeroSpend={displayZeroSpend}
                zeroReport={zeroReport}
                generateReport={generateReport}
            />
            {!refreshView && (
                <SapIdUpdateForm
                    isOpen={confirm}
                    handleClosePopUp={handleClosePopUp}
                    fdata={state.subsSapList.data?.find(
                        (item) => item.sap_id === sapId
                    )}
                    editData={editData}
                    handleClick={handleClick}
                    handleChange={handleChange}
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    reasonList={state.reasons}
                />
            )}
            {updateConfirm && (
                <Confirm
                    open={updateConfirm}
                    size="tiny"
                    content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() =>
                        handleSubmitConfirm({
                            page: parseInt(page ?? '1'),
                            limit: parseInt(limit ?? '20'),
                        })
                    }
                />
            )}
            {openAuditLogs && (
                <UpdateSubcontractorSapIdAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );
};


export const saveData = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function (data, fileName) {
        const blob = new Blob([data], { type: 'octet/stream' });
        let url = '';
        try {
            url = window.URL.createObjectURL(blob);
        } catch (e) { }

        a.href = url;
        a.download = fileName;
        a.click();
        try {
            window.URL.revokeObjectURL(url);
        } catch (e) { }
    };
})();
export default UpdateSubcontractorSapId;

import React, { useEffect, useState } from 'react';
import { ATMTable, ORGDataTable, ATMLoader } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from '../report.module.scss';
import '../report.scss';
import { formatter } from '../report.component';

const MonthSummaryReportView: React.FC<any> = ({
    verifiedData,
    unVerifiedData,
    loading,
}) => {
    const [refresh, setRefresh] = useState(false);
    const renderCell = (record, month) => {
        if (!record.subcontractor) {
            return <span>{formatter.format(record[month.toLowerCase()])}</span>;
        } else {
            if (record[month.toLowerCase()] !== 0) {
                return (
                    <span>{formatter.format(record[month.toLowerCase()])}</span>
                );
            } else {
                return <span>-</span>;
            }
        }
    };

    useEffect(() => {
        handleRefresh();
    }, [unVerifiedData, verifiedData]);

    const handleRefresh = async () => {
        await setRefresh(true);
        await setRefresh(false);
    };

    const titles = [
        'Spend Type',
        'Subcontractor',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Total',
    ];

    const columns = titles.map((i) => {
        if (i === 'Subcontractor') {
            return {
                title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SUBCONTRACTOR,
                index: i,
                render: ($, record) => {
                    if (!record.subcontractor) {
                        return <span >{Lang.LBL_REPORT_MONTH_TOTALS}</span>;
                    }
                    return <span>{record.subcontractor}</span>;
                },
                cellProps: { borderLeft: 0, },
            };
        } else if (i === 'Spend Type') {
            return {
                title: 'Spend Type',
                index: i,
                render: ($, record) => {
                    if (record.isTier3Spend) {
                        return <span>Tier III</span>;
                    } else if (!record.subcontractor) {
                        return <span></span>
                    } else {
                        return <span>Tier II</span>;
                    }
                },
                cellProps: { borderLeft: 0 },
            };
        } else {
            return {
                title: i,
                index: i,
                render: ($, record) => renderCell(record, i),
            };
        }
    });

    return loading === true ? (
        <div style={{ paddingTop: '40px' }}>
            <ATMLoader active inline="centered" size="large" />
        </div>
    ) : (
        <>
            {verifiedData?.length > 1 && !refresh && (
                <ATMTable className={style.gridTable}>
                    <ATMTable.Header>
                        <ATMTable.Row
                            className={`${style.tabeheader} ${style.green}`}
                            style={{ fontWeight: 'bold' }}
                        >
                            <span>{Lang.LBL_SPEND_VERIFIED_SPEND}</span>
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                        <ORGDataTable
                            celled={false}
                            loading={false}
                            columns={columns}
                            data={verifiedData || []}
                            addParams={['keyword']}
                            history
                            showPagination={false}
                            className={style.table1}
                        />
                    </ATMTable.Body>
                </ATMTable>
            )}

            {unVerifiedData?.length > 1 && !refresh && (
                <ATMTable className={style.gridTable}>
                    <ATMTable.Header>
                        <ATMTable.Row
                            className={`${style.tabeheader}`}
                            style={{ color: '#db2828', fontWeight: 'bold' }}
                        >
                            <span>
                                {Lang.LBL_SPEND_SUMMARY_UNVERIFIED_SPEND}
                            </span>
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                        <ORGDataTable
                            celled={false}
                            loading={false}
                            columns={columns}
                            data={unVerifiedData || []}
                            addParams={['keyword']}
                            history
                            showPagination={false}
                            className={`${style.table1} ${style.table2}`}
                        />
                    </ATMTable.Body>
                </ATMTable>
            )}
        </>
    );
};

export default MonthSummaryReportView;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import { ResetPasswordResponseSchema } from 'src/models/reset-password.model';

const client = httpClient();

export const services = {
    resetPasswordPOST: async (email) => {
        return client.post(
            '/resetPassword',
            { email },
            ResetPasswordResponseSchema
        );
    },
};
export default services;

import moment from 'moment-timezone';
import React from 'react';
import { ATMIcon, ATMModal, IORGDataTableColumn, IORGDataTableQueryState, ORGDataTable } from 'shared-it-appmod-ui';
import { concatinateArrayWithSpace } from 'src/components/pages/utils/ArrayUtils';
import Lang from 'src/libraries/language';

interface IProps {
    open: boolean;
    handleClose: () => void;
    loading: boolean;
    fetchData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
    handleOpenEmailView: (data) => void;
    data: any

}



const MassMailAuditLogView: React.FC<IProps> = ({ open,
    handleClose,
    loading,
    fetchData,
    totalRecord,
    handleOpenEmailView,
    data }) => {
    const columns: IORGDataTableColumn<any>[] = [
        {
            title: Lang.TTL_MESSAGE,
            index: 'message',
            render: (_, value) => <span>{Lang.LBL_MASS_EMAIL_SENT}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: Lang.TTL_TRIGGERE_BY,
            index: 'triggered_by',
            render: (_, value) => <span>{concatinateArrayWithSpace([value.firstName, value.lastName])===''?'-':concatinateArrayWithSpace([value.firstName, value.lastName])}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        }, {
            title: Lang.TTL_TIME_STAMP,
            index: 'timestamp',
            render: (_, value) => <span>{`${value?.loggedAt && value?.loggedAt != '-' ? moment.utc(value?.loggedAt).format('MM/DD/YYYY hh:mm A') : '-'}`}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: Lang.TTL_EMAIL,
            index: 'email',
            sortable: false,
            render: (_, value) => <span>{!value.status && <i
                style={{
                    color: '#009BDA',
                    // fontWeight: 'bold',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                }}
                onClick={() => handleOpenEmailView(value)}
            >{'Click to view Email'}</i>}</span>,
            headerProps: {
                //className: Style.prime,
            },
        }
    ];
    return (
        <ATMModal
            size="large"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                {Lang.TTL_MASS_EMAIL_AUDIT_LOGS}
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060', position: 'absolute', 'right': '0', top: '5px', cursor: 'pointer' }} />

            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={data}
                    loading={loading}
                    sortable
                    counter
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchData}
                    total={totalRecord}
                    key={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    )
}

export default MassMailAuditLogView
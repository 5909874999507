/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    contractsSchema,
    SpendListPayloadSchema,
    subcontractorsSchema,
    UpdateListPayloadSchema,
    primeContractsSchema,
    BlockAndUnblockExternalUserGetSchema,
    ReportingyearsGetSchema
} from '../models/spend.model';
import {
    ContractorSchema
} from '../models/contractor.model';
const client = httpClient();

export const services = {
    listGET: async (params) => {
        const response = client.get(
            '/spendData',
            params,
            SpendListPayloadSchema
        );
        return response;
    },
    listUPDATE: async (params) => {
        const response = client.put(
            '/spendData',
            params,
            UpdateListPayloadSchema
        );
        return response;
    },
    listSUBCONTRACTS: async (params) => {
        const response = client.get(
            '/SubcontractorUnderPrime',
            params,
            subcontractorsSchema
        );
        return response;
    },
    listCONTRACTS: async (params) => {
        const response = client.get('/poNumbers', params, contractsSchema);
        return response;
    },
    listPRIMECONTRACTS: async (params) => {
        const response = client.get('/getContractsToAddByPrime', params, primeContractsSchema);
        return response;
    },
    listCONTRACTS_ALL: async (params) => {
        const response = client.get('/contracts', params, contractsSchema);
        return response;
    },
    listDistSUBCONTRACTORS: async (params) => {
        const response = client.get(
            '/getDistinctSubContractorsUnderPrime',
            params,
            subcontractorsSchema
        );
        return response;
    },
    notSpendMonthUPDATE: async (params) => {
        const response = client.put(
            '/notSpendMonth',
            params,
            UpdateListPayloadSchema
        );
        return response;
    },
    blockAndUnblockExternalUserGet: async () => {
        return client.get('/blockAndUnblockExternalUser', {}, BlockAndUnblockExternalUserGetSchema);
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
    primeContractor: async (params) => {
        return client.get('/primeContractor', params, ContractorSchema);
    },
};
export default services;

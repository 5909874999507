import * as yup from 'yup';
import Lang from './language';

yup.setLocale({
  mixed: {
    required: Lang.MSG_ERROR_REQUIRED,
  },
});

export default yup;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import {
    getSubcontractorStatus,
    getTableListParams,
} from 'src/selectors/subcontractor.selector';
import { actionTypes } from 'src/ducks/subcontractor.duck';
import SubcontractorAdd from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-add-view/subcontractor-add-view.component';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import AdminSubcontractorView from './admin-subcontractor.view';

const AdminSubcontractor: React.FC = () => {
    const { state, actions } = useSubcontractorContext();
    const [open, setOpen] = useState(false);
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
    });
    const [loading, setLoading] = useState(false);
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const dataRef = useRef<any>();

    const search: string = window?.location?.search || '?page=1&limit=10';

    const formRef = useRef<HTMLFormElement>();

    useEffect(() => {
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '10'),
            });
        }
    }, []);
    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const { page, limit, sort, order } = params;
            tableParamsRef.current = params;
            let data: any = {};
            if (params != undefined && params) {
                data = getTableListParams(params);
            }
            const param =
                {
                    sort: sort || '',
                    order: order || '',
                    page: page,
                    limit: limit,
                    subcontractorName: data.name || '',
                    state: data.state || '',
                    city: '',
                    name_begin_with: data.name_begin_with || '',
                    verification_expiration_status:
                        data.verification_expiration_status || '',
                } || queryParams;
            const datanew = await actions.adminSubcontractors(param);
        },
        [actions]
    );

    const handleSearch = useCallback(async () => {
        setLoading(true);
        const searchFilterParams =
            {
                subcontractorName: dataRef.current?.name || '',
                state: dataRef.current?.state || '',
                city: '',
                name_begin_with: dataRef.current?.name_begin_with || '',
                verification_expiration_status:
                    dataRef.current?.verification_expiration_status || '',
                page: 1,
                limit: 10,
            } || queryParams;
        await fetchData(searchFilterParams);
        setLoading(false);
    }, [setLoading]);

    const handleClear = useCallback(async () => {
        dataRef.current = [];

        setLoading(true);
        const param =
            {
                page: 1,
                limit: 10,
            } || queryParams;
        await fetchData(param);
        setLoading(false);
    }, [setLoading]);

    const handleFilter = (key, value: any) => {
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const handleViewSubContractor = useCallback(
        async (id) => {
            const datanew = await actions.DetailGET_Not_Prime(id);
            setOpen(true);
        },
        [setOpen]
    );
    const handleClose = useCallback(() => {
        // close popup form view form
        setOpen(false);
    }, [setOpen]);
    return (
        <>
            <AdminSubcontractorView
                data={state?.adminSucbontractorList}
                fetchData={fetchData}
                loading={
                    loading ||
                    getSubcontractorStatus(
                        state,
                        actionTypes.ADMIN_SUBCONTRACTOR
                    ).fetching
                }
                totalRecord={state?.adminSucbontractorList?.totalRows || 0}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                handleClear={handleClear}
                verificationStatus={
                    state?.adminSucbontractorList?.filters_data
                        ?.verificationExpirationStatus || []
                }
                handleViewSubContractor={handleViewSubContractor}
                dataRef={dataRef}
            />
            <SubcontractorAdd
                isOpen={open}
                handleClose={handleClose}
                data={state?.notPrimeDetail?.data || { data: [] }}
                formRef={formRef}
            />
        </>
    );
};

export default AdminSubcontractor;

import React from 'react';
import Lang from 'src/libraries/language';
import { ATMMessage } from 'shared-it-appmod-ui';

export interface IProps {
    title?: string;
    content?: string;
}

const Instruction: React.FC<IProps> = ({ content }) => {
    return (
        <>
            <ATMMessage info style={{ marginBottom: '15px' }} className='small'>
                <ATMMessage.Header>{Lang.LBL_NOTE}</ATMMessage.Header>
                <p>{content}</p>
            </ATMMessage>
        </>
    );
};

export default Instruction;

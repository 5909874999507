import Lang from 'src/libraries/language';
import yup from '../libraries/validator.library';

export const LoginResponseSchema = yup.object({
    data: yup
        .object({
            token: yup.string(),
            change_password: yup.boolean(),
            errorCode: yup.number(),
            user_email: yup.string(),
            prime_id: yup.number(),
            user_group: yup.string(),
            user_group_id: yup.string(),
            reports: yup.array().of(yup.mixed()),
            menu_items: yup.array().of(yup.mixed()),
            first_name: yup.string(),
            full_name: yup.string(),
            last_name: yup.string().nullable(),
            username: yup.string().nullable(),
            user_id: yup.number(),
        })
        .defined(),
});

export const LoginFormSchema = yup.object({
    user_email: yup
        .string()
        .email('Please enter valid email Address.')
        .required(Lang.MSG_LOGIN_EMAIL_REQUIRED),
    password: yup.string().required(Lang.MSG_LOGIN_PASSWORD_REQUIRED),
    certify: yup.string().required(Lang.MSG_LOGIN_INFO_CERTIFY_REQUIRED),
});

export const ForgotPassSchema = yup.object({
    forgot_pass_email: yup
        .string()
        .email('Please enter valid email Address.')
        .required(Lang.MSG_LOGIN_EMAIL_REQUIRED),
});
export const ForgotPassResPayloadSchema = yup.object({
    message: yup.string(),
    status: yup.boolean(),
});

export const RefreshTokenPayloadSchema = yup.object({
    data: yup.string(),
    message: yup.string(),
});

// CDC login
export const GetCDCSAPLoginURLSchema = yup.object({    
    message: yup.string(),
});
export const PostAccessTokenRequestDataSchema = yup.object({    // post data for accessing token data in CDC login 
    requestId: yup.string(),
    nameId: yup.string(),
    cdcUID: yup.string(),
    sessionIndex: yup.string(),
});


export type ILogin = yup.InferType<typeof LoginFormSchema>;
export type ILoginResponse = yup.InferType<typeof LoginResponseSchema>;
export type IForgotPass = yup.InferType<typeof ForgotPassSchema>;
export type IRefreshTokenPayload = yup.InferType<
    typeof RefreshTokenPayloadSchema
>;
export type IPostAccessTokenRequestData = yup.InferType<typeof PostAccessTokenRequestDataSchema>;
import React, { useEffect, useState } from 'react';
import MonthSummaryReportView from './month-summary-report-tier-III.view';
import MonthSummaryReportViewTierIII from './month-summary-report-tier-III.view';

const MonthSummaryReportTierIIIComponent: React.FC<any> = ({
    subcontractors,
    structureData,
    monthSummaryList,
    loading
}) => {
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        handleRefresh();
    }, [monthSummaryList]);

    const handleRefresh = async () => {
        await setRefresh(true);
        await setRefresh(false);
    };

    return (
        <>
            {!refresh && (
                <MonthSummaryReportViewTierIII
                    verifiedData={structureData(
                        monthSummaryList?.data,
                        'verified',
                        subcontractors
                    )}
                    loading={loading}
                    unVerifiedData={structureData(
                        monthSummaryList?.data,
                        'unverified',
                        subcontractors
                    )}
                />
            )}
        </>
    );
};

export default MonthSummaryReportTierIIIComponent;

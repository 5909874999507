import React, { useState, useCallback, useRef, useEffect } from 'react';
import ViewAllSubAuditLogView from './view-all-subs-audit-log.view';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const ViewAllSubAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [loadinglog, setLoadinglog] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
   

    // const fetchLogData = useCallback(async () => {
    //     const data = await actions.viewAllSubAuditLog({page:1,limit:10});
    //     setLoading(false)
    //     },
    //     [actions]
    // );
    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.viewAllSubAuditLog(param);
            setLoadinglog(false);
        },
        [actions]
    );



    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);
    return (
        <>
            <ViewAllSubAuditLogView
                data={state?.viewAllSubAuditLogs?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.viewAllSubAuditLogs?.total_rows || 0}
            />

        </>
    );
};

export default ViewAllSubAuditLog;

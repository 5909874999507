import React from 'react';
import { Button } from 'semantic-ui-react';
import { ATMMessage } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';

export type IProps = {
    sp3Loading: boolean;
    fetchReport: () => void;
    loadEvent: boolean;
};

const AdHocSpendDataView: React.FC<IProps> = ({
    sp3Loading,
    fetchReport,
    loadEvent,
}) => {
    return (

        <>
            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                {sp3Loading && (<div>
                    <ATMMessage error>
                        <ATMMessage.Header>{Lang.LBL_NOTE}</ATMMessage.Header>
                        <p style={{ marginTop: '16px' }}>
                        AD-Hoc Spend cannot be accessed right now, as SCH file is being processed in the background. Please return to this page after some time and try again
                        </p>
                    </ATMMessage>
                </div>)}
                <ATMGrid columns={1}>
                    <ATMGrid.Row >
                        
                        <ATMGrid.Column style={{ textAlign: 'center',paddingTop: '25px',paddingBottom: '25px' }}>
                            <ATMButton
                                size="small"
                                style={{ marginRight: '20px' }}
                                primary
                                onClick={() => fetchReport()}
                                loading={loadEvent}
                                disabled={loadEvent ? true :sp3Loading}

                            >
                                {Lang.LBL_GENERATE} Ad-hoc Spend File
                            </ATMButton>
                            
                            {/* <ATMButton
                                disabled={!showGenerate}
                                size="small"
                                style={{ margiLeft: '20px' }}
                                primary
                            >
                                {Lang.LBL_DOWNLOAD}
                            </ATMButton> */}
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>

            </div>
        </>
    );
};

export default AdHocSpendDataView;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import YearEndRolloverAuditLogView from './year-end-rollover-audit-log.view';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const YearEndRolloverAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [loadinglog, setLoadinglog] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
   
    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.yearEndRolloverAuditLogGet(param);
            setLoadinglog(false);
        },
        [actions]
    );



    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);
    return (
        <>
            <YearEndRolloverAuditLogView
                data={state?.yearEndRolloverAuditLogGet?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.yearEndRolloverAuditLogGet?.total_rows || 0}
            />

        </>
    );
};

export default YearEndRolloverAuditLog;

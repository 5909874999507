import React, { useState, useMemo, useEffect } from 'react';
import { routes } from 'src/router';
import history from 'src/history';
import NavigationView from './navigation.view';
import { MenuItemProps } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

export type INavigationLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps
) => void;

const Navigation: React.FC = () => {
    const urlLocation = useLocation();
    const [active, setActive] = useState<string[]>([]);

    // const handleItemClick = useCallback(
    //     (e, { name }) => {
    //         if (name) {
    //             setActive([name]);
    //         }
    //     },
    //     [setActive]
    // );

    const list = useMemo(() => routes.filter((value) => value.label), []);

    useEffect(() => {
        let list1 = history.location.pathname
            .replace(/^\/+/, '')
            .split('/')
            .filter((value) => value);

        if (list1.length) {
            const selected: string[] = [];

            list1.forEach((route, key) => {
                if (selected[key - 1]) {
                    selected.push(`${selected[key - 1]}/${route}`);
                } else {
                    selected.push(`/${route}`);
                }
            });

            list1 = selected;
        }

        setActive(list1.length ? list1 : ['/']);
    }, [urlLocation, setActive]);

    return (
        <NavigationView
            routes={list}
            active={active}
            // handleItemClick={handleItemClick}
        />
    );
};

export default Navigation;

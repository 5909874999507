/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import { ContactUserListPayloadSchema } from '../models/contactuser.model';
const client = httpClient();

export const services = {
    ContactUserList: async (params) => {
        return client.get(
            `/primeContactInfoList`,
            params,
            ContactUserListPayloadSchema
        );
    },
};

export default services;

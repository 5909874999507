import React, { useState, useCallback, useRef, useEffect } from 'react';
import PrimeTier3SubcontractorsAuditLogView from './prime-tier3-subcontractors-audit-log.view';
// import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useContactPrimesContext } from 'src/contexts/contact-primes.context';
import PrimeTierIIIAuditLogEmailView from './email/prime-tier3-audit-log-email.view';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const PrimeTier3SubcontractorsAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = useContactPrimesContext();
    const [loadinglog, setLoadinglog] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [openEmailView, setOpenEmailView] = useState<boolean>(false);
    const [auditLogData, setAuditLogData] = useState<any>({});

    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            const daatnew = await actions?.allowPrimeTier3SubcontractorsAuditLogGet(param);
            setLoadinglog(false);
        },
        []
    );

    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);

    const handleOpenEmailView = useCallback((data) => {
        setAuditLogData(data)
        setOpenEmailView(true)
    }, []);

    const handleCloseEmailView = () => {
        setOpenEmailView(false);
        setAuditLogData({})
    }
    return (
        <>
            {openEmailView && <PrimeTierIIIAuditLogEmailView
                open={openEmailView}
                handleClose={handleCloseEmailView}
                data={auditLogData} />}

            <PrimeTier3SubcontractorsAuditLogView
                data={state?.allowPrimeTire3SubcontractorsAuditLogGet?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.allowPrimeTire3SubcontractorsAuditLogGet?.total_rows || 0}
                handleOpenEmailView={handleOpenEmailView}
            />

        </>
    );
};

export default PrimeTier3SubcontractorsAuditLog;

import { useCallback } from 'react';

const useReducerLogger: any = (reducer) =>
  useCallback(
    (state, action) => {
      const next = reducer(state, action);

      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        // eslint-disable-next-line no-console  
        console.error('%cAction:', 'color: #00A7F7; font-weight: 700;', action);

        // eslint-disable-next-line no-console
        console.error('%cNext State:', 'color: #47B04B; font-weight: 700;', next);
      }

      return next;
    },
    [reducer]
  );

export default useReducerLogger;


export enum AccessRole {
  PRIME = 4,
  CONTACT = 1,
  ADMIN = 3,
  DBE_ADVISOR = 2,
  GUEST = 0
}

export enum AccessType {
  ADMIN_READ,
  CONTACT_READ,
  CONTACT_UPDATE,
  PRIME_READ,
  PRIME_UPDATE,
  REPORT_READ,
}

export const statusTypes = [
  {key:"ALL", value:"All", text:"All"},
  {key:1, value:"Active", text:"Active"},
  {key:0, value:"Inactive", text:"Inactive"}
]

export const AccessPermission = {
  [AccessRole.PRIME]: [AccessType.PRIME_READ, AccessType.REPORT_READ],
  [AccessRole.CONTACT]: [AccessType.CONTACT_READ, AccessType.CONTACT_UPDATE],
  [AccessRole.ADMIN]: [AccessType.CONTACT_READ, AccessType.CONTACT_UPDATE],
};


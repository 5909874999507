import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useAnnouncementContext } from 'src/contexts/announcement.context';
import {
    ORGDataTable,
    IORGDataTableColumn,
    ATMHeader,
    ATMButton,
    ATMMessage,
    ATMTable,
} from 'shared-it-appmod-ui';
import { Container } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import { IAnnouncementSchema } from 'src/models/announcement.model';
import style from './manage-announcements-info.module.scss';
import moment from 'moment';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import SendAnnouncement from '../send-announcements/send-announcements.module';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { useContractorContext } from 'src/contexts/contractor.context';
import { FunctionalityDisableText } from 'src/components/pages/constant/message-constant.component';

export const RecordCell: any = ({ record, value, style }) => {
    return (
        <ATMTable.Cell
            className={record.isExpired ? 'expired' : 'valid'}
            style={style ? style : { padding: 0, border: 0 }}
        >
            {value}
        </ATMTable.Cell>
    );
};

const ManageAnnouncementsInfo: React.FC = () => {
    const { state, actions } = useAnnouncementContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const [announcementData, setAnnouncementData] = useState<any>();
    const [isConfirm, setIsConfirm] = useState(false);
    const [announcementId, setAnnouncementId] = useState(0);

    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            checkSCHFileStatus()
            checkPOValidationBatchJobStatus()
            const { page, limit, sort, order } = params;
            setLoading(true);
            tableParamsRef.current = params;
            const param = {
                sort: sort || '',
                order: order || '',
                page: page,
                limit: limit,
            };
            await actions?.getAnnouncementList(param);
            setLoading(false);
        },
        [actions]
    );
    const handleSuccess = (msg) => {
        ToastSuccess(msg);
        setIsConfirm(false);
        fetchData(tableParamsRef.current);
        handleClosePopUp();
    };
    const handleError = (msg) => {
        ToastError(msg);
        setIsConfirm(false);
    };
    // const handleResend = (value) => {
    //     setAnnouncementData(value);
    //     setOpen(true);
    // };
    useEffect(() => {
        const expired = document.querySelectorAll('.expired');
        expired.forEach((expiredItem) => {
            expiredItem?.parentElement?.parentElement?.classList.add(
                style.expiredParent
            );
        });

        const valid = document.querySelectorAll('.valid');
        valid.forEach((validItem) => {
            validItem?.parentElement?.parentElement?.classList.add(
                style.validParent
            );
        });

        // $('.expired').parent().css({
        //     backgroundColor: '#D0191908',
        //     color: '#D01919',
        // });

        // $('.valid').parent().css({
        //     backgroundColor: '#fff',
        //     color: 'rgba(0, 0, 0, 0.87)',
        // });
    }, [state?.announcementList?.data]);
    const handleNewAnnouncementModalOpen = () => {
        setAnnouncementData({});
        setOpen(true);
    };
    const handleDelete = (announcementId) => {
        setAnnouncementId(announcementId);
        setIsConfirm(true);
    };
    const columns: IORGDataTableColumn<IAnnouncementSchema>[] = [
        {
            title: Lang.LBL_ANNOUNCEMENT,
            index: 'description',
            sortable: true,
            render: (_, record): any => (
                <RecordCell record={record} value={record.description} />
            ),
            headerProps: {
                className: style.announcementDescriptionHeader,
            },
        },
        {
            title: Lang.LBL_ANNOUCEMENT_TYPE,
            index: 'announcementType',
            sortable: true,
            render: (_, record): any => (
                <RecordCell record={record} value={record.announcementType} />
            ),
            headerProps: {
                className: style.announcementHeader,
            },
        },
        {
            title: Lang.LBL_ANNOUCEMENT_DATED_POSTED,
            index: 'sendDate',
            sortable: true,
            render: (_, record): any => (
                <RecordCell
                    record={record}
                    value={moment(record.sendDate).zone(0).format('MM/DD/YYYY')}
                />
            ),
            headerProps: {
                className: style.announcementHeader,
            },
        },
        {
            title: Lang.LBL_ANNOUNCEMENT_SENT_TO,
            index: 'sentToIds',
            sortable: true,
            render: (_, record): any => (
                <RecordCell
                    record={record}
                    value={record.communicationGroupName}
                />
            ),
            headerProps: {
                className: style.announcementHeader,
            },
        },
        {
            title: Lang.LBL_ANNOUNCEMENT_DURATION,
            index: 'duration',
            sortable: true,
            render: (_, record): any => (
                <RecordCell record={record} value={record.duration} />
            ),
            headerProps: {
                className: style.announcementHeader,
            },
        },
        {
            title: Lang.LBL_DELETE_COLUMN,
            index: 'action',
            sortable: false,
            render: (_, record): any => (
                <ATMTable.Cell
                    className={record.isExpired ? 'expired' : 'valid'}
                    style={{ padding: 0, border: 0 }}
                >
                    {/* <i
                        className="redo icon"
                        style={{
                            background: 'transparent',
                            color: 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleResend(record)}
                    ></i> */}
                    <i
                        className="trash alternate icon"
                        style={{
                            background: 'transparent',
                            border: 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleDelete(record.announcementId)}
                    ></i>
                </ATMTable.Cell>
            ),
            headerProps: {
                className: style.announcementHeader,
            },
        },
    ];
    const handleClosePopUp = () => {
        setOpen(false);
    };
    const handleDeleteConfirm = useCallback(async () => {
        const response = await actions.deleteAnnouncement(announcementId);
        response.payload?.status
            ? handleSuccess(response?.payload?.message)
            : handleError(response?.error?.message);
    }, [actions, setAnnouncementId, announcementId]);

    const handleOnClose = useCallback(async () => {
        setIsConfirm(false);
    }, []);

    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (<>
        {isConfirm && (
            <Confirm
                open={isConfirm}
                size="tiny"
                content={Lang.MSG_DELETE_ANNOUNCEMENT}
                loading={false}
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={handleOnClose}
                onConfirm={() => handleDeleteConfirm()}
            />
        )}
        {open && (
            <SendAnnouncement
                isOpen={open}
                handleClosePopUp={handleClosePopUp}
                // handleSuccess={handleSuccess}
                announcementData={announcementData || { data: {} }}
                fetchData={fetchData}
                params={tableParamsRef.current}
            />
        )}
        <Container fluid className={style.wrapper}>
            <ATMHeader style={{ marginTop: 0 }}>
                <h4>{Lang.TTL_MANAGE_ANNOUNCEMENTS}</h4>
            </ATMHeader>
            <ATMMessage info>
                <ATMMessage.Header>{Lang.LBL_NOTE}</ATMMessage.Header>
                <p style={{ marginTop: '16px' }}>
                    The records highlighted in red are expired
                    announcements. To send new notification click the &quot;
                    New Announcement &quot; Button.
                </p>
            </ATMMessage>
            <div style={{ height: 35 }}>
                <ATMButton
                    primary
                    floated="right"
                    onClick={() => {
                        handleNewAnnouncementModalOpen();
                    }}
                    content={Lang.LBL_NEW_ANNOUNCEMENT}
                />
            </div>

            <div>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={state?.announcementList?.data || []}
                    loading={loading}
                    sortable
                    toolbar
                    history
                    counter
                    filters={false}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchData}
                    total={state?.announcementList?.totalRows}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </div>
        </Container>
    </>)


};

export default ManageAnnouncementsInfo;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DbeSchema, MassMailAuditLogResponseSchema, MassMailSchema } from 'src/models/mass-mail.model';
import { httpClient } from '../libraries/http.library';

const client = httpClient();
export const services = {
    Send_Mass_Mail: async (params) => {
        return client.post('/massEmail', params, MassMailSchema);
    },
    Get_State_List: async () => {
        return client.get('/getStateList', null, MassMailSchema);
    },
    Get_City_List: async (params) => {
        return client.get('/getCityList', params, MassMailSchema);
    },
    Get_Dbe_List: async () => {
        const a = client.get('/getDbeAndContactUserList', null, DbeSchema);
        return a;
    },
    getMassMailAuditLog: async (param) => {
        return client.get('/massEmailAuditLog', param, MassMailAuditLogResponseSchema);
    },
};
export default services;

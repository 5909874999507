import React, { useEffect, useCallback } from 'react';
import { useState } from 'react';
import { ATMSystemMessage } from 'shared-it-appmod-ui';
import { useAdminConsoleContext } from 'src/contexts/admin-console.context';
import style from './system-message.module.scss';

export const setResponse: any = (
    setsystemMessageHeader,
    setsystemMessageContent,
    setNotificationType
) => {
    setsystemMessageHeader('');
    setsystemMessageContent('');
    setNotificationType('');
};

export const setError: any = (
    setsystemMessageHeader,
    setsystemMessageContent
) => {
    setsystemMessageHeader('');
    setsystemMessageContent('');
};

export const catchError: any = (
    setRequested,
    setsystemMessageHeader,
    setsystemMessageContent
) => {
    setRequested(true);
    setsystemMessageHeader('');
    setsystemMessageContent('');
};

const SystemMessageComponent: React.FC = () => {
    const params: any = { appId: process.env.REACT_APP_CMDB_ID };
    const { state, actions } = useAdminConsoleContext();
    const [requested, setRequested] = useState(false);
    const [successResponse, setSuccessResponse] = useState(false);
    const [systemMessageContent, setsystemMessageContent] = useState<[any]>([
        {},
    ]);

    const systemMessageComponentList: any = [];

    useEffect(() => {
        !requested && getAPI();
    }, []);

    const getAPI = useCallback(async () => {
        await actions
            .getAdminConsoleNotificationHeader(params)
            .then((res) => {
                setRequested(true);
                if (!res.error) {
                    setSuccessResponse(true);
                    res.payload.data.notification.map((notific) => {
                        const obj = {
                            key: notific.Id,
                            header: notific.MessageHeader,
                            body: notific.MessageBody,
                            type: notific.MessageType,
                        };
                        systemMessageComponentList.push(obj);
                    });
                    setsystemMessageContent(systemMessageComponentList);
                }
                return null;
            })
            .catch(() => {
                setSuccessResponse(false);
                // catchError();
            });
    }, [
        requested,
        systemMessageContent,
        state,
        actions,
        setsystemMessageContent,
    ]);
    return successResponse ? (
        <div className={style.notification}>
            {systemMessageContent.map((systemMessageComponent) => {
                return (
                    <ATMSystemMessage
                        key={systemMessageComponent.key}
                        notificationType={systemMessageComponent.type}
                        header={systemMessageComponent.header}
                        content={systemMessageComponent.body}
                    />
                );
            })}
        </div>
    ) : null;
};

export default SystemMessageComponent;

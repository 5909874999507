import React, { Fragment } from 'react';
import { Divider } from 'semantic-ui-react';
import {
    ATMGrid,
    ATMLoader,
    ATMLabel,
    ATMPopover,
    ATMHeader,
    ATMSegment,
    ATMContainer,
    ATMButton,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './contractor-info.module.scss';
import { AccessRole } from 'src/constants/access.constant';
import { getUserType } from 'src/libraries/access.library';
import { isContact } from 'src/libraries/users.library';

export type IProps = {
    isAdmin: boolean;
    handleEdit: () => void;
    handleOpen: (isOpen) => void;
    handleDownload: () => void;
    infoData: any;
    isPrime: boolean;
    loading: boolean;
};

const ContractorInfoView: React.FC<IProps> = ({
    infoData,
    handleOpen,
    loading,
    isPrime,
    isAdmin,
    handleEdit,
    handleDownload,
}) => {
    const userType = getUserType();
    return (
        <>
            <ATMContainer
                fluid
                className={
                    +(
                        AccessRole.CONTACT === userType ||
                        AccessRole.ADMIN === userType
                    )
                        ? styles.contactUserCls
                        : styles.wrapperContractorInfo
                }
            >
                {isPrime && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ATMHeader as="h1" styles={{ marginTop: '20px' }}>
                            {Lang.TTL_PRIME_CONTRACTOR}
                        </ATMHeader>
                        <div
                            onClick={() => handleEdit()}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: '13px',
                            }}
                        >
                            <button className="ui primary button">
                                <i
                                    className={`ui icon edit ${styles.ownEdit}`}
                                ></i>

                                {Lang.TTL_EDIT_INFORMATION}
                            </button>
                        </div>
                    </div>
                )}
                {isPrime ? (
                    <ATMSegment>
                        {loading == true ? (
                            <ATMLoader active inline="centered" size="large" />
                        ) : (
                            <InfoTable
                                data={infoData}
                                isPrime={isPrime}
                                isAdmin={isAdmin}
                                handleOpen={handleOpen}
                                handleDownload={handleDownload}
                            />
                        )}
                    </ATMSegment>
                ) : (
                    <>
                        {loading == true ? (
                            <ATMLoader active inline="centered" size="small" />
                        ) : (
                            <InfoTable
                                data={infoData}
                                isPrime={isPrime}
                                isAdmin={isAdmin}
                                handleOpen={handleOpen}
                                handleDownload={handleDownload}
                            />
                        )}
                    </>
                )}
            </ATMContainer>
        </>
    );
};

function CreateATMGridColumn(props: any) {
    const label = props.label;
    const fieldValue = props.fieldValue;
    const isPopOverReq = props.isPopOverReq;

    return (
        <ATMGrid.Column>
            <div className={styles.fieldWrapper}>
                <label className={styles.nameLabelField}>{label}</label>
                <ATMLabel
                    className={styles.inputFieldLabel}
                    customcolor="lightgrey"
                >
                    {isPopOverReq ? (
                        <CreateATMPopover fieldValue={fieldValue} />
                    ) : (
                        fieldValue ?? '' + ' '
                    )}
                </ATMLabel>
            </div>
        </ATMGrid.Column>
    );
}

function CreateATMPopover(props: any) {
    const fieldValue = props.fieldValue;
    const clientWdth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

    return (
        <>
            {fieldValue?.length > 26 || clientWdth <= 911 ? (
                <ATMPopover
                    trigger={
                        <span className={styles.companyNameNowrap}>
                            {fieldValue}{' '}
                        </span>
                    }
                    content={fieldValue}
                    className={styles.summarytooltip}
                    inverted
                    position="bottom center"
                    size="tiny"
                />
            ) : (
                fieldValue ?? '' + ' '
            )}
        </>
    );
}

function InfoTable(props) {
    const data = props.data;
    const isPrime = props.isPrime;
    const isAdmin = props.isAdmin;
    return (
        <>
            {data?.length === 0 ? (
                <ATMLoader active inline="centered" size="medium" />
            ) : (
                <div className={styles.gridWapper}>
                    {isPrime === true && (
                        <ATMGrid>
                            <ATMGrid.Row>
                                <ATMGrid.Column>
                                    <ATMHeader as="h2">
                                        {' '}
                                        <span>
                                            <i
                                                style={{
                                                    color: 'white',
                                                }}
                                                className={`building outline icon ${styles.buildicon}`}
                                            ></i>
                                        </span>
                                        <span className={styles.top}>
                                            {data?.company_name}
                                        </span>
                                    </ATMHeader>

                                    <Divider />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}

                    <ATMGrid columns={2}>
                        <ATMGrid.Row>
                            <ATMGrid.Column
                                styles={{ paddingLeft: 0, paddingTop: 0 }}
                            >
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {Lang.TTL_PRIME_CONTRACTOR_COMPANY_DETAILS}
                                </div>
                            </ATMGrid.Column>
                            <ATMGrid.Column>
                                {isAdmin && (
                                    <ATMButton
                                        icon="left edit outline"
                                        className={styles.editButton}
                                        primary
                                        content="Edit"
                                        onClick={props.handleOpen}
                                    />
                                )}
                                    <ATMButton
                                        icon="download"
                                        className={ (
                                                isPrime
                                            )
                                            ? styles.exportButtonPrime
                                            : styles.exportButton
                                        }
                                        secondary
                                        content="Export"
                                        onClick={props.handleDownload}
                                    />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={
                                    isPrime
                                        ? Lang.LBL_CONTRACTOR_COMPANY_NAME
                                        : Lang.LBL_CONTRACTOR_PRIME_NAME
                                }
                                fieldValue={data?.company_name}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ADDRESS}
                                fieldValue={data?.vendoradd}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ZIP}
                                fieldValue={data?.zip}
                                isPopOverReq={false}
                            />
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={
                                    isPrime
                                        ? Lang.LBL_CONTRACTOR_LOGIN_ID
                                        : Lang.LBL_CONTRACTOR_SAP_ID
                                }
                                fieldValue={data?.sapId}
                                isPopOverReq={false}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_CITY}
                                fieldValue={data?.city}
                                isPopOverReq={false}
                            />
                            <ATMGrid.Column>
                                <div className={styles.fieldWrapper}>
                                    {!isAdmin ? (
                                        <Fragment>
                                            <label
                                                className={
                                                    styles.nameLabelField
                                                }
                                            >
                                                {
                                                    Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG
                                                }
                                            </label>
                                            <ATMLabel
                                                className={
                                                    styles.inputFieldLabel
                                                }
                                                customcolor="lightgrey"
                                            >
                                                {data?.supplier_diversity_contact ??
                                                    ''}{' '}
                                            </ATMLabel>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <label
                                                className={
                                                    styles.nameLabelField
                                                }
                                            >
                                                {
                                                    Lang.LBL_ADMIN_ALLOW_AGREEMENTS
                                                }
                                            </label>
                                            <ATMLabel
                                                className={
                                                    styles.inputFieldLabel
                                                }
                                                customcolor="lightgrey"
                                            >
                                                {data?.allow_agreements
                                                    ? 'Yes'
                                                    : 'No'}
                                            </ATMLabel>
                                        </Fragment>
                                    )}
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_SUPPLIER_CODE}
                                fieldValue={data?.supplier_code}
                                isPopOverReq={false}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_STATE}
                                fieldValue={data?.state}
                                isPopOverReq={false}
                            />
                            <ATMGrid.Column>
                                <div className={styles.fieldWrapper}>
                                    {isPrime ? (
                                        <Fragment>
                                            <label
                                                className={
                                                    styles.nameLabelField
                                                }
                                            >
                                                {
                                                    Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG
                                                }
                                            </label>
                                            <ATMLabel
                                                className={
                                                    styles.inputFieldLabel
                                                }
                                                customcolor="lightgrey"
                                            >
                                                {data?.supplier_diversity_contact_socal ??
                                                    ''}{' '}
                                            </ATMLabel>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div
                                                className={styles.fieldWrapper}
                                            ></div>
                                        </Fragment>
                                    )}
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_TIER_III_ENABLED}
                                fieldValue={data?.isToggleAllow ? 'Yes' : 'No'}
                                isPopOverReq={false}
                            />
                            {/* <ATMGrid.Column>
                                <div className={styles.fieldWrapper}>
                                    {isPrime ? (
                                        <Fragment>
                                            <label
                                                className={
                                                    styles.nameLabelField
                                                }
                                            >
                                                {
                                                    Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG
                                                }
                                            </label>
                                            <ATMLabel
                                                className={
                                                    styles.inputFieldLabel
                                                }
                                                customcolor="lightgrey"
                                            >
                                                {data?.supplier_diversity_contact_socal ??
                                                    ''}{' '}
                                            </ATMLabel>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div
                                                className={styles.fieldWrapper}
                                            ></div>
                                        </Fragment>
                                    )}
                                </div>
                            </ATMGrid.Column> */}
                        </ATMGrid.Row>
                    </ATMGrid>


                    <ATMGrid>
                        <ATMGrid.Row
                            styles={{
                                paddingBottom: '0.2em',
                                paddingTop: '0em',
                                marginTop: '1em',
                            }}
                        >
                            <ATMGrid.Column>
                                <Divider />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    {isAdmin && (
                        <Fragment>
                            <SupplierDiversityContactDetails {...props} />

                            <ATMGrid>
                                <ATMGrid.Row
                                    styles={{
                                        paddingBottom: '0.2em',
                                        paddingTop: '0em',
                                        marginTop: '1em',
                                    }}
                                >
                                    <ATMGrid.Column>
                                        <Divider />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                        </Fragment>
                    )}

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column styles={{ paddingLeft: 0 }}>
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {
                                        Lang.TTL_CONTRACTOR_PRIMARY_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                            <ATMGrid.Column styles={{ paddingLeft: 0 }}>
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {
                                        Lang.TTL_CONTRACTOR_SECONDARY_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                            <ATMGrid.Column
                                className={styles.contractorHeader}
                                style={{ paddingLeft: 0 }}
                            >
                                <div style={{ paddingLeft: 14 }}>
                                    {
                                        Lang.TTL_CONTRACTOR_OPIONAL_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_PRIMARY_NAME}
                                fieldValue={data?.contactName}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ALTERNAME_NAME}
                                fieldValue={data?.contactName2}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ADDITIONAL_NAME}
                                fieldValue={data?.contactName3}
                                isPopOverReq={true}
                            />
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_PRIMARY_PHONE}
                                fieldValue={data?.contactPhone}
                                isPopOverReq={false}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ALTERNAME_PHONE}
                                fieldValue={data?.contactPhone2}
                                isPopOverReq={false}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ADDITIONAL_PHONE}
                                fieldValue={data?.contactPhone3}
                                isPopOverReq={false}
                            />
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_PRIMARY_EMAIL}
                                fieldValue={data?.contactEmail}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ALTERNAME_EMAIL}
                                fieldValue={data?.contactEmail2}
                                isPopOverReq={true}
                            />
                            <CreateATMGridColumn
                                label={Lang.LBL_CONTRACTOR_ADDITIONAL_EMAIL}
                                fieldValue={data?.contactEmail3}
                                isPopOverReq={true}
                            />
                        </ATMGrid.Row>
                    </ATMGrid>
                </div>
            )}
        </>
    );
}

const SupplierDiversityContactDetails = ({ data }) => {
    return (
        <>
            <ATMGrid columns={3}>
                <ATMGrid.Row className={styles.rowWrapper}>
                    <div
                        className={styles.contractorHeader}
                        style={{ paddingLeft: 14 }}
                    >
                        {Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_DETAILS}
                    </div>
                </ATMGrid.Row>

                <ATMGrid.Row className={styles.rowWrapper}>
                    <CreateATMGridColumn
                        label={Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG}
                        fieldValue={data?.supplier_diversity_contact}
                        isPopOverReq={true}
                    />
                    <CreateATMGridColumn
                        label={Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG}
                        fieldValue={data?.supplier_diversity_contact_socal}
                        isPopOverReq={true}
                    />
                    <ATMGrid.Column />
                </ATMGrid.Row>
            </ATMGrid>

            <ATMGrid columns={3}>
                <ATMGrid.Row className={styles.rowWrapper}>
                    <CreateATMGridColumn
                        label={Lang.LBL_ADMIN_CONTACT1}
                        fieldValue={data?.contact1_name}
                        isPopOverReq={true}
                    />
                    <CreateATMGridColumn
                        label={Lang.LBL_ADMIN_CONTACT2}
                        fieldValue={data?.contact2_name}
                        isPopOverReq={true}
                    />
                    <CreateATMGridColumn
                        label={Lang.LBL_ADMIN_CONTACT3}
                        fieldValue={data?.contact3_name}
                        isPopOverReq={true}
                    />
                </ATMGrid.Row>
            </ATMGrid>
        </>
    );
};

export default ContractorInfoView;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lang from 'src/libraries/language';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IVonsList } from 'src/models/manage-subcontractor.model';
import DeleteConfirmReasonModalView from './delete-confirm-reason-modal.view';

type Iprops = {
    open: boolean;
    handleOnDiscard: () => void;
    handleSuccess: (msg, bool) => void;
    handleSubmitDelete: (msg) => void;
    deleteContent:string;
};

const DeleteConfirmReasonModalComponent: React.FC<Iprops> = ({
    open,
    handleOnDiscard,
    handleSuccess,
    handleSubmitDelete,
    deleteContent
}) => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const formRef = useRef<HTMLFormElement>();
    const dataRef = useRef<any>();
    const oldData = useRef<any>();
    const discardStatusRef = useRef<string>();
    const handleClose = () => {
        setConfirm(false);
        handleOnDiscard()
    };
    const [vonError, setVonError] = useState(false);

  

    const handleCancel = useCallback(() => {
        handleOnDiscard()
        setConfirm(false);
    }, [dataRef]);

    const handleSubmitConfirm = async () => {
        handleSubmitDelete(dataRef.current.reasonForDeletion)
    };

    const handleChange = (key, value: any) => {
        if (value === '') {
            setButtonDisable(true);
            // setVonError(false);
        } else {
            setButtonDisable(false);
        }
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const handleClick = useCallback(() => {
        if (formRef && formRef.current) { 
            formRef.current.handleSubmit();
        }
    }, [setConfirm, formRef]);

    const handleSubmit = () => {
        handleSubmitConfirm();
    };

    return (
        <>
            <DeleteConfirmReasonModalView
                open={open}
                handleClose={handleCancel}
                handleClick={handleClick}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formRef={formRef}
                buttonDisable={buttonDisable}
                reasonForDeletionError={vonError}
                deleteContent={deleteContent}
            />

            {/* {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={confirmmsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleClose}
                    onConfirm={handleSubmitConfirm}
                />
            )} */}
        </>
    );
};

export default DeleteConfirmReasonModalComponent;

import yup from '../libraries/validator.library';
export const MassMailSchema = yup // for non prime schema shape
    .object({
        data: yup.array().defined(),
        status: yup.boolean(),
    })
    .defined();

export const dbeData = {
    advisor: yup.array().defined(),
    contact: yup.array().defined(),
};
export const DbeSchema = yup
    .object({
        data: yup
            .object({
                sdge: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
                socal: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
                contact: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                username: yup.string(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
            })
            .defined(),
    })
    .defined();

export const MassMailFormSchema = yup
    .object({
        to: yup.string().required('To is required field.'),
        // month: yup.string().when('to', {
        //     is: (val) => {
        //         return val === 'primes_with';
        //     },
        //     then: yup.string().required('Month is required field.'),
        //     otherwise: yup.string().notRequired(),
        // }),
        cc: yup
            .string()
            .matches(
                /^([\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*)+$/,
                'Please enter valid email addresses, separated by comma and without any spaces'
            ),

        subject: yup.string().required('Subject is required field.'),
        body: yup.string().required('Body is required field.'),
        bcc: yup
            .string()
            .matches(
                /^($|([\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*))+$/,
                'Please enter valid email addresses, separated by comma and without any spaces'
            ),
    })
    .defined();

export const MassMailAuditLogPayloadSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    emailTo: yup.string(),
    loggedAt: yup.date(),
    body: yup.string(),
    subject: yup.string(),
    sdcName:yup.string()
});

export const MassMailAuditLogResponseSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(MassMailAuditLogPayloadSchema),
    status: yup.boolean(),
    total_rows: yup.number(),
    total_pages: yup.number(),
})

export type IGetYearPayload = yup.InferType<typeof MassMailSchema>;

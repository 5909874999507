import yup from '../libraries/validator.library';
const PHONE_NO_REGEX = /^\d{3}-\d{3}-\d{4}$/;
export const ContractorSchema = yup
    .object({
        data: yup
            .object({
                login_id: yup.string(),
                company_name: yup.string(),
                vendoradd: yup.string(),
                city: yup.string(),
                state: yup.string().nullable(),
                zip: yup.string(),
                contactName: yup.string(),
                contactPhone: yup.string().nullable(),
                contactEmail: yup.string().nullable(),
                supplier_code: yup.string().nullable(),
                contactName2: yup.string().nullable(),
                contactName3: yup.string().nullable(),
                contactPhone2: yup.string().nullable(),
                contactPhone3: yup.string().nullable(),
                contactEmail2: yup.string().nullable(),
                contactEmail3: yup.string().nullable(),
                supplier_diversity_contact: yup.string().nullable(),
                supplier_diversity_contact_socal: yup.string().nullable(),
                active: yup.boolean(),
                contact1_name: yup.string().nullable(),
                contact2_name: yup.string().nullable(),
                contact3_name: yup.string().nullable(),
                allow_agreements: yup.boolean(),
                sapId: yup.string(),
                isToggleAllow: yup.boolean(),
                isToggleText:yup.string()
            })
            .defined(),
    })
    .defined();

export const ContractorUpdatePostSchema = yup //using for post schema
    .object({
        login_id: yup.number(),
        company_name: yup.string(),
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string(),
        contactName: yup.string(),
        contactPhone: yup.string(),
        contactEmail: yup.string(),
        supplier_code: yup.string(),
        contactName2: yup.string(),
        contactName3: yup.string(),
        contactPhone2: yup.string(),
        contactPhone3: yup.string(),
        contactEmail2: yup.string(),
        contactEmail3: yup.string(),
        supplier_diversity_contact: yup.string(),
    })
    .defined();

export const ContractorUpdateSchema = yup //using for validation
    .object({
        //  vendoradd: yup.string().max(30, "Address can't be longer than 30 characters").required('Address is required field'),
        vendoradd: yup
            .string()
            .max(50, "Address can't be longer than 50 characters")
            .matches(/^[A-Za-z0-9 ]*$/, 'Address must be alphanumeric')
            .required('Address is required field'),
        zip: yup
            .string()
            .matches(/^[0-9]{5}$/, 'Zip must be 5 digit number')
            .required('Zip is required field'),
        city: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'City must contain only letters')
            .max(20, "City can't be longer than 20 characters")
            .required('City is required field'),
        state: yup
            .string()
            .matches(/^[A-Za-z]*$/, 'State must contain only letters')
            .min(2, 'State must be 2 characters')
            .max(2, "State can't be longer than 2 characters")
            .required('State is required field'),
        contactName: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
            .max(50, "Name can't be longer than 50 characters")
            .required('Primary Name is required field'),
        contactName2: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
            .max(50, "Name can't be longer than 50 characters")
            .nullable(),
        contactName3: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
            .max(50, "Name can't be longer than 50 characters")
            .nullable(),
        contactPhone: yup
            .string()
            .matches(PHONE_NO_REGEX, {
                message: 'Phone number must be in ###-###-#### format',
                excludeEmptyString: true,
            })
            .required('Primary Phone is required field'),

        contactPhone2: yup
            .string()
            .matches(PHONE_NO_REGEX, {
                message: 'Phone number must be in ###-###-#### format',
                excludeEmptyString: true,
            })
            .nullable(),

        contactPhone3: yup
            .string()
            .matches(PHONE_NO_REGEX, {
                message: 'Phone number must be in ###-###-#### format',
                excludeEmptyString: true,
            })
            .nullable(),
        contactEmail: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid email address')
            .max(90, "Email can't be longer than 90 characters")
            .required('Email is required field'),
        contactEmail2: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid email address')
            .max(90, "Email can't be longer than 90 characters")
            .optional()
            .nullable(),
        contactEmail3: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid email address')
            .max(90, "Email can't be longer than 90 characters")
            .optional()
            .nullable(),
    })
    .defined();

export const AdminPrimeUpdateSchema = yup //using for validation
    .object({
        vendoradd: yup
            .string()
            .max(50, "Address can't be longer than 50 characters")
            .matches(/^[A-Za-z0-9 ]*$/, 'Address must be alphanumeric')
            .required('Address is required field'),
        zip: yup
            .string()
            .matches(/^[0-9]{5}$/, 'Zip must be 5 digit number')
            .required('Zip is required field'),
        city: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'City must contain only letters')
            .max(20, "City can't be longer than 20 characters")
            .required('City is required field'),
        state: yup
            .string()
            .matches(/^[A-Za-z]*$/, 'State must contain only letters')
            .min(2, 'State must be 2 characters')
            .max(2, "State can't be longer than 2 characters")
            .required('State is required field'),
        contactName: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Primary Name must contain only letters')
            .max(50, "Primary Name can't be longer than 50 characters")
            .required('Primary Name is required field'),
        contactName2: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
            .max(50, "Name can't be longer than 50 characters")
            .nullable(),
        contactName3: yup
            .string()
            .matches(/^[A-Za-z ]*$/, 'Name must contain only letters')
            .max(50, "Name can't be longer than 50 characters")
            .nullable(),
        contactPhone: yup
            .string()
            .matches(
                /^\d{3}-\d{3}-\d{4}$/,
                'Primary Phone number must be in ###-###-#### format'
            )
            .required('Primary Phone is required field.'),
        contactPhone2: yup
            .string()
            .matches(PHONE_NO_REGEX, {
                message: 'Phone number must be in ###-###-#### format',
                excludeEmptyString: true,
            })
            .nullable(),

        contactPhone3: yup
            .string()
            .matches(PHONE_NO_REGEX, {
                message: 'Phone number must be in ###-###-#### format',
                excludeEmptyString: true,
            })
            .nullable(),
        contactEmail: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid Primary email address')
            .max(90, "Primary Email can't be longer than 90 characters")
            .required('Primary Email is required field'),
        contactEmail2: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid email address')
            .max(90, "Email can't be longer than 90 characters")
            .optional()
            .nullable(),
        contactEmail3: yup
            .string()
            .strict(true)
            .lowercase("Please enter email address in lowercase only")
            .email('Please enter valid email address')
            .max(90, "Email can't be longer than 90 characters")
            .optional()
            .nullable(),
        company_name: yup
            .string()
            .required('Company Name is required field')
            .max(50, "Name can't be longer than 50 characters"),
        sapId: yup
            .string()
            .max(9, "SAP ID can't be longer than 9 numbers")
            .required('SAP ID is required field')
            .matches(/^[0-9]*$/, 'SAP ID must contain only numbers'),
        vendor_id: yup.number(),
        supplier_code: yup
            .string()
            .max(3, "Supplier Code can't be longer than 3 characters")
            .required('Supplier Code is required field')
            .matches(
                /^[a-z0-9]+$/i,
                'Supplier Code must contain only alphanumeric'
            ),
        dbeAdvisor: yup
            .string()
            .test(
                'dbeAdvisor',
                'Please select either Supplier Diversity Contact (SDG&E) / Supplier Diversity Contact (SoCalGas) or both',
                function (item) {
                    return this.parent.socalContact || this.parent.dbeAdvisor;
                }
            )
            .nullable(),
        socalContact: yup
            .string()
            .test(
                'socalContact',
                'Please select either Supplier Diversity Contact (SDG&E) / Supplier Diversity Contact (SoCalGas) or both',
                function (item) {
                    return this.parent.socalContact || this.parent.dbeAdvisor;
                }
            )
            .nullable(),
    })
    .defined();

export const AdvisorAndContactUserSchema = yup
    .object({
        data: yup
            .object({
                sdge: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
                socal: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
                contact: yup
                    .array()
                    .of(
                        yup
                            .object({
                                userID: yup.number(),
                                username: yup.string(),
                                firstName: yup.string(),
                                lastName: yup.string(),
                            })
                            .defined()
                    )
                    .defined(),
            })
            .defined(),
    })
    .defined();

export const AdminPrimeSchema = yup
    .object({
        data: yup
            .object({
                login_id: yup.string().nullable(),
                sapId: yup.string(),
                company_name: yup.string(),
                address: yup.string(),
                city: yup.string(),
                state: yup.string().nullable(),
                zip: yup.string(),
                contactName: yup.string(),
                contactPhone: yup.string().nullable(),
                contactEmail: yup.string().nullable(),
                supplier_code: yup.string().nullable(),
                contactName2: yup.string().nullable(),
                contactName3: yup.string().nullable(),
                contactPhone2: yup.string().nullable(),
                contactPhone3: yup.string().nullable(),
                contactEmail2: yup.string().nullable(),
                contactEmail3: yup.string().nullable(),
                supplier_diversity_contact: yup.string().nullable(),
                contact1: yup.number().nullable(),
                contact2: yup.number().nullable(),
                contact3: yup.number().nullable(),
                dbeAdvisor: yup.number().nullable(),
                allow_agreements: yup.boolean(),
                active: yup.boolean(),
                socalContact: yup.number().nullable(),
                vendoradd: yup.string(),
                isToggleAllow: yup.boolean(),
                isToggleText: yup.string(),
            })
            .defined(),
    })
    .defined();
export const ReportingyearsGetSchema = yup
.object({
    data: yup.array().of(yup.mixed()).defined(),
    status: yup.boolean().nullable(),
    message: yup.string(),
})
.defined();


export type IReportingyearsGetSchema = yup.InferType<
typeof ReportingyearsGetSchema
>;
export type IContractorUpdate = yup.InferType<typeof ContractorUpdateSchema>;

export type ContractorUpdatePost = yup.InferType<
    typeof ContractorUpdatePostSchema
>;

export type IContractor = yup.InferType<typeof ContractorSchema>;
export type IContractorForm = yup.InferType<typeof ContractorSchema>;
export type IAdvisorAndContactUser = yup.InferType<
    typeof AdvisorAndContactUserSchema
>;
export type IAdminPrime = yup.InferType<typeof AdminPrimeSchema>;

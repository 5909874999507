import { ISummaryReducerAction, ISummaryState } from '../ducks/summary.duck';
import { IStatus } from '../ducks';

export const getSummaryStatus = (
  state: ISummaryState,
  action: ISummaryReducerAction['type']
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

import React, { useCallback, useState } from 'react';
import MassMailAuditLogView from './mass-mail-audit-log.view';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useMassMailContext } from 'src/contexts/mass-mail.context';
import MassMailAuditLogEmailView from './email/mass-mail-audit-log-email.view';

interface IProps {
    open: boolean;
    handleClose: any
}

const MassMailAuditLog: React.FC<IProps> = ({ open, handleClose }) => {
    const { state, actions } = useMassMailContext();
    const [loading, setloading] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [openEmailView, setOpenEmailView] = useState<boolean>(false);
    const [auditLogData, setAuditLogData] = useState<any>({});

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setloading(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.getMassMailAuditLog(param);
            setloading(false);
        },
        []
    );

    const handleOpenEmailView = useCallback((data) => {
        setAuditLogData(data)
        setOpenEmailView(true)
    }, []);

    const handleCloseEmailView = () => {
        setOpenEmailView(false);
        setAuditLogData({})
    }
    return (
        <>
            {openEmailView &&
                <MassMailAuditLogEmailView
                    open={openEmailView}
                    handleClose={handleCloseEmailView}
                    data={auditLogData} />}

            <MassMailAuditLogView
                open={open}
                handleClose={handleClose}
                data={state?.massMailAuditLogList?.data || []}
                loading={loading}
                fetchData={fetchData}
                totalRecord={
                    state?.massMailAuditLogList?.total_rows ||
                    0}
                handleOpenEmailView={handleOpenEmailView} />
        </>
    )
}

export default MassMailAuditLog
import services from 'src/services/reset-password.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
  RESET_PASSWORD: 'RESET_PASSWORD',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
  resetPasswordPOST: {
    type: actionTypes.RESET_PASSWORD,
    service: services.resetPasswordPOST,
    status: {} as never
  },
};


export type IResetPasswordAsync = typeof asyncActions;

export type IResetPasswordReducerAction = IAsyncActions<IResetPasswordAsync>;

export interface IResetPasswordState extends ICommonState<IResetPasswordReducerAction> {
    status: any,
}

export const defaultState = {
  status: {},
  response: {}
};

const ResetPasswordReducer = (  
  state = defaultState,
  action: ICommonAction<IResetPasswordReducerAction>,
): IResetPasswordState => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD: {
      return {
        ...state,        
      };
    }
    default: {
      return state;
    }
  }
};

export default ResetPasswordReducer;

import React, { useEffect, useState } from 'react';
import {
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMSelect,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './supplier-diversity-mass-update.module.scss';

type IProps = {
    advisorOptions: any;
    newAdvisorOptions: any;
    socalContactOptions: any;
    newSocalContactOptions: any;
    handleChange: (key, data) => void;
    setIsContactUpdate: (boolean) => void;
    setIsSupplierDiversityUpdate: (boolean) => void;
    setIsSocalContact: (boolean) => void;
    setIsAdvisorContact: (boolean) => void;
    isSocalContact: boolean;
    isAdvisorContact: boolean;
};

type JProps = {
    name: any;
    option: any;
    label: any;
    placeholder: any;
    handleChange: (key, data) => void;
};
const SelectBoxComponent: React.FC<JProps> = ({
    name,
    option,
    label,
    placeholder,
    handleChange,
}) => {
    return (
        <ATMGrid columns={1}>
            <ATMGrid.Row className={styles.rowWrapper}>
                <ATMGrid.Column className={styles.fieldWrapper}>
                    <label className={styles.required}>
                        <b>{label}</b>
                    </label>
                    <ATMSelect
                        fluid
                        placeholder={placeholder}
                        name={name}
                        options={option}
                        style={{ width: '50%' }}
                        onChange={(_, val) => {
                            handleChange(name, val.value);
                        }}
                        required
                    />
                </ATMGrid.Column>
            </ATMGrid.Row>
        </ATMGrid>
    );
};
const SupplierDiversityMassUpdate: React.FC<IProps> = (props) => {
    useEffect(() => {
        props.setIsContactUpdate(false);
        props.setIsSupplierDiversityUpdate(true);
        props.setIsAdvisorContact(true);
        props.setIsSocalContact(false);
        props.handleChange('currentContactId', '');
        props.handleChange('newContactId', '');
    }, []);
    const advisorOptions = props.advisorOptions || [];
    advisorOptions.sort((a, b) => a.text.localeCompare(b.text));

    const newAdvisorOptions = props.newAdvisorOptions || [];
    newAdvisorOptions.sort((a, b) => a.text.localeCompare(b.text));

    const socalContactOptions = props.socalContactOptions || [];
    socalContactOptions.sort((a, b) => a.text.localeCompare(b.text));

    const newSocalContactOptions = props.newSocalContactOptions || [];
    newSocalContactOptions.sort((a, b) => a.text.localeCompare(b.text));

    return (
        <>
            <div id="group1" className={styles.nav}>
                <span>
                    <input
                        type="radio"
                        name="supplierDiversityGroup"
                        defaultChecked
                        onClick={() => {
                            props.setIsAdvisorContact(true);
                            props.setIsSocalContact(false);
                            props.handleChange('currentContactId', '');
                            props.handleChange('newContactId', '');
                        }}
                    />
                    &nbsp;
                    {Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG}
                </span>
                <span style={{ paddingLeft: '20px' }}>
                    <input
                        type="radio"
                        name="supplierDiversityGroup"
                        onClick={() => {
                            props.setIsAdvisorContact(false);
                            props.setIsSocalContact(true);
                            props.handleChange('currentContactId', '');
                            props.handleChange('newContactId', '');
                        }}
                    />
                    &nbsp;
                    {Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG}
                </span>
            </div>
            {props.isAdvisorContact && (
                <div>
                    <SelectBoxComponent
                        name="currentContactId"
                        option={advisorOptions}
                        label={Lang.LBL_CURRENT_SUPPLIER_DIVERSITY_CONTACT_SDG}
                        placeholder={Lang.LBL_PRIME_SELECT}
                        handleChange={props.handleChange}
                    />
                    <SelectBoxComponent
                        name="newContactId"
                        option={newAdvisorOptions}
                        label={Lang.LBL_NEW_SUPPLIER_DIVERSITY_CONTACT_SDG}
                        placeholder={Lang.LBL_PRIME_SELECT}
                        handleChange={props.handleChange}
                    />
                </div>
            )}
            {props.isSocalContact && (
                <div>
                    <div>
                        <SelectBoxComponent
                            name="currentContactId"
                            option={socalContactOptions}
                            label={
                                Lang.LBL_CURRENT_SUPPLIER_DIVERSITY_CONTACT_SCG
                            }
                            placeholder={Lang.LBL_PRIME_SELECT}
                            handleChange={props.handleChange}
                        />
                        <SelectBoxComponent
                            name="newContactId"
                            option={newSocalContactOptions}
                            label={Lang.LBL_NEW_SUPPLIER_DIVERSITY_CONTACT_SCG}
                            placeholder={Lang.LBL_PRIME_SELECT}
                            handleChange={props.handleChange}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default SupplierDiversityMassUpdate;

import React, { useEffect, useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMCheckbox,
    ATMToggle,
    ATMSelect,
    ATMSegment,
    ATMHeader,
    ATMTextArea,
    ATMDatePicker
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
    updateSubcontractorVonSchema,
    IVonsList,
} from 'src/models/manage-subcontractor.model';
import { IInvalidData } from 'src/models/contractorpage.model';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import styles from './invalid-contracts-edit-modal.module.scss';

const RequiredLable = ({ children, ...props }) => (
    <label {...props} className={styles.required}>
        {children}
    </label>
);

type Iprops = {
    open: boolean;
    handleClose: () => void;
    handleClick: () => void;
    data: Partial<IInvalidData | undefined>;
    handleSubmit: (data) => void;
    formRef: any;
    buttonDisable: boolean;
    reasonsDisabled: boolean;
    vonError: boolean;
    handleChange: (type, data) => void;
};
function InvalidContractsModalView({
    data,
    open,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    buttonDisable,
    reasonsDisabled,
    vonError,
    handleChange,
}: Iprops): any {
    const minDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const [reason, setReason] = useState<any>('');
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(false);
    const [expiryDate, setExpiryDate] = useState<any>('');
    useEffect(() => {
        if (!data?.spendAllowedAdmin && expiryDate !== '' && reason != '') {
            setIsUpdateEnable(true);
        } else if (data?.spendAllowedAdmin && reason != '') {
            setIsUpdateEnable(true);
        } else {
            setIsUpdateEnable(false);
        }
    }, [expiryDate, reason, setIsUpdateEnable]);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') event?.preventDefault()
    }
    return (
        <ATMModal
            size="mini"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5" >
                <div>
                    {data?.spendAllowedAdmin ? 'Stop Exception' : 'Select Exception Expiration Date'}

                </div>
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMForm
                >
                    {({ control, errors }) => (
                        <ATMGrid columns={1}>
                            <ATMGrid.Row>
                                <ATMGrid.Column>
                                    <span className={
                                        styles.datepickerParent
                                    }>
                                        {(!data?.spendAllowedAdmin && <ATMDatePicker
                                            className={
                                                styles.datepicker
                                            }
                                            datePickerOnly={
                                                true
                                            }
                                            clearOnSameDateClick={false}
                                            clearable={false}
                                            keepOpenOnSelect={false}
                                            showToday={false}
                                            inline={false}
                                            type="basic"
                                            minDate={
                                                minDate
                                            }
                                            name="endDate"
                                            format="MM/DD/YYYY"
                                            onChange={(
                                                _, val
                                            ) => {
                                                setExpiryDate(val.value)
                                                handleChange('endDate', val.value);
                                            }}
                                        />)}
                                    </span>
                                    <RequiredLable>
                                        Explanation
                                    </RequiredLable>
                                    <ATMTextArea
                                        onChange={(e, val) => {
                                            setReason(val.value)
                                            handleChange(
                                                'reason',
                                                val.value
                                            );
                                        }}
                                        value={reason}
                                        onKeyDown={handleKeyDown}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    onClick={handleClick}
                    disabled={!isUpdateEnable}
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default InvalidContractsModalView;

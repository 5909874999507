import React from 'react';
import VerificationFollowUpAdminView from './verification-follow-up-admin.view';

const VerificationFollowUpAdminComponent: React.FC<any> = ({
    data,
    onVerificationPageChange,
    loading,
    title,
    setResetPagination,
    resetPagination,
}) => {
    return (
        <VerificationFollowUpAdminView
            setResetPagination={setResetPagination}
            resetPagination={resetPagination}
            data={data?.data}
            onVerificationPageChange={onVerificationPageChange}
            totalRows={data?.total_rows}
            loading={loading}
            heading={title}
        />
    );
};

export default VerificationFollowUpAdminComponent;

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContractorReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/contractor.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(ContractorReducer, defaultState, asyncActions);
};

type IContractorContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ContractorContext = createContext<Partial<IContractorContext>>({
  state: defaultState,
}) as React.Context<IContractorContext>;

const ContractorProvider: React.FC = ({ children }) => {
  const reducer = useReducer();
  return (
    <ContractorContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ContractorContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useContractorContext: () => IContractorContext = () => useContext(ContractorContext);
export type IUseContractorContext = ReturnType<typeof useContractorContext>;

export { ContractorContext, ContractorProvider };

import React, { useRef, useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMSelect,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { UserConfigSchema } from 'src/models/user-config.model';
import type { LIST_TYPE } from '../user-configuration-info/user-config-info.component';
import style from './user-config.module.scss';

type Iprops = {
    open: boolean;
    handleClose: () => void;
    disableClearBtn: () => void;
    handleClick: () => void;
    data: any;
    handleChange: (key, data) => void;
    handleSubmit: (data) => void;
    formRef: any;
    buttonDisable: boolean;
    clearButtonDisable: boolean;
    userType: LIST_TYPE[];
    entities: LIST_TYPE[];
};

function UserUpdateConfigView({
    data,
    open,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    handleChange,
    disableClearBtn,
    buttonDisable,
    clearButtonDisable,
    userType,
    entities,
}: Iprops): React.ReactElement {
    const ref = useRef<() => void>();
    const [isError, setIsError] = useState(true);
    return (
        <ATMModal
            size="small"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5" className={style.modelHeader}>
                <div>
                    {data?.userID ? 'Edit / Update User' : 'Add New User'}
                </div>
                <div className={style.mandatory}>
                    (<span className={style.required}></span> {Lang.LBL_MONDATORY_FIELD})
                </div>
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMForm
                    className={style.form}
                    ref={formRef}
                    mode="onChange"
                    defaultValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={UserConfigSchema}
                >
                    {({ control, errors, reset }) => {
                        errors && Object.keys(errors).length > 0 ? setIsError(true) : setIsError(false);
                        ref.current = reset;
                        return (
                            <ATMGrid columns={2}>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                            {Lang.LBL_ADMIN_USER_CONFIG_FIRST_NAME}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            placeholder="Enter a First Name"
                                            name="firstName"
                                            error={errors.firstName}
                                            value={data.firstName}
                                            required
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'firstName',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                            {Lang.LBL_ADMIN_USER_CONFIG_LAST_NAME}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            placeholder="Enter a Last Name"
                                            name="lastName"
                                            value={data.lastName}
                                            error={errors.lastName}
                                            required
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'lastName',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                             {Lang.LBL_EMAIL_ADDRESS}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            placeholder="Enter a Email Address"
                                            name="eMailAddress"
                                            error={errors.eMailAddress}
                                            value={data.eMailAddress}
                                            required
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'eMailAddress',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                             {Lang.LBL_ADMIN_USER_CONFIG_USER_TYPE}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMSelect}
                                            placeholder={'Select'}
                                            name="userTypeID"
                                            error={errors.userTypeID}
                                            value={data.userTypeID}
                                            options={userType}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'userTypeID',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                            required
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                             {Lang.LBL_ADMIN_USER_CONFIG_ALIAS}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            placeholder="Enter a Alias"
                                            name="username"
                                            value={data.username}
                                            error={errors.username}
                                            required
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'username',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={style.fieldWrapper}
                                    >
                                        <label className={style.required}>
                                             {Lang.LBL_ADMIN_USER_CONFIG_UTILITY}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMSelect}
                                            placeholder={'Select'}
                                            name="entityId"
                                            error={errors.entityId}
                                            value={data.entityId}
                                            options={entities}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'entityId',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                            required
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                        );
                    }}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>

                <ATMButton
                    basic
                    color="blue"
                    disabled={clearButtonDisable}
                    onClick={() => {
                        ref.current && ref.current();
                        disableClearBtn()
                    }}
                >
                    {Lang.LBL_CLEAR}
                </ATMButton>

                <ATMButton
                    primary
                    onClick={handleClick}
                    disabled={buttonDisable ? buttonDisable : isError}
                >
                    {data?.userID ? Lang.LBL_UPDATE : 'Add'}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default UserUpdateConfigView;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    ContractorSchema,
    ContractorUpdatePost,
    ContractorUpdatePostSchema,
    AdvisorAndContactUserSchema,
    AdminPrimeSchema,
    ReportingyearsGetSchema
} from '../models/contractor.model';
import { MassUpdateResponseSchema } from '../models/mass-update.model';

const client = httpClient();
export const services = {
    listGET: async (params) => {
        return client.get('/primeContractor', params, ContractorSchema);
    },
    replacePUT: async (contractData: Partial<ContractorUpdatePost>) => {
        return client.put(
            '/primeContractor',
            {
                ...contractData,
            },
            ContractorUpdatePostSchema
        );
    },
    adminPrimeListGET: async (params) => {
        return client.get('/prime', params, AdminPrimeSchema);
    },
    advisorAndContactUserGET: async () => {
        return client.get(
            '/getDbeAndContactUserList',
            {},
            AdvisorAndContactUserSchema
        );
    },
    adminPrimeReplacePUT: async (
        contractData: Partial<ContractorUpdatePost>
    ) => {
        return client.put(
            '/prime',
            {
                ...contractData,
            },
            AdvisorAndContactUserSchema
        );
    },
    adminPrimeReplacePOST: async (
        contractData: Partial<ContractorUpdatePost>
    ) => {
        return client.post(
            '/prime',
            {
                ...contractData,
            },
            AdvisorAndContactUserSchema
        );
    },
    adminContactMassUpdatePut: async (params) => {
        return client.put(
            '/primeContactMassUpdate',
            {
                ...params,
            },
            MassUpdateResponseSchema
        );
    },
    primeAdvisorAndContactUserGET: async (params) => {
        return client.get(
            '/getPrimeSupplierDiversityContactAndContacts',
            { ...params },
            AdvisorAndContactUserSchema
        );
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
};
export default services;

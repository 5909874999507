import yup from '../libraries/validator.library';
export const ContactUserSchema = yup
    .object({
        vendor_id: yup.number(),
        name: yup.string(),
        sdge_adv_name: yup.string(),
        socal_adv_name: yup.string(),
        contact1: yup.string(),
        contact2: yup.string(),
        contact3: yup.string(),
        favourite: yup.number(),
        active: yup.boolean(),
        Fav_Id: yup.string().nullable(),
    })
    .defined();

export const ContactUserListPayloadSchema = yup
    .object({
        data: yup.array().of(ContactUserSchema).defined(),
        total_pages: yup.number().nullable(),
        total_rows: yup.number().nullable(),
    })
    .defined();

export type IContactUser = yup.InferType<typeof ContactUserSchema>;
export type IContactUserListPayload = yup.InferType<typeof ContactUserListPayloadSchema>;

import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import { ATMModal, ATMButton, ATMSegment, ATMForm } from 'shared-it-appmod-ui';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import styles from './mass-update.module.scss';
import SupplierDiversityMassUpdate from './supplier-diversity-mass-update/supplier-diversity-mass-update.component';
import ContactMassUpdate from './contact-mass-update/contact-mass-update.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useContractorContext } from 'src/contexts/contractor.context';
import { isAdmin } from 'src/libraries/users.library';

// const dialogRef = useRef<string>();

type IProps = {
    isOpen: boolean;
    handleClosePopUp: () => void;
    handleSuccess: (string) => void;
};

const MassUpdate: React.FC<IProps> = ({
    isOpen,
    handleClosePopUp,
    handleSuccess,
}) => {
    const [isUpdateEnable, setIsUpdateEnable] = useState(false);
    const [isConfirm, setIsCofirm] = useState(false);
    const [currentContactId, setCurrentContactId] = useState<string>('');
    const [newContactId, setNewContactId] = useState<string>('');
    const [isContactUpdate, setIsContactUpdate] = useState(false);
    const [isSupplierDiversityUpdate, setIsSupplierDiversityUpdate] =
        useState(false);
    const [isSocalContact, setIsSocalContact] = useState(false);
    const [isAdvisorContact, setIsAdvisorContact] = useState(false);
    const { state: stateContractor, actions: actionsContractor } =
        useContractorContext();
    const isUserAdmin = isAdmin();
    useEffect(() => {
        if (isUserAdmin) {
            actionsContractor.advisorAndContactUserGET();
        }
    }, [isUserAdmin]);

    const advisorOptions = useMemo(
        () =>
            stateContractor.advisorAndContactUserList?.data?.sdge?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            ),
        [stateContractor.advisorAndContactUserList]
    );

    const newAdvisorOptions = useMemo(() => {
        const option =
            stateContractor.advisorAndContactUserList?.data?.sdge?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            );
        if (option) {
            option.splice(0, 0, {
                key: 0,
                value: 0,
                text: '',
            });
        }
        return option;
    }, [stateContractor.advisorAndContactUserList]);

    const socalContactOptions = useMemo(
        () =>
            stateContractor.advisorAndContactUserList?.data?.socal?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            ),
        [stateContractor.advisorAndContactUserList]
    );

    const newSocalContactOptions = useMemo(() => {
        const option =
            stateContractor.advisorAndContactUserList?.data?.socal?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            );
        if (option) {
            option.splice(0, 0, {
                key: 0,
                value: 0,
                text: '',
            });
        }
        return option;
    }, [stateContractor.advisorAndContactUserList]);

    const contactOptions = useMemo(
        () =>
            stateContractor.advisorAndContactUserList?.data?.contact?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            ),
        [stateContractor.advisorAndContactUserList]
    );

    const newContactOptions = useMemo(() => {
        const option =
            stateContractor.advisorAndContactUserList?.data?.contact?.map(
                (type) => ({
                    key: type?.userID,
                    value: type?.userID,
                    text: type ? `${type?.firstName} ${type?.lastName}` : '',
                })
            );
        if (option) {
            option.splice(0, 0, {
                key: 0,
                value: 0,
                text: '',
            });
        }
        return option;
    }, [stateContractor.advisorAndContactUserList]);

    const createPane = (menuName: string, Component: any) => ({
        menuItem: menuName,
        render: () => (
            <ATMSegment>
                <Component
                    advisorOptions={advisorOptions}
                    newAdvisorOptions={newAdvisorOptions}
                    socalContactOptions={socalContactOptions}
                    newSocalContactOptions={newSocalContactOptions}
                    contactOptions={contactOptions}
                    newContactOptions={newContactOptions}
                    handleChange={handleChange}
                    setIsContactUpdate={setIsContactUpdate}
                    setIsSupplierDiversityUpdate={setIsSupplierDiversityUpdate}
                    setIsSocalContact={setIsSocalContact}
                    setIsAdvisorContact={setIsAdvisorContact}
                    isSocalContact={isSocalContact}
                    isAdvisorContact={isAdvisorContact}
                />
            </ATMSegment>
        ),
    });

    const panes = [
        createPane(
            Lang.LBL_SUPPLIER_DIVERSITY_CONTACT_UPDATE,
            SupplierDiversityMassUpdate
        ),
        createPane(Lang.LBL_CONTACT_UPDATE, ContactMassUpdate),
    ];

    const handleClick = useCallback(() => {
        setIsCofirm(true);
    }, [currentContactId, newContactId]);

    const handleSubmitConfirm = useCallback(async () => {
        const param = {
            currentContactId: currentContactId,
            newContactId: newContactId,
            isContactUpdate: isContactUpdate,
            isSupplierDiversityUpdate: isSupplierDiversityUpdate,
            isAdvisorContact: isAdvisorContact,
            isSocalContact: isSocalContact,
        };
        const response = await actionsContractor.adminContactMassUpdatePut(
            param
        );
        if (response?.payload?.status) {
            handleSuccess(response?.payload?.message);
        } else {
            ToastError(response?.error?.message);
            setIsCofirm(false);
        }
    }, [currentContactId, newContactId]);

    const handleChange = (key, val) => {
        if (key === 'currentContactId') {
            setCurrentContactId(val);
        }
        if (key === 'newContactId') {
            setNewContactId(val);
        }
    };

    const handleOnClose = useCallback(async () => {
        setIsCofirm(false);
    }, []);

    useEffect(() => {
        if (currentContactId !== '' && newContactId !== '') {
            setIsUpdateEnable(true);
        } else {
            setIsUpdateEnable(false);
        }
    }, [
        currentContactId,
        newContactId,
        isContactUpdate,
        isAdvisorContact,
        isSocalContact,
    ]);
    return (
        <>
            <ATMModal
                open={isOpen}
                onClose={handleClosePopUp}
                closeOnDimmerClick={false}
                size="small"
            >
                <ATMModal.Header as="h5" className={styles.modelHeader}>
                    <div>{Lang.LBL_MASS_UPDATE_HEADER}</div>
                    <div className={styles.mandatory}>
                        (<span className={styles.required}></span>
                        {Lang.LBL_MONDATORY_FIELD})
                    </div>
                </ATMModal.Header>

                <ATMModal.Content className={styles.wrapper}>
                    <Tabs
                        menu={{
                            fluid: false,
                            vertical: false,
                            pointing: true,
                            compact: true,
                            size: 'small',
                        }}
                        panes={panes}
                        segment="basic"
                    />
                </ATMModal.Content>
                <ATMModal.Actions>
                    <ATMButton
                        basic
                        color="blue"
                        onClick={() => {
                            handleClosePopUp();
                            setIsUpdateEnable(false);
                        }}
                    >
                        {Lang.LBL_CANCEL}
                    </ATMButton>
                    <ATMButton
                        primary
                        onClick={() => {
                            handleClick();
                        }}
                        disabled={!isUpdateEnable}
                        id="btnSubmit"
                    >
                        {Lang.LBL_UPDATE}
                    </ATMButton>
                </ATMModal.Actions>
            </ATMModal>

            {isConfirm && (
                <Confirm
                    open={isConfirm}
                    size="tiny"
                    content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleSubmitConfirm()}
                />
            )}
        </>
    );
};
export default MassUpdate;

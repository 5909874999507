import React, { useCallback, useEffect, useState } from 'react';
import { useDashboardContext } from 'src/contexts/dashboard.context';
import PrimeDashboardView from './prime-dashboard.view';

const PrimeDashboard = (): React.ReactElement => {
    const vendorId = localStorage.getItem('loginCredential');
    const [isDashboardSubcontractorSummaryLoading, setDashboardSubcontractorSummaryLoading] = useState(true);
    const [socalSubcontractingReport, setSocalSubcontractingReport] = useState({
        totalPaymentYTD: '-',
        totalVerifiedSubcontracting: '-',
        verifiedSubcontracting: '-',
        overallGoal: '-',
        goalSubcontracting: '-',
        subcontractingDiff: '-'
    });
    const [sdgeSubcontractingReport, setSdgeSubcontractingReport] = useState({
        totalPaymentYTD: '-',
        totalVerifiedSubcontracting: '-',
        verifiedSubcontracting: '-',
        overallGoal: '-',
        goalSubcontracting: '-',
        subcontractingDiff: '-'
    });
    const { state, actions } = useDashboardContext();

    const fetchSubcontractingSpendReport = useCallback(async () => {
        setDashboardSubcontractorSummaryLoading(true);
        const queryParam = {
            vendorId: vendorId,
        };

        const response = await actions.getSubcontractingSpendReportDashboardPrime(queryParam);
        const summaryData = response?.payload?.data;

        const socalData = summaryData && summaryData?.length > 0 && summaryData.filter((data) => data.compCode === 2200);
        socalData.length > 0 ? setSocalSubcontractingReport({
            totalPaymentYTD: socalData[0]?.totalPaymentYTD >= 0 ? socalData[0].totalPaymentYTD.toLocaleString() : `(${Math.abs(socalData[0].totalPaymentYTD).toLocaleString()})`,
            totalVerifiedSubcontracting: socalData[0]?.totalVerifiedSubcontracting >= 0 ? socalData[0].totalVerifiedSubcontracting.toLocaleString() : `(${Math.abs(socalData[0].totalVerifiedSubcontracting).toLocaleString()})`,
            verifiedSubcontracting: socalData[0]?.verifiedSubcontracting >= 0 ? `${socalData[0].verifiedSubcontracting}%` : `(${Math.abs(socalData[0].verifiedSubcontracting)})%`,
            overallGoal: socalData[0]?.overallGoal >= 0 ? `${socalData[0].overallGoal}%` : `(${Math.abs(socalData[0].overallGoal)})%`,
            goalSubcontracting: socalData[0]?.goalSubcontracting >= 0 ? socalData[0].goalSubcontracting.toLocaleString() : `(${Math.abs(socalData[0].goalSubcontracting).toLocaleString()})`,
            subcontractingDiff: socalData[0]?.subcontractingDiff >= 0 ? socalData[0].subcontractingDiff.toLocaleString() : `(${Math.abs(socalData[0].subcontractingDiff).toLocaleString()})`
        }) : setSocalSubcontractingReport({
            totalPaymentYTD: '-',
            totalVerifiedSubcontracting: '-',
            verifiedSubcontracting: '-',
            overallGoal: '-',
            goalSubcontracting: '-',
            subcontractingDiff: '-'
        });
        

        const sdgeData = summaryData && summaryData?.length > 0 && summaryData.filter((data) => data.compCode === 2100);
        sdgeData.length > 0 ? setSdgeSubcontractingReport({
            totalPaymentYTD: sdgeData[0]?.totalPaymentYTD >= 0 ? sdgeData[0].totalPaymentYTD.toLocaleString() : `(${Math.abs(sdgeData[0].totalPaymentYTD).toLocaleString()})`,
            totalVerifiedSubcontracting: sdgeData[0]?.totalVerifiedSubcontracting >= 0 ? sdgeData[0].totalVerifiedSubcontracting.toLocaleString() : `(${Math.abs(sdgeData[0].totalVerifiedSubcontracting).toLocaleString()})`,
            verifiedSubcontracting: sdgeData[0]?.verifiedSubcontracting >= 0 ? `${sdgeData[0].verifiedSubcontracting}%` : `(${Math.abs(sdgeData[0].verifiedSubcontracting)})%`,
            overallGoal: sdgeData[0]?.overallGoal >= 0 ? `${sdgeData[0].overallGoal}%` : `(${Math.abs(sdgeData[0].overallGoal)})%`,
            goalSubcontracting: sdgeData[0]?.goalSubcontracting >= 0 ? sdgeData[0].goalSubcontracting.toLocaleString() : `(${Math.abs(sdgeData[0].goalSubcontracting).toLocaleString()})`,
            subcontractingDiff: sdgeData[0]?.subcontractingDiff >= 0 ? sdgeData[0].subcontractingDiff.toLocaleString() : `(${Math.abs(sdgeData[0].subcontractingDiff).toLocaleString()})`
        }) : setSdgeSubcontractingReport({
            totalPaymentYTD: '-',
            totalVerifiedSubcontracting: '-',
            verifiedSubcontracting: '-',
            overallGoal: '-',
            goalSubcontracting: '-',
            subcontractingDiff: '-'
        });
        setDashboardSubcontractorSummaryLoading(false);
    }, [actions]);

    useEffect(() => {
        fetchSubcontractingSpendReport();
    }, [])

    return <PrimeDashboardView
        isDashboardSubcontractorSummaryLoading={isDashboardSubcontractorSummaryLoading}
        socalSubcontractingReport={socalSubcontractingReport}
        sdgeSubcontractingReport={sdgeSubcontractingReport}
    />;
};

export default PrimeDashboard;

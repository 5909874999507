import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMIcon,
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import moment from 'moment-timezone';
import Lang from 'src/libraries/language';

type Iprops = {
    data: any;
    openAuditLogs: boolean;
    handleClose: () => void;
    loadinglog: boolean;
    fetchLogData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
};

function ViewAllSubAuditLogView({
    data,
    openAuditLogs,
    handleClose,
    loadinglog,
    fetchLogData,
    totalRecord,
}: Iprops) : any {

    const columns: IORGDataTableColumn<any>[] = [
        {
            title: 'VON',
            index: 'verificationNumber',
            render: (_, value) => <span>{value.verificationNumber || '-'}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: 'Subcontractor',
            index: 'subcontractor_name',
            render: (_, value) => (
                <span>{value.subcontractor_name || '-'}</span>
            ),
            headerProps: {
                //className: Style.von,
            },
        },
        {
            title: 'Inactivation Reason',
            index: 'Reason',
            sortable: false,
            render: (_, value) => <span>{value?.Reason || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Deletion Reason',
            index: 'reasonForDeletion',
            sortable: false,
            render: (_, value) => <span>{value?.reasonForDeletion || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Action',
            index: 'actionName',
            sortable: false,
            render: (_, value) => <span>{value?.actionName || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Done By',
            index: 'loggedByUser',
            sortable: false,
            render: (_, value) => <span>{value?.loggedByUser || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Timestamp',
            index: 'loggedAt',
            sortable: false,
            render: (_, value) => <span>{ moment.utc(value?.loggedAt).format('MM/DD/YYYY hh:mm A') || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        }
    ];
    return (
        <ATMModal
            size="large"
            open={openAuditLogs}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                View All Subs Audit Log
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060',position: 'absolute','right': '0',top: '5px',cursor: 'pointer' }} />
            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                   celled={false}
                   columns={columns}
                   data={data}
                   loading={loadinglog}
                   sortable
                   // toolbar
                   // history
                   counter
                   // filters={true}
                   noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                   onChange={fetchLogData}
                   total={totalRecord}
                   key={totalRecord}
                   rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    );
}

export default ViewAllSubAuditLogView;

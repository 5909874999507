import React from 'react';

import { ATMLabel, IATMLabelProps } from 'shared-it-appmod-ui';

export interface IProps {
    status?: string;
}

const VerificationExpire: React.FC<IProps> = ({ status }) => {
    if (!status) {
        return null;
    }

    let color: IATMLabelProps['customcolor'];

    switch (status.split(' ').join('_').toLowerCase()) {
        case 'verified': {
            color = 'lightteal';
            break;
        }
        case 'next_60_days': {
            color = 'lightyellow';
            break;
        }
        case '1-180_days_past_due': {
            color = 'lightorange';
            break;
        }
        case 'more_than_180_days_past_due': {
            color = 'lightred';
            break;
        }
        case 'date_not_found': {
            color = 'lightgrey';
            break;
        }
    }

    return <ATMLabel customcolor={color}>{status}</ATMLabel>;
};
export default VerificationExpire;

import React from 'react';
import * as dotenv from 'dotenv';
import * as Contexts from 'src/contexts';
// Initialize environment variables from ~/.env file
dotenv.config();

//disable console.log method if app is running in production env
if (process.env.REACT_APP_ENVIRONMENT?.toLowerCase().indexOf("prod") !== -1) {
    window.console.log = () => {
        //comment
     }
}
const App: React.FC = (props) => {
     // Combines all the context providers
  const childrenWithContext = Object.keys(Contexts).reduce((child, key) => {
    const ContextProvider = Contexts[key];

    return <ContextProvider>{child}</ContextProvider>;
  }, props.children);

    return <>
        {childrenWithContext}          
        </>;
};

export default App;

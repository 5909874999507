import React, { useEffect, useState } from 'react';
import { ATMSegment } from 'shared-it-appmod-ui';
import ManageSubcontractorView from './manage-subcontractor.view';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import UpdateSubcontrctorComponent from './update-subcontractor-vons/update-subcontractor-vons-info/update-subcontractor-vons.component';
import UpdateSubcontractorSapId from './update-subcontractor-sapId/update-subcontractor-sapId.component';
import ViewAllSubs from './view-all-subs/view-all-subs.component'
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { FunctionalityDisableText } from '../../constant/message-constant.component';

const createPane = (menuName: string, Component: any) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component />
        </ATMSegment>
    ),
});

const ManageSubcontractor: React.FC = () => {
    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])

    useEffect(() => {
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus()
    }, [])
    const panes = [
        createPane(
            Lang.TTL_UPDATE_SUBCONTRACTORS_VONS,
            UpdateSubcontrctorComponent
        ),
        createPane(
            Lang.TTL_UPDATE_SUBCONTRACTORS_SAP_IDS,
            UpdateSubcontractorSapId
        ),
        createPane(
            Lang.TTL_VIEW_ALL_SUBS,
            ViewAllSubs
        ),
    ];

    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (<ManageSubcontractorView
        tabs={
            <Tabs
                menu={{
                    fluid: false,
                    vertical: false,
                    pointing: true,
                    compact: true,
                    size: 'small',
                }}
                panes={panes}
                segment="basic"
            />
        }
    />);
};

export default ManageSubcontractor;

import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMIcon,
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMToggle,
} from 'shared-it-appmod-ui';
import moment from 'moment-timezone';
import Lang from 'src/libraries/language';

type Iprops = {
    data: any;
    openAuditLogs: boolean;
    handleClose: () => void;
    loadinglog: boolean;
    fetchLogData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
    handleOpenEmailView: (data) => void
};

function PrimeTier3SubcontractorsAuditLogView({
    data,
    openAuditLogs,
    handleClose,
    loadinglog,
    fetchLogData,
    totalRecord,
    handleOpenEmailView
}: Iprops): any {


    const columns: IORGDataTableColumn<any>[] = [
        {
            title: 'Prime Name',
            index: 'primeName',
            render: (_, value) => <span>{value.primeName || '-'}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: 'Tier III Status',
            index: 'isToggleAllow',
            render: (_, value) => (
                <span>{value.isToggleAllow ? 'Tier III Enabled' : 'Tier III Disabled'}</span>
            ),
            headerProps: {
                //className: Style.von,
            },
        },
        {
            title: 'Done By',
            index: 'firstName',
            render: (_, value) => <span>{value.firstName + ' ' + value.lastName || '-'}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: 'Timestamp',
            index: 'updatedAt',
            sortable: false,
            render: (_, value) => <span>{moment.utc(value?.updatedAt).format('MM/DD/YYYY hh:mm A') || '-'}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Email',
            index: 'email',
            sortable: false,
            render: (_, value) => <span>{value.isToggleAllow && <i
                style={{
                    color: '#009BDA',
                    // fontWeight: 'bold',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                }}
                onClick={() => handleOpenEmailView(value)}
            >{'Click to view Email'}</i>}</span>,
            headerProps: {
                //className: Style.prime,
            },
        }
    ];
    return (
        <ATMModal
            size="large"
            open={openAuditLogs}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                Admin Audit Logs
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060', position: 'absolute', 'right': '0', top: '5px', cursor: 'pointer' }} />

            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={data}
                    loading={loadinglog}
                    sortable
                    // toolbar
                    // history
                    counter
                    // filters={true}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchLogData}
                    total={totalRecord}
                    key={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    );
}

export default PrimeTier3SubcontractorsAuditLogView;

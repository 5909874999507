import yup from '../libraries/validator.library';
export const MassUpdateResponseSchema = yup.object({
    message: yup.string(),
    data: yup.string(),
    status: yup.boolean(),
});

export type IMassUpdateResponse = yup.InferType<
    typeof MassUpdateResponseSchema
>;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { IContractor } from 'src/models/contractor.model';
import { useContractorContext } from 'src/contexts/contractor.context';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import Lang from 'src/libraries/language';
import UpdateFormView from './contractor-update.view';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { isAdmin } from 'src/libraries/users.library';

export type IProps = {
    isOpen: boolean;
    contractor?: IContractor;
    handleClose: () => void;
    handleSuccess: (msg, isSuccess) => void;
    handleDiscard: (param) => void;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    isNewPrime?: boolean;
    contractorData: any;
};

export const isNotEqual: any = (object1, object2) => {
    const keys1 = object1 != null ? Object.keys(object1) : [];
    const keys2 = object2 != null ? Object.keys(object2) : [];

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

const ContractorUpdateForm: React.FC<IProps> = ({
    contractorData,
    formRef,
    handleSuccess,
    handleDiscard,
    isNewPrime = false,
    ...props
}) => {
    const { state, actions } = useContractorContext();
    const [confirm, setConfirm] = useState(false);
    const [toastMsg, setToastMsg] = useState('');
    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const [confirmmsg, setConfirmMsg] = useState('');
    const [btnDisable, setBtnDisable] = useState(true);
    localStorage.setItem(
        'contractorInfo_oldData',
        JSON.stringify(contractorData.list)
    );
    localStorage.setItem(
        'contractorInfo_newData',
        JSON.stringify(contractorData.list)
    );
    const [companyStatus, setCompanyStatus] = useState(
        contractorData.list?.data?.active || false
    );
    const isUserAdmin = isAdmin();
    const [contractorInfo_newData, setContractorInfo_newData] = useState(
        contractorData.list
    );
    const [contractorInfo_oldData] = useState(contractorData.list);

    useEffect(() => {
        if (isUserAdmin) {
            actions.advisorAndContactUserGET();
        }
    }, [isUserAdmin]);

    const advisorOptions = useMemo(
        () =>
            state.advisorAndContactUserList?.data?.sdge?.map((type) => ({
                key: type?.userID,
                value: type?.userID,
                text: type ? `${type?.firstName} ${type?.lastName}` : '',
            })),
        [state.advisorAndContactUserList]
    );

    const socalContactOptions = useMemo(
        () =>
            state.advisorAndContactUserList?.data?.socal?.map((type) => ({
                key: type?.userID,
                value: type?.userID,
                text: type ? `${type?.firstName} ${type?.lastName}` : '',
            })),
        [state.advisorAndContactUserList]
    );

    const contactOptions = useMemo(
        () =>
            state.advisorAndContactUserList?.data?.contact?.map((type) => ({
                key: type?.userID,
                value: type?.userID,
                text: type ? `${type?.firstName} ${type?.lastName}` : '',
            })),
        [state.advisorAndContactUserList]
    );

    const handleChange = useCallback(
        async (fieldName, val) => {
            console.log("fieldname",fieldName,val);
            
            setContractorInfo_newData({
                ...contractorInfo_newData,
                [fieldName]: val,
                vendor_id: data.login_id || '',
            });

            setBtnDisable(false);
        },
        [setContractorInfo_newData, contractorInfo_newData]
    );

    const handleError = useCallback(
        async (msg) => {
            setConfirm(false);

            ToastError(msg);
        },
        [setConfirm, setToastMsg]
    );

    /*
     * handle cancel button of form
     */
    const handleClose = useCallback(() => {
        if (isNotEqual(contractorInfo_oldData, contractorInfo_newData)) {
            handleDiscard(false);
            setConfirm(false);
        } else {
            setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_DISCARD);
            localStorage.setItem('discard', 'close');
            setConfirm(true);
        }
    }, [handleDiscard, contractorInfo_newData]);

    const handleClick = useCallback(() => {
        if (formRef && formRef.current) {
            localStorage.setItem('discard', 'open');
            formRef.current.handleSubmit();
        }
    }, [setConfirm, formRef]);

    const handleSubmit = useCallback(
        (formData: IContractor) => {
            localStorage.setItem('contractorInfo', JSON.stringify(formData));
            localStorage.setItem('discard', 'open');
            setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_UPDATE);
            setConfirm(true);
        },
        [setConfirm, handleSuccess]
    );

    const handleSubmitConfirm = useCallback(async () => {
        if (localStorage.getItem('discard') == 'open') {
            const contractorInfo = JSON.parse(
                localStorage.getItem('contractorInfo')!
            );

            contractorInfo.state = contractorInfo?.state?.toUpperCase();
            let response;
            delete contractorInfo.isToggleText
            if (!isUserAdmin) {
                delete contractorInfo.login_id;
                contractorInfo.vendor_id =
                    localStorage.getItem('loginCredential');
                delete contractorInfo.company_name;
                delete contractorInfo.supplier_diversity_contact;
                delete contractorInfo.supplier_code;
                delete contractorInfo.supplier_diversity_contact_socal;
                response = await actions.replacePUT(contractorInfo);
            } else {
                if (contractorInfo.dbeAdvisor) {
                    contractorInfo.dbe_advisor = contractorInfo.dbeAdvisor;
                }
                if (contractorInfo.socalContact) {
                    contractorInfo.socal_contact = contractorInfo.socalContact;
                }

                delete contractorInfo.dbeAdvisor;
                delete contractorInfo.socalContact;

                if (!contractorInfo.dbe_advisor) {
                    delete contractorInfo.dbeAdvdbe_advisorisor;
                }
                if (!contractorInfo.socal_contact) {
                    delete contractorInfo.socal_contact;
                }

                contractorInfo.vendor_id = contractorInfo.login_id;
                delete contractorInfo.login_id;
                if (!contractorInfo.contact1) {
                    delete contractorInfo.contact1;
                }
                if (!contractorInfo.contact2) {
                    delete contractorInfo.contact2;
                }
                if (!contractorInfo.contact3) {
                    delete contractorInfo.contact3;
                }

                if (isNewPrime) {
                    contractorInfo.allow_agreements =
                        contractorInfo.allow_agreements ? 1 : 0;
                    contractorInfo.active = contractorInfo.active ? 1 : 0;
                    response = await actions.adminPrimeReplacePOST(
                        contractorInfo
                    );
                } else {
                    contractorInfo.vendor_id = data.login_id;
                    response = await actions.adminPrimeReplacePUT(
                        contractorInfo
                    );
                }
            }
            if (response?.payload) {
                if (localStorage.getItem('primeData')!) {
                    localStorage.setItem(
                        'primeData',
                        JSON.stringify({
                            ...JSON.parse(localStorage.getItem('primeData')!),
                            active: contractorInfo.active,
                            name: contractorInfo.company_name,
                        })
                    );
                }
                handleSuccess(
                    isUserAdmin && isNewPrime
                        ? Lang.MSG_CONTRACTOR_PRIME_INFORMATION_ADDED_SUCCESSFULLY
                        : Lang.MSG_CONTRACTOR_PRIME_INFORMATION_UPDATED_SUCCESSFULLY,
                    true
                );
                setBtnDisable(true);
                setConfirm(false);
            } else if (!response?.error?.status) {
                handleError(response?.error?.message);
                setConfirm(false);
            }
        } else {
            setConfirm(false);
            handleDiscard(false);
        }
    }, [handleSuccess]);

    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: true });
    }, [setIsVisible]);

    const handleCancel = useCallback(() => {
        setConfirm(false);
    }, [confirm]);

    const data = useMemo(() => {
        const value = contractorData?.list?.data;

        if (value) {
            return {
                ...value,
                ...{ supplier_code: value.supplier_code?.trim() },
            };
        }

        return { data: {} };
    }, contractorData);
    return (
        <>
            <UpdateFormView
                {...props}
                data={data}
                isAdmin={isUserAdmin}
                isNewPrime={isNewPrime}
                loading={false}
                isVisible={isVisible}
                toastMsg={toastMsg}
                handleDismiss={handleDismiss}
                formRef={formRef}
                handleClose={handleClose}
                handleClick={handleClick}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                btnDisable={btnDisable}
                advisorOptions={advisorOptions}
                socalContactOptions={socalContactOptions}
                contactOptions={contactOptions}
                companyStatus={companyStatus}
                setCompanyStatus={setCompanyStatus}
            />
            {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={confirmmsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleCancel}
                    onConfirm={handleSubmitConfirm}
                />
            )}
        </>
    );
};

export default ContractorUpdateForm;

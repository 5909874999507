import React, { useRef, useState } from 'react';
import { Container } from 'semantic-ui-react';
import {
    ORGDataTable,
    ATMInput,
    ATMButton,
    ATMGrid,
    ATMSelect,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
    ATMResponsive,
    ATMForm,
    ATMField,
    ATMToggle
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './primes.module.scss';
import { IContactPrime } from 'src/models/contact-primes.model';
import { alphaNumericStartsWith, alphabetStartsWith, stateOption, subContractorType } from 'src/constants';
import { isAdmin, isContact } from 'src/libraries/users.library';

export type IProps = {
    isVisible: {
        success: boolean;
        error: boolean;
    };
    isAdmin?: boolean;
    handelPrimeContact?: (state: boolean) => void;
    data: IContactPrime[];
    loading: boolean;
    toggleBool:boolean;
    handleEdit: (data) => void;
    fetchData: (params: IORGDataTableQueryState) => void;
    handleDismiss: () => void;
    primesDetail: (primeId) => void;
    params?: IORGDataTableQueryState;
    totalRecord: number;
    handleOpenAuditLogs: (data) => void;
    handlePrimeTire3Toggle: (vendor_id,value) => void;
    handleOnDiscardAuditLogs: () => void;
    handleOpenTier3AuditLogs: () => void;
    toggleFuc:(event) => void;

};

const ContactPrimesListView: React.FC<IProps> = ({
    isAdmin = false,
    data,
    loading,
    fetchData,
    handleEdit,
    totalRecord,
    primesDetail,
    handelPrimeContact,
    handleOpenAuditLogs,
    handlePrimeTire3Toggle,
    handleOnDiscardAuditLogs,
    toggleBool,
    toggleFuc,
    handleOpenTier3AuditLogs
}) => {
    const sapIdRef = useRef(null);
    const [sapIdError, setSapIdError] = useState(false);

    const checkSapId = (value) => {
        const numberRegex = /^[0-9]*$/;
        return !numberRegex.test(value);
    };

    const validateSapID = (value) => {
        setSapIdError(checkSapId(value));
    };

    const columns: IORGDataTableColumn<IContactPrime>[] = [
        {
            title: Lang.LBL_CONTACT_PRIMES_SAP_ID,
            index: 'sapId',
            render: (_, value) => (
                <span className={style.rowData}>{value.sapId}</span>
            ),
            headerProps: {
                className: style.sapId,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_BUSINESS_NAME,
            index: 'name',
            render: (_, value) => (
                <div><span className={style.rowData}>
                    <i
                        style={{
                            color: '#009BDA',
                            fontWeight: 'bold',
                            fontStyle: 'normal',
                            cursor: 'pointer',
                        }}
                        onClick={() => primesDetail(value)}
                    >
                        {value.name}
                    </i>
                </span>
                {value.isToggleAllow ? <span 
                style={{
                    position: 'relative',
                    color: '#fff',
                    background: '#15ba15',
                    marginLeft: '15px',
                    padding: '1px 5px',
                    borderRadius: '3px'
                }}
                >TIER III</span> : '' }
                </div>
            ),
            headerProps: {
                className: style.prime,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_ADDRESS,
            index: 'address',
            render: (_, value) => (
                <span className={style.rowData}>{value.address}</span>
            ),
            headerProps: {
                className: style.address,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_CITY,
            index: 'city',
            render: (_, value) => (
                <span className={style.rowData}>{value.city}</span>
            ),
            headerProps: {
                className: style.city,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_STATE,
            index: 'state',
            render: (_, value) => (
                <span className={style.rowData}>{value.state}</span>
            ),
            headerProps: {
                className: style.state,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_FAVOURITES,
            index: 'Fav_Id',
            render: (_, value) => (
                <span
                    className={style.rowData}
                    style={{ position: 'relative', left: '-5px' }}
                >
                    {value.Fav_Id > 0 ? (
                        <>
                            <i
                                className="star icon"
                                style={{ color: '#CDA000' }}
                            ></i>
                            <span> {Lang.LBL_EDIT_UPDATE_FAVOURITE}</span>
                        </>
                    ) : (
                        '-'
                    )}
                </span>
            ),
            headerProps: {
                className: style.favourite,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_STATUS,
            index: 'active',
            render: (_, value) => (
                <span className={style.rowData}>
                    <span
                        className={
                            value.active === false
                                ? style.inactiveContract
                                : style.activeContract
                        }
                    ></span>
                    {`${value.active === false ? 'Inactive' : 'Active'}`}
                </span>
            ),
            headerProps: {
                className: style.status,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_VIEW,
            index: 'actions',
            sortable: false,
            width: '5',
            render: (_, value) => (
                <span className={style.rowData}>
                    <a
                        style={{ cursor: 'pointer', padding: '0' }}
                        className="ui icon button"
                    >
                        <i
                            className="edit icon"
                            style={{
                                background: 'transparent',
                                color: 'grey',
                            }}
                            onClick={() => handleEdit(value)}
                        ></i>
                    </a>
                </span>
            ),
            headerProps: {
                className: style.action,
            },
        },
        {
            title: 'Audit Logs',
            index: 'AuditLogs',
            sortable: false,
            render: (_, value) => (
                <ATMButton
                    className="ui primary button"
                    style={{ padding: '5px 10px'}}
                    onClick={() => handleOpenAuditLogs(value)}
                >
                    Audit Logs
                </ATMButton>
            ),
            headerProps: {
                className: style.auditLog,
            },
        },
        {
            title: 'Toggle Tier III Spend',
            index: 'ToggleTier3Spend',
            sortable: false,
            render: (_, value) => (
                <div style={{position: 'relative'}}>
                     {isContact() && (
                        <span style={{ position: 'absolute',width: '100px',height: '40px',top: '-10px',zIndex: '99999',cursor: 'not-allowed'}} ></span>
                     )}
                    <ATMField
                        as={ATMToggle}
                        disabled={isContact()}
                        name="active"
                        style={{marginLeft: '15px'}}
                        checked={value.isToggleAllow}
                        onClick={(_,event) => {
                            handlePrimeTire3Toggle(value.vendor_id,event.checked);
                        }}
                    />
                </div>
        
            ),
            headerProps: {
                className: style.auditLog,
            },
        },
        
    ];

    return (
        <>
            <Container fluid className={style.wrapper}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                    }}
                >
                    <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                        {Lang.LBL_CONTACT_PRIMES}
                    </ATMHeader>
                    <div>
                    {isAdmin && (
                        <ATMButton
                            secondary
                            floated="right"
                            content="Add New Prime"
                            icon="left add"
                            onClick={() => {
                                handelPrimeContact && handelPrimeContact(true);
                            }}
                        />
                    )}
                    {isAdmin && (
                                <ATMButton
                                className="ui primary button"
                                style={{ padding: '10px',float: 'right' }}
                                onClick={() => {
                                    handleOpenTier3AuditLogs();
                                }}
                            >
                                Audit Logs
                            </ATMButton>
                    )}
                    </div>
                </div>
                <ATMSegment>
                    <ORGDataTable
                        celled={false}
                        columns={columns}
                        data={data}
                        loading={loading}
                        sortable
                        toolbar
                        history
                        counter
                        filters={true}
                        noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                        onChange={fetchData}
                        total={totalRecord}
                        rowsPerPageOptions={[10, 20, 25]}
                        filteredBy={(filterList) =>
                            (filterList || []).map(
                                (filter) =>
                                    Lang.PRIME_FILTER_LABEL[filter.name] ??
                                    filter.name
                            )
                        }
                    >
                        {() => ({ 
                            filters: {
                                name_begin_with: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_NAME_BEGIN_WITH}</span>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                name="name_begin_with"
                                                value={value}
                                                options={alphaNumericStartsWith.map(
                                                    (type) => ({
                                                        key: type.key,
                                                        value: type.value,
                                                        text: type.text,
                                                    })
                                                )}
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                            />
                                        </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                ),
                                name_contains: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_NAME_CONTAINS}</span>
                                        <ATMInput
                                            fluid
                                            value={value}
                                            name="name_contains"
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_ENTER
                                            }
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                        />
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                ),
                                state: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE}</span>
                                        <ATMSelect
                                            fluid
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="state"
                                            value={value}
                                            options={stateOption}
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                        />
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                ),
                                sapId: ({ value, setValue, setError }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_SAP_ID_CONTAINS}</span>
                                        <ATMInput
                                            fluid
                                            ref={sapIdRef}
                                            error={sapIdError}
                                            value={value}
                                            name="sapId"
                                            type="text"
                                            maxlength="7"
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_ENTER
                                            }
                                            onChange={(e) => {
                                                setValue(
                                                    e.target.value
                                                );
                                                validateSapID(e.target.value);
                                                setError(
                                                    checkSapId(e.target.value)
                                                        ? Lang.MSG_NUMBERS_ONLY
                                                        : ''
                                                );
                                            }}
                                        />
                                        {sapIdError && (
                                            <span
                                                className={`ui pointing basic label contracts ${style.tooltip}`}
                                            >
                                                {Lang.MSG_NUMBERS_ONLY}
                                            </span>
                                        )}
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                ),
                                subContractorType: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_SUBCONTRACTOR_TYPE}</span>
                                            <ATMSelect
                                                fluid
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                name="subContractorType"
                                                value={value}
                                                options={subContractorType.map(
                                                    (type) => ({
                                                        key: type.key,
                                                        value: type.value,
                                                        text: type.text,
                                                    })
                                                )}
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                            />
                                        </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                ),
                            },
                        })}
                    </ORGDataTable>
                </ATMSegment>
            </Container>
        </>
    );
};

export default ContactPrimesListView;

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import UpdateSubcontrctorView from './update-subcontractor-vons.view';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import UpdateSubContractorModal from '../update-subcontractor-vons-modal/update-subcontractor-vons.component';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import Lang from 'src/libraries/language';
import UpdateSubcontractorVonAuditLog from './update-subcontractor-von-audit-log/update-subcontractor-von-audit-log.component';

const UpdateSubcontrctorComponent: React.FC = () => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAuditLogs, setOpenAuditLogs] = useState(false);
    const [editData, setEditData] = useState();
    const [deleteMsg, setDeleteMsg] = useState('');
    const [subcontractorId, setSubcontractorId] = useState(0);

    const pageParmasref = useRef();
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [SubContractName, setSubContractName] = useState();
    const [VerificationNumber, setVerificationNumber] = useState();
    const searchRef = useRef<any>();
    const search: string = window?.location?.search || '?page=1&limit=20';
    useEffect(() => {
        const parmas = {
            export: 1,
        };
        const response = actions?.listVonNumberGET(parmas);
        if (!response) {
            // TODO
        }
        setSubContractName(searchRef.current?.subcontractor_name);
        setVerificationNumber(searchRef.current?.verification_number);
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
    }, []);

    const fetchData = useCallback(
        async (parmas) => {
            setLoading(true);
            const { page, limit, sort, order } = parmas;
            pageParmasref.current = parmas;

            const param =
                {
                    sort: sort || '',
                    order: order || '',
                    page: page,
                    limit: limit,
                    subcontractorName:
                        searchRef.current?.subcontractor_name || '',
                    verificationNumber:
                        searchRef.current?.verification_number || '',
                } || queryParams;

            await actions.UpdateSubcontractorVonGET(param);
            setLoading(false);
        },
        [setLoading]
    );

    const handleOpen = (value) => {
        setEditData(value);
        setOpen(true);
    };
    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleDelete = (value) => {
        const verificationExpirationDate = value.verificationExpirationDate;
        const verificationStatus = value.VerificationStatus.label;
        const name = value.name;
        const msg =
            name + ' has a verification status of ' +
            verificationStatus +
            ' and the verification expiration date is ' +
            verificationExpirationDate +
            '. Are you sure you want to remove it?';
        const msgWithoutVerificationDate =
            name + ' has a verification status of ' +
            verificationStatus +
            ' and has no verification expiration date. Are you sure you want to remove it?';
        if(verificationExpirationDate === "01/01/1970") {
            setDeleteMsg(
                msgWithoutVerificationDate
            );
        } else {
            setDeleteMsg(
                msg
            );
        }
        
        setConfirm(true);
        setSubcontractorId(value.subcontractor_id);
    };

    const handleOnDiscard = () => {
        setOpen(false);
    };

    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }

    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setOpen(false);
            ToastSuccess(msg);
            setConfirm(false);
            await fetchData(pageParmasref.current);
            setLoading(false);
        },
        [setOpen, setLoading]
    );

    const handleChangeSearch = (key, value: any) => {
        const newData = { ...searchRef.current, ...{ [key]: value } };
        searchRef.current = newData;
    };
    const handleClear = useCallback(async () => {
        searchRef.current = [];
        const param =
            {
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
    }, []);

    const handleSearch = useCallback(async () => {
        const param =
            {
                subcontractor_name: searchRef.current?.subcontractor_name || '',
                verification_number:
                    searchRef.current?.verification_number || '',
                prime_name: searchRef.current?.prime_name || '',
                sap_id: searchRef.current?.sap_id || '',
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
    }, []);

    const handleOnClose = useCallback(async () => {
        setConfirm(false);
    }, []);
    const handleError = (msg) => {
        ToastError(msg);
        setConfirm(false);
    };
    const handleDeleteConfirm = useCallback(async () => {
        const response = await actions.deleteSubcontractor(subcontractorId, true,'');
        response.payload?.status
            ? handleSuccess(response?.payload?.message)
            : handleError(response?.error?.message);
    }, [actions, subcontractorId]);

    return (
        <>
            <UpdateSubcontrctorView
                loading={loading}
                list={state.updateSubcontractorVonList}
                fetchData={fetchData}
                handleOpen={handleOpen}
                handleOpenAuditLogs={handleOpenAuditLogs}
                handleChangeSearch={handleChangeSearch}
                searchRef={searchRef}
                handleSearch={handleSearch}
                handleClear={handleClear}
                setSubContractName={setSubContractName}
                setVerificationNumber={setVerificationNumber}
                handleDelete={handleDelete}
            />
            {open && (
                <UpdateSubContractorModal
                    handleSuccess={handleSuccess}
                    open={open}
                    data={editData}
                    handleOnDiscard={handleOnDiscard}
                />
            )}
            {openAuditLogs && (
                <UpdateSubcontractorVonAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
            {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={deleteMsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleDeleteConfirm()}
                />
            )}
        </>
    );
};

export default UpdateSubcontrctorComponent;

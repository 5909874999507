import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SpendReducer, { defaultState, asyncActions } from 'src/ducks/spend.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(SpendReducer, defaultState, asyncActions);
};

type ISpendContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const SpendContext = createContext<Partial<ISpendContext>>({
    state: defaultState,
}) as React.Context<ISpendContext>;

const SpendProvider: React.FC = ({ children }) => {
    const reducer = useReducer();
    return (
        <SpendContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </SpendContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useSpendContext: () => ISpendContext = () => useContext(SpendContext);
export type IUseSpendContext = ReturnType<typeof useSpendContext>;

export { SpendContext, SpendProvider };

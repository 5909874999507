import services from 'src/services/report.service';
import {
    IChangeSpend,
    IPurchaseOrder,
    IReportAdminMonth,
    IReportMonth,
    IVerification,
    IReportByPrimeContract,
    IPrimeInfoData,
    IVerificationAdmin,
    AdminIChangeSpend
} from 'src/models/report.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    REPORT_MONTH_SUMMARY_LIST_FETCH: 'REPORT_MONTH_SUMMARY_LIST_FETCH',
    REPORT_MONTH_SUMMARY_ADMIN_LIST_FETCH:
        'REPORT_MONTH_SUMMARY_ADMIN_LIST_FETCH',
    REPORT_MONTH_BY_PRIME_CONTRACT_FETCH:
        'REPORT_MONTH_BY_PRIME_CONTRACT_FETCH',
    REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH:
        'REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH',
    ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH:
        'ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH',
    REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_FETCH:
        'REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_FETCH',
    REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_FETCH:
    'REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_FETCH',
    REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_DOWNLOAD:
    'REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_DOWNLOAD',
    REPORT_PRIME_INFORMATION_LIST_FETCH:
        'REPORT_PRIME_INFORMATION_LIST_FETCH',
    REPORT_BY_PURCHASE_ORDER_LIST_FETCH: 'REPORT_BY_PURCHASE_ORDER_LIST_FETCH',
    REPORT_MONTH_SUMMARY_LIST_DOWNLOAD: 'REPORT_MONTH_SUMMARY_LIST_DOWNLOAD',
    REPORT_MONTH_SUMMARY_ADMIN_LIST_DOWNLOAD:
        'REPORT_MONTH_SUMMARY_ADMIN_LIST_DOWNLOAD',
    REPORT_MONTH_BY_PRIME_CONTRACT_DOWNLOAD:
        'REPORT_MONTH_BY_PRIME_CONTRACT_DOWNLOAD',
    REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_DOWNLOAD:
        'REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_DOWNLOAD',
    REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_DOWNLOAD:
        'REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_DOWNLOAD',
    REPORT_BY_PURCHASE_ORDER_LIST_DOWNLOAD:
        'REPORT_BY_PURCHASE_ORDER_LIST_DOWNLOAD',
    REPORT_PRIME_INFORMATION_LIST_DOWNLOAD: 'REPORT_PRIME_INFORMATION_LIST_DOWNLOAD',
    REPORT_NEEDING_VERIFICATION_FOLLOW_UP_ADMIN_LIST_DOWNLOAD : 'REPORT_NEEDING_VERIFICATION_FOLLOW_UP_ADMIN_LIST_DOWNLOAD',
    REPORT_MONTH_SUMMARY_ADMIN_TIER_III_LIST_FETCH:'REPORT_MONTH_SUMMARY_ADMIN_TIER_III_LIST_FETCH',
    REPORT_MONTH_SUMMARY_LIST_TIER_III_FETCH:'REPORT_MONTH_SUMMARY_LIST_TIER_III_FETCH',
    REPORT_MONTH_SUMMARY_LIST_TIER_III_DOWNLOAD:'REPORT_MONTH_SUMMARY_LIST_TIER_III_DOWNLOAD',
    REPORT_MONTH_SUMMARY_ADMIN_LIST_TIER_III_DOWNLOAD:'REPORT_MONTH_SUMMARY_ADMIN_LIST_TIER_III_DOWNLOAD'

} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listMonthSummaryGET: {
        type: actionTypes.REPORT_MONTH_SUMMARY_LIST_FETCH,
        service: services.listMonthSummaryGET,
        status: {} as never,
    },
    listMonthSummaryTierIIIGET: {
        type: actionTypes.REPORT_MONTH_SUMMARY_LIST_TIER_III_FETCH,
        service: services.listMonthSummaryTierIIIGET,
        status: {} as never,
    },
    listMonthSummaryAdminGET: {
        type: actionTypes.REPORT_MONTH_SUMMARY_ADMIN_LIST_FETCH,
        service: services.listMonthSummaryAdminGET,
        status: {} as never,
    },
    listMonthSummaryAdminTierIIIGET: {
        type: actionTypes.REPORT_MONTH_SUMMARY_ADMIN_TIER_III_LIST_FETCH,
        service: services.listMonthSummaryAdminTierIIIGET,
        status: {} as never,
    },
    listMonthReportByPrimeContractGET: {
        type: actionTypes.REPORT_MONTH_BY_PRIME_CONTRACT_FETCH,
        service: services.listMonthReportByPrimeContractGET,
        status: {} as never,
    },
    listNeedingVerificationFollowUpGET: {
        type: actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH,
        service: services.listNeedingVerificationFollowUpGET,
        status: {} as never,
    },
    listNeedingVerificationFollowUpAdminGET: {
        type: actionTypes.ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH,
        service: services.listNeedingVerificationFollowUpAdminGET,
        status: {} as never,
    },
    listChangesToSpendAmountsGET: {
        type: actionTypes.REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_FETCH,
        service: services.listChangesToSpendAmountsGET,
        status: {} as never,
    },
    listAdminChangesToSpendAmountsGET:{
        type:actionTypes.REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_FETCH,
        service:services.listAdminChangesToSpendAmountsGET,
        status:{} as never,
    },
    listAdminChangesToSpendAmountsDOWNLOAD:{
    type:actionTypes.REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_DOWNLOAD,
    service:services.listAdminChangesToSpendAmountsDOWNLOAD,
    status:{} as never,
    },
    listByPurchaseOrderGET: {
        type: actionTypes.REPORT_BY_PURCHASE_ORDER_LIST_FETCH,
        service: services.listByPurchaseOrderGET,
        status: {} as never,
    },
    listPrimeInformationGET :{
        type: actionTypes.REPORT_PRIME_INFORMATION_LIST_FETCH,
        service: services.listPrimeInformationGET,
        status: {} as never,
    },
    listMonthSummaryDOWNLOAD: {
        type: actionTypes.REPORT_MONTH_SUMMARY_LIST_DOWNLOAD,
        service: services.listMonthSummaryDOWNLOAD,
        status: {} as never,
    },
    listMonthSummaryTierIIIDOWNLOAD: {
        type: actionTypes.REPORT_MONTH_SUMMARY_LIST_TIER_III_DOWNLOAD,
        service: services.listMonthSummaryTierIIIDOWNLOAD,
        status: {} as never,
    },
    listMonthSummaryAdminDOWNLOAD: {
        type: actionTypes.REPORT_MONTH_SUMMARY_ADMIN_LIST_DOWNLOAD,
        service: services.listMonthSummaryAdminDOWNLOAD,
        status: {} as never,
    },
    listMonthSummaryAdminTierIIIDOWNLOAD: {
        type: actionTypes.REPORT_MONTH_SUMMARY_ADMIN_LIST_TIER_III_DOWNLOAD,
        service: services.listMonthSummaryAdminTierIIIDOWNLOAD,
        status: {} as never,
    },
    listMonthReportByPrimeContractDOWNLOAD: {
        type: actionTypes.REPORT_MONTH_BY_PRIME_CONTRACT_DOWNLOAD,
        service: services.listMonthReportByPrimeContractDOWNLOAD,
        status: {} as never,
    },
    listNeedingVerificationFollowUpDOWNLOAD: {
        type: actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_DOWNLOAD,
        service: services.listNeedingVerificationFollowUpDOWNLOAD,
        status: {} as never,
    },
    listChangesToSpendAmountsDOWNLOAD: {
        type: actionTypes.REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_DOWNLOAD,
        service: services.listChangesToSpendAmountsDOWNLOAD,
        status: {} as never,
    },
    listByPurchaseOrderDOWNLOAD: {
        type: actionTypes.REPORT_BY_PURCHASE_ORDER_LIST_DOWNLOAD,
        service: services.listByPurchaseOrderDOWNLOAD,
        status: {} as never,
    },
    listPrimeInformationDOWNLOAD: {
        type: actionTypes.REPORT_PRIME_INFORMATION_LIST_DOWNLOAD,
        service: services.listPrimeInformationDOWNLOAD,
        status: {} as never,
    },
    listNeedingVerificationFollowUpAdminDOWNLOAD: {
        type: actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_ADMIN_LIST_DOWNLOAD,
        service: services.listNeedingVerificationFollowUpAdminDOWNLOAD,
        status: {} as never,
    },
};
export type IReportAsync = typeof asyncActions;

export type IReportReducerAction = IAsyncActions<IReportAsync>;
export interface IReportState extends ICommonState<IReportReducerAction> {
    monthSummaryList: IReportMonth;
    monthSummaryAdminList: IReportAdminMonth;
    listNeedingVerificationFollowUpAdmin: IVerificationAdmin;
    listChangesToSpendAmounts: IChangeSpend;
    listAdminChangesToSpendAmountsGET:AdminIChangeSpend,
    listByPurchaseOrder: IPurchaseOrder;
    monthReportByPrimeContract: IReportByPrimeContract,
    listPrimeInformation: IPrimeInfoData,
    listNeedingVerificationFollowUpGET: IVerification,
}

export const defaultState: IReportState = {
    status: {},
    monthSummaryList: {},
    monthSummaryAdminList: {},
    listNeedingVerificationFollowUpAdmin: {},
    listChangesToSpendAmounts: {},
    listByPurchaseOrder: {},
    monthReportByPrimeContract: {},
    listPrimeInformation:{},
    listAdminChangesToSpendAmountsGET:{},
    listNeedingVerificationFollowUpGET: {},
};

const ReportReducer = (
    state: IReportState = defaultState,
    action: ICommonAction<IReportReducerAction>
): IReportState => {
    switch (action.type) {
        case actionTypes.REPORT_MONTH_SUMMARY_LIST_FETCH: {
            return {
                ...state,
                monthSummaryList: action?.payload,
            };
        }
        case actionTypes.REPORT_MONTH_SUMMARY_LIST_TIER_III_FETCH: {
            return {
                ...state,
                monthSummaryList: action?.payload,
            };
        }
        case actionTypes.REPORT_MONTH_SUMMARY_ADMIN_TIER_III_LIST_FETCH: {
            return {
                ...state,
                monthSummaryAdminList: action?.payload,
            };
        }
        case actionTypes.REPORT_MONTH_SUMMARY_ADMIN_LIST_FETCH: {
            return {
                ...state,
                monthSummaryAdminList: action?.payload,
            };
        }
        case actionTypes.REPORT_MONTH_BY_PRIME_CONTRACT_FETCH: {
            return {
                ...state,
                monthReportByPrimeContract: action?.payload,
            };
        }
        case actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH: {
            return {
                ...state,
                listNeedingVerificationFollowUpGET: action?.payload,
            };
        }
        case actionTypes.ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH: {
            return {
                ...state,
                listNeedingVerificationFollowUpAdmin: action?.payload,
            };
        }
        case actionTypes.REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_FETCH: {
            return {
                ...state,
                listChangesToSpendAmounts: action?.payload,
            };
        }
        case actionTypes. REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_FETCH:{
            return {
                ...state,
                listAdminChangesToSpendAmountsGET:action?.payload,
            }
        }
        case actionTypes.REPORT_PRIME_INFORMATION_LIST_FETCH: {
            return {
                ...state,
                listPrimeInformation: action?.payload,
            };
        }
        case actionTypes.REPORT_BY_PURCHASE_ORDER_LIST_FETCH: {
            return {
                ...state,
                listByPurchaseOrder: action?.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default ReportReducer;

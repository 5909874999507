import React, { useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMGrid,
    ATMRadio,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './dialog.module.scss';
import { emailTemplateOptions } from 'src/constants/common.constant';

export const cancel: any = (
    handleModalClose, setOption
) => {
    setOption('');
    handleModalClose();
};

export const done: any = (
    handleModalDone, setOption
) => {
    setOption('');
    handleModalDone();
};

export const handleChange = (setOption, data) => {
    setOption(data);
}

interface IDialogProps {
    handleModalClose: () => void;
    handleModalDone: () => void;
    open: boolean;
    handleTemplateOptionSelected: (optionSelected) => void;
}

export const Dialog: React.FC<IDialogProps> = ({
    handleModalClose,
    handleModalDone,
    open,
    handleTemplateOptionSelected
}) => {
    const [optionSelected, setOption] = useState('');

    return (
        <ATMModal size="small" open={open} className="dim">
            <ATMModal.Header className={style.modalHeader}>
                <div>{Lang.LBL_EMAIL_TEMPLATES}</div>
            </ATMModal.Header>
            <ATMModal.Content className={style.modalContentWrapper}>
                <ATMGrid className={style.modalContent}>
                <ATMGrid.Row 
                            className={style.modalRowHeader}
                            style={{ paddingBottom: '0px' }}
                        >
                            <ATMGrid.Column className={style.modalColHeader} 
                            style={{
                                paddingBottom: 0,
                                paddingTop: -10,
                                marginTop: -10,
                            }}>
                                Template Name
                    </ATMGrid.Column>                        
                        </ATMGrid.Row>            
                    {emailTemplateOptions && (emailTemplateOptions.map((option: any, index: any) => (
                        <ATMGrid.Row key={index}
                            className={style.modalRow}
                            style={{ paddingBottom: '0px' }}
                        >
                            <ATMGrid.Column className={style.modalCol} 
                            style={{
                                paddingBottom: 0,
                                paddingTop: -10,
                                marginTop: -10,
                            }}>
                                <ATMRadio 
                                    checked={ optionSelected === option.key ? true : false }
                                    value={option.key}
                                    name="template"
                                    label={option.templateName}
                                    onChange={(e, data) => {
                                        handleChange(setOption, data.value);
                                        handleTemplateOptionSelected(data.value);
                                    }}
                                />
                            </ATMGrid.Column>                        
                        </ATMGrid.Row>    
                    )))}  
                </ATMGrid>                
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton
                    secondary
                    onClick={() => cancel(handleModalClose, setOption)}
                >
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    id="btnSubmit"
                    disabled={(optionSelected === '')}
                    primary
                    onClick={() => done(handleModalDone, setOption)}
                    >
                    {Lang.LBL_ADD}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
};

import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMIcon,
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import moment from 'moment-timezone';
import Lang from 'src/libraries/language';
import { concatinateArrayWithSpace } from 'src/components/pages/utils/ArrayUtils';


type Iprops = {
    data: any;
    openAuditLogs: boolean;
    handleClose: () => void;
    loadinglog: boolean;
    fetchLogData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
    handleOpenEmailView: (data) => void;
};

function POGuardRailsAuditLogView({
    data,
    openAuditLogs,
    handleClose,
    loadinglog,
    fetchLogData,
    totalRecord, handleOpenEmailView
}: Iprops): any {
    const columns: IORGDataTableColumn<any>[] = [
        {
            title: Lang.TTL_MESSAGE,
            index: 'message',
            render: (_, value) => <span>Number of months to use for PO Creation Date Updated to {value?.validationMonth} Months</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
        {
            title: Lang.TTL_TIME_STAMP,
            index: 'timestamp',
            sortable: false,
            render: (_, value) => <span>{`${value?.loggedAt && value?.loggedAt != '-' ? moment.utc(value?.loggedAt).format('MM/DD/YYYY hh:mm A') : '-'}`}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: Lang.TTL_DONE_BY_USER,
            index: 'doneByUser',
            sortable: false,
            render: (_, value) => <span>{concatinateArrayWithSpace([value.firstName, value.lastName])===''?'-':concatinateArrayWithSpace([value.firstName, value.lastName])}</span>,
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: Lang.TTL_EMAIL,
            index: 'email',
            sortable: false,
            render: (_, value) => <span>{!value.status && <i
                style={{
                    color: '#009BDA',
                    // fontWeight: 'bold',
                    fontStyle: 'normal',
                    cursor: 'pointer',
                }}
                onClick={() => handleOpenEmailView(value)}
            >{'Click to view Email'}</i>}</span>,
            headerProps: {
                //className: Style.prime,
            },
        }
    ];
    return (
        <ATMModal
            size="large"
            open={openAuditLogs}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                {Lang.TTL_AUDIT_LOGS}
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060', position: 'absolute', 'right': '0', top: '5px', cursor: 'pointer' }} />

            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={data}
                    loading={loadinglog}
                    sortable
                    counter
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchLogData}
                    total={totalRecord}
                    key={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    );
}

export default POGuardRailsAuditLogView;

import React from 'react';
import { ATMContainer, ATMIcon } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from '../report.module.scss';

const ReportLandingView: React.FC = () => {
    return (
        <ATMContainer className={style.landingContainer} style={{fontStyle: 'italic'}}>
            <ATMIcon
                name="info circle"
                style={{ marginTop: -6 }}
                color="grey"
            />
            {Lang.MSG_NO_REPORT_SELECTED}
        </ATMContainer>
    );
};

export default ReportLandingView;

import yup from '../libraries/validator.library';
export const GetAdHocSchema = yup // for non prime schema shape
    .object({
        data: yup.array().defined(),
        message: yup.string(),
        status: yup.boolean(),
    })
    .defined();

export const SpendFileAutoGenerationActivationStatusSchema = yup
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        isBlockedSdrBatchJob: yup.boolean(),
                    })
                    .defined()
            )
            .defined(),
        message: yup.string().nullable().defined(),
        status: yup.boolean(),
    })
    .defined();

    export const SpendFileAutoGenerationActivationAuditLogPayloadSchema = yup.object({
        firstName: yup.string(),
        lastName: yup.string(),
        loggedAt: yup.date(),
        status: yup.boolean(),
    });
    
    export const SpendFileAutoGenerationActivationAuditLogResponseSchema = yup.object({
        message: yup.string(),
        data: yup.array().of(SpendFileAutoGenerationActivationAuditLogPayloadSchema),
        status: yup.boolean(),
        total_rows: yup.number(),
        total_pages: yup.number(),
    })

export type IGetAdHocPayload = yup.InferType<
    typeof GetAdHocSchema
>;
import { IMassMailAction, IIMassMailAsyncState } from '../ducks/mass-mail.duck';
import { IStatus } from '../ducks';

export const getMassMailStatus = (
  state: IIMassMailAsyncState,
  action: IMassMailAction['type']
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

import React from 'react';
import Lang from 'src/libraries/language';

const Error403: React.FC = () => {
  return (
    <div style={{ width: '80%', margin: '100px auto' }}>
      <h2>{Lang.TTL_PAGE_ACCESS_DENIED}</h2>
      <p>
        {Lang.formatString(
          Lang.NTE_PAGE_ACCESS_DENIED,
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </a>
        )}
      </p>
    </div>
  );
};

export default Error403;

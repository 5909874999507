import React, { useState } from 'react';
import style from './invalid-contracts.module.scss';
import {
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
    ATMGrid,
    ATMSelect,
    ATMInput,
    ATMField,
    ATMCheckbox,
    ATMButton,
    ATMResponsive,
    ATMForm,
    ATMMessage,
    ATMContainer
} from 'shared-it-appmod-ui';
import { IContactUser } from 'src/models/contactuser.model';
import Lang from 'src/libraries/language';
import moment from 'moment';
import {isAdmin, isPrime, isContact} from 'src/libraries/users.library';


export type IProps = {
    // toastOpen,
    // success,
    // toastMessage,
    data: any;
    totalRecord:number;
    dataRef: any;
    handleClear: () => void;
    handleOpen: (open) => void;
    handleChange: (key, data) => void;
    setContractNumber: (data) => void;
    handleSearch: () => void;
    loading: boolean;
    fetchData: (params: IORGDataTableQueryState) => void;
    utilityData:any;
    handleOpenAuditLogs: () => void;
};

const InvalidContractsView: React.FC<IProps> = ({
    // toastOpen,
    // success,
    // toastMessage,
    data,
    totalRecord,
    dataRef,
    handleOpen,
    handleClear,
    handleSearch,
    handleChange,
    setContractNumber,
    loading,
    fetchData,
    utilityData,
    handleOpenAuditLogs
}) => {

    
    const isUserContact = isContact();

    const columns: IORGDataTableColumn<any>[] = [
        {
            title: 'Utility',
            index: 'sempra_company_id',
            sortable: true,
            render: (_, value) => (
                <span>
                    {getUtilityName(value.sempra_company_id)}
                </span>
            )
        },
        {
            title: 'Contract',
            index: 'contract_number',
            sortable: true,
            render: (_, value) => (
                <span>
                    {value.contract_number}
                </span>
            )
        },
        {
            title: 'Date Added',
            index: 'dateCreated',
            sortable: true,
            render: (_, value) => (
                <span>{`${ value?.dateCreated == null ? '-' : (new Date(value?.dateCreated).toISOString().split('T')[0] !='1900-01-01' ? moment(value?.dateCreated).zone(0).format('M/D/YYYY') : '-')}`}</span>
            )
        },
        {
            title: 'Expiration Date',
            index: 'expiryDate',
            sortable: true,
            render: (_, value) => (
                <span>{`${ value?.expiryDate == null ? '-' : (new Date(value?.expiryDate).toISOString().split('T')[0] !='1900-01-01' ? moment(value?.expiryDate).zone(0).format('M/D/YYYY') : '-')}`}</span>
            )
        },
        {
            title: 'Project Description',
            index: 'description',
            sortable: true,
            render: (_, value) => (
                <span>
                    {value.description}
                </span>
            )
        },
        {
            title: 'Exception',
            sortable: false,
            index: 'spendAllowedAdmin',
            headerProps: {
                className: style.actionWidth,
            },
            render: (_, value) => (
                <span 
                    className={!isUserContact ? (!value.isAvailableInPoTable ? style.notAllowed :(!value.spendAllowedAdmin ? style.exception:style.stopException)) :  style.notAllowed}
                    onClick={
                        () => {
                            if(!isUserContact) {
                                handleOpen(value)
                            }
                        }
                    }
                >
                    {!value.isAvailableInPoTable ? 'Not Allowed' :  (!value.spendAllowedAdmin ? 'Exception' : 'Stop Exception')}
                </span>
            )
        }, 
        {
            title: 'Exception Expiration Date',
            index: 'endDate',
            sortable: true,
            render: (_, value) => (
                <span>{`${ value?.endDate == null ? '-' : (new Date(value?.endDate).toISOString().split('T')[0] !='1900-01-01' ? moment(value?.endDate).zone(0).format('M/D/YYYY') : '-')}`}</span>
            )
        },
        {
            title: 'Last Updated On',
            index: 'createdOn',
            sortable: true,
            render: (_, value) => (
                <span>{`${ value?.createdOn == null ? '-' : (new Date(value?.createdOn).toISOString().split('T')[0] !='1900-01-01' ? moment(value?.createdOn).zone(0).format('M/D/YYYY') : '-')}`}</span>
            )
        },
        {
            title: 'Last Updated By',
            index: 'updatedByUser',
            sortable: true,
            render: (_, value) => (
                <span>
                    {value.updatedByUser}
                </span>
            )
        },
    ];

    const getUtilityName = (id) => {
        const utility = utilityData.filter((b) => { return b.Sempra_Company_ID == id;});
        return utility[0].Sempra_Company_Name;
    };

    return (
        <>
            <ATMContainer fluid>
            <ATMHeader as="h1" style={{ marginTop: '10px' }}>
                    {Lang.TTL_SCARCH_CONTRACTS}
{!isUserContact && <ATMButton
                        className="ui primary button"
                        style={{ padding: '5px 10px',float: 'right' }}
                        onClick={() => handleOpenAuditLogs()}
                    >
                        Audit Logs
                    </ATMButton>}
                </ATMHeader>
                <ATMMessage
                    style={{
                        height: '86px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <label>Contract Number</label>
                        <ATMInput
                            placeholder="Enter Contract Number"
                            style={{ marginLeft: '10px' }}
                            name="contractNumber"
                            onChange={(_, val) => {
                                handleChange('contractNumber', val.value);
                                setContractNumber(val.value);
                            }}
                            value={
                                dataRef.current?.contractNumber
                                    ? dataRef.current.contractNumber
                                    : ''
                            }


                        ></ATMInput>
                    </div>
                    <div>
                        <ATMButton
                            className="ui secondary button"
                            style={{ marginRight: '20px' }}
                            onClick={() => handleClear()}
                        >
                            {Lang.LBL_CLEAR}
                        </ATMButton>
                        <ATMButton
                            className="ui primary button"
                            onClick={() => handleSearch()}
                        >
                            {Lang.LBL_SEARCH}
                        </ATMButton>
                    </div>
                </ATMMessage>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={data}
                    loading={loading}
                    sortable
                    // toolbar
                    // history
                    counter
                    // filters={true}
                    onChange={fetchData}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}                    
                    total={totalRecord}
                    key={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMContainer>
        </>
    );
};

export default InvalidContractsView;

import React, { useState, useCallback, useRef} from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import SubcontractorAddSelectedListView from './subcontractor-add-selected.view';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
    getTableListParams,
    getSubcontractorStatus,
} from 'src/selectors/subcontractor.selector';
import { actionTypes } from 'src/ducks/subcontractor.duck';
import history from 'src/history';
import SubcontractorView from '../subcontractor-add-view/subcontractor-add-view.component';
import Lang from 'src/libraries/language';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';

export type IProps = {
    available: number;
    setavailable: (val) => void;
};

const SubcontractorAddSelected: React.FC<IProps | any> = ({
    available,
    setavailable,
}) => {
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [selectedContractors, setSlectedContractor] = useState<number[]>([]);
    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const { state, actions } = useSubcontractorContext();
    const formRef = useRef<HTMLFormElement>();
    const [isapiError, setIsApiError] = useState(false);

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const user_type = getUserType();
            let data = {};
            const { page, limit } = params;

            if (params != undefined && params) {
                data = getTableListParams(params);
            }
            let user_id = localStorage.getItem('loginCredential'); //for prime user

            if (
                AccessRole.CONTACT === user_type ||
                AccessRole.ADMIN === user_type
            ) {
                //for contact or admin user
                user_id = localStorage.getItem('prime_id');
            }
            const param = {
                ...data,
                page: page,
                limit: limit,
                vendor_id: user_id,
            };
            const resp = await actions.listGET_Not_Prime(param);
            if (resp.error) {
                setIsApiError(true);
            } else {
                setIsApiError(false);
            }
        },
        [actions]
    );

    const CreateSubContractor = useCallback(() => {
        history.push(`/subcontractors/enter`);
    }, []);

    const handleSubmitConfirm = useCallback(async () => {
        if (selectedContractors?.length > 0) {
            const user_type = getUserType();
            let user_id = Number(localStorage.getItem('loginCredential')); //for prime user

            if (
                AccessRole.CONTACT === user_type ||
                AccessRole.ADMIN === user_type
            ) {
                //for contact or admin user
                user_id = Number(localStorage.getItem('prime_id'));
            }
            const data = {
                vendor_id: user_id,
                subcontract_ids: selectedContractors,
            };



            const response = await actions.AddSelectedSub_POST(data);
            localStorage.setItem('resAddSelected', JSON.stringify(response));
            history.push('/subcontractors');
            setIsVisible({ success: true, error: false });
            setTimeout(() => {
                setIsVisible({ success: false, error: false });
            }, 5000);
        }
        setConfirm(false);
    }, [selectedContractors, setSlectedContractor, actions]);

    const AddSubContractor = useCallback(() => {
        setConfirm(true);
    }, [setConfirm, confirm]);

    /**
     * Handle view popup when we click on eye icon
     */
    const handleView = useCallback(
        async (id) => {
            await actions.DetailGET_Not_Prime(id);
            setOpen(true);
        },
        [setOpen]
    );

    const handleRemove = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const goBack = useCallback(() => {
        history.push('/subcontractors');
    }, []);

    /*
     * this will call when we click on checkbox
     */
    const handleCheckbox = useCallback(
        async (id: any) => {
            const index = selectedContractors.indexOf(id);
            if (index >= 0) {
                selectedContractors.splice(index, 1);
                setSlectedContractor([...selectedContractors]);
            } else {
                selectedContractors.push(...[id]);
                const sel = [...selectedContractors];
                setSlectedContractor(sel);
            }
        },
        [setSlectedContractor, actions, state, selectedContractors]
    );

    /**
     * function to handle message popup for few second
     */

    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: false });
    }, [setIsVisible]);

    return (
        <>
            <SubcontractorAddSelectedListView
                isVisible={isVisible}
                handleView={handleView}
                CreateSubContractor={CreateSubContractor}
                data={!isapiError?state?.notPrimeList?.data: []}
                selectedContractors={selectedContractors}
                fetchData={fetchData}
                goBack={goBack}
                totalRecord={!isapiError?(state.notPrimeList?.total_rows ||0): 0}
                AddSubContractor={AddSubContractor}
                handleCheckbox={handleCheckbox}
                handleRemove={handleRemove}
                handleDismiss={handleDismiss}
                available={available}
                setavailable={setavailable}
                loading={
                    getSubcontractorStatus(
                        state,
                        actionTypes.SUBCONTRACTOR_LIST_NOT_PRIME_FETCH
                    ).fetching
                }
            />
            <SubcontractorView
                isOpen={open}
                handleClose={handleClose}
                data={state?.notPrimeDetail?.data || {}}
                formRef={formRef}
            />
            <Confirm
                open={confirm}
                size="tiny"
                content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                loading={false}
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={() => setConfirm(false)}
                onConfirm={handleSubmitConfirm}
            />
        </>
    );
};

export default SubcontractorAddSelected;

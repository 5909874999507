import React, { useEffect, useState } from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMCheckbox,
    ATMToggle,
    ATMSelect,
    ATMSegment,
    ATMHeader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
    updateSubcontractorVonSchema,
    IVonsList,
} from 'src/models/manage-subcontractor.model';
import { ISubList } from 'src/models/manage-subcontractor.model';

import styles from './update-spend-report-modal.module.scss';
const RequiredLable = ({ children, ...props }) => (
    <label {...props} className={styles.required}>
        {children}
    </label>
);

type Iprops = {
    open: boolean;
    btnEnable:boolean;
    handleClose: () => void;
    handleUpdate: () => void;
    handleChange: (key, data) => void;
    data:  any;
    formRef: any;
    currentMonth: any;
    month: any;
    dataRef:any;
};
function UpdateSpendReportModalView({
    open,
    btnEnable,
    handleClose,
    handleUpdate,
    data,
    formRef,
    currentMonth,
    month,
    handleChange,
    dataRef
}: Iprops): any {
    const [error, setError] = useState<boolean>(false);
    const [dropDownError, setDropdownError] = useState(false);
    const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(false);
    return (
        <ATMModal
            size="tiny"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5" className={styles.modelHeader}>
                <div>Spend / No Spend Report</div>
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMForm
                    ref={formRef}
                    mode="onChange"
                    defaultValues={data}
                    //onSubmit={handleSubmit}
                    validationSchema={updateSubcontractorVonSchema}
                >
                    {({ control, errors }) => (
                        <ATMGrid columns={1}>
                            

                            <ATMGrid.Row className={styles.rowWrapper}>
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <RequiredLable>
                                        Select the month with no spend
                                    </RequiredLable>
                                    <ATMField
                                        control={control}
                                        as={ATMSelect}
                                        name="notspendmonth"
                                        onChange={(e) => {
                                            setDropdownError(false);
                                            handleChange(
                                                'notspendmonth',
                                                e[1].value || []
                                            );
                                        }}
                                        multiple
                                        placeholder="Select Months"
                                        options={
                                            month.map(
                                                (reasonItem) => {
                                                    return {
                                                        key: reasonItem.value,
                                                        value: reasonItem.value,
                                                        text: reasonItem.text,
                                                    };
                                                }
                                            )
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
                <ATMButton basic color="blue" onClick={handleClose}>
                    {Lang.LBL_CANCEL}
                </ATMButton>
                <ATMButton
                    primary
                    disabled={btnEnable}
                    onClick={handleUpdate}
                >
                    {Lang.LBL_UPDATE}
                </ATMButton>
            </ATMModal.Actions>
        </ATMModal>
    );
}

export default UpdateSpendReportModalView;

import React, { useEffect, useState } from 'react';
import {
    ATMDropdown,
    ATMPagination,
    ATMTable,
    ATMLoader,
    ATMLabel,
} from 'shared-it-appmod-ui';
import style from '../report.module.scss';
import '../report.scss';
import { formatter } from '../report.component';
import Lang from 'src/libraries/language';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const getCellData: any = (data, type) => {
    if (type === 'verified') {
        data.verifySpendTotal.subcontractor = 'Month Totals';
        return [...data.verifySpend, data.verifySpendTotal];
    } else if (type === 'unVerified') {
        data.unVerifySpendTotal.subcontractor = 'Month Totals';
        return [...data.UnVerifySpend, data.unVerifySpendTotal];
    }
    return [];
};

export const getAmount: any = (row, value) => {
    if (row.subcontractor === 'Month Totals') {
        return formatter.format(value);
    } else {
        return Number(value) !== 0 ? formatter.format(value) : '-';
    }
};

const VerificationFollowUpAdminView: React.FC<any> = ({
    data,
    onVerificationPageChange,
    totalRows = 0,
    loading,
    setResetPagination,
    resetPagination,
}) => {
    const [pageOption, selectPageOption] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        setPageNumber(1);
        setResetPagination(false);
    }, [resetPagination]);

    useEffect(() => {
        handlePageChange();
    }, [pageOption, pageNumber]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window]);

    const handlePageChange = () => {
        onVerificationPageChange({
            page: pageNumber,
            limit: pageOption,
        });
    };

    const pageOptions = [10, 20, 25].map((i) => ({
        key: i,
        value: i,
        text: i,
    }));

    const titles = [
        {
            title: Lang.TTL_REPORT_PRIME_INFO_PRIME_INFORMATION,
            key: 'primeInformation',
        },
        {
            title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_SDC_NAME,
            key: 'sdcName',
        },
        { title: Lang.TTL_SUB_CONTRACTOR_MANAGE, key: 'subcontractorsList' },
        { title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_VON, key: 'von' },
        {
            title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_VERIFICAITON_EXP_DATE,
            key: 'vexdate',
        },
        {
            title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_VERIFICATION_EXP_STATUS,
            key: 'status',
        },
        {
            title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_SUBCONTRACTOR_SPEND_TOTAL,
            key: 'spend',
        },
    ];

    return loading === true ? (
        <div style={{ paddingTop: '30px', paddingBottom: '20px' }}>
            <ATMLoader active inline="centered" size="large" />
        </div>
    ) : (
        <>
            <ATMTable
                className={`${style.purchaseOrderTable} ${style.adminMonthSummaryTable}`}
                style={{
                    width: '100%',
                    marginTop: '2rem',
                    marginBottom: '1rem',
                }}
                id="abc"
                enableScroll
            >
                <ATMTable.Header>
                    <ATMTable.Row>
                        {titles.map((item) => {
                            return (
                                <ATMTable.HeaderCell key={item.key}>
                                    {item.title}
                                </ATMTable.HeaderCell>
                            );
                        })}
                    </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                    {data?.map((j) => {
                        return (
                            <>
                                <ATMTable.Row>
                                    <ATMTable.Cell
                                        rowSpan={
                                            j?.subcontractorsList?.length + 2
                                        }
                                    >
                                        <p>
                                            <b>
                                                {j?.primeInformation?.primeName}
                                            </b>
                                        </p>
                                        <p>
                                            {j?.primeInformation?.contactName}
                                        </p>
                                        <p>{j?.primeInformation?.phone}</p>
                                        <p>{j?.primeInformation?.email}</p>
                                        <p>{j?.primeInformation?.address}</p>
                                        <p>
                                            {j?.primeInformation?.state}{' '}
                                            {j?.primeInformation?.city}{' '}
                                            {j?.primeInformation?.zip}
                                        </p>
                                    </ATMTable.Cell>
                                </ATMTable.Row>
                                <ATMTable.Row>
                                    <ATMTable.Cell
                                        rowSpan={
                                            j?.subcontractorsList?.length + 2
                                        }
                                    >
                                        <p>{j?.sdcName?.contact1}</p>
                                        <p>{j?.sdcName?.contact2}</p>
                                        <p>{j?.sdcName?.contact3}</p>
                                        <p>{j?.sdcName?.sdgeName}</p>
                                        <p>{j?.sdcName?.socalName}</p>
                                    </ATMTable.Cell>
                                </ATMTable.Row>

                                {j?.subcontractorsList?.map((item) => {
                                    return (
                                        <ATMTable.Row
                                            key={item.verificationNumber}
                                            style={{
                                                color:
                                                    item.verificationStatus
                                                        ?.label ===
                                                    'Next 60 days'
                                                        ? ''
                                                        : '#D01919DD',
                                                background:
                                                    item.verificationStatus
                                                        ?.label ===
                                                    'Next 60 days'
                                                        ? ''
                                                        : '#D0191908 0% 0% no-repeat padding-box',
                                            }}
                                        >
                                            <ATMTable.Cell>
                                                {item.subContractorName}
                                            </ATMTable.Cell>
                                            <ATMTable.Cell>
                                                {item.verificationNumber}
                                            </ATMTable.Cell>
                                            <ATMTable.Cell>
                                                {item.verificationExpirationDate
                                                    ? item.verificationExpirationDate
                                                    : '-'}
                                            </ATMTable.Cell>
                                            <ATMTable.Cell>
                                                <ATMLabel
                                                    style={{
                                                        background:
                                                            item
                                                                .verificationStatus
                                                                ?.label ===
                                                            'More than 180 days past due'
                                                                ? '#DB28286B'
                                                                : item
                                                                      .verificationStatus
                                                                      ?.label ===
                                                                  'Next 60 days'
                                                                ? '#FFFF33'
                                                                : item
                                                                      .verificationStatus
                                                                      ?.label ===
                                                                  '1-180 days past due'
                                                                ? '#FFD580'
                                                                : item
                                                                      .verificationStatus
                                                                      ?.label ===
                                                                  'Verified'
                                                                ? '#B5EBD7'
                                                                : '',
                                                    }}
                                                >
                                                    {
                                                        item.verificationStatus
                                                            ?.label
                                                    }
                                                </ATMLabel>
                                            </ATMTable.Cell>
                                            <ATMTable.Cell>
                                                {formatter.format(
                                                    item.totalSpendAmount
                                                )}
                                            </ATMTable.Cell>
                                        </ATMTable.Row>
                                    );
                                })}
                            </>
                        );
                    })}
                </ATMTable.Body>
            </ATMTable>

            <ATMTable.Footer
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <ATMTable.Row colSpan="15">
                    <span>
                        {Lang.LBL_ROW_PER_PAGE}&nbsp;
                        <ATMDropdown
                            compact
                            options={pageOptions}
                            value={pageOption}
                            onChange={(i, j) =>
                                selectPageOption(Number(j.value))
                            }
                        />
                    </span>
                    <ATMTable.HeaderCell
                        style={{ marginLeft: windowDimensions.width }}
                    >
                        <ATMPagination
                            style={{
                                marginLeft:
                                    (windowDimensions.width - 200) / 2.5,
                            }}
                            defaultActivePage={pageNumber}
                            totalPages={Math.ceil(
                                totalRows / Number(pageOption)
                            )}
                            size="tiny"
                            onPageChange={(i, j) => {
                                setPageNumber(Number(j.activePage));
                            }}
                        />
                    </ATMTable.HeaderCell>
                </ATMTable.Row>
            </ATMTable.Footer>
        </>
    );
};

export default VerificationFollowUpAdminView;

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContractorPageReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/contractorpage.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(ContractorPageReducer, defaultState, asyncActions);
};

type IContractorPageContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ContractorPageContext = createContext<Partial<IContractorPageContext>>({
    state: defaultState,
}) as React.Context<IContractorPageContext>;

const ContractorPageProvider: React.FC = ({ children }) => {
    const reducer = useReducer();
    return (
        <ContractorPageContext.Provider
            value={{
                ...reducer,
            }}
        >
            {children}
        </ContractorPageContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useContractorPageContext: () => IContractorPageContext = () => useContext(ContractorPageContext);
export type IUseContractorPageContext = ReturnType<
    typeof useContractorPageContext
>;

export { ContractorPageContext, ContractorPageProvider };

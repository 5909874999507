import { ATMInput, ATMTable, ATMPopover } from 'shared-it-appmod-ui';
import React, { useEffect, useState } from 'react';
import { useSpendContext } from 'src/contexts/spend.context';
import style from './update-spend.module.scss';
import { getPONumbers } from './update-spend.view';
import * as _ from 'lodash';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';

type ISpendTable = {
    header: string;
    handleCellChange;
    cellBorder?: string;
    headerColor: string;
    unverifiedSpendTable?: boolean;
    setFloatError: (i: boolean) => void;
    handleMaxError: (i: boolean) => void;
    type: string;
    changes: any;
};

export const getDefaultValue: any = (j, l, type, changes) => {
    if (
        changes.find(
            (i) =>
                Number(i.subcontractor_id) === Number(l.subcontractor_id) &&
                Object.keys(l).find(
                    (key) => key === 'PO' + i.contract_number
                ) &&
                j.contract_number == i.contract_number
        )
    ) {
        const temp = changes.find(
            (i) =>
                Number(i.subcontractor_id) === Number(l.subcontractor_id) &&
                Object.keys(l).find(
                    (key) => key === 'PO' + i.contract_number
                ) &&
                j.contract_number == i.contract_number
        );
        return temp.new_spend_amount_new;
    } else if (l[`PO${j.contract_number}`] >= 0) {
        return l[`PO${j.contract_number}`];
    } else {
        return '';
    }
};

export const isNormalInteger:any = (str) => {
    let str1 = str.trim();
    str1 = str1.replace(/^0+/, '') || '0';
    const n = Math.floor(Number(str1));
    const x = n !== Infinity && String(n) === str1 && n >= 0;
    return x
};

export const SpendTable: React.FC<ISpendTable> = ({
    header,
    handleCellChange,
    setFloatError,
    handleMaxError,
    headerColor,
    type,
    changes,
}) => {
    const { state, actions } = useSpendContext();
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        refreshTable();
    }, [actions, state]);

    const refreshTable = async () => {
        await setRefresh(false);
        await setRefresh(true);
    };
    const PONumbers = getPONumbers(state?.list?.contracts);

    const changeCell = async (i, j, value, setError) => {
        const obj = {
            i,
            j,
        };
        let hasError = false;
        if (!isNormalInteger(value)) {
            setFloatError(true);
            setError(true);
            hasError = true;
        } else {
            setFloatError(false);
            if(!hasError) {
                setError(false);
            }
        }
        if (String(value).length > 8) {
            handleMaxError(true);
            setError(true);
            hasError = true;
        } else {
            handleMaxError(false);
            if(!hasError) {
                setError(false);
            }
        }

        await handleCellChange(obj, String(value));
    };

    const getDefaultValueCall = (j, l, type) =>
        getDefaultValue(j, l, type, changes);

    const user_type = getUserType();

    const contractsList = state?.contracts?.data?.filter((v) => {
            // if(AccessRole.ADMIN === user_type) {
            //     return true
            // } else {
                if(!v?.isHideForSpend) {
                    return true
                } else {
                    return false;
                }
            //}
            
        }
    ); 
    return (
        <ATMTable
            compact
            structured
            enableScroll
            className={style.mainTable}
            style={{ width: PONumbers?.length == 8 ? '100%' : 'auto' }}
        >
            {refresh && (
                <>
                    <ATMTable.Header>
                        <ATMTable.Row>
                            <ATMTable.HeaderCell
                                rowSpan="2"
                                style={{
                                    borderRight: '1px #DEDEDF',
                                    width: 350,
                                    fontSize: 13,
                                    color: '#000000CC',
                                }}
                            >
                                {'Subcontractor'}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell
                                className={style.th}
                                colSpan={PONumbers?.length}
                                style={{
                                    color: () =>
                                        type === 'verified'
                                            ? '#2DAD4E  !important'
                                            : '#DB2828  !important',
                                    borderRight: 0,
                                    borderLeft: 0,
                                    textAlign: 'center',
                                    minWidth: 125,
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                }}
                            >
                                <span
                                    style={{
                                        color: headerColor,
                                    }}
                                >
                                    {header}  
                                </span>
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        {state?.list && (
                            <ATMTable.Row>
                                {state?.list?.contracts?.map(
                                    (i: any, index) => {
                                        return (
                                            <ATMTable.HeaderCell
                                                style={{
                                                    borderRight: '1px #DEDEDF',
                                                    width: 130,
                                                    maxWidth: 130,
                                                    textAlign: 'center',
                                                    fontSize: 13,
                                                    fontWeight: 'bold',
                                                    color: '#000000CC',
                                                    textOverflow: 'ellipsis',
                                                    textTransform: 'uppercase',
                                                }}
                                                key={index}
                                            >
                                                <span style={{position:'relative'}}>
                                                {i?.contract_number} 
                                                    <ATMPopover
                                                        trigger={
                                                            <i
                                                                className={`info circle icon`}
                                                                style={{
                                                                    color: '#848080',
                                                                    position: 'absolute',
                                                                    right: '-21px',
                                                                }}
                                                            ></i>
                                                        }
                                                        content={i?.description}
                                                        size="mini"
                                                        position="top center"
                                                        flowing={true}
                                                        style={{ marginLeft: '0px', marginTop: '10px' }}
                                                    />
                                                </span>
                                                
                                            </ATMTable.HeaderCell>
                                        );
                                    }
                                )}
                            </ATMTable.Row>
                        )}
                    </ATMTable.Header>
                    <ATMTable.Body>
                        {state?.list?.subcontractors
                            ?.filter((a: any) => {
                                if (type === 'verified' && a.is_verified) {
                                    return a;
                                }

                                if (type === 'unVerified' && !a.is_verified) {
                                    return a;
                                }
                            })
                            ?.map((i: any, index) => {
                                return (
                                    <ATMTable.Row key={index}>
                                        <ATMTable.Cell
                                            style={{
                                                textOverflow: 'ellipsis',
                                                width: 120,
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {i?.name}
                                        </ATMTable.Cell>

                                        {state?.list?.contracts?.map(
                                            (j: any, index) => {
                                                return (
                                                    <ATMTable.Cell key={index}>
                                                        {_.uniqBy(
                                                            state?.list?.data,
                                                            'subcontractor_id'
                                                        )

                                                            ?.filter(
                                                                (k: any) =>
                                                                    (k &&
                                                                        k?.subcontractor_id) ===
                                                                    (i &&
                                                                        i?.subcontractor_id)
                                                            )
                                                            ?.map(
                                                                (
                                                                    l: any,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <SpendCell
                                                                            l={
                                                                                l
                                                                            }
                                                                            i={
                                                                                i
                                                                            }
                                                                            j={
                                                                                j
                                                                            }
                                                                            type={
                                                                                type
                                                                            }
                                                                            getDefaultValue={
                                                                                getDefaultValueCall
                                                                            }
                                                                            changeCell={
                                                                                changeCell
                                                                            }
                                                                            setFloatError={
                                                                                setFloatError
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                    </ATMTable.Cell>
                                                );
                                            }
                                        )}
                                    </ATMTable.Row>
                                );
                            })}
                    </ATMTable.Body>
                </>
            )}
        </ATMTable>
    );
};

export const handleChange:any = (
    change,
    setError,
    setFloatError,
    changeCell,
    i,
    j
) => {

    if (!isNormalInteger(change)) {

        setError(true);
        setFloatError(true);
    } else {
        setError(false);
        setFloatError(false);
    }
    changeCell(i, j, change, setError);
};

export const SpendCell: React.FC<any> = ({
    l,
    i,
    j,
    type,
    changeCell,
    getDefaultValue,
    setFloatError,
}) => {
    const [error, setError] = useState(false);
    const user_type = getUserType();
    return (
        <ATMInput
            fluid
            onChange={(e) =>
                handleChange(
                    e.target.value,
                    setError,
                    setFloatError,
                    changeCell,
                    i,
                    j
                )
            }
            error={error}
            value={getDefaultValue(j, l, type)}
            className={type === 'verified' ? style.updated : style.unupdated}
            style={{
                opacity: 1,
            }}
            disabled={
                AccessRole.CONTACT === user_type ||
                AccessRole.ADMIN === user_type
            }
        />
    );
};

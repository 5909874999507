import { httpClient } from '../libraries/http.library';
import { SummarySchema } from '../models/summary.model';

const client = httpClient();

export const services = {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    listGET: async (params) => {
        return client.get('/viewSpendSummary', params, SummarySchema);
    },
};
export default services;

import { lang } from 'moment';
import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import {
    ATMMessage,
    ATMSelect,
    ATMButton,
    ATMToastMessage,
    ATMConfirmationModal,
} from 'shared-it-appmod-ui';
import { useContractorContext } from 'src/contexts/contractor.context';
import { useResetPasswordContext } from 'src/contexts/reset-password.context';
import { actionTypes } from 'src/ducks/reset-password.duck';
import Lang from 'src/libraries/language';
import { getResetPasswordStatus } from 'src/selectors/reset-password.selector';
import style from './reset-password.module.scss';

export const handleSubmit: any = async (
    state,
    email,
    actions,
    setShowToast,
    setToastMessage,
    setSuccess,
    setError
) => {
    const response = await actions.resetPasswordPOST(email);

    if (response?.payload?.status) {
        setShowToast(true);
        setToastMessage('Password Reset Email Sent Successfully');
        setSuccess(true);
        setError(false);
    } else if(getResetPasswordStatus(state, actionTypes.RESET_PASSWORD).error) {
        setShowToast(true);
        setToastMessage('Error in Password Reset');
        setSuccess(false);
        setError(true);
    } else {
        setShowToast(true);
        setToastMessage('Error in Password Reset');
        setSuccess(false);
        setError(true);
    }
};
const ResetPasswordView: React.FC = () => {
    const [email, setEmail] = useState('');
    const [toastOpen, setShowToast] = useState(false);
    const { state, actions } = useResetPasswordContext();
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const { state: stateContractor } = useContractorContext();

    const [options, setOptions] = useState([
        {
            key: stateContractor?.list?.data?.contactEmail || '',
            value: stateContractor?.list?.data?.contactEmail || '',
            text: stateContractor?.list?.data?.contactEmail || '',
        },
        {
            key: stateContractor?.list?.data?.contactEmail3 || '',
            value: stateContractor?.list?.data?.contactEmail3 || '',
            text: stateContractor?.list?.data?.contactEmail3 || '',
        },
        {
            key: stateContractor?.list?.data?.contactEmail2 || '',
            value: stateContractor?.list?.data?.contactEmail2 || '',
            text: stateContractor?.list?.data?.contactEmail2 || '',
        },
    ]);

    useEffect(() => {
        setOptions([
            {
                key: stateContractor?.list?.data?.contactEmail || '',
                value: stateContractor?.list?.data?.contactEmail || '',
                text: stateContractor?.list?.data?.contactEmail || '',
            },
            {
                key: stateContractor?.list?.data?.contactEmail3 || '',
                value: stateContractor?.list?.data?.contactEmail3 || '',
                text: stateContractor?.list?.data?.contactEmail3 || '',
            },
            {
                key: stateContractor?.list?.data?.contactEmail2 || '',
                value: stateContractor?.list?.data?.contactEmail2 || '',
                text: stateContractor?.list?.data?.contactEmail2 || '',
            },
        ]);
    }, [stateContractor]);

    const openSaveModal = () => {
        setSaveModalOpen(true);
    };

    const closeSaveModal = () => {
        setSaveModalOpen(false);
    };

    const handleAction = () => {
        handleSubmit(
            state,
            email,
            actions,
            setShowToast,
            setToastMessage,
            setSuccess,
            setError
        );
        closeSaveModal();
    };

    return (
        <>
            {toastOpen && (
                <ATMToastMessage
                    className={success ? style.toastSuccess : style.toastError}
                    onDismiss={() => setShowToast(false)}
                    icon={success ? 'check circle' : 'warning circle'}
                    timeout={50}
                    error={error}
                    position="center"
                    success={success}
                    header={toastMessage}
                    style={{
                        marginTop: 61.47,
                        width: '70%',
                        marginLeft: '15%',
                    }}
                />
            )}
            <ATMConfirmationModal
                isActive={saveModalOpen}
                modalContent={'Please confirm your changes.'}
                headerContent="Confirmation"
                cancelButtonContent="Cancel"
                confirmButtonContent="Confirm"
                openModal={() => openSaveModal()}
                closeModal={() => closeSaveModal()}
                action={() => handleAction()}
            />
            <Container fluid style={{ paddingBottom: '20px' }}>
                <ATMMessage info>
                    <ATMMessage.Header>
                        {Lang.LBL_RESET_PASSWORD_FOR}{' '}
                        {
                            JSON.parse(localStorage.getItem('primeData') || '')
                                ?.name
                        }
                    </ATMMessage.Header>
                    <p></p>
                    <p> {Lang.MSG_CONFIRM_YOUR_CHANGES}</p>
                    <p>
                      {Lang.MSG_RESET_PASSWORD}
                    </p>
                    <br />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ marginRight: 15 }}>{Lang.LBL_SUBCONTRACTOR_CONTACT_EMAIL}</span>
                        &nbsp;
                        <ATMSelect
                            style={{ marginRight: 11 }}
                            options={options?.filter((i) => i.value !== '')}
                            onChange={(e, v) => setEmail(v?.value || '')}
                        />
                        &nbsp;
                        <ATMButton
                            primary
                            onClick={() => openSaveModal()}
                            disabled={!email}
                        >
                           {Lang.LBL_RESET_PASSWORD}
                        </ATMButton>
                    </div>
                </ATMMessage>
            </Container>
        </>
    );
};

export default ResetPasswordView;

import React, { useEffect } from 'react';

const PrimeResetPassword = () => {
    useEffect(() => {
        const proxyURL = window.location.pathname;
        const apiKey = process.env.REACT_APP_CDC_API_KEY;
        const login = `<script type="text/javascript"
    src="https://cdns.gigya.com/js/gigya.js?apiKey=${apiKey}">
   
    </script>  <div id="container"></div>
    <script>
       
        gigya.accounts.addEventHandlers({
            onLogin: function () {                
                gigya.fidm.saml.continueSSO();
            }
        });

        gigya.accounts.showScreenSet({
            screenSet: 'Default-RegistrationLogin',
            startScreen: 'gigya-reset-password-screen',
            containerID: "container",
            sessionExpiration: '20000',
        });
        
    </script>`;

        if (proxyURL === '/proxy') {
            const hostName = window.location.origin;
            const script = document.createElement('script');
            script.src = `https://cdns.gigya.com/js/gigya.saml.js?apiKey=${apiKey}`;
            script.textContent = `{
                loginURL: '${hostName}/login',
                logoutURL: "${hostName}/logout"
            }`;
            script.async = true;
            document.body.appendChild(script);
        } else if (proxyURL === '/resetpassword') {
            const script = document.createElement('script');
            script.type = `text/javascript`;
            script.src = `https://cdns.gigya.com/js/gigya.js?apiKey=${apiKey}`;
            document.body.append(script);

            const elemDiv = document.createElement('div');
            elemDiv.id = 'container';
            document.body.appendChild(elemDiv);

            const scriptContent = document.createElement('script');
            scriptContent.textContent = `gigya.accounts.addEventHandlers({
                onLogin: function () {                
                    gigya.fidm.saml.continueSSO();
                }
            });
    
            gigya.accounts.showScreenSet({
                screenSet: 'Default-RegistrationLogin',
                startScreen: 'gigya-reset-password-screen',
                containerID: "container",
                sessionExpiration: '20000',
            });`;

            document.write(login);
        }
    }, []);

    return <>{/* <CDCLoginProxyView /> */}</>;
};

export default PrimeResetPassword;

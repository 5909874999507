import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import AdHocSpendFileReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/ad-hoc-spend-file.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(AdHocSpendFileReducer, defaultState, asyncActions);
};

type IAdHocSpendFileContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const AdHocSpendFileContext = createContext<Partial<IAdHocSpendFileContext>>({
  state: defaultState,
}) as React.Context<IAdHocSpendFileContext>;

const AdHocSpendProvider: React.FC = ({ children }) => {
  const reducer = useReducer();  
  return (
    <AdHocSpendFileContext.Provider
      value={{
        ...reducer,
      }}>
      {children}
    </AdHocSpendFileContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useAdHocSpendFileContext: () => IAdHocSpendFileContext = () => useContext(AdHocSpendFileContext);
export type IUseAdHocSpendFileContext = ReturnType<typeof useAdHocSpendFileContext>;

export { AdHocSpendFileContext, AdHocSpendProvider };

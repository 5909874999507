/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import { ContactPrimeListPayloadSchema, FavouriteUpdateSchema, SpendDataAuditLogSchema, AllowPrimeTire3SubcontractorsSchema, AllowPrimeTire3SubcontractorsAuditLogSchema, AllPrimeResponseSchema } from '../models/contact-primes.model';

const client = httpClient();
export const services = {
    listGET_Contact_Primes: async (params) => {
        return client.get('/getAllPrimeByUserId', params, ContactPrimeListPayloadSchema);
    },
    Primes_Favourite_Update: async (params) => {
        return client.put('/primeFavourite', params, FavouriteUpdateSchema);
    },
    spendDataAuditLogGet: async (params) => {
        return client.get('/spendDataAuditLog', params, SpendDataAuditLogSchema);
    },
    allowPrimeTire3Subcontractors: async (params) => {
        return client.post('/allowPrimeTier3Subcontractors', params, AllowPrimeTire3SubcontractorsSchema);
    },
    allowPrimeTire3SubcontractorsAuditLog: async (params) => {
        return client.get('/allowPrimeTier3SubcontractorsAuditLog', params, AllowPrimeTire3SubcontractorsAuditLogSchema);
    },
    getAllPrimes: async () => {
        return client.get('/getAllPrimes', {}, AllPrimeResponseSchema);
    },

    //spendDataAuditLog?vendorId=9543&page=1&limit=10

    
};
export default services;

import React, { useCallback, useEffect, useState } from 'react';
import { ATMIcon, ATMLoader, ATMRadio, ATMSelect } from 'shared-it-appmod-ui';
import { useContractorContext } from 'src/contexts/contractor.context';
import { getUserID, isAdmin, isContact, isPrime } from 'src/libraries/users.library';
import style from './dashboard-head.module.scss';
import Lang from 'src/libraries/language';

export type IProps = {
    primeFilter: string;
    utilityDropdownOptions: any;
    primeDropdownOptions: any;
    handleChangePrimeOptions: (value) => void;
    handleSelectPrime: (value) => void;
    handleSelectUtility: (value) => void;
    sapId: string;
    companyCode: string;
    isDashboardHeaderLoading: boolean
};

const AdminDashboardHead: React.FC<IProps> = ({
    companyCode,
    primeFilter,
    utilityDropdownOptions,
    primeDropdownOptions,
    handleChangePrimeOptions,
    handleSelectPrime,
    handleSelectUtility,
    sapId,
    isDashboardHeaderLoading
}) => {
    return isDashboardHeaderLoading ?
        <div style={{ width: '100%', paddingTop: '55px', display: 'flex', justifyContent: 'center' }}>
            <ATMLoader active inline="centered" size="medium" />
        </div> :
        <div style={{ display: 'contents' }}>
            <div style={{ width: '50%' }} className={style.leftContent}>
                <span className={style.main}>
                    <ATMIcon color="blue" name="building" />
                    <span className={style.head}>{Lang.LBL_PRIMES}</span>
                </span>
                <div style={{ color: '#000000A5', float: 'left', marginTop: '5px', marginBottom: 0 }}>
                    <ATMRadio
                        checked={primeFilter === 'ALL'}
                        value='ALL'
                        name="template"
                        label={Lang.LBL_ALL}
                        style={{
                            marginRight: '5px'
                        }}
                        onClick={(e) => handleChangePrimeOptions('ALL')}
                    />
                    <ATMRadio
                        checked={primeFilter === 'Individual'}
                        value='Individual'
                        name="template"
                        label={Lang.LBL_INDIVIDUAL_PRIME}
                        style={{
                            marginRight: '5px'
                        }}
                        onClick={(e) => handleChangePrimeOptions('Individual')}
                    />
                </div>
                <div className={style.leftDropDown}>
                    <span style={{ float: 'left', fontWeight: '600' }}>
                        {Lang.LBL_SELECT_INDIVIDUAL_PRIME}</span>
                    <ATMSelect
                        search
                        placeholder={Lang.LBL_SELECT_PRIME}
                        options={
                            primeDropdownOptions
                        }
                        selection
                        value={sapId}
                        disabled={primeFilter === 'ALL'}
                        className={style.select}
                        compact
                        onChange={(_, val) =>
                            handleSelectPrime(val.value)
                        }

                    />
                </div>
            </div>

            <div style={{ width: '50%' }} className={style.rightContent}>
                <span className={style.main}>
                    <ATMIcon color="blue" name="industry" />
                    <span className={style.head}>{Lang.LBL_UTILITY}</span>
                </span>
                <div className={style.rightDropDown}>
                    <ATMSelect
                        value={companyCode}
                        placeholder="Select Utility"
                        options={
                            utilityDropdownOptions
                        }
                        className={style.select}
                        compact
                        onChange={(_, val) =>
                            handleSelectUtility(val.value)
                        }
                    />
                </div>
                {/* <span className={style.main}>
                    <ATMIcon color="blue" name="calendar alternate" />
                    <span className={style.head}>Subcontracting Reporting Status</span>
                </span> */}
                {/* <div className={style.rightDropDown}>
                    <ATMSelect
                        placeholder="Select Subcontracting Reporting Status"
                        options={
                            selectOptions
                        }
                        defaultValue='ALL'
                        className={style.select}
                        compact
                    />
                </div> */}
            </div>
        </div>
};

export default AdminDashboardHead;

import * as Msal from 'msal';
import crypto from 'crypto';
import Lang from './language';
import { RefreshTokenPayloadSchema } from '../models/login.model';
import { httpClient } from 'src/libraries/http.library';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import history from 'src/history';

const clientId = process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID ?? '';

Object.defineProperty(global, 'crypto', {
    // code for unit test
    value: {
        getRandomValues: (arr: any) => crypto.randomBytes(arr.length),
    },
}); // end code for unit test

const getmsalInstance = () => {
    const msalConfig: Msal.Configuration = {
        auth: {
            clientId,
            authority: process.env.REACT_APP_ACTIVE_DIRECTORY_AUTHORITY ?? '',
            redirectUri:
                process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI ??
                window.location.origin,
            validateAuthority: false,
            postLogoutRedirectUri: window.location.origin,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
    };

    return new Msal.UserAgentApplication(msalConfig);
};

const userScopes = ['User.Read', 'User.ReadBasic.All'];
export const logout = (): void => {
    const user_type = getUserType();
    const client_id = localStorage.getItem('loginCredential');
    const loginUrl = localStorage.getItem('loginUrl');

    if (AccessRole.PRIME != user_type) {
        const msalInstance = getmsalInstance();
        msalInstance.logout();
    } else {
        if (loginUrl && loginUrl === '/primelogin') {
            history.push('/primelogin');
        } else {
            history.push('/');
        }
    }
    localStorage.removeItem(`loginCredential`);
    localStorage.removeItem(`${client_id}_user_data`);
    localStorage.removeItem(`user_token`);
    localStorage.removeItem('primeData');
    localStorage.removeItem('prime_id');
    localStorage.clear();
};

export const adLogin = async (
    setIsVisible: (arg: { success: boolean; error: boolean }) => void,
    setToastMsg: (message: string) => void
): Promise<boolean> => {
    const loginRequest = {
        scopes: userScopes,
        prompt: 'select_account',
    };

    try {
        const msalInstance = getmsalInstance();
        await msalInstance.loginPopup(loginRequest);
        if (msalInstance.getAccount()) {
            const account = msalInstance.getAccount();
            const accessTokenRequest = {
                scopes: ['user.read'],
                account: account,
            };
            return msalInstance
                .acquireTokenPopup(accessTokenRequest)
                .then(function (accessTokenResponse) {
                    const accessToken = accessTokenResponse.accessToken;
                    localStorage.setItem(`user_token`, accessToken);
                    return true;
                })
                .catch(function (error) {
                    setIsVisible({ success: false, error: true });
                    setToastMsg(error?.message);
                    setTimeout(() => {
                        setIsVisible({ success: false, error: false });
                    }, 30000);
                    return false;
                });
        } else {
            return false;
        }
    } catch (error) {
        console.log('login error', error);
        return false;
    }
};
// Get user details from active directory
export const ProfileContent = (): boolean => {
    return true;
};

// Get user details from microsoft graph server
export const getUser: any = () => {
    const client_id = localStorage.getItem('loginCredential');
    const user = localStorage.getItem(`${client_id}_user_data`);
    return user ? JSON.parse(user) : null;
};

export const getToken: any = () => {
    return localStorage.getItem(`user_token`) ?? null;
};

export const redirect: any = () => {
    //msalInstance.loginRedirect({ scopes: tokenScopes });
};

export const refreshToken: any = async () => {
    const client = httpClient();

    const response = await client.get(
        '/refreshToken',
        '',
        RefreshTokenPayloadSchema
    );
    if (
        response.data &&
        response.message === Lang.MSG_TOKEN_GENERATED_SUCCESS
    ) {
        localStorage.setItem(`user_token`, response.data);
        return true;
    } else {
        return false;
    }
};

// This will fetch the user details from microsoft graph server
// Please see https://developer.microsoft.com/en-us/graph/graph-explorer for the documentation
export const fetchUserData: any = async () => {
    return null;
};

export const hasAuth: any = async () => {
    return null;
};

export const encryptMSALToken = (data) => {
    const secret = process.env.REACT_APP_JWTSECRET;    
    const header = {
        alg: 'HS256',
        typ: 'JWT',
      };
    
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const dateString = date.toISOString();

    const payLoad = {
        data: data,
        exp: dateStringToUnixTimestamp(dateString)
    }

    const jwtB64Header = replaceSpecialChars(toBase64(header));
    const jwtB64Payload = replaceSpecialChars(toBase64(payLoad));
    const signature = createSignature(jwtB64Header, jwtB64Payload, secret);
    const token = jwtB64Header + '.' + jwtB64Payload + '.' + signature;
    return token;
}

const dateStringToUnixTimestamp = (dateString) => {
    return Math.floor(new Date(dateString).getTime() / 1000);
}
  
const toBase64 = (obj) => {
    return Buffer.from(JSON.stringify(obj)).toString('base64');
}

const replaceSpecialChars = (b64string) => {
return b64string.replace(/[=+/]/g, (charToBeReplaced: any) => {
    switch (charToBeReplaced) {
    case '=':
        return '';
    case '+':
        return '-';
    case '/':
        return '_';
    default:
        return;
    }
});
}

const createSignature = (jwtB64Header, jwtB64Payload, secret) => {
    let signature = crypto.createHmac('sha256', secret);
    signature.update(jwtB64Header + '.' + jwtB64Payload);
    const base64Signature = signature.digest('base64');
    signature = replaceSpecialChars(base64Signature);
    return signature;
}  

export const encrypt: any = (text) => {
    const IV_LENGTH = 16; // For AES, this is always 16
    const iv = crypto.randomBytes(IV_LENGTH);
    const ENCRYPTION_KEY_ENV = process.env.ENCRYPTION_KEY ?? '';

    const cipher = crypto.createCipheriv(
        'AES-256-GCM',
        Buffer.from(ENCRYPTION_KEY_ENV),
        iv
    );
    let encrypted = cipher.update(text);

    encrypted = Buffer.concat([encrypted, cipher.final()]);

    return iv.toString('hex') + ':' + encrypted.toString('hex');
};

export const removeSearchParams = (): void => {
    if (window.location.href.split('?').length > 1)
        window.history.pushState(
            {},
            '',
            window.location.href.split('?')[0]
            // htmlEncode(window.location.href.split('?')[0])
        );
};

const htmlEncode = (str) => {
    return String(str).replace(/[^\w. ]/gi, function (c) {
        return '&#' + c.charCodeAt(0) + ';';
    });
};

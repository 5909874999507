import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lang from 'src/libraries/language';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IVonsList } from 'src/models/manage-subcontractor.model';
import UpdateSubcontractorView from './update-subcontractor-vons.view';

type Iprops = {
    open: boolean;
    data: Partial<IVonsList | undefined>;
    handleOnDiscard: () => void;
    handleSuccess: (msg, bool) => void;
};

const UpdateSubcontrctorComponent: React.FC<Iprops> = ({
    open,
    data,
    handleOnDiscard,
    handleSuccess,
}) => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const formRef = useRef<HTMLFormElement>();
    const dataRef = useRef<any>();
    const oldData = useRef<any>();
    const discardStatusRef = useRef<string>();
    const handleClose = () => {
        setConfirm(false);
    };
    const [vonError, setVonError] = useState(false);

    useEffect(() => {
        if (data) {
            dataRef.current = data;
            oldData.current = data;
        }
    }, [data]);

    const handleCancel = useCallback(() => {
        if (data?.verificationNumber === dataRef.current.verificationNumber) {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        } else {
            discardStatusRef.current = 'close';
            setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_DISCARD);
            setConfirm(true);
        }
    }, [data, dataRef]);

    const handleSubmitConfirm = async () => {
        if (discardStatusRef.current === 'open') {
            const oldVerificationNumber  = oldData.current.verificationNumber ? oldData.current.verificationNumber : "";
            const params = {
                subcontractor_id: dataRef.current.subcontractor_id,
                verification_number: dataRef.current.verificationNumber,
                old_verification_number: oldVerificationNumber,
            };

            const response = await actions.UpdateSubcontractorVonPOST(params);

            if (response?.payload) {
                handleSuccess('VON has been updated successfully', true);
                setButtonDisable(true);
                setConfirm(false);
            } else if (!response?.error?.status) {
                ToastError(response?.error?.message);
                setConfirm(false);
            }
        } else {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        }
    };

    const handleChange = (key, value: any) => {
        if (data?.verificationNumber === value) {
            setButtonDisable(true);
            setVonError(false);
        } else {
            const found = state.listVon?.data?.some(
                (key) => key.verificationNumber === value
            );
            if (found) {
                setVonError(true);
            } else {
                setVonError(false);
            }
            setButtonDisable(false);
        }
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const handleClick = useCallback(() => {
        if (formRef && formRef.current) { 
            formRef.current.handleSubmit();
        }
    }, [setConfirm, formRef]);

    const handleSubmit = () => {
        discardStatusRef.current = 'open';
        setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_UPDATE);
        setConfirm(true);
    };

    return (
        <>
            <UpdateSubcontractorView
                data={data}
                open={open}
                handleClose={handleCancel}
                handleClick={handleClick}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formRef={formRef}
                buttonDisable={buttonDisable}
                vonError={vonError}
            />

            {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={confirmmsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleClose}
                    onConfirm={handleSubmitConfirm}
                />
            )}
        </>
    );
};

export default UpdateSubcontrctorComponent;

import React, { useEffect } from 'react';
import {
    ATMButton,
    ATMContainer,
    ATMGrid,
    ATMIcon,
    ATMLabel,
    ATMPopover,
    ATMSegment,
    IORGDataTableQueryState,
    ORGDataTable,
} from 'shared-it-appmod-ui';
import { columns } from 'src/components/pages/report/changes-to-spend-amount/changes-to-spend-amount.view';
import { handleDownload } from 'src/components/pages/report/report.component';
import Lang from 'src/libraries/language';
import style from './subcontractor-spend-list-admin.module.scss';
import moment from 'moment';
import { formatter } from './subcontractor-spend-list-admin.component';

export type ISubcontractorProps = {
    data: any;
    fetchData: (params: IORGDataTableQueryState) => void;
    loading: boolean;
    handleViewSubContractor: (id: number) => void;
    handleDownload: () => void;
};
const addlTxt = (
    <div>
    <p style={{ fontSize: '11px' }}><u><strong>Under the new subcontractor expiration logic:</strong></u></p>
    <p style={{ fontSize: '11px' }}><strong>If the subcontractor&apos;s expiration date is the last day of the current month:</strong></p>
    <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be verified.</p>
    <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>Next 60 Days</strong> in the portal.</p>
    <p style={{ fontSize: '11px' }}><strong>If the expiration date is any day other than the last day of the current month for which the spend is being recorded:</strong></p>
    <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be non-verified.</p>
    <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>1 - 180 Days past due</strong> in the portal.</p>
    </div>
        );

const colorCode = (
    <>
    <ATMGrid columns={2}>
        <ATMGrid.Row className={style.colorRowTop}>
            <ATMGrid.Column width={2} className={style.labelcolor}>
                <ATMLabel
                    customcolor="lightyellow"
                    className={style.labelBox}
                />
            </ATMGrid.Column>
            <ATMGrid.Column width={14} textAlign="left">
                {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS}
            </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={style.colorRow}>
            <ATMGrid.Column width={2} className={style.labelcolor}>
                <ATMLabel
                    customcolor="lightorange"
                    className={style.labelBox}
                />
            </ATMGrid.Column>
            <ATMGrid.Column width={14} textAlign="left">
                {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE}
            </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={style.colorRow}>
            <ATMGrid.Column width={2} className={style.labelcolor}>
                <ATMLabel customcolor="lightred" className={style.labelBox} />
            </ATMGrid.Column>
            <ATMGrid.Column width={14} textAlign="left">
                {Lang.LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE}
            </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={style.colorRow}>
            <ATMGrid.Column width={2} className={style.labelcolor}>
                <ATMLabel customcolor="lightgrey" className={style.labelBox} />
            </ATMGrid.Column>
            <ATMGrid.Column width={14} textAlign="left">
                {Lang.LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH}
            </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className={style.colorRow}>
            <ATMGrid.Column width={2} className={style.labelcolor}>
                <ATMLabel customcolor="lightteal" className={style.labelBox} />
            </ATMGrid.Column>
            <ATMGrid.Column width={14} textAlign="left">
                {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED}
            </ATMGrid.Column>
        </ATMGrid.Row>
    </ATMGrid>
<ATMGrid columns={1}>
<ATMGrid.Row>
<ATMGrid.Column textAlign="left">
                        {addlTxt}
</ATMGrid.Column>
</ATMGrid.Row>
</ATMGrid>
</>
);

const titles = [
    {
        title: Lang.TTL_SUBCONTRACTOR_NAME,
        key: 'subcontractor_name',
        styleClass: style.subcontractorName,
    },
    {
        title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT_NAME,
        key: 'contactName',
        styleClass: style.contactName,
    },
    {
        title: Lang.TTL_CONTACT_NUMBER,
        key: 'contactPhone',
        styleClass: style.contactNumber,
    },
    {
        title: Lang.TTL_CONTACT_EMAIL,
        key: 'contactEmail',
        styleClass: style.contactEmail,
    },
    {
        title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ADMIN_VERIFICATION_EXP_STATUS,
        key: 'verificationStatus',
        styleClass: style.verificationExpStatus,
    },
    {
        title: Lang.TTL_VERIFICATION_NUMBER,
        key: 'verification_number',
        styleClass: style.verificationNo,
    },
    {
        title: Lang.TTL_VERIFICATION_EXP_DATE,
        key: 'verification_expiration_date',
        styleClass: style.verificationExpDate,
    },

    {
        title: Lang.TTL_TOTAL_SPEND_AT_RISK,
        key: 'spendAmount',
        styleClass: style.totalSpend,
    },
];

const SubcontractorSpendListViewAdmin: React.FC<ISubcontractorProps> = ({
    data,
    fetchData,
    loading,
    handleViewSubContractor,
    handleDownload,
}) => {
    const columns = titles.map(({ title, key, styleClass }, index) => {
        return {
            title: (
                <>
                    {(() => {
                        if (key === 'verificationStatus') {
                            return (
                                <span>
                                    {title}
                                    <ATMPopover
                                        trigger={
                                            <i
                                                className={`info circle icon`}
                                                style={{
                                                    color: '#848080',
                                                    position: 'absolute',
                                                    marginTop: '1px',
                                                    paddingLeft: '2px',
                                                }}
                                            ></i>
                                        }
                                        content={colorCode}
                                        size="small"
                                        position="bottom center"
                                        style={{
                                            marginLeft: '0px',
                                            marginTop: '10px',
                                        }}
                                    />
                                </span>
                            );
                        } else {
                            return title;
                        }
                    })()}
                </>
            ),
            index: String(index),
            sortable: false,
            render: (_, record) => (
                <span className={style.rowData}>
                    {(() => {
                        // if (key === 'subcontractor_name'){
                        //     return
                        //     {record[key]}
                        //     <a
                        //     style={{
                        //         cursor: 'pointer',
                        //         fontSize: '14px',
                        //         color: '#009BDA',
                        //         fontWeight: 'bold',
                        //     }}
                        //     onClick={() =>
                        //         handleViewSubContractor(record?.subcontractor_id)
                        //     }
                        // >
                        // </a>
                        // }else
                        if (key === 'verificationStatus') {
                            return (
                                <span
                                    className={style.rowData}
                                    style={{
                                        fontSize: '14px',
                                        color: '#009BDA',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <ATMLabel customcolor={record[key].color}>
                                        {record[key].label}
                                    </ATMLabel>
                                </span>
                            );
                        } else if (key === 'verification_expiration_date') {
                            const expDate = moment(
                                record.verification_expiration_date
                            );
                            return expDate.isValid()
                                ? expDate.format('M/D/YYYY')
                                : '-';
                        } else if (key === 'spendAmount') {
                            return formatter.format(record[key]);
                        } else {
                            return record[key];
                        }
                    })()}
                </span>
            ),
            headerProps: {
                className: styleClass,
            },
        };
    });

    return (
        <>
            <ATMContainer fluid className={style.wrapper}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '15px',
                    }}
                >
                    <div className={style.pageTitle}>
                        {Lang.TTL_SUB_CONTRACTOR_MANAGE}
                    </div>
                </div>
                <ATMSegment>
                    <ATMButton
                        secondary
                        onClick={() => handleDownload()}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            position: 'absolute',
                            right: '10px',
                        }}
                    >
                        <ATMIcon name="download" />
                        {Lang.LBL_DOWNLOAD}
                    </ATMButton>
                    <br />
                    <ORGDataTable
                        celled={false}
                        data={data?.data ?? []}
                        loading={loading}
                        noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                        sortable
                        history
                        counter
                        total={data?.total_rows}
                        onChange={fetchData}
                        rowsPerPageOptions={[10, 20, 25]}
                        columns={columns}
                    />
                </ATMSegment>
            </ATMContainer>
        </>
    );
};

export default SubcontractorSpendListViewAdmin;

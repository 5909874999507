import services from 'src/services/mass-mail.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    SEND_MASS_MAIL: 'SEND_MASS_MAIL',
    GET_STATE_LIST: 'GET_STATE_LIST',
    GET_CITY_LIST: 'GET_CITY_LIST',
    GET_DBE_LIST: 'GET_DBE_LIST',
    GET_MASS_MAIL_AUDIT_LOG: 'GET_MASS_MAIL_AUDIT_LOG',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {

    Send_Mass_Mail: {
        type: actionTypes.SEND_MASS_MAIL,
        service: services.Send_Mass_Mail,
        status: {} as never,
    },
    Get_State_List: {
        type: actionTypes.GET_STATE_LIST,
        service: services.Get_State_List,
        status: {} as never,
    },
    Get_City_List: {
        type: actionTypes.GET_CITY_LIST,
        service: services.Get_City_List,
        status: {} as never,
    },
    Get_Dbe_List: {
        type: actionTypes.GET_DBE_LIST,
        service: services.Get_Dbe_List,
        status: {} as never,
    },
    getMassMailAuditLog: {
        type: actionTypes.GET_MASS_MAIL_AUDIT_LOG,
        service: services.getMassMailAuditLog,
        status: {} as never,
    }

};
export type IMassMailAsync = typeof asyncActions;

export type IMassMailAction = IAsyncActions<IMassMailAsync>;
export interface IIMassMailAsyncState
    extends ICommonState<IMassMailAction> {
    status: any;
    data: any;
    cities: any;
    states: any;
    dbeList: any;
    massMailAuditLogList: any
}

export const defaultState: IIMassMailAsyncState = {
    status: {},
    data: [],
    cities: [],
    states: [],
    dbeList: [],
    massMailAuditLogList: []
};

const MassMailReducer = (
    state: IIMassMailAsyncState = defaultState,
    action: ICommonAction<IMassMailAction>
): IIMassMailAsyncState => {
    switch (action.type) {
        case actionTypes.SEND_MASS_MAIL: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case actionTypes.GET_CITY_LIST: {
            return {
                ...state,
                cities: action.payload,
            };
        }
        case actionTypes.GET_STATE_LIST: {
            return {
                ...state,
                states: action.payload,
            };
        }
        case actionTypes.GET_DBE_LIST: {
            return {
                ...state,
                dbeList: action.payload,
            };
        }
        case actionTypes.GET_MASS_MAIL_AUDIT_LOG: {
            return {
                ...state,
                massMailAuditLogList: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default MassMailReducer;

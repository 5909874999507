import React, { useState, useEffect } from 'react';
import {
    Segment,
    Sidebar,
    Responsive,
    Menu,
    Visibility,
    Container,
    Icon,
    Image,
} from 'semantic-ui-react';
import { logout, getUser } from 'src/libraries/auth.library';
import { checkVisibility } from '../../libraries/access.library';
import { ORGHeader, MOLUserAgent, MOLIdleTime } from 'shared-it-appmod-ui';
import { useLocation } from 'react-router-dom';
import Navigation from 'src/components/pages/navigation/navigation.component';
import Logo from 'src/assets/images/sempra_logo.png';
import Lang from 'src/libraries/language';
import style from 'src/layouts/private/private.module.scss';
import { AccessRole } from 'src/constants/access.constant';
import ToasterContainer from 'src/components/atoms/toaster/toaster.component';
import SystemMessageComponent from 'src/components/organisms/system-message/system-message.component';
import FooterComponent from 'src/components/organisms/footer/footer.component';

import { routes } from 'src/router';
import history from 'src/history';

import {
    PRIME_LOGIN_REDIRECT_URL,
    CONTACT_LOGIN_REDIRECT_URL,
} from 'src/constants';

const getWidth = () => {
    const isSSR = typeof window === 'undefined';

    return Number(isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth);
};

const DesktopContainer: React.FC = ({ children }) => {
    const [fixed, setFixed] = useState(false);
    const location = useLocation();
    const user = getUser();
    if (fixed) {
        // TODO
    }
    if (
        location.pathname === '/' ||
        location.pathname === '/resetpassword' ||
        location.pathname === '/proxy' ||
        location.pathname === '/login' ||
        location.pathname === '/error' ||
        location.pathname === '/success' ||
        location.pathname === '/primelogin'
    ) {
        return (
            <>
                <ToasterContainer />
                <Responsive
                    getWidth={getWidth}
                    minWidth={Responsive.onlyTablet.minWidth}
                    style={{ maxWidth: '1920px', margin: '0 auto' }}
                >
                    {children}
                </Responsive>
            </>
        );
    } else {
        return (
            <>
                <Responsive
                    getWidth={getWidth}
                    minWidth={Responsive.onlyTablet.minWidth}
                >
                    <Visibility
                        once={false}
                        onBottomPassed={() => setFixed(true)}
                        onBottomPassedReverse={() => setFixed(false)}
                    >
                        <div className={style.contentWrapper}>
                            <ToasterContainer />
                            <SystemMessageComponent />

                            <ORGHeader
                                //logoName="sdge"
                                homeLink={
                                    user != null &&
                                    user.user_group == AccessRole.PRIME
                                        ? PRIME_LOGIN_REDIRECT_URL
                                        : CONTACT_LOGIN_REDIRECT_URL
                                }
                                application={Lang.TTL_APPLICATION}
                                environment={process.env.REACT_APP_ENVIRONMENT}
                                menuCount={9}
                                showHelp={false}
                                menus={routes
                                    .filter(
                                        (value) =>
                                            value.label &&
                                            checkVisibility(value.access_role)
                                    )
                                    .map((route) => {
                                        return {
                                            name: route.label,
                                            to: route.path,
                                        };
                                    })}
                                user={
                                    user?.first_name
                                        ? { username: `Hi, ${user.first_name}` }
                                        : undefined
                                }
                                onLogout={() => logout()}
                            >
                                <div
                                    className={style.wrapper}
                                    style={{ maxWidth: '98%', margin: '0 auto' }}
                                >
                                    {children}
                                </div>
                            </ORGHeader>
                            <FooterComponent />
                        </div>
                    </Visibility>
                </Responsive>
            </>
        );
    }
};

const MobileContainer: React.FC = ({ children }) => {
    const location = useLocation();
    const [sidebarOpened, setSidebarOpened] = useState(false);
    if (location.pathname === '/') {
        return (
            <Responsive
                as={Sidebar.Pushable}
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >
                {children}
            </Responsive>
        );
    } else {
        return (
            <Responsive
                as={Sidebar.Pushable}
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >
                <Sidebar
                    as={Menu}
                    animation="push"
                    inverted
                    onHide={() => setSidebarOpened(false)}
                    vertical
                    visible={sidebarOpened}
                >
                    <Navigation />
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment inverted textAlign="center" vertical>
                        <Container>
                            <Menu inverted pointing secondary size="large">
                                <Menu.Item
                                    onClick={() => setSidebarOpened(true)}
                                >
                                    <Icon name="sidebar" />
                                </Menu.Item>
                                <Menu.Item className={style.brand}>
                                    <Image src={Logo} centered />
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>

                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
};

const MasterLayout: React.FC = ({ children }) => {
    const location = useLocation();
    const user = getUser();
    const idleTimeInMinutes: number = parseFloat(
        process.env.REACT_APP_IDLE_TIME_IN_MINUTES ?? ''
    );

    useEffect(() => {
        if (
            user == null &&
            (window.location.pathname == '/proxy' ||
                window.location.pathname == '/login' ||
                window.location.pathname == '/error' ||
                window.location.pathname == '/success' ||
                window.location.pathname == '/primelogin' ||
                window.location.pathname == '/resetpassword')
        ) {
            return;
        } else if (user == null) {
            history.push('/');
        }
    }, [user]);

    return (
        <div>
            {
                <MOLUserAgent /> // for browsers notification
            }
            <DesktopContainer>{children}</DesktopContainer>
            <MobileContainer>{children}</MobileContainer>
            {location.pathname != '/' &&
                location.pathname != '/primelogin' &&
                location.pathname != '/resetpassword' &&
                user != null && (
                    <MOLIdleTime
                        timeout={idleTimeInMinutes} //popup for idle time
                        onLogout={() => {
                            logout();
                        }}
                    />
                )}
        </div>
    );
};

export default MasterLayout;

import { ILoginReducerAction, ILoginState } from '../ducks/login.duck';
import { IStatus } from '../ducks';

export const getLoginStatus = (
  state: ILoginState,
  action: ILoginReducerAction['type']
): IStatus =>
  state?.status[action] ?? {
    fetching: false,
    error: null,
  };

import yup from '../libraries/validator.library';

export const SummaryShape = yup
    .object({
        name: yup.string().nullable().defined(),
        currentUnverifiedSpend: yup.string().nullable().defined(),
        currentVerifiedSpend: yup.string().nullable().defined(),
        totalUnVerifiedSpend: yup.string().nullable().defined(),
        totalVerifiedSpend: yup.string().nullable().defined(),
    })
    .defined();

export const SummarySchema = yup
    .object({
        data: yup.array().of(SummaryShape).defined(),
    })
    .defined();
export type ISummaryShape = yup.InferType<typeof SummaryShape>;
export type ISummary = yup.InferType<typeof SummarySchema>;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import ViewAllSubsView from './view-all-subs.view';
// import SubcontractorStatusEditModalView from './subcontractor-status-edit-modal/subcontractor-status-edit-modal.view';
import SubcontractorStatusEditModalComponent from './subcontractor-status-edit-modal/subcontractor-status-edit-modal.component';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import DeleteConfirmReasonModalComponent from './delete-confirm-reason-modal/delete-confirm-reason-modal.component';
import { actionTypes } from 'src/ducks/manage-contractor.duck';
import {
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { getSPStatus } from 'src/selectors/manage-subcontractor.selector';
import Lang from 'src/libraries/language';
import ViewAllSubAuditLog from './view-all-subs-audit-log/view-all-subs-audit-log.component';

const ViewAllSubs: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState();
    const [open, setOpen] = useState(false);
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const search: string = window?.location?.search || '?page=1&limit=20';
    const dataRef = useRef<any>();
    const [VerificationNumber, setVerificationNumber] = useState();
    const [SubContractName, setSubContractName] = useState();
    const [Hidden, setHidden] = useState();
    const [subcontractorId, setSubcontractorId] = useState<number>(0);
    const [reasonForDeletion, setReasonForDeletion] = useState('');
    const [confirm, setConfirm] = useState<boolean>(false);
    const [deleteMsg, setDeleteMsg] = useState('');
    const [openAuditLogs, setOpenAuditLogs] = useState(false);

    useEffect(() => {
        setVerificationNumber(dataRef.current?.verification_number);
        setSubContractName(dataRef.current?.subContractName);
        setHidden(dataRef.current?.hidden);
        const page = new URLSearchParams(search).get('page');
        const limit = new URLSearchParams(search).get('limit');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
    }, []);

    const handleOpenAuditLogs = () => {
        setOpenAuditLogs(true);
    }
    const handleOnDiscardAuditLogs = () => {
        setOpenAuditLogs(false);
    }
    const handleChange = (key, value: any) => {
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoading(true);
            state.reasons?.data.length == 0 && (await actions.Reason_getList());

            const { page, limit, sort, order } = params;
            tableParamsRef.current = params;
            const param =
                {
                    sort: sort || '',
                    order: order || '',
                    page: page,
                    limit: limit,
                    hidden: dataRef.current?.hidden || '',
                    verificationNumber:
                        dataRef.current?.verification_number || '',
                    subContractName: dataRef.current?.subContractName || '',
                } || queryParams;
            await actions?.viewAllSubcontractor(param);
            setLoading(false);
        },
        [actions]
    );

    const handleOpen = (value) => {
        setEditData(value);
        setOpen(true);
    };
    const handleDelete = (value) => {
        const verificationExpirationDate = value.verificationExpirationDate;
        const verificationStatus = value.VerificationStatus.label;
        const name = value.company_name;
        const msg =
            name +
            ' has a verification status of ' +
            verificationStatus +
            ' and the verification expiration date is ' +
            verificationExpirationDate +
            '. Are you sure you want to remove it?';
        const msgWithoutVerificationDate =
            name +
            ' has a verification status of ' +
            verificationStatus +
            ' and has no verification expiration date. Are you sure you want to remove it?';
        setDeleteMsg(
            verificationExpirationDate ? msg : msgWithoutVerificationDate
        );
        setSubcontractorId(value.subcontractor_id);
        setConfirm(true);
    };

    const handleClear = useCallback(async () => {
        dataRef.current = [];

        setLoading(true);
        const param =
            {
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
        setLoading(false);
    }, [setLoading]);

    const handleSearch = useCallback(async () => {
        let hidden: any;
        if (dataRef.current?.hidden == 1) {
            hidden = true;
        } else if (dataRef.current?.hidden == 0) {
            hidden = false;
        } else if (dataRef.current?.hidden == 2) {
            hidden = '';
        }
        setLoading(true);
        const param =
            {
                verificationNumber: dataRef.current?.verificationNumber || '',
                subContractName: dataRef.current?.subContractName || '',
                hidden: dataRef.current?.hidden || '',
                page: 1,
                limit: 20,
            } || queryParams;
        await fetchData(param);
        setLoading(false);
    }, [setLoading]);

    const handleOnDiscard = () => {
        setOpen(false);
    };
    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setConfirm(false);
            ToastSuccess(msg);
            setOpen(false);
            const param =
                {
                    page: 1,
                    limit: 20,
                } || queryParams;
            await fetchData(param);
            setLoading(false);
        },
        [setOpen, setLoading]
    );
    const handleDeleteConfirm = useCallback(async (reason) => {
        const response = await actions.deleteSubcontractor(subcontractorId,false,reason);
        response.payload?.status
            ? handleSuccess(response?.payload?.message)
            : handleError(response?.error?.message);
    }, [actions, subcontractorId]);
    const handleError = (msg) => {
        ToastError(msg);
        setConfirm(false);
    };

    const handleOnDiscardDelete = useCallback(async () => {
        setConfirm(false);
    }, []);
    const handleOnClose = useCallback(async () => {
        setConfirm(false);
    }, []);
    return (
        <>
            <ViewAllSubsView
                handleOpen={handleOpen}
                dataRef={dataRef}
                data={state?.allSubList.data || []}
                handleSearch={handleSearch}
                handleClear={handleClear}
                fetchData={fetchData}
                totalRecord={state.allSubList.total_rows || 0}
                handleChange={handleChange}
                handleDelete={handleDelete}
                setVerificationNumber={setVerificationNumber}
                setHidden={setHidden}
                setSubContractName={setSubContractName}
                loading={
                    loading ||
                    getSPStatus(state, actionTypes.LIST_VIEW_ALL_SUB).fetching
                }                
                handleOpenAuditLogs={handleOpenAuditLogs}

            />
            {open && (
                <SubcontractorStatusEditModalComponent
                    open={open}
                    reasonList={state.reasons}
                    data={editData}
                    handleOnDiscard={handleOnDiscard}
                    handleSuccess={handleSuccess}
                />
            )}

            {/* {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={deleteMsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleDeleteConfirm()}
                />
            )} */}

            {confirm && (
                <DeleteConfirmReasonModalComponent
                    handleSuccess={handleSuccess}
                    open={confirm}
                    handleOnDiscard={handleOnDiscardDelete}
                    deleteContent={deleteMsg}
                    handleSubmitDelete={handleDeleteConfirm}
                />
            )}

            {openAuditLogs && (
                <ViewAllSubAuditLog
                    openAuditLogs={openAuditLogs}
                    handleOnDiscardAuditLogs={handleOnDiscardAuditLogs}
                />
            )}
        </>
    );
};

export default ViewAllSubs;

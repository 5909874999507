import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ATMContainer, IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import { actionTypes } from 'src/ducks/subcontractor.duck';
import { getSubcontractorStatus } from 'src/selectors/subcontractor.selector';
import SubcontractorAdd from '../../subcontractor-add/subcontractor-add-view/subcontractor-add-view.component';
import { useReportContext } from 'src/contexts/report.context';
import SubcontractorSpendListPrimeView from './subcontractor-spend-list-prime.view';
import XLSX from 'xlsx-js-style';
import moment from 'moment';
import { formatter } from 'src/components/pages/report/report.component';
import {
    border,
    leftAlignment,
    rightAlignment,
    centerAlignment,
    reportHeaderBackground,
    reportCellFont,
    reportHeaderFont,
} from 'src/constants/common.constant';

export const saveData = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function (data, fileName) {
        const blob = new Blob([data], { type: 'octet/stream' });
        let url = '';
        try {
            url = window.URL.createObjectURL(blob);
        } catch (e) {}

        a.href = url;
        a.download = fileName;
        a.click();
        try {
            window.URL.revokeObjectURL(url);
        } catch (e) {}
    };
})();

export const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const SubcontractorSpendListPrime: React.FC = () => {
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const { state, actions } = useSubcontractorContext();
    // const { state: reportState, actions: reportActions } = useReportContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const [currentApiYear, setCurrentApiYear] = useState(Number(localStorage.getItem('reportYear')));

    const initialVerificationStatus = localStorage.getItem(
        'subContractorVerificationStatus'
    );

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const { page, limit, sort, order } = params;
            const data = await actions.reportingyearsGet();
            tableParamsRef.current = params;
            const param = {
                page: page,
                limit: limit,
                spend_year: data.payload.data[0].report_year,
                // vendor_id: localStorage.getItem('loginCredential'),
                expDates: initialVerificationStatus,
                spend_amounts: initialVerificationStatus === '1' ? 0 : 1,
            };
            setLoading(true);
            await actions.getSubcontractorSpendList(param);
            setLoading(false);
        },
        [actions]
    );

    
    // const fetchYear = async () => {
    //     const data = await actions.reportingyearsGet();
    //     localStorage.setItem('reportYearUpdate',data.payload.data[0].report_year)
    //     if(localStorage.getItem('reportYear') != localStorage.getItem('reportYearUpdate')) {
    //         localStorage.setItem('reportYear',data.payload.data[0].report_year)
    //         setCurrentApiYear(data.payload.data[0].report_year);
    //         setTimeout(() => {
    //             window.location.reload();
    //           }, 100);
    //     }
    // }

    const handleDownload = useCallback(async () => {
        const data = await actions.reportingyearsGet();
        const param = {
            spend_year: data.payload.data[0].report_year,
            // vendor_id: localStorage.getItem('loginCredential'),
            expDates: initialVerificationStatus,
            spend_amounts: initialVerificationStatus === '1' ? 0 : 1,
            export: 1,
        };
        const response: any = await actions.getSubcontractorSpendList(param);
        let subcontractorSpendData: any = [];
        subcontractorSpendData = response.payload.data.map(
            (subcontractorData) => ({
                'Subcontractor Name': subcontractorData.subcontractor_name,
                'Contact Name': subcontractorData.contactName,
                'Contact Number': subcontractorData.contactPhone,
                'Contact Email': subcontractorData.contactEmail,
                'Verification Expiration Status':
                    subcontractorData.verificationStatus.label,
                'Verification Number': subcontractorData.verification_number,
                'Verification Exp. Date':
                    subcontractorData.verification_expiration_date
                        ? `${moment(
                              subcontractorData.verification_expiration_date
                          )
                              .format('MM/DD/YYYY')}`
                        : `-`,
                'Total Spend at Risk': subcontractorData.spendAmount,
            })
        );

        const col = [
            { wch: 40 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
            { wch: 35 },
        ];
        let spendReport: any = [];
        try {
            spendReport = XLSX.utils.json_to_sheet(subcontractorSpendData);
            for (const key in spendReport) {
                //The first line
                if (key.replace(/[^0-9]/gi, '') === '1') {
                    const alignment = { ...centerAlignment, wrapText: true };
                    spendReport[key].s = {
                        fill: reportHeaderBackground,
                        alignment: alignment,
                        font: reportHeaderFont,
                        border: border,
                    };
                } else {
                    let alignment = rightAlignment;
                    alignment = leftAlignment;

                    const cellFont = {
                        ...reportCellFont,
                        color: { rgb: '000000' },
                    };
                    spendReport[key].s = {
                        font: cellFont,
                        border: border,
                        alignment: alignment,
                    };

                    if (key.charAt(0) === 'H') {
                        spendReport[key].z = '#,##0';
                    }
                }
            }
        } catch (e) {}

        spendReport['!cols'] = col;
        const workbook = {
            Sheets: {
                Subs_Total_Spend_At_Risk: spendReport,
            },
            SheetNames: ['Subs_Total_Spend_At_Risk'],
        };

        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const a = document.createElement('a');
        document.body.appendChild(a);
        saveData(excelBuffer, 'Subs_Total_Spend_At_Risk.xlsx');
        return null;
    }, []);

    const handleViewSubContractor = useCallback(
        async (id) => {
            await actions.DetailGET_Not_Prime(id);
            setOpen(true);
        },
        [setOpen]
    );
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <ATMContainer fluid>
            <SubcontractorSpendListPrimeView
                data={state?.subcontractorSpendList}
                fetchData={fetchData}
                loading={loading}
                handleDownload={handleDownload}
                handleViewSubContractor={handleViewSubContractor}
            />

            <SubcontractorAdd
                isOpen={open}
                handleClose={handleClose}
                data={state?.notPrimeDetail?.data || { data: [] }}
                formRef={formRef}
            />
        </ATMContainer>
    );
};

export default SubcontractorSpendListPrime;

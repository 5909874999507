import React from 'react';
import { Container } from 'semantic-ui-react';
import { ATMHeader } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './manage-subcontractor.module.scss';

export type IProps = {
    tabs: any;
   
};
const ManageSubcontractorView: React.FC<IProps> = ({ tabs }) => {
    return (
        <>
            <Container
                fluid
                className={style.wrapperPrimeDetails}
                style={{ paddingBottom: '20px' }}
            >
                <ATMHeader as="h1" style={{ marginTop: '10px' }}>
                    {Lang.TTL_MANAGE_SUBCONTRACTORS}
                </ATMHeader>
                {tabs}
            </Container>
        </>
    );
};

export default ManageSubcontractorView;

import React from 'react';
import { Link } from 'react-router-dom';

const Erro404: React.FC = () => {
    return (
        <div>
            <div style={{width: '40%', margin: '0 auto', paddingTop: '10rem', textAlign: 'center'}}>
            <h2>Error 404</h2>
            <p>Page not found.</p>
            <Link to="/">Click to return home</Link>
            </div>
        </div>
    );
};

export default Erro404;

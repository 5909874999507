import { useLocation } from 'react-router-dom';

const useTabLocation = () :  {currentTab: number, pathname: string} => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const currentTab = Number(params.get('tab')) ?? 0;

  return { currentTab, pathname: location.pathname };
};

export default useTabLocation;

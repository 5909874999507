import yup from '../libraries/validator.library';

export const UserShape = yup
    .object({
        userID: yup.number().defined(),
        firstName: yup.string().defined(),
        lastName: yup.string().defined(),
        eMailAddress: yup.string().defined(),
        userTypeID: yup.number().defined(),
        mapping: yup.number().defined(),
        username: yup.string().defined(),
        entityId: yup.number().defined(),
    })
    .defined();

export const UserSchema = yup
    .object({
        data: yup.array().of(UserShape).defined(),
        status: yup.boolean().defined(),
        total_pages: yup.number().defined(),
        total_rows: yup.number().defined(),
    })
    .defined();

export const UserConfigSchema = yup //using for validation
    .object({
        firstName: yup
            .string()
            .required('First Name is required field')
            .max(20, "First Name can't be longer than 20 characters")
            .matches(/^[A-Za-z]*$/, 'First Name must contain only letters'),
        lastName: yup
            .string()
            .required('Last Name is required field')
            .max(20, "Last Name can't be longer than 20 characters")
            .matches(/^[A-Za-z]*$/, 'Last Name contain only letters'),
        eMailAddress: yup
            .string()
            .email('Please enter valid email')
            .required('Email Address is required field'),
        userTypeID: yup.string().required('User Type is required field'),
        username: yup
            .string()
            .required('Alias is required field')
            .max(15, "Alias can't be longer than 15 characters"),
        entityId: yup
            .string()
            .required('Utility is required field'),
    })
    .defined();

export const UserSucess = yup
    .object({
        message: yup.string().nullable().defined(),
        status: yup.boolean().nullable().defined(),
    })
    .defined();

export type IUserShape = yup.InferType<typeof UserShape>;
export type IUSer = yup.InferType<typeof UserSchema>;

import { IResetPasswordReducerAction, IResetPasswordState } from '../ducks/reset-password.duck';
import { IStatus } from '../ducks';

export const getResetPasswordStatus = (
    state: IResetPasswordState,
    action: IResetPasswordReducerAction['type']
): IStatus =>
    state.status[action] ?? {
        fetching: false,
        error: null,
    };

import services from 'src/services/forgot-password.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    FORGOT_PASSWORD_FETCH: 'FORGOT_PASSWORD_FETCH',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    resetPasswordPost: {
        type: actionTypes.FORGOT_PASSWORD_FETCH,
        service: services.resetPasswordPost,
        status: {} as never,
    },
};
export type IForgotPasswordAsync = typeof asyncActions;

export type IForgotPasswordReducerAction = IAsyncActions<IForgotPasswordAsync>;
export interface IForgotPasswordState extends ICommonState<IForgotPasswordReducerAction> {
    forgotpassword: any;
}

export const defaultState: IForgotPasswordState = {
    status: {},
    forgotpassword: {data: {} },
};

const ForgotPasswordReducer = (
    state: IForgotPasswordState = defaultState,
    action: ICommonAction<IForgotPasswordReducerAction>
): IForgotPasswordState => {
    switch (action.type) {
        case actionTypes.FORGOT_PASSWORD_FETCH: {
            return {
                ...state,
                forgotpassword: action?.payload || {data: {} },
            };
        }
        default: {
            return state;
        }
    }
};

export default ForgotPasswordReducer;

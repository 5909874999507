/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */

import PrivateLayout from 'src/layouts/private/private.component';
import routerFactory, { IRoute } from './libraries/router.library';
import Error404 from './components/pages/error/error-404/error-404.component';
import Error403 from 'src/components/pages/error/error-403/error-403.component';
import Lang from './libraries/language';
import Report from './components/pages/report/report.component';
import Login from 'src/components/pages/login/login.component';
import CDCLoginProxy from 'src/components/pages/login/login-proxy/cdc-login-proxy.component';
import SpendSummary from 'src/components/pages/spend-summary/spend-summary.component';
import ContractorInfo from 'src/components/pages/contractor/contractor-info/contractor-info.component';
import UpdateSpend from './components/pages/update-spend/update-spend.component';
import Contracts from 'src/components/pages/contracts/contracts.component';
import Subcontractor from 'src/components/pages/subcontractor/subcontractor.component';
import SubcontractorAddSelected from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-add-selected/subcontractor-add-selected.component';
import SubcontractorCreate from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-create/subcontractor-create.component';
import PrimesContactList from 'src/components/pages/contact-user/primes-contact/primes-contact.component';
import AdminMenu from './components/pages/admin/admin-menu.component';
import ContactPrimesDetail from 'src/components/pages/contact-user/primes/primes-detail/primes-detail.component';
import ContactPrimes from 'src/components/pages/contact-user/primes/primes-info/primes.component';
import { AccessRole } from 'src/constants/access.constant';
import MassMail from './components/pages/admin/mass-mail/mass-mail.component';
import ManageNotification from './components/pages/admin/managenotification/information/manage-notification.component';
import PrimeLogin from './components/pages/login/prime-login/prime-login.component';
import PrimeResetPassword from './components/pages/contact-user/prime-reser-password/prime-reset-password.component';
import AdminSubcontractor from './components/pages/admin-subcontractor/admin-subcontractor.component';
import PrimeDashboard from './components/pages/dashboard/prime-dashboard/prime-dashboard.component';
import AdminDashboard from './components/pages/dashboard/admin-dashboard/admin-dashboard.component';
import SubcontractorSpendListAdmin from './components/pages/subcontractor/subcontractor-spend-list/admin/subcontractor-spend-list-admin.component';
import SubcontractorSpendListPrime from './components/pages/subcontractor/subcontractor-spend-list/prime/subcontractor-spend-list-prime.component';
import IndividualContractInfo from './components/pages/dashboard/subcontracting-summary/individual-contract-info/individual-contract-info.component';

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */

const LoginCDCProxyRoute: IRoute = {
    path: '/proxy',
    layout: PrivateLayout,
    component: CDCLoginProxy,
    access_role: [AccessRole.GUEST],
};
const LoginCDCRoute: IRoute = {
    path: '/login',
    layout: PrivateLayout,
    component: CDCLoginProxy,
    access_role: [AccessRole.GUEST],
};
const LoginCDCErrorRoute: IRoute = {
    path: '/error',
    layout: PrivateLayout,
    component: Login,
    access_role: [AccessRole.GUEST],
};
const LoginCDCSuccessRoute: IRoute = {
    path: '/success',
    layout: PrivateLayout,
    component: Login,
    access_role: [AccessRole.GUEST],
};
const MainRoute: IRoute = {
    path: '/',
    layout: PrivateLayout,
    component: Login,
    access_role: [AccessRole.GUEST],
};
const PrimeLoginRoute: IRoute = {
    path: '/primelogin',
    layout: PrivateLayout,
    component: PrimeLogin,
    access_role: [AccessRole.GUEST],
};
const ResetPasswordRoute: IRoute = {
    path: '/resetpassword',
    layout: PrivateLayout,
    component: PrimeResetPassword,
    access_role: [AccessRole.GUEST],
};
const PrimeDashboardRoute: IRoute = {
    label: Lang.TTL_DASHBOARD,
    path: '/primedashboard',
    layout: PrivateLayout,
    component: PrimeDashboard,
    access_role: [AccessRole.PRIME],
    childRoutes: [
        {
            path: '/primedashboard/subcontractorspendlist',
            component: SubcontractorSpendListPrime,
            access_role: [AccessRole.PRIME],
        },
        {
            path: '/primedashboard/individualContractInfo',
            component: IndividualContractInfo,
            access_role: [AccessRole.PRIME],
        }
    ],
};
const AdminDashboardRoute: IRoute = {
    label: Lang.TTL_DASHBOARD,
    path: '/dashboard',
    layout: PrivateLayout,
    component: AdminDashboard,
    access_role: [AccessRole.ADMIN, AccessRole.CONTACT],
    childRoutes: [
        {
            path: '/dashboard/subcontractorspendlist',
            component: SubcontractorSpendListAdmin,
            access_role: [AccessRole.CONTACT, AccessRole.ADMIN],
        }, {
            path: '/dashboard/individualContractInfo',
            component: IndividualContractInfo,
            access_role: [AccessRole.CONTACT, AccessRole.ADMIN],
        }
    ],
};
const PrimeContactorInfoRoute: IRoute = {
    label: Lang.TTL_CONTRACTOR_INFO,
    path: '/primecontractor',
    layout: PrivateLayout,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    component: ContractorInfo,
    access_role: [AccessRole.PRIME],
};
const ManageSubContractorsRoute: IRoute = {
    label: Lang.TTL_SUB_CONTRACTOR_MANAGE,
    path: '/subcontractors',
    layout: PrivateLayout,
    component: Subcontractor,
    access_role: [AccessRole.PRIME],
    childRoutes: [
        {
            path: '/subcontractors/add',
            component: SubcontractorAddSelected,
            access_role: [AccessRole.PRIME],
        },
        {
            path: '/subcontractors/enter',
            component: SubcontractorCreate,
            access_role: [AccessRole.PRIME],
        },
    ],
};
const ManageContractsRoute: IRoute = {
    label: Lang.TTL_CONTRACTOR_MANAGE,
    path: '/contracts',
    layout: PrivateLayout,
    component: Contracts,
    access_role: [AccessRole.PRIME],
};

const UpdateSpendRoute: IRoute = {
    label: Lang.TTL_UPDATE_SPEND,
    path: '/updatespend',
    layout: PrivateLayout,
    component: UpdateSpend,
    access_role: [AccessRole.PRIME],
};

const ViewSpendSummaryRoute: IRoute = {
    label: Lang.TTL_VIEW_SPEND_SUMMARY,
    path: '/spendsummary',
    layout: PrivateLayout,
    component: SpendSummary,
    access_role: [AccessRole.PRIME],
};
const ContactPrimesRoute: IRoute = {
    label: Lang.TTL_CONTACT_PRIMES,
    path: '/primes',
    layout: PrivateLayout,
    component: ContactPrimes,
    access_role: [AccessRole.CONTACT, AccessRole.ADMIN, AccessRole.DBE_ADVISOR],
    childRoutes: [
        {
            path: '/primes/primecontractor',
            component: ContactPrimesDetail,
            access_role: [
                AccessRole.CONTACT,
                AccessRole.ADMIN,
                AccessRole.DBE_ADVISOR,
            ],
        },
    ],
};

const ContactRoute: IRoute = {
    label: Lang.TTL_PRIMES_AND_CONTACT,
    path: '/primesandcontact',
    layout: PrivateLayout,
    component: PrimesContactList,
    access_role: [AccessRole.CONTACT, AccessRole.ADMIN, AccessRole.DBE_ADVISOR],
};

const ReportRoute: IRoute = {
    label: Lang.TTL_REPORTS,
    path: '/report',
    layout: PrivateLayout,
    component: Report,
    access_role: [
        AccessRole.PRIME,
        AccessRole.ADMIN,
        AccessRole.CONTACT,
        AccessRole.DBE_ADVISOR,
    ],
};
const AdminRoute: IRoute = {
    label: Lang.TTL_ADMIN,
    path: '/admin',
    layout: PrivateLayout,
    component: AdminMenu,
    access_role: [AccessRole.ADMIN],
    childRoutes: [
        {
            path: '/admin/massmail',
            component: MassMail,
            access_role: [AccessRole.ADMIN],
        },

        {
            path: '/admin/managenotification',
            component: ManageNotification,
            access_role: [AccessRole.ADMIN],
        },
    ],
};

const AdminSubcontractors: IRoute = {
    label: Lang.TTL_SUB_CONTRACTOR_MANAGE,
    path: '/adminsubcontractors',
    layout: PrivateLayout,
    component: AdminSubcontractor,
    access_role: [AccessRole.ADMIN],
};

export const routes = [
    MainRoute,
    LoginCDCProxyRoute,
    LoginCDCRoute,
    PrimeDashboardRoute,
    AdminDashboardRoute,
    LoginCDCErrorRoute,
    LoginCDCSuccessRoute,
    PrimeContactorInfoRoute,
    ManageSubContractorsRoute,
    ManageContractsRoute,
    UpdateSpendRoute,
    ViewSpendSummaryRoute,
    ContactPrimesRoute,
    ContactRoute,
    AdminSubcontractors,
    ReportRoute,
    AdminRoute,
    PrimeLoginRoute,
    ResetPasswordRoute,
];
export type IRouter = typeof routes[number];

export default routerFactory(routes, {
    Error404,
    Error403,
});

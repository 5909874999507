import React, { useState, useCallback, useRef, useEffect } from 'react';
import InvalidContractsAuditLogView from './invalid-contracts-audit-log.view';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import { getUserID } from 'src/libraries/users.library';
import InvalidContractsAuditLogEmailView from './email/invalid-contracts-audit-log-email.view';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const InvalidContractsAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = useContractorPageContext();
    const [loadinglog, setLoadinglog] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [openEmailView, setOpenEmailView] = useState<boolean>(false);
    const [auditLogData, setAuditLogData] = useState<any>({});
    const vendorId = getUserID();

    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    vendorId: vendorId,
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.getInvalidContractAuditLog(param);
            setLoadinglog(false);
        },
        [actions]
    );

    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);

    const handleOpenEmailView = useCallback((data) => {
        setAuditLogData(data)
        setOpenEmailView(true)
    }, []);

    const handleCloseEmailView = () => {
        setOpenEmailView(false);
        setAuditLogData({})
    }

    return (
        <>
            {openEmailView && <InvalidContractsAuditLogEmailView open={openEmailView} handleClose={handleCloseEmailView} data={auditLogData} />}
            <InvalidContractsAuditLogView
                data={state?.invalidContractAuditLog?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.invalidContractAuditLog?.total_rows || 0}
                handleOpenEmailView={handleOpenEmailView}
            />

        </>
    );
};

export default InvalidContractsAuditLog;

import React, { useRef, useState, useCallback } from 'react';
import { Container, Message, Transition } from 'semantic-ui-react';
import {
    ORGDataTable,
    ATMButton,
    ATMPopover,
    ATMLabel,
    ATMGrid,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
    ATMSelect,
    ATMInput,
    ATMResponsive,
    ATMForm,
    ATMToggle,
    ATMField
} from 'shared-it-appmod-ui';
import { ISubcontractor } from 'src/models/subcontractor.model';
import Lang from 'src/libraries/language';
import style from './subcontractor-list.module.scss';
import Instruction from 'src/components/atoms/instruction/instruction.component';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import SubcontractorAddSelected from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-add-selected/subcontractor-add-selected.component';
import history from 'src/history';
import { alphaNumericStartsWith, stateOption } from 'src/constants';
import { isAdmin, isContact } from 'src/libraries/users.library';

export type IProps = {
    isVisible: {
        success: boolean;
        error: boolean;
    };
    isPrime: boolean;
    isAdmin: boolean;
    isContact: boolean;
    totalRecord: number;
    handleEdit: (data) => void;
    data: Partial<ISubcontractor>;
    loading: boolean | undefined;
    toastMsg: string;
    hidden: number;
    sethidden: (val) => void;
    fetchData: (params: IORGDataTableQueryState) => void;
    handleRemove: () => void;
    handleDelete: (data) => void;
    AddSubContractor: () => void;
    handleDismiss: () => void;
    handleViewSubContractor: (id) => void;
    handlerToggle: (Vendor_SubContractor_Id, vendor_id, Tier3SpendAllow, Tier2SpendAllow, Hidden, isToggleAllow) => void;
    isTier2: boolean;
};
const addlTxt = (
    <div>
    <p style={{ fontSize: '11px' }}><u><strong>Under the new subcontractor expiration logic:</strong></u></p>
    <p style={{ fontSize: '11px' }}><strong>If the subcontractor&apos;s expiration date is the last day of the current month:</strong></p>
    <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be verified.</p>
    <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>Next 60 Days</strong> in the portal.</p>
    <p style={{ fontSize: '11px' }}><strong>If the expiration date is any day other than the last day of the current month for which the spend is being recorded:</strong></p>
    <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be non-verified.</p>
    <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>1 - 180 Days past due</strong> in the portal.</p>
    </div>
        );

const SubcontractorListView: React.FC<IProps> = ({
    isVisible,
    data,
    toastMsg,
    loading,
    totalRecord,
    handleEdit,
    handleViewSubContractor,
    fetchData,
    AddSubContractor,
    handleDismiss,
    isPrime,
    hidden,
    sethidden,
    handleDelete,
    handlerToggle,
    isTier2
}) => {
    const verificationExpirationStatusList =
        data?.filters_data?.verificationExpirationStatus;
    const sapIdRef = useRef(null);
    const [sapIdError, setSapIdError] = useState(false);
    const checkSapId = (value) => {
        const numberRegex = /^[0-9]*$/;
        return !numberRegex.test(value);
    };
    const validateSapID = (value) => {
        setSapIdError(checkSapId(value));
    };
    const user_type = getUserType();
    const handlehidden = () => {
        if (hidden === 1) {
            sethidden(0);
        } else {
            sethidden(1);
        }
    };
    const [available, setavailable] = useState(0);
    const availableSubcontractor = () => {
        if (available == 0) {
            setavailable(1);
        } else {
            setavailable(0);
        }
        history.push('/primes/primecontractor?tab=1');
    };
    const changeavailable = useCallback(
        (val) => {
            setavailable(val);
            sethidden(1);
            history.push('/primes/primecontractor?tab=1');
        },
        [setavailable, sethidden]
    );



    const checkDefaultValueTier2 = useCallback(
        (val) => {
            if (val.Hidden) {
                return false
            } else {
                return val.Tier2SpendAllow
            }
        },
        []
    );
    const checkDefaultValueTier3 = useCallback(
        (val) => {
            if (val.Hidden) {
                return false
            } else {
                return val.Tier3SpendAllow
            }
        },
        []
    );
    const checkDisableTier = useCallback(
        (val) => {
            if (isAdmin() || isContact()) {
                return true
            } else {
                if (val.Hidden) {
                    return true
                } else {
                    if (val.isToggleAllow) {
                        if (val.Tier2SpendAllow && !val.Tier3SpendAllow) {
                            return true
                        } else if (!val.Tier2SpendAllow && val.Tier3SpendAllow) {
                            return false
                        } else if ((!val.Tier2SpendAllow && !val.Tier3SpendAllow)) {
                            return false
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }

                }
            }
        },
        [isAdmin, isContact]
    );

    const checkDisableTier3 = useCallback(
        (val) => {
            if (isAdmin() || isContact()) {
                return true
            } else {
                if (val.Hidden) {
                    return true
                } else {
                    if (val.isToggleAllow) {
                        if (val.Tier2SpendAllow && !val.Tier3SpendAllow) {
                            return false
                        } else if (!val.Tier2SpendAllow && val.Tier3SpendAllow) {
                            return true
                        } else if ((!val.Tier2SpendAllow && !val.Tier3SpendAllow)) {
                            return false
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }

                }
            }

        },
        [isAdmin, isContact]
    );
    const colorCode = (
        <>
        <ATMGrid columns={2}>
            <ATMGrid.Row className={style.colorRowTop}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightyellow"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightorange"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightred"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightgrey"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightteal"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED}
                </ATMGrid.Column>
            </ATMGrid.Row>
        </ATMGrid>
<ATMGrid columns={1}>
<ATMGrid.Row>
<ATMGrid.Column textAlign="left">
                        {addlTxt}
</ATMGrid.Column>
</ATMGrid.Row>
</ATMGrid>
</>
    );
    const columns = [
        {
            title: Lang.LBL_SUBCONTRACTOR,
            index: 'name',

            render: (_, value) =>
                AccessRole.PRIME === user_type ? (
                    <a
                        style={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            color: '#009BDA',
                            fontWeight: 'bold',
                        }}
                        onClick={() =>
                            handleViewSubContractor(value?.subcontractor_id)
                        }
                    >
                        {value.name}
                    </a>
                ) : (
                    <span className={style.rowData}>{value.name}</span>
                ),
            headerProps: {
                className: style.subcontractor,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTORS_CITY,
            index: 'city',
            render: (_, value) => (
                <span className={style.rowData}>{value.city}</span>
            ),
            headerProps: {
                className: style.city,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_STATE,
            index: 'state',
            render: (_, value) => (
                <span className={style.rowData}>{value.state}</span>
            ),
            headerProps: {
                className: style.state,
            },
        },
        {
            title: (
                <span>
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFICATION}
                    <br /> {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_EXP_STATUS}
                    <ATMPopover
                        trigger={
                            <i
                                className={`info circle icon`}
                                style={{
                                    color: '#848080',
                                    position: 'absolute',
                                    marginTop: '1px',
                                    paddingLeft: '2px',
                                }}
                            ></i>
                        }
                        content={colorCode}
                        size="small"
                        position="bottom center"
                        style={{ marginLeft: '0px', marginTop: '10px' }}
                    />
                </span>
            ),
            index: 'VerificationStatus',
            sortable: false,

            render: (_, value) => (
                <span
                    className={style.rowData}
                    style={{
                        fontSize: '14px',
                        color: '#009BDA',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                    }}
                >
                    <ATMLabel customcolor={value.VerificationStatus.color}>
                        {value.VerificationStatus.label}
                    </ATMLabel>
                </span>
            ),
            headerProps: {
                className: style.verificationcell,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_STATUS,
            index: 'Hidden',
            render: (_, value) => (
                <span style={{ width: '45%' }} className={style.rowData}>
                    <span
                        className={
                            value.Hidden === false
                                ? style.activeContract
                                : style.inactiveContract
                        }
                    ></span>
                    {`${value.Hidden === false ? 'Active' : 'Inactive'}`}
                </span>
            ),
            headerProps: {
                className: style.status,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_REASON,
            index: 'Reason',
            render: (_, value) => {
                return value.Reason.length > 35 ? (
                    <ATMPopover
                        className={style.summarytooltip}
                        trigger={
                            <span>
                                {value.Reason.slice(0, 35).concat('...')}
                            </span>
                        }
                        content={value.Reason}
                        inverted
                        size="tiny"
                        position="bottom center"
                    />
                ) : (
                    <span className={style.rowData}>{`${value.Hidden === true ? value.Reason : '-'
                        }`}</span>
                );
            },
            headerProps: {
                className: style.reason,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_TIER_II,
            index: 'Tier2',

            render: (_, value) => (<>{checkDefaultValueTier2(value) ? <ATMField
                as={ATMToggle}
                name="active"
                style={{ marginLeft: '15px' }}
                disabled={checkDisableTier(value)}
                defaultChecked={checkDefaultValueTier2(value)}
                checked={true}
                onClick={(_, event) => {
                    handlerToggle(value.Vendor_SubContractor_Id, value.vendor_id, value.Tier3SpendAllow, event.checked, value.Hidden, value.isToggleAllow)
                }}
            /> : <ATMField
                as={ATMToggle}
                checked={false}
                name="active"
                style={{ marginLeft: '15px' }}
                disabled={checkDisableTier(value)}
                defaultChecked={checkDefaultValueTier2(value)}
                onClick={(_, event) => {
                    handlerToggle(value.Vendor_SubContractor_Id, value.vendor_id, value.Tier3SpendAllow, event.checked, value.Hidden, value.isToggleAllow)
                }}
            />}</>

            ),
            isVisible: isTier2,
            headerProps: {
                className: style.reason,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_TIER_III,
            index: 'Tier3',

            render: (_, value) => (<>{checkDefaultValueTier3(value) ? <ATMField
                as={ATMToggle}
                name="active"
                style={{ marginLeft: '15px' }}
                disabled={checkDisableTier3(value)}
                defaultChecked={true}
                checked={true}
                onClick={(_, event) => {
                    handlerToggle(value.Vendor_SubContractor_Id, value.vendor_id, event.checked, value.Tier2SpendAllow, value.Hidden, value.isToggleAllow)
                }}
            /> : <ATMField
                as={ATMToggle}
                name="active"
                style={{ marginLeft: '15px' }}
                disabled={checkDisableTier3(value)}
                checked={false}
                defaultChecked={false}
                onClick={(_, event) => {
                    handlerToggle(value.Vendor_SubContractor_Id, value.vendor_id, event.checked, value.Tier2SpendAllow, value.Hidden, value.isToggleAllow)
                }}
            />}
            </>
            ),
            isVisible: isTier2,
            headerProps: {
                className: style.reason,
            },
        },
        {
            title:
                AccessRole.CONTACT === user_type
                    ? Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VIEW
                    : Lang.LBL_SUBCONTRACTOR_ACTION,
            index: 'actions',
            sortable: false,
            render: (_, value) => {
                return (
                    <span className={style.rowData}>
                        {(AccessRole.CONTACT === user_type ||
                            AccessRole.ADMIN === user_type) && (
                                <a
                                    style={{ cursor: 'pointer', padding: '0' }}
                                    className="ui icon button"
                                >
                                    <i
                                        className="eye icon"
                                        style={{
                                            background: 'transparent',
                                            color: 'grey',
                                        }}
                                        onClick={() =>
                                            handleViewSubContractor(
                                                value?.subcontractor_id
                                            )
                                        }
                                    ></i>
                                </a>
                            )}
                        {(AccessRole.PRIME === user_type ||
                            AccessRole.ADMIN === user_type) && (
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleEdit(value)}
                                >
                                    <i
                                        className="edit outline icon"
                                        style={{
                                            background: 'transparent',
                                            color: '#606060',
                                        }}
                                    ></i>
                                </a>
                            )}
                        {AccessRole.PRIME === user_type && (
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDelete(value)}
                            >
                                <i
                                    className="trash icon"
                                    style={{
                                        background: 'transparent',
                                        color: '#606060',
                                    }}
                                ></i>
                            </a>
                        )}
                    </span>
                );
            },
            headerProps: {
                className: style.action,
            },
        },
    ];
    return (
        <>
            <Container fluid className={style.wrapper}>
                {isPrime === false && available === 0 && (
                    <div
                        style={{
                            width: '32rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'relative',
                            margin: '-15px 0px -10px 0px',
                            zIndex: 1,
                            float: 'right',
                        }}
                    >
                        <div>
                            <ATMButton
                                className="ui secondary button"
                                onClick={() => handlehidden()}
                            >
                                {hidden !== 1
                                    ? Lang.TTL_SHOW_INACTIVE_SUBCONTRACTOR
                                    : Lang.TTL_HIDE_INACTIVE_SUBCONTRACTOR}
                            </ATMButton>
                        </div>
                        <div>
                            <ATMButton
                                className="ui secondary button"
                                onClick={() => availableSubcontractor()}
                            >
                                {Lang.TTL_AVAILABLE_SUBCONTRACTOR}
                            </ATMButton>
                        </div>
                    </div>
                )}
                {available === 0 ? (
                    <div>
                        <Transition
                            visible={isVisible.success}
                            animation="scale"
                            duration={1000}
                        >
                            <Message
                                className={style.transitionMsg}
                                success
                                compact={true}
                                floating={true}
                                content={
                                    <span>
                                        <i
                                            className="icon check"
                                            style={{
                                                background: '#568555',
                                                color: '#fff',
                                                height: '31px',
                                                width: '31px',
                                            }}
                                        ></i>
                                        <span>{toastMsg}</span>
                                    </span>
                                }
                                onDismiss={handleDismiss}
                            />
                        </Transition>

                        <ATMSegment>
                            <div
                                style={{ marginTop: '15px' }}
                                className={
                                    isPrime === true
                                        ? style.filterPrime
                                        : style.filterAdmin
                                }
                            >
                                <ORGDataTable
                                    celled={false}
                                    columns={columns}
                                    data={data?.data ?? []}
                                    loading={loading}
                                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                                    //filteredBy={false}
                                    sortable
                                    history
                                    counter
                                    total={totalRecord}
                                    onChange={fetchData}
                                    rowsPerPageOptions={[10, 20, 25]}
                                    filters={true}
                                    filteredBy={(filterList) =>
                                        (filterList || []).map(
                                            (filter) =>
                                                Lang.PRIME_SUBCONTRACTOR_FILTER_LABEL[
                                                filter.name
                                                ] ?? filter.name
                                        )
                                    }
                                >
                                    {/*{() => ({
                                        filters: ({
                                            values,
                                            setValue,
                                            setError,
                                        }) => (
                                            <ATMGrid
                                                columns={4}
                                                className={style.filters}
                                                doubling
                                                stackable
                                            >
                                                <ATMGrid.Column>
                                                    <label>
                                                        Name Begins With
                                                    </label>
                                                    <ATMSelect
                                                        fluid
                                                        placeholder={
                                                            Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                        }
                                                        name="name_begin_with"
                                                        value={
                                                            values.name_begin_with
                                                        }
                                                        options={alphaNumericStartsWith.map(
                                                            (type) => ({
                                                                key: type.key,
                                                                value: type.value,
                                                                text: type.text,
                                                            })
                                                        )}
                                                        onChange={(_, val) => {
                                                            setValue(
                                                                'name_begin_with',
                                                                val.value
                                                            );
                                                        }}
                                                    />
                                                </ATMGrid.Column>
                                                <ATMGrid.Column>
                                                    <label> {Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS}</label>
                                                    <ATMInput
                                                        fluid
                                                        value={
                                                            values.name_contains
                                                        }
                                                        name="name_contains"
                                                        placeholder={Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS}
                                                        onChange={(_, val) =>
                                                            setValue(
                                                                'name_contains',
                                                                val.value
                                                            )
                                                        }
                                                    />
                                                </ATMGrid.Column>

                                                <ATMGrid.Column>
                                                    <label>
                                                        {
                                                            Lang.LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE
                                                        }
                                                    </label>
                                                    <ATMSelect
                                                        fluid
                                                        placeholder={
                                                            Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                        }
                                                        name="state"
                                                        value={values.state}
                                                        options={stateOption}
                                                        onChange={(_, val) => {
                                                            setValue(
                                                                'state',
                                                                val.value
                                                            );
                                                        }}
                                                    />
                                                </ATMGrid.Column>
                                                
                                                
                                            </ATMGrid>
                                        ),
                                    })}*/}

                                    {() => ({
                                        filters: {
                                            name_begin_with: ({ value, setValue }) => (
                                                <>
                                                    <ATMResponsive greaterThan="mobile">
                                                        {' '}
                                                        <ATMForm.Field>
                                                            <span className={style.filterSection}>Name Begins With</span>
                                                            <ATMSelect
                                                                fluid
                                                                placeholder={
                                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                                }
                                                                name="name_begin_with"
                                                                value={
                                                                    value
                                                                }
                                                                options={alphaNumericStartsWith.map(
                                                                    (type) => ({
                                                                        key: type.key,
                                                                        value: type.value,
                                                                        text: type.text,
                                                                    })
                                                                )}
                                                                onChange={(_, val) =>
                                                                    setValue(val.value)
                                                                }
                                                            />
                                                        </ATMForm.Field>
                                                    </ATMResponsive>
                                                </>
                                            ),
                                            name_contains: ({ value, setValue }) => (
                                                <>
                                                    <ATMResponsive greaterThan="mobile">
                                                        {' '}
                                                        <ATMForm.Field>
                                                            <span className={style.filterSection}>{Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS}</span>
                                                            <ATMInput
                                                                fluid
                                                                value={
                                                                    value
                                                                }
                                                                name="name_contains"
                                                                placeholder={Lang.LBL_VIEW_ALL_SUBS_SUBCONTRACTOR_NAME_CONTAINS}
                                                                onChange={(_, val) =>
                                                                    setValue(val.value)
                                                                }
                                                            />
                                                        </ATMForm.Field>
                                                    </ATMResponsive>
                                                </>
                                            ),
                                            state: ({ value, setValue }) => (
                                                <>
                                                    <ATMResponsive greaterThan="mobile">
                                                        {' '}
                                                        <ATMForm.Field>
                                                            <span className={style.filterSection}>{Lang.LBL_CONTACT_PRIMES_FILTER_LOCATED_IN_THE_STATE}</span>
                                                            <ATMSelect
                                                                fluid
                                                                placeholder={
                                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                                }
                                                                name="state"
                                                                value={value}
                                                                options={stateOption}
                                                                onChange={(_, val) =>
                                                                    setValue(val.value)
                                                                }
                                                            />
                                                        </ATMForm.Field>
                                                    </ATMResponsive>
                                                </>
                                            )
                                        },
                                    })}
                                </ORGDataTable>
                            </div>
                        </ATMSegment>
                    </div>
                ) : (
                    <SubcontractorAddSelected
                        available={available}
                        setavailable={changeavailable}
                    />
                )}
            </Container>
        </>
    );
};
export default SubcontractorListView;

export const concatinateArrayWithSpace = (val) => {
    let title = "";
    val.map((valObj, index) => {
      title = index === 0 ? valObj : title.concat(" ").concat(valObj);
    });
    return title.trim();
  };
  
  export const concatinateArray = (val) => {
    let title = "";
    val.map((valObj) => {
      title = title.concat(valObj);
    });
    return title.trim();
  };
  
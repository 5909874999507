import services from 'src/services/summary.service';
import { ISummary } from 'src/models/summary.model';
import { IReportingyearsGetSchema } from '../models/manage-subcontractor.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import { services as manageSubcontractorService } from 'src/services/manage-subcontractor.service';

export const actionTypes = {
    SUMMARY_LIST_FETCH: 'SUMMARY_LIST_FETCH',
    REPORTING_YEAR: 'REPORTING_YEAR',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET: {
        type: actionTypes.SUMMARY_LIST_FETCH,
        service: services.listGET,
        status: {} as never,
    }, 
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: manageSubcontractorService.reportingyearsGet,
        status: {} as never,
    },
};
export type ISummaryAsync = typeof asyncActions;

export type ISummaryReducerAction = IAsyncActions<ISummaryAsync>;
export interface ISummaryState extends ICommonState<ISummaryReducerAction> {
    list: ISummary;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
}

export const defaultState: ISummaryState = {
    status: {},
    list: {data: [] },
    reportingyearsGet:{},
};

const SummaryReducer = (
    state: ISummaryState = defaultState,
    action: ICommonAction<ISummaryReducerAction>
): ISummaryState => {
    switch (action.type) {
        case actionTypes.SUMMARY_LIST_FETCH: {
            return {
                ...state,
                list: action?.payload || {data: [] },
            };
        }
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default SummaryReducer;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { POCreationValidationMonthPayloadSchema, POGuardRailsAuditLogResponseSchema, POValidationBatchJobStatusSchema } from 'src/models/po-guardrails.model';
import { httpClient } from '../libraries/http.library';

const client = httpClient();
export const services = {
    
    getPOCreationValidationMonth: async () => {
        return client.get('/poCreationValidationMonth', {}, POCreationValidationMonthPayloadSchema);
    },
    updatePOCreationValidationMonth: async (params) => {
        return client.put('/poCreationValidationMonth', params, POCreationValidationMonthPayloadSchema);
    },
    getPOGuardrailsAuditLog: async (params) => {
        return client.get('/poCreationValidationMonthAuditLog', params, POGuardRailsAuditLogResponseSchema);
    },
    getPOValidationBatchJobStatus: async () => {
        return client.get('/poValidationBatchJobStatus', {}, POValidationBatchJobStatusSchema);
    },
};
export default services;

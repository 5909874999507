import React, { useEffect, useRef } from 'react';
import { ATMGrid, ATMSelect } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './contact-mass-update.module.scss';

type IProps = {
    contactOptions: any;
    newContactOptions: any;
    handleChange: (key, data) => void;
    setIsContactUpdate: (boolean) => void;
    setIsSupplierDiversityUpdate: (boolean) => void;
    setIsSocalContact: (boolean) => void;
    setIsAdvisorContact: (boolean) => void;
};

type JProps = {
    name: any;
    option: any;
    label: any;
    placeholder: any;
    handleChange: (key, data) => void;
    ref?: React.MutableRefObject<HTMLFormElement | undefined>;
};
const SelectBoxComponent: React.FC<JProps> = ({
    name,
    option,
    label,
    placeholder,
    handleChange,
    ref,
}) => {
    return (
        <ATMGrid columns={1}>
            <ATMGrid.Row className={styles.rowWrapper}>
                <ATMGrid.Column className={styles.fieldWrapper}>
                    <label className={styles.required}>
                        <b>{label}</b>
                    </label>
                    <ATMSelect
                        ref={ref}
                        fluid
                        placeholder={placeholder}
                        name={name}
                        options={option}
                        style={{ width: '50%' }}
                        onChange={(_, val) => {
                            handleChange(name, val.value);
                        }}
                        required
                    />
                </ATMGrid.Column>
            </ATMGrid.Row>
        </ATMGrid>
    );
};
const ContactMassUpdate: React.FC<IProps> = ({
    contactOptions,
    newContactOptions,
    handleChange,
    setIsSupplierDiversityUpdate,
    setIsContactUpdate,
    setIsAdvisorContact,
    setIsSocalContact,
}) => {
    const currentContactIdRef = useRef<HTMLFormElement>();
    const newContactIdRef = useRef<HTMLFormElement>();

    useEffect(() => {
        setIsContactUpdate(true);
        setIsSupplierDiversityUpdate(false);
        setIsAdvisorContact(false);
        setIsSocalContact(false);
        handleChange('currentContactId', '');
        handleChange('newContactId', '');
    }, []);

    const contactOption = contactOptions || [];
    contactOption.sort((a, b) => a.text.localeCompare(b.text));

    const newContactOption = newContactOptions || [];
    newContactOption.sort((a, b) => a.text.localeCompare(b.text));

    return (
        <>
            <div>
                <SelectBoxComponent
                    name="currentContactId"
                    option={contactOption}
                    label={Lang.LBL_CURRENT_CONTACT}
                    placeholder={Lang.LBL_PRIME_SELECT}
                    handleChange={handleChange}
                    ref={currentContactIdRef}
                />
                <SelectBoxComponent
                    name="newContactId"
                    option={newContactOption}
                    label={Lang.LBL_NEW_CONTACT}
                    placeholder={Lang.LBL_PRIME_SELECT}
                    handleChange={handleChange}
                    ref={newContactIdRef}
                />
            </div>
        </>
    );
};
export default ContactMassUpdate;

import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
    IContactUserState,
    IContactUserReducerAction,
} from '../ducks/contactuser.duck';
import { IStatus } from '../ducks';

export const getContactUserStatus = (
    state: IContactUserState,
    action: IContactUserReducerAction['type']
): IStatus =>
    state.status[action] ?? {
        fetching: false,
        error: null,
    };

export const getTableListParams = (
    params: IORGDataTableQueryState
): Partial<IORGDataTableQueryState> => {
    const { ...data } = params;
    const ret = {};
    if (data.filters) {
        for (const dataParam of data.filters) {
            ret[dataParam.name] = dataParam.value;
        }
    }
    delete data.filters;
    return { ...data, ...ret };
};

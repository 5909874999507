import React, { useCallback } from 'react';
import { Container, Message, Transition } from 'semantic-ui-react';
import {
    ORGDataTable,
    ATMInput,
    ATMButton,
    ATMPopover,
    ATMLabel,
    ATMGrid,
    ATMCheckbox,
    ATMSelect,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMMessage,
    ATMHeader,
    ATMSegment,
    ATMIcon,
    ATMResponsive,
    ATMForm
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './subcontractor-add-selected.module.scss';
import VerificationExpire from 'src/components/atoms/verificationExpire/verification-expire.component';
//import imgTrash from 'src/assets/images/icon_trash.png';  //CR Request
import { ISubcontractorNotPrime } from 'src/models/subcontractor.model';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import {
    alphaNumericStartsWith,
    stateOption,
} from '../../../../../constants/common.constant';

export type IProps = {
    isVisible: {
        success: boolean;
        error: boolean;
    };
    handleView: (data) => void;
    data: ISubcontractorNotPrime[];
    loading: boolean;
    selectedContractors: any[];
    fetchData: (params: IORGDataTableQueryState) => void;
    goBack: () => void;
    handleCheckbox: (id: any, selectedContractors) => void;
    handleRemove: () => void;
    AddSubContractor: (val, _) => void;
    handleDismiss: () => void;
    CreateSubContractor: () => void;
    params?: IORGDataTableQueryState;
    totalRecord: number;
    available: number;
    setavailable: (val) => void;
};

const SubcontractorAddSelectedListView: React.FC<IProps> = ({
    isVisible,
    data,
    loading,
    selectedContractors,
    goBack,
    handleCheckbox,
    handleView,
    fetchData,
    AddSubContractor,
    handleDismiss,
    CreateSubContractor,
    totalRecord,
    available,
    setavailable,
}) => {
    const user_type = getUserType();
    const handleBack = useCallback(() => {
        setavailable(0);
    }, []);
    const { state: SubcontractorState } = useSubcontractorContext();
    const verificationExpirationStatusList =
        SubcontractorState?.notPrimeList?.filters_data
            ?.verificationExpirationStatus;
            const addlTxt = (
                <div>
                <p style={{ fontSize: '11px' }}><u><strong>Under the new subcontractor expiration logic:</strong></u></p>
                <p style={{ fontSize: '11px' }}><strong>If the subcontractor&apos;s expiration date is the last day of the current month:</strong></p>
                <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be verified.</p>
                <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>Next 60 Days</strong> in the portal.</p>
                <p style={{ fontSize: '11px' }}><strong>If the expiration date is any day other than the last day of the current month for which the spend is being recorded:</strong></p>
                <p style={{ fontSize: '11px' }}>&emsp;The spend recorded for that month will be non-verified.</p>
                <p style={{ fontSize: '11px' }}>&emsp;The subcontractor verification expiration status will show as <strong>1 - 180 Days past due</strong> in the portal.</p>
                </div>
                    );        
    const colorCode = (
        <>
        <ATMGrid columns={2}>
            <ATMGrid.Row className={style.colorRowTop}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightyellow"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS}
                </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightorange"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE}
                </ATMGrid.Column>
            </ATMGrid.Row>

            <ATMGrid.Row className={style.colorRow}>
                <ATMGrid.Column width={2} className={style.labelcolor}>
                    <ATMLabel
                        customcolor="lightteal"
                        className={style.labelBox}
                    />
                </ATMGrid.Column>
                <ATMGrid.Column width={14} textAlign="left">
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED}
                </ATMGrid.Column>
            </ATMGrid.Row>
        </ATMGrid>
<ATMGrid columns={1}>
<ATMGrid.Row>
<ATMGrid.Column textAlign="left">
                        {addlTxt}
</ATMGrid.Column>
</ATMGrid.Row>
</ATMGrid>
</>
    );

    const columns: IORGDataTableColumn<ISubcontractorNotPrime>[] = [
        {
            title: Lang.LBL_SUBCONTRACTOR_SELECT,
            index: 'subcontractor_id',
            sortable: false,
            render: (_, value) => (
                <ATMCheckbox
                    checked={
                        selectedContractors?.indexOf(value.subcontractor_id) +
                            1 >
                        0
                            ? true
                            : false
                    }
                    style={{ paddingTop: '3px', marginLeft: '5px' }}
                    value={value.subcontractor_id}
                    onChange={(_, val) => {
                        handleCheckbox(val.value, selectedContractors);
                        return val.value;
                    }}
                ></ATMCheckbox>
            ),
            headerProps: {
                className: style.select,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR,
            index: 'name',
            render: (_, value) => (
                <span className={style.rowData}>{value.name}</span>
            ),
            headerProps: {
                className: style.subcontractor,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_ADDRESS,
            index: 'address',
            render: (_, value) => (
                <span className={style.rowData}>{value.address}</span>
            ),
            headerProps: {
                className: style.address,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTORS_CITY,
            index: 'city',
            render: (_, value) => (
                <span className={style.rowData}>{value.city}</span>
            ),
            headerProps: {
                className: style.city,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_STATE,
            index: 'state',
            render: (_, value) => (
                <span className={style.rowData}>{value.state}</span>
            ),
            headerProps: {
                className: style.state,
            },
        },
        {
            title: (
                <span>
                    {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFICATION}
                    <br /> {Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_EXP_STATUS}
                    <ATMPopover
                        trigger={
                            <i
                                className={`info circle icon `}
                                style={{
                                    color: '#848080',
                                    paddingLeft: '3px',
                                    position: 'absolute',
                                    marginTop: '1px',
                                }}
                            ></i>
                        }
                        content={colorCode}
                        size="small"
                        position="bottom center"
                    />
                </span>
            ),
            index: 'VerificationStatus',
            sortable: false,
            render: ($, value) => {
                return (
                    <span
                        className={style.rowData}
                        style={{
                            fontSize: '14px',
                            color: '#009BDA',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                    >
                        {' '}
                        <VerificationExpire
                            status={value?.VerificationStatus?.label}
                        />{' '}
                    </span>
                );
            },

            headerProps: {
                className: style.verificationcell,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VIEW,
            index: 'actions',
            sortable: false,
            width: '5',
            render: (_, value) => (
                <span className={style.rowData}>
                    <a
                        style={{ cursor: 'pointer', padding: '0' }}
                        onClick={() => handleView(value.subcontractor_id)}
                        className="ui icon button"
                    >
                        <i
                            className="eye icon"
                            style={{
                                background: 'transparent',
                                color: 'grey',
                            }}
                        ></i>
                    </a>
                </span>
            ),
            headerProps: {
                className: style.action,
            },
        },
    ];
    if (available) {
        columns.shift();
    }

    return (
        <>
        
            <Container fluid className={style.wrapper}>
                {AccessRole.PRIME === user_type && (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                                {Lang.LBL_SUBCONTRACTOR_ADD}
                            </ATMHeader>

                            <div style={{ display: 'flex' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingTop: '13px',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <ATMButton
                                        onClick={() => goBack()}
                                        className={`ui secondary medium button sempraMediumMarginHorizontal`}
                                        size="medium"
                                    >
                                        {Lang.TTL_BACK}
                                    </ATMButton>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingTop: '13px',
                                        marginLeft: '.5rem',
                                        marginRight: '.5rem',
                                    }}
                                >
                                    { <ATMButton
                                        onClick={() => CreateSubContractor()}
                                        className={`ui medium secondary button `}
                                        size="medium"
                                        iconcolor="grey"
                                        style={{ opacity: '1 !important' }}
                                    >
                                        {Lang.TTL_ENTER_A_NEW_SUBCONTRACTOR}
                                    </ATMButton> }
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingTop: '13px',
                                    }}
                                >
                                    <ATMButton
                                        onClick={() =>
                                            AddSubContractor(
                                                selectedContractors,
                                                ''
                                            )
                                        }
                                        className={`ui primary button `}
                                        size="medium"
                                        disabled={
                                            selectedContractors?.length == 0
                                        }
                                    >
                                        {Lang.TTL_ADD_SELECTED_SUBCONTRACTOR}
                                    </ATMButton>
                                </div>
                            </div>
                        </div>
                        <Transition
                            visible={isVisible.success}
                            animation="scale"
                            duration={1000}
                        >
                            <Message
                                className={style.transitionMsg}
                                success
                                compact={true}
                                floating={true}
                                content={
                                    <span>
                                        <i
                                            className="icon check"
                                            style={{
                                                background: '#568555',
                                                color: '#fff',
                                                height: '31px',
                                                width: '31px',
                                            }}
                                        ></i>
                                        <span>
                                            {
                                                Lang.MSG_SUBCONTRACTOR_ADDED_SUCCESSFULLY
                                            }
                                        </span>
                                    </span>
                                }
                                onDismiss={handleDismiss}
                            />
                        </Transition>
                    </div>
                )}

                <ATMSegment>
                    {AccessRole.PRIME === user_type && <Instruction />}
                    {AccessRole.PRIME != user_type && (
                        <ATMButton
                            className="ui secondary button"
                            style={{
                                width: '2%',
                                height: '35px',
                                display: 'flex',
                                position: 'relative',
                                margin: '-10px 115px -30px 0px',
                                zIndex: 1,
                                float: 'right',
                                bottom: '-11px',
                            }}
                            onClick={handleBack}
                        >
                            <ATMIcon
                                style={{
                                    margin: '-6px .42857143em 0 -.21428571em',
                                }}
                                color="blue"
                                name="arrow left"
                                size="large"
                            />
                        </ATMButton>
                    )}

                    <ORGDataTable
                        celled={false}
                        columns={columns}
                        data={data}
                        loading={!data?.length && loading}
                        sortable
                        toolbar
                        history
                        counter
                        filters={true}
                        noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                        onChange={fetchData}
                        total={totalRecord}
                        rowsPerPageOptions={[10, 20, 25]}
                        filteredBy={(filterList) =>
                            (filterList || []).map(
                              (filter) =>
                                Lang.SUBCONTRACTOR_FILTER_LABEL[filter.name] ?? filter.name
                            )
                          }
                    >
                        {/* {() => ({
                            filters: ({ values, setValue }) => (
                                <ATMGrid
                                    columns={6}
                                    className={style.filters}
                                    stretched
                                    doubling
                                    stackable
                                >
                                    <ATMGrid.Column width="3">
                                        <label>
                                            {
                                                Lang.LBL_SUBCONTRACTOR_FILTER_NAME_BEGIN_WITH
                                            }
                                        </label>
                                        <ATMSelect
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="name_begin_with"
                                            value={values.name_begin_with}
                                            options={alphaNumericStartsWith.map(
                                                (type) => ({
                                                    key: type.key,
                                                    value: type.value,
                                                    text: type.text,
                                                })
                                            )}
                                            onChange={(_, val) => {
                                                setValue(
                                                    'name_begin_with',
                                                    val.value
                                                );
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column width="3">
                                        <label>
                                            {
                                                Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS
                                            }
                                        </label>
                                        <ATMInput
                                            value={values.name}
                                            name="name"
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_ENTER
                                            }
                                            onChange={(_, val) =>
                                                setValue('name', val.value)
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column>
                                        <label>
                                            {
                                                Lang.LBL_SUBCONTRACTOR_FILTER_LOCATED_IN_THE_STATE
                                            }
                                        </label>
                                        <ATMSelect
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="state"
                                            value={values.state}
                                            options={stateOption}
                                            onChange={(_, val) => {
                                                setValue('state', val.value);
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    {AccessRole.PRIME !== user_type && (
                                        <ATMGrid.Column>
                                            <label>
                                                {
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_VERIFICATION_EXP_STATUS
                                                }
                                            </label>
                                            <ATMSelect
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                name="verification_expiration_status"
                                                value={
                                                    values.verification_expiration_status
                                                }
                                                options={verificationExpirationStatusList?.map(
                                                    (type) => ({
                                                        key: type?.value,
                                                        value: type?.value,
                                                        text: type?.name,
                                                    })
                                                )}
                                                onChange={(_, val) => {
                                                    setValue(
                                                        'verification_expiration_status',
                                                        val.value
                                                    );
                                                }}
                                            />
                                        </ATMGrid.Column>
                                    )}
                                </ATMGrid>
                            ),
                        })} */}
                        {() => ({ 
                            filters: {
                                name_begin_with: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                            {' '}
                                            <ATMForm.Field>
                                                <span className={style.filterSection}>{ Lang.LBL_SUBCONTRACTOR_FILTER_NAME_BEGIN_WITH}</span>
                                                <ATMSelect
                                                    placeholder={
                                                        Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                    }
                                                    name="name_begin_with"
                                                    value={value}
                                                    options={alphaNumericStartsWith.map(
                                                        (type) => ({
                                                            key: type.key,
                                                            value: type.value,
                                                            text: type.text,
                                                        })
                                                    )}
                                                    onChange={(_, val) =>
                                                        setValue(val.value)
                                                    }
                                                />
                                            </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                ),
                                name: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS}</span>
                                        <ATMInput
                                            value={value}
                                            name="name"
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_ENTER
                                            }
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                        />
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                ),
                                state: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_SUBCONTRACTOR_FILTER_LOCATED_IN_THE_STATE}</span>
                                        <ATMSelect
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="state"
                                            value={value}
                                            options={stateOption}
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                        />
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                ),
                                verification_expiration_status: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                    {' '}
                                    <ATMForm.Field>
                                        <span className={style.filterSection}>{ Lang.LBL_SUBCONTRACTOR_FILTER_VERIFICATION_EXP_STATUS}</span>
                                        <ATMSelect
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="verification_expiration_status"
                                            value={
                                                value
                                            }
                                            options={verificationExpirationStatusList?.map(
                                                (type) => ({
                                                    key: type?.value,
                                                    value: type?.value,
                                                    text: type?.name,
                                                })
                                            )}
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                        />
                                    </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                                )
                            },
                        })}
                    </ORGDataTable>
                </ATMSegment>
            </Container>
        </>
        
    );
};

const Instruction: React.FC = () => {
    return (
        <>
            <ATMMessage info style={{ marginBottom: '15px' }} className="small">
                <ATMMessage.Header>{Lang.LBL_NOTE}</ATMMessage.Header>
                <p>
                    {Lang.NTE_SUBCONTRACTOR_ADD_SUBCONTRACTOR_NOTE}{' '}
                    <b>
                        {Lang.LBL_SUBCONTRACTOR_ADD_SUBCONTRACTOR}{' '}
                        <i
                            style={{ background: 'none', color: 'grey' }}
                            className="eye icon"
                        />{' '}
                        {Lang.NTE_SUBCONTRACTOR_ADD_SUBCONTRACTOR_CONFIRM}
                       
                    </b>
                </p>
            </ATMMessage>
        </>
    );
};

export default SubcontractorAddSelectedListView;


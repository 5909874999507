/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect,
  Switch,
} from 'react-router-dom';

import { SemanticICONS } from 'semantic-ui-react';
import { checkVisibility, IHasAccessType } from './access.library';

export interface IRoute extends RouteProps {
  path?: string;
  component?: React.FunctionComponent<RouteComponentProps<any>>;
  layout?: React.FunctionComponent;
  icon?: SemanticICONS;
  label?: string;
  to?: string;
  tooltip?: any;
  childRoutes?: Omit<IRoute, 'layout'>[];
  href?: string;
  permission?: IHasAccessType; // For ACL
  access_role?: any;
}

type IRouteNode = {
  layout: string;
  path?: string;
  component: React.FunctionComponent;
  children: React.ReactNode[];
};

type IDefaultPage = {
  Error404: React.FC;
  Error403?: React.FC;
};

const createRoute = (params: IRoute, DefaultPage?: React.FC, exact = false) => {
  const { permission, component: Component, access_role, ...route } = params;
  
  return (
    <Route
      key={route.path}
      exact={exact}
      {...route}
      render={(props) => {
  
          
        if (checkVisibility(access_role)) {
          return Component && <Component {...props} />;
        }

        if (DefaultPage) {
          return <DefaultPage />;
        }

        return null;
      }}
    />
  );
};

const renderRouter = (route: IRoute, defaultPage: IDefaultPage) => {
  const fallbackPage = defaultPage.Error403 ?? defaultPage.Error404;

  if (route.layout) {
    const node: IRouteNode = {
      layout: route.layout.displayName ?? route.layout.name,
      path: route.path,
      component: route.layout,
      children: [] as any,
    };

    // Main component handler
    if (route.path && route.component && !route.to) {
      node.children.push(createRoute(route, fallbackPage, true));
    } else if (route.path && route.to) {
      node.children.push(
        <Redirect key={route.path} from={route.path} to={route.to} exact />
      );
    }

    if (route.childRoutes) {
      node.children = node.children.concat(
        route.childRoutes.map((value) =>
          renderRouter(
            {
              ...value,
              permission: [route.permission, value.permission].filter(
                (val) => val !== undefined
              ),
            } as IRoute,
            defaultPage
          )
        )
      );
    }

    return node;
  }

  if (route.path && route.to) {
    return <Redirect key={route.path} from={route.path} to={route.to} exact />;
  }

  // Children component handler
  return createRoute(route, fallbackPage);
};

const routerFactory = (
  routes: IRoute[],
  defaultPage: IDefaultPage
): React.ReactNode => {
  const result = routes.map((route) =>
    renderRouter(route, defaultPage)
  ) as IRouteNode[];

  const list: Record<string, Omit<IRouteNode, 'layout'>> = result.reduce(
    (items, item) => {
      if (!items[item.layout]) {
        return {
          ...items,
          [item.layout]: {
            path: item.path ?? '/', // We will get the shortest path as the main for the group
            component: item.component,
            children: item.children,
          },
        };
      }

      let children: React.ReactNode[] = [];

      if (
        Array.isArray(items[item.layout].children) &&
        Array.isArray(item.children)
      ) {
        children = [...items[item.layout].children, ...item.children];
      } else {
        children.push(item.children);
      }

      return {
        ...items,
        [item.layout]: {
          ...items[item.layout],
          path:
            item.path && item.path.length < items[item.layout].path.length
              ? item.path
              : items[item.layout].path,
          children,
        },
      };
    },
    {}
  );

  return Object.entries(list).map(([key, value]: [string, any]) => {
    if (value.component) {
      return (
        <Route key={key} path={value.path}>
          <value.component>
            <Switch>
              {value.children}
              <Route component={defaultPage.Error404} />
            </Switch>
          </value.component>
        </Route>
      );
    }

    return null;
  });
};

export default routerFactory;

import React from 'react';
import { Container } from 'semantic-ui-react';
import { ATMCheckbox } from 'shared-it-appmod-ui';
import Announcemnt from '../announcement/announcement.component';
import ContractExpirationStatus from '../contract-expiration-status/contract-expiration-status.component';
import AdminDashboardHead from '../dashboard-head/admin-dashboard-head.component';
import DashboardSummary from '../dashboard-summary/dashboard-summary.component';
import SubcontractingSummary from '../subcontracting-summary/subcontracting-summary.component';
import style from './admin-dashboard.module.scss';


export type IProps = {
    setValue: (name, value) => void;
    graph: boolean;
    handleChangePrimeOptions: (value) => void;
    handleSelectPrime: (value) => void;
    handleSelectUtility: (value) => void;
    primeFilter: string;
    utilityDropdownOptions: any;
    sapId: string;
    companyCode: string;
    primeDropdownOptions: any;
    socalSubcontractingReport: any;
    sdgeSubcontractingReport: any;
    isDashboardSubcontractorSummaryLoading: boolean;
    isDashboardHeaderLoading: boolean;
    selectedUtilityName: string;
    selectedPrimeName: string
};

const AdminDashboardView: React.FC<IProps> = ({
    setValue,
    graph,
    primeFilter,
    utilityDropdownOptions,
    primeDropdownOptions,
    handleChangePrimeOptions,
    handleSelectPrime,
    handleSelectUtility,
    sapId,
    companyCode,
    socalSubcontractingReport,
    sdgeSubcontractingReport,
    isDashboardSubcontractorSummaryLoading,
    isDashboardHeaderLoading,
    selectedUtilityName,
    selectedPrimeName }) => {

    return (
        <Container fluid className={style.wrapper}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    justifyContent: 'space-between',
                    marginTop: 16,
                }}
            >
                <span>Welcome</span>
                {/* <ATMCheckbox
                    toggle
                    label="View Graphs"
                    onChange={(_, val) => {
                        setValue('graph', val.checked || false);
                        return val.checked;
                    }}
                />  */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                        height: 150,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{ flexGrow: 2, margin: 0, marginRight: 5 }}
                        className="ui card"
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                            <AdminDashboardHead
                                companyCode={companyCode}
                                sapId={sapId}
                                handleChangePrimeOptions={handleChangePrimeOptions}
                                handleSelectPrime={handleSelectPrime}
                                handleSelectUtility={handleSelectUtility}
                                primeFilter={primeFilter}
                                utilityDropdownOptions={utilityDropdownOptions}
                                primeDropdownOptions={primeDropdownOptions}
                                isDashboardHeaderLoading={isDashboardHeaderLoading}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            flexGrow: 5,
                            margin: 0,
                            marginLeft: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        className="ui card"
                    >
                        <DashboardSummary />

                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 442,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginRight: 10,
                        }}
                        className="ui card"
                    >

                        <SubcontractingSummary
                            socalSubcontractingReport={socalSubcontractingReport}
                            sdgeSubcontractingReport={sdgeSubcontractingReport}
                            isDashboardSubcontractorSummaryLoading={isDashboardSubcontractorSummaryLoading}
                            selectedUtilityName={selectedUtilityName}
                            selectedPrimeName={selectedPrimeName}
                        />
                    </div>
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginLeft: 10,
                        }}
                        className="ui card"
                    >
                        <ContractExpirationStatus />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 243,
                        marginTop: 16,
                    }}
                >
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginRight: 10,
                        }}
                        className="ui card"
                    >
                        <CardHeader1
                            title="Notification"
                            leftIcon="bell outline"
                        // rightText="03 New Notification"
                        />
                        {/* <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                                position: 'relative',
                                top: '40%',
                            }}
                        >
                            Coming Soon
                        </div> */}
                    </div>
                    <div
                        style={{
                            width: '50%',
                            padding: 19,
                            margin: 0,
                            marginLeft: 10,
                        }}
                        className="ui card"
                    >
                        <Announcemnt />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default AdminDashboardView;

interface ICardHeaderProps {
    title: string;
    leftIcon?: string;
    rightText?: string;
}

const CardHeader1: React.FC<ICardHeaderProps> = ({
    title,
    leftIcon,
    rightText,
}) => {
    return (
        <Container fluid>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ fontWeight: 'bold' }}>{title}</div>
                <div style={{ color: '#000000A5' }}>
                    {leftIcon && rightText && (
                        <i className={leftIcon + ' icon'}></i>
                    )}
                    {leftIcon && rightText && rightText}
                </div>
            </div>
        </Container>
    );
};

const Table: React.FC = () => {
    return (
        <table className="ui  table">
            <thead>
                <tr>
                    <th>Subcontractor</th>
                    <th>Jan</th>
                    <th>Feb</th>
                    <th>Mar</th>
                    <th>Apr</th>
                    <th>May</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="Name">1ST JOHN INC</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$500</td>
                </tr>
                <tr style={{ fontWeight: 'bold', background: '#FFA50016' }}>
                    <td data-label="Name">PO Month Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>$500</td>
                </tr>
            </tbody>
        </table>
    );
};

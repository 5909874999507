import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ATMContainer } from 'shared-it-appmod-ui';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
    ToastClear,
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useMassMailContext } from 'src/contexts/mass-mail.context';
import { getMassMailStatus } from 'src/selectors/mass-mail.selector';
import { actionTypes } from 'src/ducks/mass-mail.duck';
import Lang from 'src/libraries/language';
import MassMailView from './mass-mail.view';
import MassMailAuditLog from './audit-log/mass-mail-audit-log.component';
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { Card } from 'semantic-ui-react';
import { FunctionalityDisableText } from '../../constant/message-constant.component';
import { useSummaryContext } from 'src/contexts/summary.context';

const MassMail: React.FC = () => {
    const formRef = useRef<HTMLFormElement>();

    const [confirm, setConfirm] = useState(false);
    const [loading] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const { state, actions } = useMassMailContext();
    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);
    const { state: summaryState, actions: summaryAction } = useSummaryContext();
    const [reportYearDropdown, setReportYearDropdown] = useState<any>([]);

    const fetchReportData = useCallback(async () => {
        const data = await summaryAction.reportingyearsGet();
        setReportYearDropdown([{ key: data.payload.data[0].report_year, text: data.payload.data[0].report_year, value: data.payload.data[0].report_year },
        { key: data.payload.data[0].report_year - 1, text: data.payload.data[0].report_year - 1, value: data.payload.data[0].report_year - 1 }]);
    }, []);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])

    const [data, setData] = useState({
        to: '',
        from: 'Sempra_Subcontracting@app.sempra.com',
        cc: 'Sempra_Subcontracting@app.sempra.com',
        subject: '',
        body: '',
        state: '',
        city: '',
        month: '',
        year: '',
        by_supplier: '',
        radDBE: '',
        radPM: '',
        radALL: '',
        attachments: [],
        by_supplier_check: false,
        contact_check: false,
        all_check: false,
        by_supplier_check_cc_bcc: '',
        contact_check_cc_bcc: '',
        all_check_cc_bcc: '',
        bcc: '',
    });
    const [showTo, setTo] = useState({});
    const [showCc, setCc] = useState({});
    const [pdfFile] = useState<File | null | any>(null);
    const [isSending, setIsSendig] = useState<boolean>(false);
    const [openAuditLog, setOpenAuditLog] = useState<boolean>(false);
    if (showCc) {
        // TODO
    }
    useEffect(() => {
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus();
        actions.Get_State_List();
        actions.Get_Dbe_List();
        fetchReportData();
    }, []);

    const handleClick = async () => {
        return;
    };

    const handleSubmit = useCallback(() => {
        setConfirm(true);
    }, [confirm, setConfirm]);

    const handleSubmitConfirm = useCallback(async () => {
        setIsSendig(true);
        const toData =
            data.to === 'all_primes'
                ? 'all'
                : data.to === 'primes_with'
                    ? 'criteria'
                    : data.to === 'primes_located_in'
                        ? 'state'
                        : data.to === 'by_supplier'
                            ? 'Advisor'
                            : '';

        const formData = {
            to: toData,
            from: data.from,
            subject: data.subject,
            body: data.body,
            cc: data.cc.replaceAll(/\s/g, '').split(','),
            state: data.state,
            city: data.city,
            month: data.month,
            year: data.year,
            dbe: data.by_supplier.toString(),
            radDBE: data.by_supplier_check_cc_bcc,
            radPM: data.contact_check_cc_bcc,
            radALL: data.all_check_cc_bcc,
            attachments: data.attachments,
            bcc: data.bcc.trim()
                ? data.bcc.replaceAll(/\s/g, '').split(',')
                : [],
            toObjectMapper: {
                all: 'All Primes',
                criteria: 'Primes With...',
                state: 'Primes Located In...',
                Advisor: 'By Supplier Diversity Contact...'
            }
        };

        await actions
            .Send_Mass_Mail(formData)
            .then((res) => {
                if (res?.payload?.status) {
                    ToastClear();
                    ToastSuccess(Lang.MSG_MAIL_SENT_SUCCESSFULLY);
                    setConfirm(false);
                    setMailSent(true);
                    resetForm();
                } else if (!res?.error?.status) {
                    ToastError(res.error?.message);
                }
                setIsSendig(false);
                return null;
            })
            .catch(() => {
                setIsSendig(false);
                return null;
            });
        setMailSent(false);
    }, [data, setData, mailSent, confirm, setConfirm]);

    const handleChange = useCallback(
        (fieldName, val) => {
            // if (fieldName === 'all_check') {
            //     setData((prevState) => ({
            //         ...prevState,
            //         by_supplier_check: val,
            //         contact_check: val,
            //     }));
            // }

            // if (fieldName === 'all_check_cc_bcc') {
            //     const _val = val === 'cc' ? 'cc' : 'bcc';
            //     setData((prevState) => ({
            //         ...prevState,
            //         by_supplier_check_cc_bcc: data['all_check'] ? _val : '',
            //         contact_check_cc_bcc: data['all_check'] ? _val : '',
            //     }));
            // }

            if (fieldName === 'state') {
                actions.Get_City_List({
                    stateName: val,
                });
            }

            setData((prevState) => ({
                ...prevState,
                [fieldName]: val,
            }));

            if (fieldName === 'to') {
                if (val === 'all_primes') {
                    setData((prevState) => ({
                        ...prevState,
                        year: '',
                        month: '',
                        city: '',
                        state: '',
                        by_supplier: '',
                    }));
                }

                if (val === 'primes_with') {
                    setData((prevState) => ({
                        ...prevState,
                        city: '',
                        state: '',
                        by_supplier: '',
                    }));
                }

                if (val === 'primes_located_in') {
                    setData((prevState) => ({
                        ...prevState,
                        month: '',
                        year: '',
                        by_supplier: '',
                    }));
                }

                if (val === 'by_supplier') {
                    setData((prevState) => ({
                        ...prevState,
                        month: '',
                        year: '',
                        city: '',
                        state: '',
                    }));
                }

                setTo({
                    [val]: true,
                });
            }

            // if (
            //     fieldName === 'all_check' ||
            //     fieldName === 'contact_check' ||
            //     fieldName === 'by_supplier_check'
            // ) {
            //     setCc((prevState) => ({
            //         ...prevState,
            //         [fieldName]: val,
            //     }));

            //     setData((prevState) => ({
            //         ...prevState,
            //         [`${fieldName}_cc_bcc`]: val
            //             ? data[fieldName.replace('_cc_bcc', '')]
            //             : '',
            //     }));
            // }
        },
        [data, setData]
    );

    const setValue = () => {
        return;
    };

    const handleFileChange = (e) => {
        // get the files
        const files = e.target.files;

        // Process each file
        const allFiles: any = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Make new FileReader
            const reader: any = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                const size = Math.round(file.size / 1000);
                // Make a fileInfo Object
                if (size > 5120) {
                    ToastClear();
                    ToastError(Lang.MSG_MAXIMUM_FILE_SIZE);
                } else if (file.type !== 'application/pdf') {
                    ToastClear();
                    ToastError(Lang.MSG_FILE_FORMAT);
                } else {
                    const fileInfo = {
                        fileName: file.name,
                        fileType: file.type,
                        size: size + ' kB',
                        base64String: reader.result.split('base64,')[1],
                        //   file: file,
                    };

                    // Push it to the state
                    allFiles.push(fileInfo);
                }

                setData((prevState) => ({
                    ...prevState,
                    attachments: allFiles,
                }));
            }; // reader.onload
        } // for
    };

    const resetForm = useCallback(() => {
        setTo({});
        setData({
            to: '',
            from: 'Sempra_Subcontracting@app.sempra.com',
            cc: 'Sempra_Subcontracting@app.sempra.com',
            subject: '',
            body: '',
            state: '',
            city: '',
            month: '',
            year: '',
            by_supplier: '',
            radDBE: '',
            radPM: '',
            radALL: '',
            attachments: [],
            by_supplier_check: false,
            contact_check: false,
            all_check: false,
            by_supplier_check_cc_bcc: '',
            contact_check_cc_bcc: '',
            all_check_cc_bcc: '',
            bcc: '',
        });
    }, [data, setData, state]);

    useEffect(() => {
        if (!!pdfFile && !pdfFile?.name.includes('.pdf')) {
            ToastError(
                'File format not supported. Please upload PDF File only.'
            );
        }
    }, [pdfFile]);

    const handleClose = () => {
        setConfirm(false);
    };

    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (<>
        {openAuditLog &&
            <MassMailAuditLog open={openAuditLog} handleClose={() => setOpenAuditLog(false)} />}
        <ATMContainer className="fluid">
            <MassMailView
                reportYearDropdown={reportYearDropdown}
                isSending={isSending}
                handleClick={handleClick}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                mailSent={mailSent}
                setValue={setValue}
                formRef={formRef}
                loading={
                    loading ||
                    getMassMailStatus(state, actionTypes.SEND_MASS_MAIL)
                        .fetching
                }
                showTo={showTo}
                states={state?.states?.data}
                cities={state?.cities?.data}
                advisorOptions={state?.dbeList?.data?.sdge}
                socalOptions={state?.dbeList?.data?.socal}
                data={data}
                handleFileChange={handleFileChange}
                resetForm={resetForm}
                // openAuditLog={openAuditLog}
                setOpenAuditLog={setOpenAuditLog}
            />

            <Confirm
                open={confirm}
                size="tiny"
                content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                loading={
                    loading ||
                    getMassMailStatus(state, actionTypes.SEND_MASS_MAIL)
                        .fetching
                }
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={handleClose}
                onConfirm={handleSubmitConfirm}
            />
        </ATMContainer>
    </>)


};

export default MassMail;

import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContactPrimesReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/contact-primes.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(ContactPrimesReducer, defaultState, asyncActions);
};

type IContactPrimesContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ContactPrimesContext = createContext<Partial<IContactPrimesContext>>({
  state: defaultState,
}) as React.Context<IContactPrimesContext>;

const ContactPrimesProvider: React.FC = ({ children }) => {
  const reducer = useReducer();  
  return (
    <ContactPrimesContext.Provider
      value={{
        ...reducer,
      }}>
      {children}
    </ContactPrimesContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useContactPrimesContext: () => IContactPrimesContext = () => useContext(ContactPrimesContext);
export type IUseContactPrimesContext = ReturnType<typeof useContactPrimesContext>;

export { ContactPrimesContext, ContactPrimesProvider };

import React, { useEffect, useState, useCallback } from 'react';
import { useAdminConsoleContext } from 'src/contexts/admin-console.context';
import style from './footer.module.scss';

const FooterComponent: React.FC = () => {
    const params: any = {
        appId: process.env.REACT_APP_CMDB_ID,
        attachcompanyinfo: 1,
    };
    const [footerText, setFooterText] = useState<string>('');
    const { state, actions } = useAdminConsoleContext();
    const [requested, setRequested] = useState(false);
    let result;

    useEffect(() => {
        !requested && getAPI();
    }, []);

    const getAPI = useCallback(async () => {
        await actions
            .getAdminConsoleNotificationFooter(params)
            .then((res: any) => {
                setRequested(true);
                result = res.payload.data;
                if (!res.error) {
                    let footerItrText = '';
                    res.payload.data.footer.map((footerItr) => {
                        footerItrText += footerItr.message + '<br>';
                    });

                    if(footerItrText.endsWith('<br>')) {
                        footerItrText = footerItrText.substring(0, footerItrText.length - 4);
                    }

                    setFooterText(footerItrText);
                } else {
                    setFooterText('');
                }
                return null;
            })
            .catch(() => {
                setFooterText('');
            });
    }, [requested, footerText, state, actions, setFooterText]);

    return footerText ? (
        <div
            className={style.notification}
            dangerouslySetInnerHTML={{ __html: footerText }}
        />
    ) : null;
};

export default FooterComponent;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    ForgotPassResPayloadSchema,
    ILoginResponse,
    IPostAccessTokenRequestData,
    LoginResponseSchema,
    GetCDCSAPLoginURLSchema,
    IForgotPass,
    RefreshTokenPayloadSchema,
} from '../models/login.model';

const client = httpClient();

export const services = { //CDC: get SAP login URL
    CDCLoginGetSAPLoginURLGet: async () => {
        const response = client.get('/samlLogin', '', GetCDCSAPLoginURLSchema);
        return response;
    },

    CDCAccessTokenPost: async (data: IPostAccessTokenRequestData) => {
        return client.post(
            '/accessToken',
            {
                ...data,
            },
            LoginResponseSchema
        );
    },

    loginPost: async (
        // add selected subcontractors who are not prime
        data: ILoginResponse
    ) => {
        return client.post(
            '/login',
            {
                ...data,
            },
            LoginResponseSchema
        );
    },
    forgotPassPost: async (data: IForgotPass) => {
        const res = client.post(
            '/resetPassword',
            {
                ...data,
            },
            ForgotPassResPayloadSchema
        );
        return res;
    },

    loginGet: async (params) => {
        const response = client.get('/login', params, LoginResponseSchema);
        return response;
    },
    refreshToken: async () => {
        const response = client.get(
            '/refreshToken',
            '',
            RefreshTokenPayloadSchema
        );
        return response;
    },
};

export default services;

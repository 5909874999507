import yup from '../libraries/validator.library';

export const AdminConsoleNotificationSchema = yup
    .object({
        name: yup.string().nullable().defined(),
        currentUnverifiedSpend: yup.string().nullable().defined(),
        currentVerifiedSpend: yup.string().nullable().defined(),
        totalUnVerifiedSpend: yup.string().nullable().defined(),
        totalVerifiedSpend: yup.string().nullable().defined(),
    })
    .defined();

export const NotificationHeaderSchema = yup
    .object({
        data: yup
            .object({
                count: yup.number().nullable().defined(),
                notification: yup
                    .array(
                        yup
                            .object({
                                Id: yup.number().nullable().defined(),
                                AppId: yup.string().nullable().defined(),
                                MessageHeader: yup
                                    .string()
                                    .nullable()
                                    .defined(),
                                MessageBody: yup.string().nullable().defined(),
                                MessageType: yup.string().nullable().defined(),
                                StartTime: yup.string().nullable().defined(),
                                EndTime: yup.string().nullable().defined(),
                                CreatedDate: yup.string().nullable().defined(),
                                Status: yup.string().nullable().defined(),
                                Priority: yup.string().nullable().defined(),
                                Application: yup
                                    .object({
                                        AppId: yup
                                            .string()
                                            .nullable()
                                            .defined(),
                                        AppName: yup
                                            .string()
                                            .nullable()
                                            .defined(),
                                        Company: yup
                                            .string()
                                            .nullable()
                                            .defined(),
                                        IsActive: yup
                                            .boolean()
                                            .nullable()
                                            .defined(),
                                    })
                                    .defined(),
                            })
                            .defined()
                    )
                    .defined(),
            })
            .defined(),
    })
    .defined();

export const NotificationFooterSchema = yup
    .object({
        data: yup
            .object({
                count: yup.number().defined(),
                footer: yup
                    .array(
                        yup
                            .object({
                                FooterId: yup.string().defined(),
                                AppId: yup.string().defined(),
                                message: yup.string().defined(),
                                Application: yup
                                    .object({
                                        AppId: yup.string().defined(),
                                        AppName: yup.string().defined(),
                                        Company: yup.string().defined(),
                                        IsActive: yup.boolean().defined(),
                                    })
                                    .defined(),
                            })
                            .defined()
                    )
                    .defined(),
            })
            .defined(),
    })
    .defined();

export type IAdminConsoleNotification = yup.InferType<
    typeof AdminConsoleNotificationSchema
>;

export type INotificationHeaderSchema = yup.InferType<
    typeof NotificationHeaderSchema
>;

export type INotificationFooterSchema = yup.InferType<
    typeof NotificationFooterSchema
>;

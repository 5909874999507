import React, { useEffect, useState } from 'react';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useAdHocSpendFileContext } from 'src/contexts/ad-hoc-spend-file.context';
import { month } from '../../../../constants/common.constant';

import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import AdHocSpendDataView from './ad-hoc-spend-data.view';
import { FunctionalityDisableText } from '../../constant/message-constant.component';

const AdHocSpendData: React.FC = () => {
    const { state, actions } = useAdHocSpendFileContext();
    const [startMonth, setStartMonth] = useState('');
    const [sp3Loading, setsp3Loading] = useState(false);
    const [loadEvent, setLoadEvent] = useState(false);
    const { state:schFileState, actions:schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])

    
    const monthEndObject = month.filter(month => parseInt(startMonth) <= month.value);
   
    useEffect(() => {
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus()
        checkStatus();
    },[]);
    const checkStatus = async () => {
        setLoadEvent(true);
        const response = await actions.schFileStatusGet();
        if(response.payload?.status){
            setsp3Loading(response.payload?.data.isInProgress)
        }
        setLoadEvent(false)
    };
    const fetchReport = async () => {
        setLoadEvent(true);
        const data = await actions.SpendD_Data_Report();
        ToastSuccess(data.payload?.message)
        setLoadEvent(false);
    }
    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (
        <div>
            <AdHocSpendDataView
                fetchReport={fetchReport}
                sp3Loading={sp3Loading}
                loadEvent={loadEvent}
            />
        </div>);
   


}

export default AdHocSpendData;
import services from 'src/services/ad-hoc-spend.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { services as manageSubcontractorService } from 'src/services/manage-subcontractor.service';

import { ICommonAction, ICommonState } from '.';
import {
    IschFileStatusSchema
} from '../models/manage-subcontractor.model';
export const actionTypes = {
    GET_AD_HOK_SPEND_DATA: 'GET_AD_HOK_SPEND_DATA',
    SCH_FILE_STATUS: 'SCH_FILE_STATUS',
    SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS: 'SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS',
    CHANGE_SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS: 'CHANGE_SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS',
    SPEND_FILE_AUTO_GENERATION_ACTIVATION_AUDIT_LOG: "SPEND_FILE_AUTO_GENERATION_ACTIVATION_AUDIT_LOG"
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    SpendD_Data_Report: {
        type: actionTypes.GET_AD_HOK_SPEND_DATA,
        service: services.SpendD_Data_Report,
        status: {} as never,
    },
    schFileStatusGet: {
        type: actionTypes.SCH_FILE_STATUS,
        service: manageSubcontractorService.schFileStatus,
        status: {} as never,
    },
    spendFileAutoGenerationActivationStatus: {
        type: actionTypes.SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS,
        service: services.getSpendFileAutoGenerationActivationStatus,
        status: {} as never,
    },
    changeSpendFileAutogenerationActivationStatus: {
        type: actionTypes.CHANGE_SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS,
        service: services.changeSpendFileAutogenerationActivationStatus,
        status: {} as never,
    },
    getSpendFileAutoGenerationActivationAuditLog: {
        type: actionTypes.SPEND_FILE_AUTO_GENERATION_ACTIVATION_AUDIT_LOG,
        service: services.getSpendFileAutoGenerationActivationAuditLog,
        status: {} as never,

    }
};
export type IAdHocSpendFileAsync = typeof asyncActions;

export type AdHocSpendFileAction = IAsyncActions<IAdHocSpendFileAsync>;
export interface IAdHocSpendFileState
    extends ICommonState<AdHocSpendFileAction> {
    // data: IGetYearPayload;
    status: any;
    data: any;
    schFileStatusGet: any;
    spendFileAutoGenerationActivationStatus: any,
    spendFileAutoGenerationActivationAuditLogList:any
}

export const defaultState: IAdHocSpendFileState = {
    status: {},
    data: [],
    schFileStatusGet: {},
    spendFileAutoGenerationActivationStatus: {},
    spendFileAutoGenerationActivationAuditLogList:{}
};

const AdHocSpendFileReducer = (
    state: IAdHocSpendFileState = defaultState,
    action: ICommonAction<AdHocSpendFileAction>
): IAdHocSpendFileState => {
    switch (action.type) {
        case actionTypes.GET_AD_HOK_SPEND_DATA: {
            return {
                ...state,
                data: action.payload,
            };
        }

        case actionTypes.SCH_FILE_STATUS: {
            return {
                ...state,
                schFileStatusGet: action.payload || {},
            };
        }

        case actionTypes.SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS: {
            return {
                ...state,
                spendFileAutoGenerationActivationStatus: action.payload.data || {},
            };
        }

        case actionTypes.CHANGE_SPEND_FILE_AUTO_GENERATION_ACTIVATION_STATUS: {
            return {
                ...state,
                spendFileAutoGenerationActivationStatus: action.payload || {},
            };
        }

        case actionTypes.SPEND_FILE_AUTO_GENERATION_ACTIVATION_AUDIT_LOG: {
            return {
                ...state,
                spendFileAutoGenerationActivationAuditLogList: action.payload || {},
            };
        }

        default: {
            return state;
        }
    }
};

export default AdHocSpendFileReducer;

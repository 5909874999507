import React, { useEffect, useState } from 'react';
import { Container, Table } from 'semantic-ui-react';
import {
    ATMHeader,
    ATMLoader,
    ATMPopover,
    ATMSegment,
    ATMSelect,
} from 'shared-it-appmod-ui';

import { ISummaryShape } from 'src/models/summary.model';
import Lang from 'src/libraries/language';
import style from './spend-summary.module.scss';
import moment from 'moment';
export type IProps = {
    data: ISummaryShape[];
    loading: boolean;
    fetchData: () => void;
    handleYearChange: (data) => void;
    currentyear: number;
};

// const currentyear = new Date().getFullYear();
// const yearOps = [
//     { key: 1, value: (currentyear - 1), text: (currentyear - 1) },
//     { key: 2, value: currentyear, text: currentyear }
// ]
const SpendSummaryView: React.FC<IProps> = ({ data, loading, fetchData, handleYearChange,currentyear }) => {
    return (
        <>
            <Container fluid className={style.wrapper}>
                <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                    {Lang.TTL_SPEND_SUMMARY}
                </ATMHeader>

                <ATMSegment>
                    <div>
                        <span
                            style={{
                                color: '#000000DE',
                                fontWeight: 'bold',
                                fontSize: '13px',
                            }}
                        >
                            {Lang.LBL_SPEND_SUMMARY_YEAR}
                        </span>
                        <span
                            style={{
                                color: '#000000DE',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            {' '}
                            {currentyear}
                        </span>
                        {/* <ATMSelect
                            placeholder="Year"
                            options={
                                yearOps
                            }
                            style={{



                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#606060',
                                maxWidth: '55rem',
                                minWidth: '12rem',
                            }}
                            value={year}
                            compact
                            onChange={(i, v) => {
                                setYear(v.value)
                                handleYearChange(
                                    v.value
                                )
                            }
                            }
                        /> */}
                    </div>
                    <ReportsTable
                        data={data}
                        onChange={fetchData}
                        loading={loading}
                    />
                </ATMSegment>
            </Container>
        </>
    );
};

export const ReportsTable: any = (props: any) => {
    props?.data?.length > 0 && props.data.some(val => val.name == 'total') && props.data.pop();
    let gTotalVerified = 0;
    let gTotalCurrentVerified = 0;
    let gTotalNonVerified = 0;
    let gTotalCurrentNonVerified = 0;

    const getConditionalRows: any = (props: any) => {
        return props.data != undefined &&
            props.data.map((dta) => {
                if (dta.totalVerifiedSpend !== null) {
                    gTotalVerified =
                        gTotalVerified + parseInt(dta.totalVerifiedSpend);
                }
                if (dta.currentVerifiedSpend !== null) {
                    gTotalCurrentVerified =
                        gTotalCurrentVerified + parseInt(dta.currentVerifiedSpend);
                }
                if (dta.totalUnVerifiedSpend !== null) {
                    gTotalNonVerified =
                        gTotalNonVerified + parseInt(dta.totalUnVerifiedSpend);
                }
                if (dta.currentUnverifiedSpend !== null) {
                    gTotalCurrentNonVerified =
                        gTotalCurrentNonVerified +
                        parseInt(dta.currentUnverifiedSpend);
                }
                let tverified = '';
                let cVS = '';
                let tnonverified = '';
                let cuvs = '';
                if (
                    (dta.totalVerifiedSpend == null ||
                        dta.totalVerifiedSpend == 0) &&
                    (dta.currentVerifiedSpend == null ||
                        dta.currentVerifiedSpend == 0) &&
                    (dta.totalUnVerifiedSpend == null ||
                        dta.totalUnVerifiedSpend == 0) &&
                    (dta.currentUnverifiedSpend == null ||
                        dta.currentUnverifiedSpend == 0)
                ) {
                    tverified = '';
                    cVS = '';
                    tnonverified = '';
                    cuvs = '';
                } else {
                    tverified = dta.totalVerifiedSpend
                        ? '$ ' + dta.totalVerifiedSpend
                        : '$ 0';

                    cVS = dta.currentVerifiedSpend
                        ? '$ ' + dta.currentVerifiedSpend
                        : '$ 0';

                    tnonverified = dta.totalUnVerifiedSpend
                        ? '$ ' + dta.totalUnVerifiedSpend
                        : '$ 0';

                    cuvs = dta.currentUnverifiedSpend
                        ? '$ ' + dta.currentUnverifiedSpend
                        : '$ 0';
                }

                return (
                    <tr key={dta.name}>
                        <td className={style.contractorNam}>{dta.name}</td>
                        <td className={style.td} style={{ borderRight: '0px' }}>
                            {tverified}
                        </td>
                        <td className={style.td} style={{ borderLeft: '0px' }}>
                            {cVS}
                        </td>
                        <td className={style.tdRed} style={{ borderRight: '0px' }}>
                            {tnonverified}
                        </td>
                        <td className={style.tdRed} style={{ borderLeft: '0px' }}>
                            {cuvs}
                        </td>
                    </tr>
                );
            });
    };
    return (
        <>
            {
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                rowSpan="2"
                                className={style.summaryHeader}
                            >
                                {Lang.LBL_SPEND_SUMMARY_SUBCONTRACTOR}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                colSpan="2"
                                className={`${style.textCenter}`}
                                style={{ color: '#2DAD4E' }}
                            >
                                <span className={` ${style.greenColor}`}>
                                    {Lang.LBL_SPEND_SUMMARY_VERIFIED_SPEND}
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                colSpan="2"
                                className={style.textCenter}
                                style={{ color: '#DB2828' }}
                            >
                                <span style={{ color: '#DB2828' }}>
                                    {Lang.LBL_SPEND_SUMMARY_UNVERIFIED_SPEND}
                                </span>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell
                                className={style.textCenter}
                                style={{ borderRight: '0px' }}
                            >
                                <span className={style.summaryHeader}>
                                    {Lang.LBL_SPEND_SUMMARY_TOTAL_YTD}
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={style.borderlefttNo}>
                                <span className={style.summaryHeader}>
                                    {Lang.LBL_SPEND_SUMMARY_CURRENT}
                                </span>
                                <div
                                    className={``}
                                    style={{
                                        paddingLeft: '5px',
                                        display: 'inline',
                                    }}
                                >
                                    <ATMPopover
                                        trigger={
                                            <i
                                                className={`info circle icon ${style.infoIcon}`}
                                            ></i>
                                        }
                                        content={Lang.MSG_SPEND_SUMMARY_TOOLTIP}
                                        className={style.summarytooltip}
                                        inverted
                                        position="bottom center"
                                        size="tiny"
                                    />
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                className={style.textCenter}
                                style={{ borderRight: '0px' }}
                            >
                                <span className={style.summaryHeader}>
                                    {Lang.LBL_SPEND_SUMMARY_TOTAL_YTD}
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={style.borderlefttNo}>
                                <span className={style.summaryHeader}>
                                    {' '}
                                    {Lang.LBL_SPEND_SUMMARY_CURRENT}
                                </span>
                                <div
                                    className={``}
                                    style={{
                                        paddingLeft: '5px',
                                        display: 'inline',
                                    }}
                                >
                                    <ATMPopover
                                        trigger={
                                            <i
                                                className={`info circle icon ${style.infoIcon} `}
                                            ></i>
                                        }
                                        content={Lang.MSG_SPEND_SUMMARY_TOOLTIP}
                                        className={style.summarytooltip}
                                        inverted
                                        position="bottom center"
                                        size="tiny"
                                    />
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <tbody>
                        {props.loading == true ? (
                            <tr>
                                <td colSpan={5}>
                                    <ATMLoader
                                        active
                                        inline="centered"
                                        size="large"
                                    />
                                </td>
                            </tr>
                        ) : props.data != undefined &&
                            props.data.length === 0 ? (
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'center' }}>
                                    <i
                                        aria-hidden="true"
                                        className="info circle icon"
                                    ></i>
                                    {Lang.MSG_NO_RECORDS_TO_DISPLAY}
                                </td>
                            </tr>
                        ) : (
                            getConditionalRows(props)
                        )}
                        {props.data != undefined && props.data.length > 0 && (
                            <tr>
                                <td
                                    style={{
                                        borderRight: '0px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {Lang.LBL_SPEND_SUMMARY_TOTAL}
                                </td>
                                <td
                                    className={style.textCenter}
                                    style={{
                                        borderRight: '0px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {Lang.LBL_SPEND_SUMMARY_GTOTALVERIFIED} {gTotalVerified}
                                </td>
                                <td
                                    className={style.textCenter}
                                    style={{
                                        borderLeft: '0px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {Lang.LBL_SPEND_SUMMARY_GTOTALVERIFIED} {gTotalCurrentVerified}
                                </td>
                                <td
                                    className={style.textCenter}
                                    style={{
                                        borderRight: '0px',
                                        fontWeight: 'bold',
                                        color: '#D01919',
                                    }}
                                >
                                    {Lang.LBL_SPEND_SUMMARY_GTOTALVERIFIED} {gTotalNonVerified}
                                </td>
                                <td
                                    className={style.textCenter}
                                    style={{
                                        borderLeft: '0px',
                                        fontWeight: 'bold',
                                        color: '#D01919',
                                    }}
                                >
                                    {Lang.LBL_SPEND_SUMMARY_GTOTALVERIFIED} {gTotalCurrentNonVerified}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            }
        </>
    );
};

export default SpendSummaryView;

import React from 'react';
import {
    ATMModal,
    ATMButton,
    ATMForm,
    ATMGrid,
    ATMInput,
    ATMField,
    ATMIcon,
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import moment from 'moment-timezone';
import Lang from 'src/libraries/language';

type Iprops = {
    data: any;
    openAuditLogs: boolean;
    handleClose: () => void;
    loadinglog: boolean;
    fetchLogData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
};

function YearEndRolloverAuditLogView({
    data,
    openAuditLogs,
    handleClose,
    loadinglog,
    fetchLogData,
    totalRecord,
}: Iprops) : any {
    const columns: IORGDataTableColumn<any>[] = [
        {
            title: 'Status',
            index: 'roleBackStatus',
            render: (_, value) => (
                <span>{`${ value?.roleBackStatus ? 'Rolled Back To Previous Year' : 'Rolled Over To Current Year'}`}</span>
            )
        },
        // {
        //     title: 'Role Over Status',
        //     index: 'roleOverStatus',
        //     sortable: false,
        //     render: (_, value) => (
        //         <span>{`${ value?.roleOverStatus ? 'Yes' : 'No'}`}</span>
        //     ),
        //     headerProps: {
        //         //className: Style.prime,
        //     },
        // },
        {
            title: 'Timestamp',
            index: 'createdAt',
            sortable: false,
            render: (_, value) =>(
                <span>{`${ value?.createdAt && value?.createdAt !='-' ? moment.utc(value?.createdAt).format('MM/DD/YYYY hh:mm A') : '-'}`}</span>
            ),
            headerProps: {
                //className: Style.prime,
            },
        },
        {
            title: 'Done By User',
            index: 'doneByUser',
            render: (_, value) => (
                <span>{value?.doneByUser || '-'}</span>
            ),
            headerProps: {
                //className: Style.von,
            },
        },
        // {
        //     title: 'Current Year',
        //     index: 'current_year',
        //     sortable: false,
        //     render: (_, value) => (
        //         <span>{new Date().getFullYear()}</span>
        //     ),
        // },
        {
            title: 'Reporting Year',
            index: 'report_year',
            sortable: false,
            render: (_, value) => (
                <span>{`${ value?.roleBackStatus ? (value?.report_year-1) : (value?.report_year+1)}`}</span>

            ),
        }
    ];
    return (
        <ATMModal
            size="large"
            open={openAuditLogs}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                Year-end Rollover Audit Log
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060',position: 'absolute','right': '0',top: '5px',cursor: 'pointer' }} />
            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                   celled={false}
                   columns={columns}
                   data={data}
                   loading={loadinglog}
                   sortable
                   // toolbar
                   // history
                   counter
                   // filters={true}
                   noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                   onChange={fetchLogData}
                   total={totalRecord}
                   key={totalRecord}
                   rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    );
}

export default YearEndRolloverAuditLogView;

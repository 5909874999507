import yup from '../libraries/validator.library';

export const ContractsListPayloadSchema = yup
    .object({
        contract_id: yup.number().nullable().defined(),
        contract_number: yup.string().nullable().defined(),
        dateCreated: yup.string().nullable().defined(),
        expiryDate: yup.string().nullable().defined(),
        description: yup.string().nullable().defined(),
        hide: yup.boolean(),
        sempra_company_id: yup.number(),
        sempra_company_name: yup.string(),
        vendor_id: yup.number(),
        spendEntryAdmin: yup.boolean(),
        spendAllowed: yup.boolean(),
        spendAllowedAdmin: yup.boolean(),
        isHideForSpend: yup.boolean(),
        validityExplanation: yup.string().nullable().defined(),
        invoiceDate: yup.string().nullable().defined(),
        poCreationDate: yup.string().nullable().defined(),
        totalSpendAmt: yup.number().nullable().defined(),
        contractAdminId: yup.number(),
        isValidContract: yup.boolean()
    })
    .defined();

export const ContractsPageDataSchema = yup.object({
    data: yup.array().of(ContractsListPayloadSchema),
});

export const POListPayloadSchema = yup
    .object({
        contract_id: yup.number(),
        contract_number: yup.string(),
        vendor_id: yup.string(),
    })
    .defined();

export const AddContractsSchema = yup.object({
    company: yup.string().nullable().defined(),
    contract_id: yup.number().nullable().defined(),
    contract_number: yup.string().nullable().defined(),
    vendor_id: yup.number(),
});

export const EditContractsSchema = yup.object({
    company: yup.string().nullable().defined(),
    contract_id: yup.number().nullable().defined(),
    contract_number: yup.number().nullable().defined(),
    hide: yup.number(),
    vendor_id: yup.string(),
});

export const utilitySchema = yup
    .object({
        Sempra_Company_ID: yup.number(),
        Sempra_Company_Name: yup.string(),
    })
    .defined();

export const ContractsPage = yup.object({
    data: yup.array().of(ContractsListPayloadSchema),
    total_rows: yup.number(),
    total_pages: yup.number(),
    utilityData: yup.array().of(utilitySchema)
});


export const IndividualPOInfoSchema = yup.object({
    compCode: yup.number(),
    primeName: yup.string(),
    poNumber: yup.string(),
    description: yup.string().nullable(),
    POYTD: yup.number(),
    overallGoal: yup.number(),
    POGoal: yup.number(),
    verifiedSub: yup.number(),
    verifiedSubcontracting: yup.number(),
    subcontractingDiff: yup.number()
})

export const IndividualPOInfoTotalRowsSchema = yup.object({
    POYTD: yup.number(),
    overallGoal: yup.number(),
    POGoal: yup.number(),
    verifiedSub: yup.number(),
    verifiedSubcontracting: yup.number(),
    subcontractingDiff: yup.number()
})

export const IndividualPOInfoResponseSchema = yup.object({
    data: yup.object({
        individualContracts: yup.array().of(IndividualPOInfoSchema),
        totals: IndividualPOInfoTotalRowsSchema,
        UtilityData: yup.object({
            Sempra_Company_ID: yup.number(),
            Sempra_Company_Name: yup.string(),
            Sempra_Company_Code: yup.number()
        })
    }),
    total_rows: yup.number(),
    total_pages: yup.number(),
    status: yup.boolean()
});

export const DashboardContractsStatusSchema = yup
    .object({
        contract_id: yup.number().nullable().defined(),
        contract_number: yup.string().nullable().defined(),
        description: yup.string().nullable().defined(),
        expiryDate: yup.string().nullable().defined(),
        spendAllowedAdmin: yup.boolean(),
        expiryStatus: yup.string().nullable().defined(),
        totalSpendAmt: yup.number().nullable().defined()
    })
    .defined();


export const DashboardContractsStatus = yup.object({
    data: yup.array().of(DashboardContractsStatusSchema),
    utilityData: yup.array().of(utilitySchema)
});

export const contractsChangeResponseSchema = yup.object({
    message: yup.string(),
    data: yup.string(),
    status: yup.boolean(),
});

export const verifyContractorSchema = yup.object({
    message: yup.string(),
    data: yup.string(),
    status: yup.boolean(),
});

export const utilityListSchema = yup.object({
    data: yup.array().of(utilitySchema),
});

export const utilityPayloadSchema = yup
    .object({
        Sempra_Company_ID: yup.number().nullable().defined(),
        Sempra_Company_Name: yup.string().nullable().defined()
    })
    .defined();


export const updateSubcontractorVonSchema = yup //using for validation
    .object({
        verificationNumber: yup
            .string()
            .max(15, "VON can't be longer than 15 characters")
            .required('VON is required field')
            .matches(/^[a-z0-9]+$/i, 'VON must contain only alphanumeric'),
    })
    .defined();


export const InvalidContractsListPayloadSchema = yup
    .object({
        contract_id: yup.number().nullable().defined(),
        contract_number: yup.string().nullable().defined(),
        sempra_company_id: yup.number().nullable().defined(),
        description: yup.string().nullable().defined(),
        vendor_id: yup.number().nullable().defined(),
        hide: yup.boolean(),
        dateCreated: yup.string().nullable().defined(),
        expiryDate: yup.string().nullable().defined(),
        spendAllowed: yup.boolean(),
        spendAllowedAdmin: yup.boolean(),
        contractAdminId: yup.number().nullable().defined(),
        startDate: yup.string().nullable().defined(),
        endDate: yup.string().nullable().defined(),
        createdByUser: yup.string().nullable().defined(),
        status: yup.boolean().nullable().defined(),
        startExceptionReason: yup.string().nullable().defined(),
        createdOn: yup.string().nullable().defined(),
        isAvailableInPoTable: yup.boolean(),
        updatedByUser: yup.string().nullable().defined(),
    })
    .defined();

export const invalidContractsResponseSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(InvalidContractsListPayloadSchema),
    status: yup.boolean(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    UtilityData: yup.array().of(utilityPayloadSchema),
});

export const InvalidContractsAuditLogPayloadSchema = yup.object({
    PrimeName: yup.string(),
    contract_number: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    startDate: yup.date(),
    endDate: yup.date(),
    status: yup.boolean(),
    loggedAt: yup.date(),
    emailBody: yup.string(),
    emailSubject: yup.string().nullable(),
    emailSendStatus: yup.boolean()
});

export const InvalidContractAuditLogResponseSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(InvalidContractsAuditLogPayloadSchema),
    status: yup.boolean(),
    total_rows: yup.number(),
    total_pages: yup.number(),
})

export type IContractorPage = yup.InferType<typeof ContractsPage>;
export type IInvalidData = yup.InferType<typeof ContractsListPayloadSchema>;
export type IInvalidContractAuditLogResponse = yup.InferType<typeof InvalidContractAuditLogResponseSchema>;




import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useUserConfigContext } from 'src/contexts/user-config.context';
import Lang from 'src/libraries/language';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { getTableListParams } from 'src/selectors/contractorpage.selector';

import type { IUserShape } from 'src/models/user-config.model';
import UserUpdateConfig from '../user-config-update/user-config-update.component';
import UserConfigView from './user-config-info.view';
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { FunctionalityDisableText } from 'src/components/pages/constant/message-constant.component';

export type LIST_TYPE = {
    key: number;
    value: number;
    text: string;
};

const USER_TYPE = [
    {
        key: 3,
        value: 3,
        text: 'Admin',
    },
    { key: 1, value: 1, text: 'Contact' },
    { key: 2, value: 2, text: 'Supplier Diversity Contact' },
];

const ENTITIES = [
    {
        key: 1,
        value: 1,
        text: 'San Diego Gas & Electric',
    },
    {
        key: 2,
        value: 2,
        text: 'Southern California Gas Co.',
    },
];

const defaultValue = {
    firstName: '',
    lastName: '',
    eMailAddress: '',
    userTypeID: '',
    username: '',
    entityId: '',
};

const UserConfigInfoComponent: React.FC = () => {
    const { state, actions } = useUserConfigContext();
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setdeleteOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<IUserShape>();
    const [open, setOpen] = useState(false);
    const [edtData, setEditData] = useState<IUserShape>();
    const pageParmasref = useRef();
    const [newOpen, setNewOpen] = useState(false);
    const [deleteConfirmMsg, setDeleteConfirmMsg] = useState('');

    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();
    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])


    const fetchData = useCallback(
        async (params) => {
            checkSCHFileStatus()
            checkPOValidationBatchJobStatus()
            setLoading(true);
            pageParmasref.current = params;
            let data: any = {};
            if (params != undefined && params) {
                data = getTableListParams(params);
            }
            const { page, limit, sort, order } = params;
            const param = {
                sort: sort || '',
                order: order || '',
                page: page,
                limit: limit,
                firstName: data.firstName || '',
                lastName: data?.lastName || '',
                eMailAddress: data?.eMailAddress || '',
                userName: data?.userName || '',
                userID: data?.userID || '',
                entityId: data?.entityId || '',
                userTypeID: data?.userTypeId || '',
            };
            await actions.listGET(param);
            setLoading(false);
        },
        [setLoading]
    );

    const handleDelete = (value) => {
        setDeleteConfirmMsg(Lang.MSG_USER_DELETE_RECORD_CONFIRM);
        setDeleteData(value);
        setdeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setdeleteOpen(false);
    };

    const handleDeleteConfirm = async () => {
        if (deleteData) {
            const parmas = {
                user_id: deleteData.userID?.toString(),
                user_name: deleteData.username,
            };

            const response = await actions.delete(parmas);

            if (response?.payload) {
                handleSuccess(Lang.MSG_USER_DELETE_SUCCESFUL);
                setdeleteOpen(false);
            } else if (!response?.error?.status) {
                ToastError(response?.error?.message);
                setdeleteOpen(false);
            }
        }
    };

    const handleEdit = (value) => {
        setNewOpen(false);
        setOpen(true);
        setEditData(value);
    };

    const handleOnDiscard = () => {
        setNewOpen(false);
        setOpen(false);
    };

    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setOpen(false);
            ToastSuccess(msg);
            await fetchData(pageParmasref.current);
            setLoading(false);
        },
        [setOpen, setLoading]
    );

    const handleOnOpenNew = () => {
        setOpen(true);
        setNewOpen(true);
    };

    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (<>
        <UserConfigView
            list={state.list}
            fetchData={fetchData}
            loading={loading}
            handleDelete={handleDelete}
            userType={USER_TYPE}
            entities={ENTITIES}
            handleEdit={handleEdit}
            handleOnOpenNew={handleOnOpenNew}
        />

        {deleteOpen && (
            <Confirm
                open={deleteOpen}
                size="tiny"
                content={deleteConfirmMsg}
                loading={false}
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={handleDeleteClose}
                onConfirm={handleDeleteConfirm}
            // isDelete
            />
        )}
        {open && (
            <UserUpdateConfig
                open={open}
                data={newOpen ? defaultValue : edtData}
                handleSuccess={handleSuccess}
                handleOnDiscard={handleOnDiscard}
                userType={USER_TYPE}
                entities={ENTITIES}
            />
        )}
    </>)

};

export default UserConfigInfoComponent;

import React from 'react';
import { Tab, TabProps } from 'semantic-ui-react';
import history from 'src/history';
import useTabLocation from 'src/hooks/tab-location.hook';
import styles from './tabs.module.scss';

const Tabs: React.FC<TabProps> = (props) => {
  const { currentTab, pathname } = useTabLocation();

  return (
    <div className={styles.container}>
      <Tab
        {...props}
        activeIndex={currentTab}
        className="tab-menu"
        onTabChange={(_, data) => {
          history.push(`${pathname}?tab=${data.activeIndex}`);
        }}
      />
    </div>
  );
};

export default Tabs;

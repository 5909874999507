import React from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { ATMGrid, ATMHeader, ATMSegment } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './primes-detail.module.scss';

export type IProps = {
    tabs: any;
    handleBack: () => void;
};

const ContactPrimesDetailView: React.FC<IProps> = ({ tabs, handleBack }) => {
    const primeDataObj: string = localStorage.getItem('primeData') || '';
    const primeData = primeDataObj && JSON.parse(primeDataObj ?? '');
    return (
        <>
            <Container
                fluid
                className={style.wrapperPrimeDetails}
                style={{ paddingBottom: '20px' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                        {Lang.LBL_CONTACT_PRIMES_DETAIL || ''}
                    </ATMHeader>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingTop: '13px',
                        }}
                    >
                        <button
                            className="ui secondary button"
                            onClick={() => handleBack()}
                        >
                            {Lang.TTL_BACK}
                        </button>
                    </div>
                </div>
                <ATMSegment>
                    <ATMGrid>
                        <ATMGrid.Row>
                            <ATMGrid.Column>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <ATMHeader as="h2">
                                        {' '}
                                        <span>
                                            <i
                                                style={{
                                                    color: 'white',
                                                }}
                                                className={`building outline icon ${style.buildicon}`}
                                            ></i>
                                        </span>
                                        <span className={style.top}>
                                            {primeData.name}
                                            <span
                                                className={`${
                                                    primeData.active === true
                                                        ? style.active
                                                        : style.inactive
                                                }`}
                                            >
                                                {primeData.active === true
                                                    ? 'Active'
                                                    : 'Inactive'}
                                            </span>
                                        </span>
                                    </ATMHeader>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        className={style.dateAdded}
                                    >
                                        <span>
                                            {Lang.LBL_CONTACT_DATE_ADDED}
                                        </span>
                                        <span style={{ fontWeight: 'normal' }}>
                                            <i className="calendar alternate outline icon"></i>
                                            {primeData.creation_date}
                                        </span>
                                    </div>
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                    <Divider />
                    {tabs}
                </ATMSegment>
            </Container>
        </>
    );
};

export default ContactPrimesDetailView;

import { IReportReducerAction, IReportState } from '../ducks/report.duck';
import { IStatus } from '../ducks';

export const getReportStatus = (
  state: IReportState,
  action: IReportReducerAction['type']
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

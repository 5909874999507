import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import UserConfigReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/user-config.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(UserConfigReducer, defaultState, asyncActions);
};

type IUserConfigContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const UserConfigContext = createContext<Partial<IUserConfigContext>>({
  state: defaultState,
}) as React.Context<IUserConfigContext>;

const UserConfigProvider: React.FC = ({ children }) => {
  const reducer = useReducer();  
  return (
    <UserConfigContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </UserConfigContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useUserConfigContext: () => IUserConfigContext = () => useContext(UserConfigContext);
export type IUseUserConfigContext = ReturnType<typeof useUserConfigContext>;

export { UserConfigContext, UserConfigProvider };

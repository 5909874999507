import React from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
    ISubcontractorForTesting,
    ISubcontractorReasonGet,
} from 'src/models/subcontractor.model';
import UpdateFormView from './subcontractor-update.view';
import { useLocation } from "react-router-dom";

export type IProps = {
    isOpen: boolean;
    fdata?: Partial<ISubcontractorForTesting>;
    editData?: Partial<ISubcontractorForTesting>;
    btndisable: boolean;
    handleClosePopUp: () => void;
    handleClick: (params: IORGDataTableQueryState) => void;
    reasonList: Partial<ISubcontractorReasonGet>;
    handleSubmit: () => void;
    handleSuccess: (params: IORGDataTableQueryState) => void;
    setValue: (fieldName, value) => void;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
};

const SubcontractorUpdateForm: React.FC<IProps> = ({
    formRef,
    reasonList,
    editData,
    fdata,
    btndisable,
    handleClick,
    setValue,
    handleSuccess,
    handleSubmit,
    ...props
}) => {
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');
    return (
        <>
            <UpdateFormView
                {...props}
                fdata={fdata || {}}
                editData={editData}
                btndisable={btndisable}
                loading={false}
                formRef={formRef}
                reasonList={reasonList}
                handleClick={handleClick}
                setValue={setValue}
                page={page??'1'}
                limit={limit??'20'}
                handleSubmit={handleSubmit}
            />
        </>
    );
};

export default SubcontractorUpdateForm;

import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import * as yup from 'yup';
import { getToken } from './auth.library';

const createMethod = (xhr: AxiosInstance) => ({
    instance: xhr,

    post: async <D, S extends yup.Schema<any>>(
        url: string,
        data: D,
        schema: S,
        config?: AxiosRequestConfig
    ): Promise<yup.InferType<S>> => {
        const response = await xhr.post(url, data, config);
        return schema.validate(response.data, { stripUnknown: true });
    },

    get: async <D, S extends yup.Schema<any>>(
        url: string,
        data: D,
        schema: S,
        config?: AxiosRequestConfig
    ): Promise<yup.InferType<S>> => {
        const response = await xhr.get(url, {
            ...config,
            params: data,
        });
        return schema.validate(response.data, { stripUnknown: true });
    },

    put: async <D, S extends yup.Schema<any>>(
        url: string,
        data: D,
        schema: S,
        config?: AxiosRequestConfig
    ): Promise<yup.InferType<S>> => {
        const response = await xhr.put(url, data, config);
        return schema.validate(response.data, { stripUnknown: true });
    },

    delete: async <D, S extends yup.Schema<any>>(
        url: string,
        data: D,
        schema: S,
        config?: AxiosRequestConfig
    ): Promise<yup.InferType<S>> => {
        const response = await xhr.delete(url, { ...config, data });
        return schema.validate(response.data, { stripUnknown: true });
    },
});

export const httpClient = () : any => {
    const baseURL = process.env.REACT_APP_API_ENDPOINT;

    const xhr = axios.create({
        baseURL,
        headers: { 'Content-Type': 'application/json' },
        transformRequest: [
            (data) => {
                // This is for uploading files
                if (data instanceof FormData) {
                    return data;
                }

                return JSON.stringify(data);
            },
        ],
    });

    // Set the AUTH token for any request
    xhr.interceptors.request.use((oldConfig) => {
        const config = {
            ...oldConfig,
        };
        
        config.headers!['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_SUBSCRIPTION_KEY??'';

        const token = getToken();
        if (token) {
            config.headers!.Authorization = `${token}`;
        }

        return config;
    });

    // We'll override Axios default error handler
    xhr.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error.response || error);
        }
    );
    return createMethod(xhr);
};

export const commonClient = (): any => {
    const commonURL = process.env.REACT_APP_API_ENDPOINT_COMMON;

    const xhr = axios.create({
        headers: { 'Content-Type': 'application/json' },
        transformRequest: [
            (data) => {
                // This is for uploading files
                if (data instanceof FormData) {
                    return data;
                }

                return JSON.stringify(data);
            },
        ],
        baseURL: commonURL,
    });

    // Set the AUTH token for any request
    xhr.interceptors.request.use((oldConfig) => {
        const config = {
            ...oldConfig,
        };

        config.headers!['Ocp-Apim-Subscription-Key'] =
            process.env.REACT_APP_API_SUBSCRIPTION_KEY ?? '';

        const token = getToken();
        if (token) {
            config.headers!.Authorization = `${token}`;
        }

        return config;
    });

    // We'll override Axios default error handler
    xhr.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error.response || error);
        }
    );

    return createMethod(xhr);
};

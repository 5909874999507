import 'src/assets/styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Router } from 'react-router-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import history from 'src/history';
import routes from 'src/router';

ReactDOM.render(
    <App>
        <Router history={history}>
            <Switch>{routes}</Switch>
        </Router>
    </App>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    UserSchema,
    UserSucess
} from '../models/user-config.model';

const client = httpClient();

export const services = {
    listGET: async (params) => {
        return client.get('/getUserLists', params, UserSchema);        
    },
    delete: async (params) => {
        return client.delete(`/removeInternalUser?user_id=${params.user_id}&user_name=${params.user_name}`, {}, UserSucess);
    },
    create: async (params) => {
        return client.post('/createInternalUser', params, UserSucess);
    },
    update: async (params) => {
        return client.put('/user', params, UserSucess);
    },
};
export default services;

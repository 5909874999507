import React, { useCallback, useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import {
    ATMMessage,
    ATMButton,
    ATMHeader,
    ATMInput,
    ATMField,
    ATMSelect,
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMSegment,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from './view-all-subs.module.scss';
import { viewAllSubStatus } from 'src/constants/common.constant';

export type IProps = {
    data: any;
    dataRef: any;
    handleOpen: (open) => void;
    handleSearch: () => void;
    handleClear: () => void;
    handleChange: (key, data) => void;
    loading: boolean;
    setVerificationNumber: (data) => void;
    setHidden: (data) => void;
    setSubContractName: (data) => void;
    // handleEdit: (data) => void;
    fetchData: (params: IORGDataTableQueryState) => void;
    // // handleDismiss: () => void;
    totalRecord: number;
    handleDelete: (data) => void;
    handleOpenAuditLogs: () => void;
};

type IColumnProps = {
    company_name: string;
    subcontractor_id: number;
    owner_name: string;
    sap_id: number;
    city: string;
    state: string;
    gender: number;
    gender_name: string;
    contact_name: string;
    contact_email: string;
    verificationExpirationDate: string;
    verification_number: string;
    verification_agency_id: number;
    verification_agency_name: string;
    zip: number;
    supplier_code: number;
    reason: number;
    reason_name: string;
    ethinicity: string;
    hidden: boolean;
    nationality_id: number;
    primename: string;
    // VSID: number;
};

const ViewAllSubsView: React.FC<IProps> = ({
    data,
    dataRef,
    handleOpen,
    handleSearch,
    loading,
    setVerificationNumber,
    setHidden,
    setSubContractName,
    handleClear,
    handleChange,
    fetchData,
    // handleEdit,
    totalRecord,
    handleDelete,
    handleOpenAuditLogs
}) => {
    const columns: IORGDataTableColumn<IColumnProps>[] = [
        {
            title: Lang.LBL_UPDATE_SAPID_VON,
            index: 'verificationNumber',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.verification_number
                        ? value.verification_number
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.von,
            },
        },
        {
            title: Lang.LBL_UPDATE_SAPID_SUBCONTRACTOR_NAME,
            index: 'subContractName',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.company_name ? value.company_name : '-'}
                </span>
            ),
            headerProps: {
                className: style.subcontractorName,
            },
        },
        {
            title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SELECTED_BY_PRIME,
            index: 'primeName',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.primename ? value.primename : '-'}
                </span>
            ),
            headerProps: {
                className: style.subcontractorName,
            },
        },

        {
            title: Lang.LBL_CONTRACTS_STATUS,
            index: 'hidden',
            render: (_, value) => (
                <span className={style.rowData}>
                    <span
                        className={
                            value.reason != 0
                                ? style.inactiveContract
                                : style.activeContract
                        }
                    ></span>
                    {`${value.reason != 0 ? 'Inactive' : 'Active'}`}
                </span>
            ),
            headerProps: {
                className: style.status,
            },
        },
        {
            title: Lang.LBL_SUBCONTRACTOR_REASON,
            index: 'Reason',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.reason != 0 ? value.reason_name : ''}
                </span>
            ),
            headerProps: {
                className: style.reason,
            },
        },

        {
            title: Lang.LBL_ACTION,
            index: 'actions',
            sortable: false,
            width: '5',
            render: (_, value) => (
                <span className={style.rowData}>
                    <i
                        className="edit icon"
                        style={{
                            background: 'transparent',
                            color: 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleOpen(value)}
                    ></i>
                    <i
                        className="trash alternate icon"
                        style={{
                            background: 'transparent',
                            color: 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleDelete(value)}
                    ></i>
                </span>
            ),
            headerProps: {
                className: style.action,
            },
        },
    ];
    return (
        <>
            <Container fluid>
                <ATMHeader as="h1" style={{ marginTop: '10px' }}>
                    {Lang.TTL_SCARCH_SUBCONTRACTORS}
                    <ATMButton
                        className="ui primary button"
                        style={{ padding: '5px 10px',float: 'right' }}
                        onClick={() => handleOpenAuditLogs()}
                    >
                        Audit Logs
                    </ATMButton>
                </ATMHeader>
                <ATMMessage
                    style={{
                        height: '86px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <label>{Lang.LBL_VIEW_ALL_SUBS_VON}</label>
                        <ATMInput
                            className={style.inputSectionVon}
                            placeholder={
                                Lang.PLACEHOLDER.VIEW_ALL_SUBS_ENTER_VON
                            }
                            name="verification_number"
                            onChange={(_, val) => {
                                handleChange('verification_number', val.value);
                                setVerificationNumber(val.value);
                            }}
                            value={
                                dataRef.current?.verification_number
                                    ? dataRef.current.verification_number
                                    : ''
                            }
                        ></ATMInput>
                        <label className={style.inputLabel}>
                            {Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS}
                        </label>
                        <ATMInput
                            className={style.inputSection}
                            placeholder={
                                Lang.LBL_SUBCONTRACTOR_FILTER_NAME_CONTAINS
                            }
                            name="subContractName"
                            onChange={(_, val) => {
                                handleChange('subContractName', val.value);
                                setSubContractName(val.value);
                            }}
                            value={
                                dataRef.current?.subContractName
                                    ? dataRef.current.subContractName
                                    : ''
                            }
                        ></ATMInput>
                        <label className={style.inputLabel}>
                            {Lang.LBL_VIEW_ALL_SUBS_STATUS}
                        </label>
                        <ATMSelect
                            className={style.inputSectionSelect}
                            placeholder={Lang.LBL_VIEW_ALL_SUBS_STATUS}
                            options={viewAllSubStatus.map((item) => ({
                                key: item.key,
                                value: item.value,
                                text: item.text,
                            }))}
                            name="hidden"
                            onChange={(_, data) => {
                                handleChange('hidden', data.value);
                                setHidden(data.value);
                                return data.value;
                            }}
                            value={
                                dataRef.current?.hidden
                                    ? dataRef.current.hidden
                                    : ''
                            }
                        />
                    </div>
                    <div>
                        <ATMButton
                            className="ui secondary button"
                            style={{ marginRight: '20px' }}
                            onClick={() => handleClear()}
                        >
                            {Lang.LBL_CLEAR}
                        </ATMButton>
                        <ATMButton
                            className="ui primary button"
                            onClick={() => handleSearch()}
                        >
                            {Lang.LBL_SEARCH}
                        </ATMButton>
                    </div>
                </ATMMessage>
                <ATMSegment>
                    <ORGDataTable
                        celled={false}
                        columns={columns}
                        data={data}
                        loading={loading}
                        sortable
                        // toolbar
                        // history
                        counter
                        // filters={true}
                        noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                        onChange={fetchData}
                        total={totalRecord}
                        key={totalRecord}
                        rowsPerPageOptions={[10, 20, 25]}
                    />
                </ATMSegment>
            </Container>
        </>
    );
};

export default ViewAllSubsView;

export default {
    PRIME_ANDCONTACT_FILTER_LABEL: {
        name_begin_with: 'Prime Name Begins With',
        name_contains: 'Prime Name Contains',
        sdge_name: 'Supplier Diversity Contact (SDG&E)',
        socal_name: 'Supplier Diversity Contact (SoCalGas)',
        is_active: 'Status',
        contact_name_contains: 'Contact Name Contains',
        favourite: 'Favorites',
        advisor_name: 'Advisor Name Contains' 
    },

    PRIME_FILTER_LABEL: {
        name_begin_with: 'Prime Name Begins With',
        name_contains: 'Prime Name Contains',
        state: 'Located In This State',
        vendor_id: 'SAP ID Contains',
        subContractorType:'Subcontractor Type'
    },
    PRIME_SUBCONTRACTOR_FILTER_LABEL: {
        name_begin_with: 'Subcontractor Name Begins With',
        name_contains: 'Subcontractor Name Contains',
        state: 'Located In This State',
        vendor_id: 'SAP ID Contains',
    },
    ADMIN_USER_FILTER_LABEL: {
        firstName: 'First Name Contains',
        lastName: 'Last Name Contains',
        eMailAddress: 'Email Address',
        userName: 'Alias Contains',
        userTypeId: 'User Type',
        entityId: 'Utility',
    },
    SUBCONTRACTOR_FILTER_LABEL: {
        name_begin_with: 'Subcontractor Name Begins With',
        name: 'Subcontractor Name Contains',
        state: 'Located In This State',
        city: 'Located In This City',
        verification_expiration_status: 'Verification Expiration Status',
    },
    CONTRACT_FILTER_LABEL: {
        contract_number: 'Contract Number Contains',
        sempra_company_name: 'Utility'
    },
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import { GetAdHocSchema, SpendFileAutoGenerationActivationAuditLogResponseSchema, SpendFileAutoGenerationActivationStatusSchema } from 'src/models/ad-hok-spend-file.model';

const client = httpClient();
export const services = {
    SpendD_Data_Report: async () => {
        return client.get('/spendDataReport', null, GetAdHocSchema);
    },
    getSpendFileAutoGenerationActivationStatus: async () => {
        return client.get('/enableAndDisableSpendDataReportBatchJob', {}, SpendFileAutoGenerationActivationStatusSchema);
    },
    changeSpendFileAutogenerationActivationStatus: async (params) => {
        return client.post('/enableAndDisableSpendDataReportBatchJob', params, SpendFileAutoGenerationActivationStatusSchema);
    },
    getSpendFileAutoGenerationActivationAuditLog: async (param) => {
        return client.get('/enableAndDisableSpendDataReportBatchJobAuditLog', param, SpendFileAutoGenerationActivationAuditLogResponseSchema);
    },
};
export default services;

import React, { useEffect } from 'react';
import {
    ORGDataTable,
    IORGDataTableColumn,
    ATMHeader,
    ATMButton,
    ATMIcon,
    ATMGrid,
    ATMInput,
    ATMSelect,
    ATMDropdown,
    ATMResponsive,
    ATMForm,
} from 'shared-it-appmod-ui';
import { Container } from 'semantic-ui-react';

import Lang from 'src/libraries/language';
import Style from './user-config-info.module.scss';
import type { LIST_TYPE } from './user-config-info.component';
import { removeSearchParams } from 'src/libraries/auth.library';
import { lang } from 'moment';

type IProps = {
    list: any;
    fetchData: (data) => void;
    loading: boolean;
    handleDelete: (value) => void;
    userType: LIST_TYPE[];
    entities: LIST_TYPE[];
    handleEdit: (value) => void;
    handleOnOpenNew: () => void;
};

type IColumnProps = {
    firstName: string;
    lastName: string;
    eMailAddress: string;
    userTypeID: number;
    entityId: number;
    username: string;
    mapping: number;
};

const ContactPrimesListView: React.FC<IProps> = ({
    list,
    fetchData,
    loading,
    handleDelete,
    userType,
    entities,
    handleEdit,
    handleOnOpenNew,
}) => {
    const userTypeSorted = userType.sort((a, b) =>
        a.text.localeCompare(b.text)
    );
    const entitiesSorted = entities.sort((a, b) =>
        a.text.localeCompare(b.text)
    );
    useEffect(() => {
        if (window.location.href.split('?').length > 1)
            localStorage.setItem('selectedTab', 'AddUpdateUser');
        removeSearchParams();
    }, []);

    const columns: IORGDataTableColumn<IColumnProps>[] = [
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_FIRST_NAME,
            index: 'firstName',
            render: (_, value) => <span>{value.firstName || '-'}</span>,
            headerProps: {
                className: Style.firstName,
            },
        },
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_LAST_NAME,
            index: 'lastName',
            render: (_, value) => <span>{value.lastName || '-'}</span>,
            headerProps: {
                className: Style.lastName,
            },
        },
        {
            title: Lang.TTL_EMAIL_ADDRESS,
            index: 'eMailAddress',
            render: (_, value) => <span>{value.eMailAddress || '-'}</span>,
            headerProps: {
                className: Style.emailAddress,
            },
        },
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE,
            index: 'userTypeID',
            render: (_, value) => (
                <span>
                    {userType?.find(({ key }) => key === value.userTypeID)
                        ?.text || '-'}
                </span>
            ),
            headerProps: {
                className: Style.userType,
            },
        },
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY,
            index: 'entityId',
            render: (_, value) => (
                <span>
                    {entities?.find(({ key }) => key === value.entityId)
                        ?.text || '-'}
                </span>
            ),
            headerProps: {
                className: Style.entity,
            },
        },
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_ALIAS,
            index: 'username',
            render: (_, value) => <span>{value.username || '-'}</span>,
            headerProps: {
                className: Style.alias,
            },
        },
        {
            title: Lang.TTL_ACTION,
            index: 'mapping',
            sortable: false,
            render: (_, value) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <a
                        style={{
                            cursor: 'pointer',
                            padding: '0',
                            marginRight: 30,
                        }}
                        className="ui icon button"
                    > */}
                    <i
                        className="edit icon"
                        style={{
                            background: 'transparent',
                            color: 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleEdit(value)}
                    ></i>
                    {/* </a>
                    <a
                        style={{
                            cursor: 'pointer',
                            padding: '0',
                            marginRight: 10,
                        }}
                        className="ui icon button"
                    > */}
                    <i
                        className="trash alternate icon"
                        style={{
                            background: 'transparent',
                            color: 'grey',
                            opacity: 1,
                            cursor: 'pointer',
                        }}
                        onClick={() => handleDelete(value)}
                    ></i>
                    {/* </a> */}
                    {value.mapping > 0 && (
                        <span style={{ fontSize: '0.9rem' }}>
                            {value.mapping > 1
                                ? `${value.mapping} Records`
                                : `${value.mapping} Record`}
                        </span>
                    )}
                </span>
            ),
            headerProps: {
                className: Style.action,
            },
        },
    ];
    return (
        <Container fluid className={Style.wrapper}>
            <ATMButton
                primary
                floated="right"
                onClick={() => handleOnOpenNew()}
                className="plusIcon"
                //   className='ui primary button'
            >
                <ATMIcon name="plus" className="secondary" />
                {Lang.LBL_ADMIN_USER_CONFIG_ADD_NEW_USER}
            </ATMButton>
            <ATMHeader style={{ marginTop: 0 }}>
                <h4>{Lang.LBL_ADMIN_USER_CONFIG_INFO_USER_CONFIG}</h4>
            </ATMHeader>
            <div style={{ marginTop: '25px' }}>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={list?.data || []}
                    loading={loading}
                    sortable
                    toolbar
                    history
                    counter
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchData}
                    total={list?.total_rows || 0}
                    rowsPerPageOptions={[10, 20, 25]}
                    filters={true}
                    filteredBy={(filterList) =>
                        (filterList || []).map(
                            (filter) =>
                                Lang.ADMIN_USER_FILTER_LABEL[filter.name] ??
                                filter.name
                        )
                    }
                >
                    {/* {() => ({
                        filters: ({ values, setValue }) => (
                            <ATMGrid
                                columns={4}
                                className={Style.filters}
                                doubling
                                stackable
                            >
                                <ATMGrid.Column>
                                    <label>
                                        {
                                            Lang.LBL_ADMIN_USER_CONFIG_INFO_FIRST_NAME
                                        }
                                    </label>
                                    <ATMInput
                                        fluid
                                        value={values.firstName}
                                        name="firstName"
                                        placeholder={
                                            Lang.LBL_ADMIN_USER_CONFIG_INFO_FIRST_NAME
                                        }
                                        onChange={(_, val) =>
                                            setValue('firstName', val.value)
                                        }
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column>
                                    <label>
                                        {
                                            Lang.LBL_ADMIN_USER_CONFIG_INFO_LAST_NAME
                                        }
                                    </label>
                                    <ATMInput
                                        fluid
                                        value={values.lastName}
                                        name="lastName"
                                        placeholder={
                                            Lang.LBL_ADMIN_USER_CONFIG_INFO_LAST_NAME
                                        }
                                        onChange={(_, val) =>
                                            setValue('lastName', val.value)
                                        }
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column>
                                    <label>{Lang.TTL_EMAIL_ADDRESS}</label>
                                    <ATMInput
                                        fluid
                                        value={values.eMailAddress}
                                        name="eMailAddress"
                                        placeholder={Lang.TTL_EMAIL_ADDRESS}
                                        onChange={(_, val) =>
                                            setValue('eMailAddress', val.value)
                                        }
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column>
                                    <label>
                                        {Lang.TTL_ADMIN_USER_CONFIG_INFO_ALIAS}
                                    </label>
                                    <ATMInput
                                        fluid
                                        value={values.userName}
                                        name="userName"
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_ALIAS
                                        }
                                        onChange={(_, val) =>
                                            setValue('userName', val.value)
                                        }
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column>
                                    <label>
                                        {
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE
                                        }
                                    </label>
                                    <ATMSelect
                                        fluid
                                        value={
                                            values?.userTypeId
                                                ? parseInt(values.userTypeId)
                                                : ''
                                        }
                                        name="userTypeId"
                                        options={userTypeSorted.map((type) => ({
                                            key: type.key,
                                            value: type.value,
                                            text: type.text,
                                        }))}
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE
                                        }
                                        onChange={(_, val) =>
                                            setValue('userTypeId', val.value)
                                        }
                                    />
                                </ATMGrid.Column>

                                <ATMGrid.Column>
                                    <label>
                                        {
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY
                                        }
                                    </label>
                                    <ATMSelect
                                        fluid
                                        value={
                                            values?.entityId
                                                ? parseInt(values.entityId)
                                                : ''
                                        }
                                        name="entityId"
                                        options={entitiesSorted.map((type) => {
                                            return {
                                                key: type.key,
                                                value: type.value,
                                                text: type.text,
                                            };
                                        })}
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY
                                        }
                                        onChange={(_, val) =>
                                            setValue('entityId', val.value)
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid>
                        ),
                    })} */}

                    {() => ({ 
                        filters: {
                            firstName: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={Style.filterSection}>{ Lang.LBL_ADMIN_USER_CONFIG_INFO_FIRST_NAME}</span>
                                            <ATMInput
                                                fluid
                                                value={value}
                                                name="firstName"
                                                placeholder={
                                                    Lang.LBL_ADMIN_USER_CONFIG_INFO_FIRST_NAME
                                                }
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                            />
                                        </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                            ),
                            lastName: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={Style.filterSection}>{ Lang.LBL_ADMIN_USER_CONFIG_INFO_LAST_NAME}</span>
                                    <ATMInput
                                        fluid
                                        value={value}
                                        name="lastName"
                                        placeholder={
                                            Lang.LBL_ADMIN_USER_CONFIG_INFO_LAST_NAME
                                        }
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            eMailAddress: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={Style.filterSection}>{ Lang.TTL_EMAIL_ADDRESS}</span>
                                    <ATMInput
                                        fluid
                                        value={value}
                                        name="eMailAddress"
                                        placeholder={Lang.TTL_EMAIL_ADDRESS}
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            userName: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={Style.filterSection}>{ Lang.TTL_ADMIN_USER_CONFIG_INFO_ALIAS}</span>
                                    <ATMInput
                                        fluid
                                        value={value}
                                        name="userName"
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_ALIAS
                                        }
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                    
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            userTypeId: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={Style.filterSection}>{ Lang.TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE}</span>
                                    <ATMSelect
                                        fluid
                                        value={
                                            value
                                                ? parseInt(value)
                                                : ''
                                        }
                                        data-id={value}
                                        name="userTypeId"
                                        options={userTypeSorted.map((type) => ({
                                            key: type.key,
                                            value: type.value,
                                            text: type.text,
                                        }))}
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_USER_TYPE
                                        }
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            entityId: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={Style.filterSection}>{ Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY}</span>
                                    <ATMSelect
                                        fluid
                                        value={
                                            value
                                                ? parseInt(value)
                                                : ''
                                        }
                                        name="entityId"
                                        options={entitiesSorted.map((type) => {
                                            return {
                                                key: type.key,
                                                value: type.value,
                                                text: type.text,
                                            };
                                        })}
                                        placeholder={
                                            Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY
                                        }
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                        },
                    })}

                </ORGDataTable>
            </div>
        </Container>
    );
};

export default ContactPrimesListView;

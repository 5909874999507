import React, { useState, useEffect } from 'react';
import AdHocSCHFileUploadView from './ad-hoc-sch-file-upload.view';
import {
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { useUpdateSubcontractorDBContext } from 'src/contexts/manage-subcontractor.context';
import { useContractorContext } from 'src/contexts/contractor.context';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { FunctionalityDisableText } from '../../constant/message-constant.component';

const AdHocSCHFileUpload: React.FC = () => {
    const { state, actions } = useUpdateSubcontractorDBContext();
    const [xlsxFile, setXlsxFile] = useState<File | null>(null);
    const [sp3Loading, setsp3Loading] = useState(false);
    const [loadEvent, setLoadEvent] = useState(false);
    const user_id: any = localStorage.getItem('loginCredential');

    const { state: schFileState, actions: schFileActions } = useContractorContext();
    const { state: poState, actions: poActions } = usePOGuardrailsContext();

    const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
    const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
    const [isDisableFunctionality, setDisableFunctionality] = useState(false);

    const checkSCHFileStatus = async () => {
        const response = await schFileActions.schFileStatusGet();
        if (response.payload?.status) {
            setSCHFileRunnig(response.payload?.data.isInProgress)
        }
    };

    const checkPOValidationBatchJobStatus = async () => {
        const response = await poActions.getPOValidationBatchJobStatus();
        if (response.payload?.status) {
            setPOValidationBatchJobRunnig(response.payload?.data.isInProgress)
        }
    };

    useEffect(() => {
        if (isSCHFileRunning || isPOValidationBatchJobRunning) {
            setDisableFunctionality(true)
        } else {
            setDisableFunctionality(false)
        }
    }, [isSCHFileRunning, isPOValidationBatchJobRunning])

    useEffect(() => {
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus();
    }, [])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!!file) {
            setXlsxFile(file);
        }
    };

    useEffect(() => {
        checkStatus();
        if (
            !!xlsxFile &&
            !(xlsxFile?.name === 'supplierclearinghouse.csv' ? true : false)
        ) {
            ToastError(Lang.MSG_EXTERNAL_DATA_FILE_UPLOAD_CSV_FILE_ONLY);
        }
        if (
            xlsxFile &&
            (xlsxFile?.name === 'supplierclearinghouse.csv' ? true : false)
        ) {
            handleUpload();
        }
    }, [xlsxFile]);
    const checkStatus = async () => {
        setLoadEvent(true)
        const response = await actions.schFileStatusGet();
        if (response.payload?.status) {
            setsp3Loading(response.payload?.data.isInProgress)
        }
        setLoadEvent(false)
    };
    const handleUpload = async () => {
        if (!!xlsxFile) {
            const formData = new FormData();
            formData.append('sch-input-file', xlsxFile);
            formData.append('userId', user_id);
            const response = await actions.updateSubcontractorDBPOST(formData);

            if (response.payload?.status) {
                ToastSuccess(response.payload.message);
            } else if (response.error.message === 'Network Error') {
                ToastError(response.error.message);
            }
        }
    };

    const handleClick = async () => {
        setLoadEvent(true);
        const data = await actions.schFileProcessAndUpdateSubcontractors();
        console.log('data', data)
        if (data.payload?.data?.id) {
            ToastSuccess(data.payload?.message)
        } else {
            ToastError(data.payload?.message)
        }
        setLoadEvent(false);
    };
    return isDisableFunctionality ? (<div style={{
        display: 'flex',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </div >) : (<AdHocSCHFileUploadView
        handleFileChange={handleFileChange}
        sp3Loading={sp3Loading}
        loadEvent={loadEvent}
        handleClick={handleClick}
    />);
};

export default AdHocSCHFileUpload;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { useContactUserContext } from 'src/contexts/contactuser.context';
import { useContractorContext } from 'src/contexts/contractor.context';
import { getContactUserStatus } from 'src/selectors/contactuser.selector';
import PrimesContactView from './primes-contact.view';
import { actionTypes } from 'src/ducks/contactuser.duck';
import { getUser, removeSearchParams } from 'src/libraries/auth.library';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import ContractorUpdateForm from 'src/components/pages/contractor/contractor-update/contractor-update.component';
import { isAdmin } from 'src/libraries/users.library';
import MassUpdate from 'src/components/pages/contact-user/primes-contact/mass-update/mass-update.component';

const PrimesContactList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const { state, actions } = useContactUserContext();

    const { state: stateContractor, actions: actionsContractor } =
        useContractorContext();
    const [open, setOpen] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const isUserAdmin = isAdmin();
    const tableParamsRef = useRef({ page: 0, limit: 0 });
    const [massUpdateModalOpen, setMassUpdateModalOpen] = useState(false);

    useEffect(() => {
        removeSearchParams();
    }, []);

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            const user = getUser();
            const { page, limit, sort, order } = params;

            tableParamsRef.current = params;
            const param = {
                sort_by: sort || '',
                sort_direction: order || '',
                page: page,
                limit: limit,
                name_begin_with: params.filters?.find(
                    (data) => data.name === 'name_begin_with'
                )?.value,
                name_contains: params.filters?.find(
                    (data) => data.name === 'name_contains'
                )?.value,
                sdge_name: params.filters?.find(
                    (data) => data.name === 'sdge_name'
                )?.value,
                socal_name: params.filters?.find(
                    (data) => data.name === 'socal_name'
                )?.value,
                is_active: params.filters?.find(
                    (data) => data.name === 'is_active'
                )
                    ? +params.filters?.find((data) => data.name === 'is_active')
                          ?.value
                    : '',
                contact_name_contains: params.filters?.find(
                    (data) => data.name === 'contact_name_contains'
                )?.value,
                favorite:
                    params.filters?.find((data) => data.name === 'favourite')
                        ?.value || '',
                username: user.username,
            };
            await actions.listGET(param);
        },
        [actions]
    );
    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: false });
    }, [setIsVisible]);

    const handlePrimeClick = useCallback(
        async (vendorId: number) => {
            if (vendorId) {
                const params = { vendor_id: vendorId };
                await actionsContractor.adminPrimeListGET(params);
                setOpen(true);
            }
        },
        [setOpen]
    );

    const handleMassUpdateModalClose = () => {
        setMassUpdateModalOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDiscard = () => {
        setOpen(false);
    };

    const handleSuccess = useCallback(
        async (msg) => {
            setLoading(true);
            setOpen(false);
            ToastSuccess(msg);
            await fetchData(tableParamsRef.current);
            setLoading(false);
        },
        [setOpen, setLoading]
    );

    const handleMassUpdateSuccess = useCallback(
        async (msg) => {
            handleMassUpdateModalClose();
            ToastSuccess(msg);
            await fetchData(tableParamsRef.current);
        },
        [setMassUpdateModalOpen]
    );

    return (
        <>
            <PrimesContactView
                isAdmin={isUserAdmin}
                isVisible={isVisible}
                data={state.list?.data || []}
                fetchData={fetchData}
                totalRecord={state.list.total_rows || 0}
                handleDismiss={handleDismiss}
                handlePrimeClick={handlePrimeClick}
                loading={
                    loading ||
                    (!state.list.data.length &&
                        getContactUserStatus(
                            state,
                            actionTypes.CONTACTUSER_LIST_FETCH
                        ).fetching)
                }
                handleMassUpdateModalOpen={setMassUpdateModalOpen}
            />
            {open && (
                <ContractorUpdateForm
                    isOpen={open}
                    handleClose={handleClose}
                    handleSuccess={handleSuccess}
                    formRef={formRef}
                    handleDiscard={handleDiscard}
                    contractorData={{ list: stateContractor.adminPrimeList }}
                />
            )}
            {massUpdateModalOpen && (
                <MassUpdate
                    isOpen={massUpdateModalOpen}
                    handleClosePopUp={handleMassUpdateModalClose}
                    handleSuccess={handleMassUpdateSuccess}
                />
            )}
        </>
    );
};
export default PrimesContactList;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import {
    UpdateSubcontractorDBSchema,
    IUpdateSubcontractorDB,
    UpdateSubcontractorVonsListPayload,
    UpdateSubcontractorVonPostSchema,
    UpdateSubcontractorSapIdSchema,
    ViewAllSubcontractorSchema,
    UpdateSapIdPostSchema,
    IUpdateSapIdPost,
    SubsWithoutSapIdAuditLogSchema,
    SubsWithoutVonAuditLogSchema,
    ViewAllSubAuditLogSchema,
    BlockAndUnblockExternalUserSchema,
    BlockAndUnblockExternalUserGetSchema,
    BlockAndUnblockExternalUserAuditLogGetSchema,
    ReportYearRoleBackAndRoleOverPostSchema,
    ReportYearRoleBackAndRoleOverGetSchema,
    ReportingyearsGetSchema,
    YearEndRolloverAuditLogGetSchema,
    schFileStatusSchema,
    schFileProcessAndUpdateSubcontractorsSchema
} from '../models/manage-subcontractor.model';
const client = httpClient();

export const services = {
    updateSubcontractorDatabase: async (
        data: Partial<IUpdateSubcontractorDB>
    ) => {
        return client.post(
            '/sp3SchFileUpload',
            data,
            UpdateSubcontractorDBSchema,
        );
    },
   
    UpdateSubcontractorVonGet: async (data) => {
        return client.get(
            '/inSubcontractorWithoutVons',
            data,
            UpdateSubcontractorVonsListPayload
        );
    },
    UpdateSubcontractorVonPost: async (params) => {
        return client.post(
            '/updateSubcontractorVON',
            params,
            UpdateSubcontractorVonPostSchema
        );
    },
    listVonNumberGET: async (params) => {
        return client.get(
            '/inSubcontractorWithoutVons',
            params,
            UpdateSubcontractorVonsListPayload
        );
    },
    ViewAllSubConstractor: async (params) => {
        return client.get(
            '/viewAllSub',
            params,
            ViewAllSubcontractorSchema
        );
    },
    UpdateSubcontractorSapIdGet: async (params) => {
        return client.get(
            '/subsWithoutSapId',
            params,
            UpdateSubcontractorSapIdSchema
        );
    },
    UpdateSapIdPost: async (params: Partial<IUpdateSapIdPost>) => {
        return client.put('/subsWithoutSapId', params, UpdateSapIdPostSchema);
    },
    subsWithoutSapIdAuditLog: async (params) => {
        return client.get('/subsWithoutSapIdAuditLog', params, SubsWithoutSapIdAuditLogSchema);
    },
    subsWithoutVonAuditLog: async (params) => {
        return client.get('/subsWithoutVonAuditLog', params, SubsWithoutVonAuditLogSchema);
    },
    viewAllSubAuditLog: async (params) => {
        return client.get('/viewAllSubAuditLog', params, ViewAllSubAuditLogSchema);
    },
    blockAndUnblockExternalUser: async (params) => {
        return client.post('/blockAndUnblockExternalUser', params, BlockAndUnblockExternalUserSchema);
    },

    blockAndUnblockExternalUserGet: async () => {
        return client.get('/blockAndUnblockExternalUser', {}, BlockAndUnblockExternalUserGetSchema);
    },
    blockAndUnblockExternalUserAuditLog: async (params) => {
        return client.get('/blockAndUnblockExternalUserAuditLog', params, BlockAndUnblockExternalUserAuditLogGetSchema);
    },

    reportYearRoleBackAndRoleOverPost: async (params) => {
        return client.post('/reportYearRoleBackAndRoleOver', params, ReportYearRoleBackAndRoleOverPostSchema);
    },

    reportYearRoleBackAndRoleOverGet: async () => {
        return client.get('/reportYearRoleBackAndRoleOver', {}, ReportYearRoleBackAndRoleOverGetSchema);
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
    yearEndRolloverAuditLogGet: async (params) => {
        return client.get('/reportYearRoleBackAndRoleOverAuditLog', params, YearEndRolloverAuditLogGetSchema);
    },
    schFileStatus: async () => {
        return client.get('/schFileStatus', {}, schFileStatusSchema);
    },
    schFileProcessAndUpdateSubcontractors: async () => {
        return client.get('/schFileProcessAndUpdateSubcontractors', {}, schFileProcessAndUpdateSubcontractorsSchema);
    }

    
};
export default services;

import React, { useState } from 'react'
import { ATMButton, ATMContainer, ATMIcon, ATMLoader, ATMPopover, ATMTable } from 'shared-it-appmod-ui'
import Lang from 'src/libraries/language'
import style from './subcontracting-summary.module.scss';

export type IProps = {
    previousMonth: string
    socalSubcontractingReport: any;
    sdgeSubcontractingReport: any;
    isDashboardSubcontractorSummaryLoading: boolean;
    exportSummaryData: () => void;
    handleClickViewIndividualContactInfo: () => void
}

const SubcontractingSummaryView: React.FC<IProps> = ({
    previousMonth,
    isDashboardSubcontractorSummaryLoading,
    socalSubcontractingReport,
    sdgeSubcontractingReport,
    exportSummaryData,
    handleClickViewIndividualContactInfo
}) => {
    return isDashboardSubcontractorSummaryLoading ?
        <div style={{ width: '100%', paddingTop: '200px', display: 'flex', justifyContent: 'center' }}>
            <ATMLoader active inline="centered" size="medium" />
        </div> : <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                    {Lang.LBL_SUBCONTRACTING_SUMMARY_DASHBOARD_TITLE}
                </div>
                <div >
                    <b>{Lang.LBL_PREVIOUS_MONTH}</b> | {previousMonth}
                    <div
                        style={{
                            display: 'initial',
                            padding: '5px 5px 5px 8px',
                            marginLeft: 10,
                            border: '1px solid rgb(33, 133, 208)',
                            borderRadius: '5px',
                            color: 'rgb(33, 133, 208)',
                            cursor: 'pointer'
                        }}
                        onClick={exportSummaryData}
                    >
                        <ATMIcon color="blue" name="download" />
                    </div>
                </div>
            </div>
            <ATMContainer fluid className={style.wrapper}>
                <ATMTable compact
                    structured
                    enableScroll
                    className={style.mainTable}
                    style={{ width: '100%' }}
                >
                    <ATMTable.Header>
                        <ATMTable.Row >
                            <ATMTable.HeaderCell >
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell style={{ textAlign: 'right' }}>
                                {Lang.LBL_SDGNE}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell style={{ textAlign: 'right' }}>
                                {Lang.LBL_SOCALGAS}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell >
                                {Lang.LBL_TOTAL_PAYMENTS_YTD}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport.totalPaymentYTD}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport.totalPaymentYTD}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_TOTAL_VERIFIED_SUBCONTRACTING}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport.totalVerifiedSubcontracting}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport.totalVerifiedSubcontracting}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell >
                                {Lang.LBL_VERIFIED_SUBCONTRACTING_YTD}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport?.verifiedSubcontracting}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport?.verifiedSubcontracting}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_OVERALL_GOALS}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport?.overallGoal}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport?.overallGoal}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_GOAL_SUBCONTRACTING}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport.goalSubcontracting}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport.goalSubcontracting}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                        <ATMTable.Row className={style.subcontractingRow}>
                            <ATMTable.HeaderCell>
                                {Lang.LBL_SUBCONTRACTING_DIFFERENCE}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {sdgeSubcontractingReport.subcontractingDiff}
                            </ATMTable.HeaderCell>
                            <ATMTable.HeaderCell className={style.alignRight}>
                                {socalSubcontractingReport.subcontractingDiff}
                            </ATMTable.HeaderCell>
                        </ATMTable.Row>
                    </ATMTable.Body>
                </ATMTable>
            </ATMContainer >
            <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'center' }}>
                <ATMButton
                    secondary
                    onClick={handleClickViewIndividualContactInfo}
                >
                    {Lang.LBL_VIEW_INDIVIDUAL_CONTRACT_INFO}
                </ATMButton>
            </div>
        </>

}

export default SubcontractingSummaryView
import React, { useCallback, useState } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';
import { getUserID } from 'src/libraries/users.library';
import POGuardRailsAuditLogView from './po-guardrails-audit-log.view';
import POGuardRailsAuditLogEmailView from './email/po-guardrails-audit-log-email.view';

type Iprops = {
    openAuditLogs: boolean;
    handleOnDiscardAuditLogs: () => void;
};
const POGuardrailsAuditLog: React.FC<Iprops> = ({
    openAuditLogs,
    handleOnDiscardAuditLogs,
}) => {
    const { state, actions } = usePOGuardrailsContext();
    const [loadinglog, setLoadinglog] = useState(false);
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>();
    const [openEmailView, setOpenEmailView] = useState<boolean>(false);
    const [auditLogData, setAuditLogData] = useState<any>({});
    

    const fetchLogData = useCallback(
        async (params: IORGDataTableQueryState) => {
            setLoadinglog(true);

            const { page, limit } = params;
            const param =
                {
                    page: page,
                    limit: limit
                } || queryParams;
            await actions?.getInvalidContractAuditLog(param);
            setLoadinglog(false);
        },
        [actions]
    );

    const handleCancel = useCallback(() => {
        handleOnDiscardAuditLogs();
    }, []);

    const handleOpenEmailView = useCallback((data) => {
        setAuditLogData(data)
        setOpenEmailView(true)
    }, []);

    const handleCloseEmailView = () => {
        setOpenEmailView(false);
        setAuditLogData({})
    }

    return (
        <>
             {openEmailView && <POGuardRailsAuditLogEmailView open={openEmailView} handleClose={handleCloseEmailView} data={auditLogData} />}
             
            <POGuardRailsAuditLogView
                data={state?.poGuardrailsAuditLog?.data || []}
                openAuditLogs={openAuditLogs}
                handleClose={handleCancel}
                loadinglog={loadinglog}
                fetchLogData={fetchLogData}
                totalRecord={state?.poGuardrailsAuditLog?.total_rows || 0}
                handleOpenEmailView={handleOpenEmailView}
            />
        </>
    );
};

export default POGuardrailsAuditLog;

import React, { useState, useCallback, useEffect } from 'react';
import { useSummaryContext } from 'src/contexts/summary.context';
import SpendSummaryView from './spend-summary.view';
import { getSummaryStatus } from 'src/selectors/summary.selector';
import { actionTypes } from 'src/ducks/summary.duck';
import moment from 'moment';

const SpendSummary: React.FC = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const { state, actions } = useSummaryContext();
    const [currentApiYear, setCurrentApiYear] = useState(new Date().getFullYear());

    const fetchData = useCallback(async () => {
        setLoading(true);
        const data = await actions.reportingyearsGet();
        setCurrentApiYear(data.payload.data[0].report_year)
        const currentYear = currentApiYear //current year
        const params = {
            user_id: localStorage.getItem('loginCredential'),
            report_year: data.payload.data[0].report_year,
        };
        await actions.listGET(params);
        setLoading(false)
    }, [actions]);

    useEffect(() => {
        fetchData();
    }, [actions]);

    const handleYearChange = (data) => {
        setYear(data);
        fetchDataOnYearChange(data);
    }

 

    const fetchDataOnYearChange = useCallback(async (yearData) => {
        setLoading(true);
        const params = {
            user_id: localStorage.getItem('loginCredential'),
            report_year: yearData,
        };
        await actions.listGET(params);
        setLoading(false)
    }, [actions]);

    return (
        <SpendSummaryView
            data={state.list?.data || { data: [] }}
            fetchData={fetchData}
            handleYearChange={handleYearChange}
            currentyear={currentApiYear}
            loading={
                loading ||
                (!state.list.data.length &&
                    getSummaryStatus(state, actionTypes.SUMMARY_LIST_FETCH)
                        .fetching)
            }
        />
    );
};

export default SpendSummary;

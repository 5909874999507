import React from 'react';
import ChangesToSpendAmount from './changes-to-spend-amount.view';

const ChangesToSpendAmountComponent: React.FC<any> = ({
    onChangesPageChange,
    listChangesToSpendAmounts,
    loading,
}) => {
    return (
        <ChangesToSpendAmount
            data={listChangesToSpendAmounts?.data || []}
            onChangesPageChange={onChangesPageChange}
            total={listChangesToSpendAmounts?.total_rows}
            loading={loading}
            listChangesToSpendAmounts={listChangesToSpendAmounts}
        />
    );
};
export default ChangesToSpendAmountComponent;

import {
    IContractorpagePageReducerAction,
    IContractorpageState,
} from '../ducks/contractorpage.duck';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { IStatus } from '../ducks';

export const contractorpageFetchStatus = (
    state: IContractorpageState,
    action: IContractorpagePageReducerAction['type']
): IStatus =>
    state.status[action] ?? {
        fetching: false,
        error: null,
    };

export const getTableListParams = (
        params: IORGDataTableQueryState
): Partial<IORGDataTableQueryState> => {
    const { ...data } = params;
    const ret = {};
    if (data.filters) {
        for (const dataParam of data.filters) {
            ret[dataParam.name] = dataParam.value;
        }
    }
    delete data.filters;
    return { ...data, ...ret };
};

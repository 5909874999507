import React, { useEffect, useState } from 'react';
import {
    ATMContainer,
    ATMHeader,
    ATMSegment,
    ATMSelect,
} from 'shared-it-appmod-ui';
import style from '../report.module.scss';
import Lang from 'src/libraries/language';
import ByPurchaseOrder from '../by-purchase-order/by-purchase-order.view';
import MonthSummaryReportComponent from '../month-summary-report/month-summary-report.component';
import ChangesToSpendAmountComponent from '../changes-to-spend-amount/changes-to-spend-amount.component';
import ReportLandingView from '../report-landing/report-landing.view';
import ReportHead from './report-head';
import '../report.scss';
import VerificationFollowUpAdminComponent from '../verification-follow-up-admin/verification-follow-up-admin.component';
import MonthSummaryReportAdminComponent from '../month-summary-report-admin/month-summary-report-admin.component';
import { actionTypes } from 'src/ducks/report.duck';
import { getReportStatus } from 'src/selectors/report.selector';
import { useReportContext } from 'src/contexts/report.context';
import PrimeInformation from '../prime-information/prime-information.component';
import VerificationFollowUpComponent from '../verification-follow-up/verification-follow-up.component';
import { isAdmin, isPrime, isContact } from 'src/libraries/users.library';
import MonthSummaryReportAdminTierIIIComponent from '../month-summary-report-admin-tier3/month-summary-report-admin-tier-III.component';
import MonthSummaryReportTierIIIComponent from '../month-summary-report-tier3/month-summary-report-tier-III.component';

const ReportView: React.FC<any> = ({
    setResetPagination,
    resetPagination,
    structureData,
    structureAdminData,
    structurePurchaseOrderData,
    listByPurchaseOrder,
    listChangesToSpendAmounts,
    monthSummaryList,
    monthSummaryAdminList,
    monthReportByPrimeContract,
    handleSubcontractorFilter,
    handleContractorFilter,
    subcontractorsSelected,
    contractorsSelected,
    handleDownload,
    onVerificationPageChange,
    onChangesPageChange,
    onPurchaseOrderPageChange,
    handleReportChange,
    handlePrimesFilter,
    handlePrimesAdminFilter,
    primesSelected,
    onMonthSummaryOrderAdminPageChange,
    handleSupplierDiversityFilter,
    supplierDiversitySelected,
    supplierDiversityOptions,
    handleStatusChange,
    onPrimeInfoPageChange,
    selectedStatus,
    primeInfoData,
    handleUtilityFilter,
    selectedUtility,
    listAdminChangesToSpendAmountsGET,
    verificationFollowupList,
    handleChangeVerificationExpDate,
    verificationExpDates,
    handleSpendAmountFilter,
    handleSupplierDiversityAdminFilter,
    handlePrimesAdminNeedFollowUpFilter,
    spendAmount,
    handleClearAdminVerificationFollowUp,
    handleClearPrimeChangestoSpendAmount,
    handleSpendDateRangeFilter,
    spendDateRange,
    currentApiYear, selectSupplierDiversity
}) => {
    const [reportType, setReportType] = useState(0);
    const [reload, setReload] = useState(false);
    const [reloadHead, setReloadHead] = useState(false);
    const [loading] = useState(false);
    const { state } = useReportContext();

    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();

    const mappedMonthReportByPrimeContract = {
        data: monthReportByPrimeContract?.data?.map((item) => {
            return {
                data: {
                    UnVerifySpend: item?.spend_data?.unVerify_spend,
                    unVerifySpendTotal: item?.spend_data?.unVerify_spend_total,
                    verifySpend: item?.spend_data?.verify_spend,
                    verifySpendTotal: item?.spend_data?.verify_spend_total,
                },
                primeName: item?.prime_name,
                sdgeAdvisorName: item?.sdge_adv_name,
                socalAdvisorName: item?.socal_adv_name,
                c1: item?.c1,
                c2: item?.c2,
                c3: item?.c3,
                contractNumber: item?.contract_number,
                contractDescription: item?.contract_description,
                utility: item?.utility,
                vendorId: item?.vendor_id,
            };
        }),
        filters_data: monthReportByPrimeContract?.filters_data,
        total_pages: monthReportByPrimeContract?.total_pages,
        total_rows: monthReportByPrimeContract?.total_rows,
    };

    const reportOptions =
        isUserAdmin || isUserContact
            ? [
                { key: '0', value: 0, text: 'Select Report' },
                {
                    key: '1',
                    value: 1,
                    text: Lang.TTL_REPORT_PRIME_INFO_PRIME_INFORMATION,
                },
                {
                    key: '2',
                    value: 2,
                    text: Lang.LBL_REPORT_SUBCONTRACTORS_NEEDING_VERIFICATION_FOLLOW_UP,
                },
                {
                    key: '3',
                    value: 3,
                    text: Lang.LBL_REPORT_CHANGES_TO_SPEND_AMOUNTS,
                },
                {
                    key: '4',
                    value: 4,
                    text: Lang.LBL_REPORT_PRIME_SUBCONTRACTING_SUMMARY,
                },
                {
                    key: '5',
                    value: 5,
                    text: Lang.LBL_REPORT_PRIME_SUBCONTRACTING_DETAILS,
                },
                //   {
                //       key: '6',
                //       value: 6,
                //       text: 'Subcontracting by Month by Prime Supplier CC',
                //   },
                {
                    key: '7',
                    value: 7,
                    text: Lang.LBL_REPORT_PRIME_SUBCONTRACTING_SUMMARY_TIER_III,
                },
            ]
            : [
                { key: '0', value: 0, text: 'Select Report' },
                {
                    key: '1',
                    value: 1,
                    text: Lang.LBL_REPORT_SUBCONTRACTING_BY_MONTH_SUMMARY_REPORT,
                },
                {
                    key: '4',
                    value: 4,
                    text: Lang.LBL_REPORT_SUBCONTRACTING_BY_CONTRACT,
                },
                {
                    key: '2',
                    value: 2,
                    text: Lang.LBL_REPORT_SUBCONTRACTORS_NEEDING_VERIFICATION_FOLLOW_UP,
                },
                {
                    key: '3',
                    value: 3,
                    text: Lang.LBL_REPORT_CHANGES_TO_SPEND_AMOUNTS,
                }, {
                    key: '6',
                    value: 6,
                    text: Lang.LBL_REPORT_SUBCONTRACTING_BY_MONTH_SUMMARY_REPORT_TIER_THREE,
                },
            ];
    useEffect(() => {
        handleHeadReload();
    }, [reportType]);

    const handleHeadReload = async () => {
        await setReloadHead(true);
        await setReloadHead(false);
    };

    const primeOptions = [{}];

    if (reportType === 1 && state?.listPrimeInformation) {
        if (state?.listPrimeInformation) {
            primeInfoData?.filters_data?.primes?.forEach((type) => {
                primeOptions.push({
                    key: type.vendorId,
                    value: type.vendorId,
                    text: type.name,
                });
            });
        }
    } else if (!isUserPrime && (reportType === 4 || reportType === 7)) {
        monthSummaryAdminList?.filters_data?.primes.forEach((i) => {
            primeOptions.push({
                key: i.vendor_id,
                value: i.vendor_id,
                text: i.prime,
            });
        });
    } else if (reportType === 5) {
        mappedMonthReportByPrimeContract?.filters_data?.AllUniquePrimes.forEach(
            (i) => {
                primeOptions.push({
                    key: i.vendor_id,
                    value: i.vendor_id,
                    text: i.prime_name,
                });
            }
        );
    } else if (
        reportType === 3 &&
        listAdminChangesToSpendAmountsGET?.filtersData?.primes?.length > 0
    ) {
        if (listAdminChangesToSpendAmountsGET) {
            listAdminChangesToSpendAmountsGET?.filtersData?.primes?.forEach(
                (i) => {
                    primeOptions.push({
                        key: i.vendorId,
                        value: i.vendorId,
                        text: i.primeName,
                    });
                }
            );
        }
    } else if (
        reportType === 2 &&
        verificationFollowupList?.filters_data?.prime?.length > 0
    ) {
        verificationFollowupList?.filters_data?.prime.forEach((i) => {
            primeOptions.push({
                key: i.vendor_id,
                value: i.vendor_id,
                text: i.prime,
            });
        });
    } else {
        if (monthSummaryAdminList?.filters_data?.primes?.length > 0) {
            monthSummaryAdminList?.filters_data?.primes.forEach((i) => {
                primeOptions.push({
                    key: i.vendor_id,
                    value: i.vendor_id,
                    text: i.prime,
                });
            });
        }
    }
    const getContractOptions = () => {
        switch (reportType) {
            case 5:
                return mappedMonthReportByPrimeContract?.filters_data?.AllUniqueContracts?.map(
                    (i) => ({
                        key: i,
                        value: i,
                        text: i,
                    })
                );
            case 3:
                return listChangesToSpendAmounts?.filters_data?.contracts?.map(
                    (i, index) => ({
                        key: index + 1,
                        value: i.contract_number,
                        text: i.contract_number,
                    })
                );
            default:
                return listByPurchaseOrder?.poNumbers?.map((i) => ({
                    key: i,
                    value: i,
                    text: i,
                }));
        }
    };

    const getUtilityOptions = () => {
        const options =
            mappedMonthReportByPrimeContract?.filters_data?.UtilityData?.map(
                (i) => ({
                    key: i.Sempra_Company_ID,
                    value: i.Sempra_Company_ID,
                    text: i.Sempra_Company_Name,
                })
            ) || [];
        if (options.length > 0) {
            options.unshift({
                key: 'ALL',
                value: 'ALL',
                text: 'All',
            });
        } else {
            const summaryOptions = monthSummaryAdminList?.filters_data?.UtilityData?.map(
                (i) => ({
                    key: i.Sempra_Company_ID,
                    value: i.Sempra_Company_ID,
                    text: i.Sempra_Company_Name,
                })
            ) || [];

            if (summaryOptions.length > 0) {
                summaryOptions.unshift({
                    key: 'ALL',
                    value: 'ALL',
                    text: 'All',
                });
            } else {

                const spendAmountOptions = listAdminChangesToSpendAmountsGET?.filtersData?.utilityData?.map(
                    (i) => ({
                        key: i.Sempra_Company_ID,
                        value: i.Sempra_Company_ID,
                        text: i.Sempra_Company_Name,
                    })
                ) || [];

                if (spendAmountOptions.length > 0) {
                    spendAmountOptions.unshift({
                        key: 'ALL',
                        value: 'ALL',
                        text: 'All',
                    });
                    return spendAmountOptions;
                } else {
                    const primeSpendAmountOptions = listChangesToSpendAmounts?.filters_data?.utilityData?.map(
                        (i) => ({
                            key: i.Sempra_Company_ID,
                            value: i.Sempra_Company_ID,
                            text: i.Sempra_Company_Name,
                        })
                    ) || [];
                    if (primeSpendAmountOptions.length > 0) {
                        primeSpendAmountOptions.unshift({
                            key: 'ALL',
                            value: 'ALL',
                            text: 'All',
                        });
                        return primeSpendAmountOptions;
                    }

                    const listByPurchaseOrderOptions = listByPurchaseOrder?.utilityData?.map(
                        (i) => ({
                            key: i.Sempra_Company_ID,
                            value: i.Sempra_Company_ID,
                            text: i.Sempra_Company_Name,
                        })
                    ) || [];
                    if (listByPurchaseOrderOptions.length > 0) {
                        listByPurchaseOrderOptions.unshift({
                            key: 'ALL',
                            value: 'ALL',
                            text: 'All',
                        });
                        return listByPurchaseOrderOptions;
                    }


                }

            }
            return summaryOptions;
        }

        return options;
    };

    const contractsOptions = getContractOptions();
    const utilityOptions = getUtilityOptions();

    const getSubcontractOptions = (reportType) => {
        if (reportType === 1 || reportType === 6) {
            return monthSummaryList?.filters_data?.subcontractors?.map(
                (i, index) => ({
                    key: index + 1,
                    value: i.subcontractor_id,
                    text: i.subcontractor_name,
                })
            );
        } else if (reportType === 3) {
            return listChangesToSpendAmounts?.filters_data?.subcontractors?.map(
                (i, index) => ({
                    key: index + 1,
                    value: i.subcontractor_id,
                    text: i.subcontractor_name,
                })
            );
        }
    };

    const subcontractorsOptions = getSubcontractOptions(reportType);

    const handleSubcontractorChange = (value) => {
        handleSubcontractorFilter(value);
    };

    const handleSupplierChange = (value) => {
        handleSupplierDiversityFilter(value);
    };

    const handleContractsChange = (value) => {
        handleContractorFilter(value);
    };

    const handleSpendDateRangeChange = (value) => {
        handleSpendDateRangeFilter(value);
    };

    const handleUtilityChange = (value) => {
        handleUtilityFilter(value);
    };

    const handleClearSubcontractors = async () => {
        await setReload(true);
        await handleSubcontractorFilter([]);
        await setReload(false);
    };

    const handleClearVerificationExpDates = async () => {
        await setReload(true);
        await handleChangeVerificationExpDate([]);
        await setReload(false);
    };

    const handleClearFilter = async () => {
        await setReload(true);
        handleSupplierDiversityFilter([], 'clear');
        await setReload(false);
    };

    const handleClearPrimes = async () => {
        await setReload(true);
        await handlePrimesFilter([]);
        await handlePrimesAdminFilter([]);
        await setReload(false);
    };

    const handleClearContracts = async () => {
        await setReload(true);
        await handleContractsChange([]);
        await handleUtilityChange('ALL')
        await setReload(false);
    };

    const handleClearAdminVerificationFollowUpCall = async () => {
        await setReload(true);
        await handleClearAdminVerificationFollowUp();
        await setReload(false);
    };

    const handleClearPrimeChangestoSpendAmountCall = async () => {
        await setReload(true);
        await handleUtilityChange('ALL');
        await handleClearPrimeChangestoSpendAmount();
        await setReload(false);
    };
    return (
        <ATMContainer fluid >
            <>
                <ATMContainer fluid className={style.pageName}>
                    <ATMHeader
                        className={`${style.headerContent}`}
                        style={{ marginBottom: 20 }}
                    >
                        {Lang.TTL_REPORTS}
                    </ATMHeader>
                </ATMContainer>
                <ATMContainer fluid>
                    <ATMSegment className={style.content}>
                        <span
                            style={{
                                marginRight: 20,
                                fontSize: 13,
                                fontWeight: 'bold',
                            }}
                        >
                            Report
                        </span>
                        <ATMSelect
                            placeholder="Select Report"
                            options={reportOptions}
                            value={reportType}
                            onChange={(_, v) => {
                                handleReportChange(v.value);
                                setReportType((prev) => {
                                    let prevValue = prev;
                                    prevValue = Number(v.value);
                                    return prevValue;
                                });
                            }}
                            style={{ width: 365 }}
                        />
                    </ATMSegment>
                    <ATMSegment className={style.content}>
                        {!reloadHead && (
                            <ReportHead
                                selectSupplierDiversity={selectSupplierDiversity}
                                verificationFollowupList={
                                    verificationFollowupList || []
                                }
                                reportType={reportType}
                                reload={reload}
                                subcontractorsOptions={subcontractorsOptions}
                                handleSubcontractorChange={
                                    handleSubcontractorChange
                                }
                                subcontractorsSelected={subcontractorsSelected}
                                contractorsSelected={contractorsSelected}
                                handleClearSubcontractors={
                                    handleClearSubcontractors
                                }
                                contractsOptions={contractsOptions}
                                handleContractsChange={handleContractsChange}
                                handleClearContracts={handleClearContracts}
                                handleDownload={handleDownload}
                                primeOptions={primeOptions}
                                handlePrimesFilter={handlePrimesFilter}
                                handlePrimesAdminFilter={handlePrimesAdminFilter}
                                primesSelected={primesSelected}
                                handleClearPrimes={handleClearPrimes}
                                handleSupplierChange={handleSupplierChange}
                                supplierDiversitySelected={
                                    supplierDiversitySelected
                                }
                                handleClearFilter={handleClearFilter}
                                handleSupplierDiversityFilter={
                                    handleSupplierDiversityFilter
                                }
                                supplierDiversityOptions={supplierDiversityOptions}
                                handleStatusChange={handleStatusChange}
                                selectedStatus={selectedStatus}
                                utilityOptions={utilityOptions}
                                handleUtilityChange={handleUtilityChange}
                                selectedUtility={selectedUtility}
                                verificationExpDates={verificationExpDates}
                                handleChangeVerificationExpDate={
                                    handleChangeVerificationExpDate
                                }
                                handleClearVerificationExpDates={
                                    handleClearVerificationExpDates
                                }
                                handleSpendAmountFilter={handleSpendAmountFilter}
                                handleSupplierDiversityAdminFilter={
                                    handleSupplierDiversityAdminFilter
                                }
                                handlePrimesAdminNeedFollowUpFilter={
                                    handlePrimesAdminNeedFollowUpFilter
                                }
                                spendAmount={spendAmount}
                                handleClearAdminVerificationFollowUp={
                                    handleClearAdminVerificationFollowUpCall
                                }
                                handleClearPrimeChangestoSpendAmount={
                                    handleClearPrimeChangestoSpendAmountCall
                                }
                                handleSpendDateRangeChange={
                                    handleSpendDateRangeChange
                                }
                                spendDateRange={spendDateRange}
                                setResetPagination={setResetPagination}
                                resetPagination={resetPagination}
                                currentApiYear={currentApiYear}
                            />
                        )}

                        <>{reportType === 0 && <ReportLandingView />}</>
                        <>
                            {reportType === 1 && isUserPrime && (
                                <MonthSummaryReportComponent
                                    subcontractors={subcontractorsSelected}
                                    structureData={structureData}
                                    monthSummaryList={monthSummaryList}
                                    loading={
                                        loading ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_MONTH_SUMMARY_LIST_FETCH
                                        ).fetching
                                    }
                                />
                            )}
                        </>
                        <>
                            {reportType === 6 && isUserPrime && (
                                <MonthSummaryReportTierIIIComponent
                                    subcontractors={subcontractorsSelected}
                                    structureData={structureData}
                                    monthSummaryList={monthSummaryList}
                                    loading={
                                        loading ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_MONTH_SUMMARY_LIST_TIER_III_FETCH
                                        ).fetching
                                    }
                                />
                            )}
                        </>

                        <>
                            {reportType === 1 && !isUserPrime && (
                                <PrimeInformation
                                    primeDetails={state?.listPrimeInformation || []}
                                    loading={
                                        loading ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_PRIME_INFORMATION_LIST_FETCH
                                        ).fetching
                                    }
                                    onPrimeInfoPageChange={onPrimeInfoPageChange}
                                />
                            )}
                        </>

                        <>
                            {(isUserAdmin || isUserContact) &&
                                (reportType === 4 || reportType === 5) && (
                                    <MonthSummaryReportAdminComponent
                                        setResetPagination={setResetPagination}
                                        resetPagination={resetPagination}
                                        subcontractors={subcontractorsSelected}
                                        structureAdminData={structureAdminData}
                                        reportType={reportType}
                                        monthSummaryAdminList={
                                            reportType === 4
                                                ? monthSummaryAdminList
                                                : mappedMonthReportByPrimeContract
                                        }
                                        onMonthSummaryOrderAdminPageChange={
                                            onMonthSummaryOrderAdminPageChange
                                        }
                                        loading={
                                            loading ||
                                            getReportStatus(
                                                state,
                                                reportType === 4
                                                    ? actionTypes.REPORT_MONTH_SUMMARY_ADMIN_LIST_FETCH
                                                    : actionTypes.REPORT_MONTH_BY_PRIME_CONTRACT_FETCH
                                            ).fetching
                                        }
                                    />
                                )}
                        </>
                        <>
                            {(isUserAdmin || isUserContact) &&
                                (reportType === 7) && (
                                    <MonthSummaryReportAdminTierIIIComponent
                                        setResetPagination={setResetPagination}
                                        resetPagination={resetPagination}
                                        subcontractors={subcontractorsSelected}
                                        structureAdminData={structureAdminData}
                                        reportType={reportType}
                                        monthSummaryAdminList={
                                            monthSummaryAdminList
                                        }
                                        onMonthSummaryOrderAdminPageChange={
                                            onMonthSummaryOrderAdminPageChange
                                        }
                                        loading={
                                            loading ||
                                            getReportStatus(
                                                state,
                                                actionTypes.REPORT_MONTH_SUMMARY_ADMIN_TIER_III_LIST_FETCH
                                            ).fetching
                                        }
                                    />
                                )}
                        </>
                        <>
                            {reportType === 2 && (
                                <>
                                    {isUserPrime ? (
                                        <VerificationFollowUpComponent
                                            setResetPagination={setResetPagination}
                                            resetPagination={resetPagination}
                                            data={verificationFollowupList}
                                            onVerificationPageChange={
                                                onVerificationPageChange
                                            }
                                            loading={
                                                loading ||
                                                getReportStatus(
                                                    state,
                                                    actionTypes.ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH
                                                ).fetching ||
                                                getReportStatus(
                                                    state,
                                                    actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH
                                                ).fetching
                                            }
                                        />
                                    ) : (
                                        <VerificationFollowUpAdminComponent
                                            setResetPagination={setResetPagination}
                                            resetPagination={resetPagination}
                                            data={verificationFollowupList}
                                            onVerificationPageChange={
                                                onVerificationPageChange
                                            }
                                            loading={
                                                loading ||
                                                getReportStatus(
                                                    state,
                                                    actionTypes.ADMIN_REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH
                                                ).fetching ||
                                                getReportStatus(
                                                    state,
                                                    actionTypes.REPORT_NEEDING_VERIFICATION_FOLLOW_UP_LIST_FETCH
                                                ).fetching
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </>
                        <>
                            {reportType === 3 && (
                                <ChangesToSpendAmountComponent
                                    onChangesPageChange={onChangesPageChange}
                                    listChangesToSpendAmounts={
                                        !isUserPrime
                                            ? listAdminChangesToSpendAmountsGET
                                            : listChangesToSpendAmounts
                                    }
                                    loading={
                                        loading ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_CHANGES_TO_SPEND_AMOUNTS_LIST_FETCH
                                        ).fetching ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_ADMIN_CHANGES_TO_SPEND_AMOUNT_LIST_FETCH
                                        ).fetching
                                    }
                                />
                            )}
                        </>
                        <>
                            {!isUserAdmin && !isUserContact && reportType === 4 && (
                                <ByPurchaseOrder
                                    listByPurchaseOrder={structurePurchaseOrderData(
                                        listByPurchaseOrder
                                    )}
                                    onPurchaseOrderPageChange={
                                        onPurchaseOrderPageChange
                                    }
                                    loading={
                                        loading ||
                                        getReportStatus(
                                            state,
                                            actionTypes.REPORT_BY_PURCHASE_ORDER_LIST_FETCH
                                        ).fetching
                                    }
                                />
                            )}
                        </>

                    </ATMSegment>
                </ATMContainer>
            </>
        </ATMContainer>
    );
};

export default ReportView;

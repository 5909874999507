import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';
import {
    ATMButton,
    ATMContainer,
    ATMField,
    ATMForm,
    ATMGrid,
    ATMHeader,
    ATMInput,
    ATMLabel,
    ATMSelect,
} from 'shared-it-appmod-ui';
import {
    emailTemplateOptions,
    month as monthObject,
    yearObject,
} from 'src/constants/common.constant';
import Lang from 'src/libraries/language';
import { MassMailFormSchema } from 'src/models/mass-mail.model';
import { Dialog } from './dialog.view';
import styles from './mass-mail.module.scss';
import MassMailAuditLog from './audit-log/mass-mail-audit-log.component';

interface showType {
    by_supplier?: boolean;
    primes_located_in?: boolean;
    primes_with?: boolean;
    all_primes?: boolean;
}

export const handleEditModalOpen: any = (openTemplateDialog) => {
    openTemplateDialog(true);
};

export const handleTemplateModalDone: any = (
    handleTemplateModalClose,
    handleChange
) => {
    handleTemplateModalClose();
};

export const getTemplateBody = (key: string) => {
    return emailTemplateOptions.filter((x) => x.key == key).map((x) => x.body);
};

export const getTemplateSubject = (key: string) => {
    return emailTemplateOptions
        .filter((x) => x.key == key)
        .map((x) => x.subject);
};

export type IProps = {
    isSending: boolean;
    loading: boolean;
    handleClick: () => void;
    handleSubmit: () => void;
    handleChange: (id: string, value: string) => void;
    showTo: showType | any;
    mailSent: boolean;
    states: [] | any;
    cities: [] | any;
    advisorOptions: [] | any;
    socalOptions: [] | any;
    data: any;
    handleFileChange: (e: any) => void;
    resetForm: () => void;
    setValue: (val: boolean) => void;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    setOpenAuditLog: any; reportYearDropdown: any
};

const MassMailView: React.FC<IProps> = ({
    isSending,
    formRef,
    mailSent,
    handleSubmit,
    handleChange,
    loading,
    showTo,
    states,
    cities,
    data,
    handleFileChange,
    resetForm,
    advisorOptions,
    socalOptions,
    setOpenAuditLog, reportYearDropdown
}) => {
    const resetRef = useRef<any>();
    let dbeList = advisorOptions || [];
    dbeList = dbeList.concat(socalOptions);
    if (resetRef.current) {
        mailSent == true && resetRef.current();
    }

    const [errorExists, setErrorExists] = useState(false);

    const checkErrorExists = (errors) => {
        if (errors && Object.keys(errors).length > 0) {
            setErrorExists(true);
        } else {
            setErrorExists(false);
        }
    };


    const [templateDialogOpen, openTemplateDialog] = useState(false);
    const [templateOptionSelected, setTemplateOption] = useState('');
    const [monthOptions, setMonthOptions] = useState(monthObject);

    const handleTemplateModalClose = () => openTemplateDialog(false);
    const handleTemplateModalOpen = () => openTemplateDialog(true);

    const handleTemplateModalDoneCall = () => {
        handleTemplateModalDone(handleTemplateModalClose);

        const templateSubjectVal = getTemplateSubject(templateOptionSelected);
        const templateBodyVal = getTemplateBody(templateOptionSelected);

        handleChange('subject', templateSubjectVal[0]);
        handleChange('body', templateBodyVal[0]);
    };

    const handleTemplateOptionSelectedCall = (data) => {
        setTemplateOption(data);
    };
    const [count, setCount] = useState(0)

    const toOptions = [
        {
            key: 'All Primes',
            text: 'All Primes',
            value: 'all_primes',
        },
        {
            key: 'Primes With',
            text: 'Primes With...',
            value: 'primes_with',
        },
        {
            key: 'Primes Located In',
            text: 'Primes Located In...',
            value: 'primes_located_in',
        },
        {
            key: 'By Supplier Diversity Contact',
            text: 'By Supplier Diversity Contact...',
            value: 'by_supplier',
        },
    ];

    let filteredMonthObject = monthObject;

    const filterMonthObject = (year) => {
        const date = new Date();
        const currYear = date.getFullYear();
        if (currYear === year) {
            const currMonth = date.getMonth() + 1;
            filteredMonthObject = filteredMonthObject.slice(0, currMonth);
            setMonthOptions(filteredMonthObject);
            if (
                !filteredMonthObject.some(
                    (monObj) => monObj.value === data.month
                )
            ) {
                handleChange('month', '');
            }
        } else {
            setMonthOptions(monthObject);
        }
    };

    const handleOpenAuidtLog = useCallback(() => {
        setOpenAuditLog(true);
    }, []);
    const handleCloseAuidtLog = useCallback(() => {
        setOpenAuditLog(false);
    }, [])

    return (
        <>


            <ATMContainer
                fluid
                className={styles.resetInner}
                style={{ marginBottom: '20px' }}
            >
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            width: '100%'
                        }}
                    > <div className={styles.resetPasswordHeader}>
                            <ATMHeader as="h1">{Lang.TTL_MASS_MAIL}</ATMHeader>

                        </div>
                        <Button
                            as={'label'}
                            primary
                            type="button"
                            style={{
                                margin: '2px 0px',
                                width: 'auto',
                            }}
                            onClick={() => {
                                setOpenAuditLog(true);
                            }}
                        >
                            <Button.Content
                                visible
                            >
                                {
                                    Lang.LBL_AUDIT_LOG
                                }
                            </Button.Content>
                        </Button></div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                            //paddingBottom: '15px',
                        }}
                    >


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: '13px',
                                paddingBottom: '18px',
                            }}
                        >
                            <ATMButton
                                className="ui secondary button"
                                onClick={() => {
                                    setCount(count + 1)
                                    resetForm();
                                    resetRef.current({});
                                }}
                                style={{ marginRight: '10px' }}
                            >
                                {Lang.LBL_CLEAR}
                            </ATMButton>
                            <ATMButton
                                loading={loading}
                                disabled={
                                    errorExists ||
                                    !(
                                        data.to &&
                                        data.subject &&
                                        data.body &&
                                        data.from &&
                                        (data.by_supplier_check
                                            ? data.by_supplier_check &&
                                            data.by_supplier_check_cc_bcc
                                            : true) &&
                                        (data.contact_check
                                            ? data.contact_check &&
                                            data.contact_check_cc_bcc
                                            : true) &&
                                        (data.all_check
                                            ? data.all_check &&
                                            data.all_check_cc_bcc
                                            : true)
                                    ) ||
                                    isSending ||
                                    (data.to == 'primes_with' &&
                                        (data.month == '' ||
                                            data.year == '')) ||
                                    (data.to == 'primes_located_in' &&
                                        (data.state == '' ||
                                            data.city == '')) ||
                                    (data.to == 'by_supplier' &&
                                        data.by_supplier == '')
                                }
                                className="ui primary button"
                                onClick={() => handleSubmit()}
                            >
                                {isSending ? 'Sending...' : Lang.LBL_SEND}
                            </ATMButton>
                        </div>
                    </div>
                    <ATMGrid style={{ marginRight: '0' }}>
                        <ATMGrid.Row className={styles.loginrow}>
                            <ATMGrid.Column
                                textAlign="center"
                                className={styles.signIn}
                            >
                                <div>
                                    <ATMForm
                                        key={count}
                                        ref={formRef}
                                        defaultValues={data}
                                        mode={'onChange'}
                                        validationSchema={MassMailFormSchema}
                                        onSubmit={handleSubmit}
                                        // className={styles.form}
                                        style={{ display: 'inline-block' }}
                                    >
                                        {({
                                            control,
                                            errors,
                                            reset,
                                            setValue,
                                        }) => {
                                            resetRef.current = reset;
                                            checkErrorExists(errors);
                                            return (
                                                <>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                margin: '10px 0px',
                                                            }}
                                                        >
                                                            {
                                                                Lang.LBL_MASS_MAIL_TO
                                                            }
                                                        </label>
                                                        <ATMField
                                                            style={{
                                                                marginLeft:
                                                                    '65px',
                                                                width: '19rem',
                                                            }}
                                                            control={control}
                                                            name="to"
                                                            as={ATMSelect}
                                                            placeholder={
                                                                Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                            }
                                                            options={toOptions}
                                                            error={
                                                                errors.password
                                                            }
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange(
                                                                    'to',
                                                                    data.value
                                                                );
                                                                return data.value;
                                                            }}
                                                        />
                                                        {showTo.primes_with && (
                                                            <>
                                                                <label
                                                                    style={{
                                                                        minWidth:
                                                                            '110px',
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {
                                                                        Lang.LBL_MASS_MAIL_MISSING_DATA_FOR
                                                                    }
                                                                </label>
                                                                <ATMField
                                                                    style={{
                                                                        minWidth:
                                                                            '8rem',
                                                                    }}
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="month"
                                                                    as={
                                                                        ATMSelect
                                                                    }
                                                                    render={setValue(
                                                                        'month',
                                                                        data.month
                                                                    )}
                                                                    placeholder={
                                                                        Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                                    }
                                                                    options={monthOptions.map(
                                                                        (
                                                                            item
                                                                        ) => ({
                                                                            key: item.label,
                                                                            value: item.value,
                                                                            text: item.label,
                                                                        })
                                                                    )}
                                                                    error={
                                                                        errors.month
                                                                    }
                                                                    onChange={([
                                                                        _,
                                                                        data,
                                                                    ]) => {
                                                                        handleChange(
                                                                            'month',
                                                                            data.value
                                                                        );
                                                                        return data.value;
                                                                    }}
                                                                />
                                                                <ATMField
                                                                    style={{
                                                                        minWidth:
                                                                            '8rem',
                                                                        marginLeft:
                                                                            '5px',
                                                                    }}
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="year"
                                                                    as={
                                                                        ATMSelect
                                                                    }
                                                                    placeholder={
                                                                        Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                                    }
                                                                    options={
                                                                        reportYearDropdown
                                                                    }
                                                                    error={
                                                                        errors.year
                                                                    }
                                                                    onChange={([
                                                                        _,
                                                                        data,
                                                                    ]) => {
                                                                        handleChange(
                                                                            'year',
                                                                            data.value
                                                                        );
                                                                        filterMonthObject(
                                                                            data.value
                                                                        );
                                                                        return data.value;
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                        {showTo.primes_located_in && (
                                                            <>
                                                                <label
                                                                    style={{
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {
                                                                        Lang.LBL_MASS_MAIL_STATE
                                                                    }
                                                                </label>
                                                                <ATMField
                                                                    style={{
                                                                        minWidth:
                                                                            '10rem',
                                                                    }}
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="state"
                                                                    as={
                                                                        ATMSelect
                                                                    }
                                                                    placeholder={
                                                                        Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                                    }
                                                                    options={states?.map(
                                                                        (
                                                                            item: any
                                                                        ) => ({
                                                                            key: item.state,
                                                                            value: item.state,
                                                                            text:
                                                                                item.state ===
                                                                                    ''
                                                                                    ? 'Select'
                                                                                    : item.state,
                                                                        })
                                                                    )}
                                                                    error={
                                                                        errors.state
                                                                    }
                                                                    onChange={([
                                                                        _,
                                                                        data,
                                                                    ]) => {
                                                                        handleChange(
                                                                            'state',
                                                                            data.value
                                                                        );

                                                                        return data.value;
                                                                    }}
                                                                />
                                                                <label
                                                                    style={{
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {
                                                                        Lang.LBL_MASS_MAIL_CITY
                                                                    }
                                                                </label>
                                                                <ATMField
                                                                    control={
                                                                        control
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            '10rem',
                                                                    }}
                                                                    name="city"
                                                                    as={
                                                                        ATMSelect
                                                                    }
                                                                    placeholder={
                                                                        Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                                    }
                                                                    options={cities?.map(
                                                                        (
                                                                            item: any
                                                                        ) => ({
                                                                            key: item.city,
                                                                            value: item.city,
                                                                            text:
                                                                                item.city.toUpperCase() ===
                                                                                    ''
                                                                                    ? 'Select'
                                                                                    : item.city.toUpperCase(),
                                                                        })
                                                                    )}
                                                                    error={
                                                                        errors.city
                                                                    }
                                                                    onChange={([
                                                                        _,
                                                                        data,
                                                                    ]) => {
                                                                        handleChange(
                                                                            'city',
                                                                            data.value
                                                                        );

                                                                        return data.value;
                                                                    }}
                                                                />
                                                            </>
                                                        )}

                                                        {showTo.by_supplier && (
                                                            <>
                                                                <label
                                                                    style={{
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {
                                                                        Lang.LBL_MASS_MAIL_BY_SUPPLIER
                                                                    }
                                                                </label>
                                                                <ATMField
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="by_supplier"
                                                                    as={
                                                                        ATMSelect
                                                                    }
                                                                    placeholder={
                                                                        Lang.LBL_MASS_MAIL_PLACEHOLDER_SELECT
                                                                    }
                                                                    options={dbeList?.map(
                                                                        (
                                                                            item: any
                                                                        ) => ({
                                                                            key: item.userID,
                                                                            value: item.userID,
                                                                            text:
                                                                                item.firstName ===
                                                                                    ''
                                                                                    ? 'Select'
                                                                                    : item.firstName +
                                                                                    ' ' +
                                                                                    item.lastName,
                                                                        })
                                                                    )}
                                                                    error={
                                                                        errors.by_supplier
                                                                    }
                                                                    onChange={([
                                                                        _,
                                                                        data,
                                                                    ]) => {
                                                                        handleChange(
                                                                            'by_supplier',
                                                                            data.value
                                                                        );

                                                                        return data.value;
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                margin: '10px 0px',
                                                                width: '87px',
                                                            }}
                                                        >
                                                            {
                                                                Lang.LBL_MASS_MAIL_FROM
                                                            }
                                                        </label>
                                                        <ATMField
                                                            disabled
                                                            style={{
                                                                minWidth:
                                                                    '50rem',
                                                            }}
                                                            control={control}
                                                            name="from"
                                                            as={ATMInput}
                                                            error={errors.from}
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange(
                                                                    'from',
                                                                    data.value
                                                                );
                                                                return data.value;
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                margin: '10px 0px',
                                                                width: '95px',
                                                            }}
                                                        >
                                                            {
                                                                Lang.LBL_MASS_MAIL_CC
                                                            }
                                                        </label>
                                                        <ATMField
                                                            style={{
                                                                width: '50rem',
                                                            }}
                                                            control={control}
                                                            as={ATMInput}
                                                            type="text"
                                                            name="cc"
                                                            className={
                                                                styles.passwordField
                                                            }
                                                            error={errors.cc}
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange(
                                                                    'cc',
                                                                    data.value
                                                                );
                                                                return data.value;
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                margin: '10px 0px',
                                                                width: '95px',
                                                            }}
                                                        >
                                                            {Lang.LBL_BCC}
                                                        </label>
                                                        <ATMField
                                                            style={{
                                                                width: '50rem',
                                                            }}
                                                            control={control}
                                                            as={ATMInput}
                                                            type="text"
                                                            name="bcc"
                                                            className={
                                                                styles.passwordField
                                                            }
                                                            error={errors.bcc}
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange(
                                                                    'bcc',
                                                                    data.value
                                                                );
                                                                return data.value;
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div
                                                        style={{
                                                            display: 'flex',
                                                            marginLeft: '80px',
                                                        }}
                                                    >
                                                        <ATMField
                                                            style={{
                                                                margin: '0px 10px',
                                                            }}
                                                            control={control}
                                                            as={ATMCheckbox}
                                                            name="by_supplier_check"
                                                            type="checkbox"
                                                            checked={data.by_supplier_check}
                                                            onChange={([_, data,]) => {
                                                                handleChange('by_supplier_check', data.checked);
                                                                return data.checked;
                                                            }}
                                                        />
                                                        <label style={{width: '15rem'}}>
                                                          { Lang.LBL_MASS_MAIL_BY_SUPPLIER_CHECK }
                                                        </label>
                                                        {data.by_supplier_check && (
                                                            <>
                                                                <ATMRadio
                                                                    checked={ data.by_supplier_check_cc_bcc === 'cc' ? true : false }
                                                                    onChange={() => {
                                                                        handleChange('by_supplier_check_cc_bcc', 'cc');
                                                                    }}
                                                                    value="cc"
                                                                    name="by_supplier_check_cc_bcc"
                                                                    label="CC"
                                                                />
                                                                <ATMRadio
                                                                    checked={ data.by_supplier_check_cc_bcc === 'bcc' ? true : false }
                                                                    style={{ marginLeft: '10px' }}
                                                                    onChange={() => {
                                                                        handleChange('by_supplier_check_cc_bcc', 'bcc');
                                                                    }}
                                                                    value="bcc"
                                                                    name="by_supplier_check_cc_bcc"
                                                                    label="BCC"
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{ display: 'flex', marginLeft: '80px' }}
                                                    >
                                                        <ATMField
                                                            style={{ margin: '0px 10px' }}
                                                            control={control}
                                                            as={ATMCheckbox}
                                                            name="contact_check"
                                                            checked={ data.contact_check }
                                                            type="checkbox"
                                                            onChange={([_, data]) => {
                                                                handleChange( 'contact_check', data.checked );
                                                                return data.checked;
                                                            }}
                                                        />
                                                        <label style={{width: '15rem',}}>
                                                            {Lang.LBL_MASS_MAIL_CONTACT_CHECK}
                                                        </label>
                                                        {data.contact_check && (
                                                            <>
                                                                <ATMRadio
                                                                    checked={ data.contact_check_cc_bcc ===  'cc' ? true : false}
                                                                    onChange={() => {
                                                                        handleChange('contact_check_cc_bcc', 'cc');
                                                                    }}
                                                                    value="cc"
                                                                    name="contact_check_cc_bcc"
                                                                    label="CC"
                                                                />
                                                                <ATMRadio
                                                                    style={{marginLeft: '10px'}}
                                                                    checked={ data.contact_check_cc_bcc === 'bcc' ? true : false }
                                                                    onChange={() => {
                                                                        handleChange('contact_check_cc_bcc', 'bcc');
                                                                    }}
                                                                    value="bcc"
                                                                    name="contact_check_cc_bcc"
                                                                    label="BCC"
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{display: 'flex', marginLeft: '80px'}}
                                                    >
                                                        <ATMField
                                                            style={{margin: '0px 10px'}}
                                                            control={control}
                                                            as={ATMCheckbox}
                                                            name="all_check"
                                                            checked={ data.all_check }
                                                            type="checkbox"
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange('all_check', data.checked);
                                                                return data.checked;
                                                            }}
                                                        />
                                                        <label
                                                            style={{
                                                                width: '15rem',
                                                            }}
                                                        >
                                                            { Lang.LBL_MASS_MAIL_ALL_CHECK}
                                                        </label>
                                                        {data.all_check && (
                                                            <>
                                                                <ATMRadio
                                                                    checked={ data.all_check_cc_bcc === 'cc' ? true : false }
                                                                    onChange={() => {
                                                                        handleChange('all_check_cc_bcc', 'cc');
                                                                    }}
                                                                    value="cc"
                                                                    name="all_check_cc_bcc"
                                                                    label="CC"
                                                                />
                                                                <ATMRadio
                                                                    style={{marginLeft: '10px'}}
                                                                    checked={ data.all_check_cc_bcc === 'bcc' ? true : false}                                                                     
                                                                    onChange={() => {
                                                                        handleChange('all_check_cc_bcc', 'bcc');
                                                                    }}
                                                                    value="bcc"
                                                                    name="all_check_cc_bcc"
                                                                    label="BCC"
                                                                />
                                                            </>
                                                        )}
                                                    </div> */}
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                margin: '10px 0px',
                                                                width: '95px',
                                                            }}
                                                        >
                                                            {
                                                                Lang.LBL_MASS_MAIL_SUBJECT
                                                            }
                                                        </label>
                                                        <ATMField
                                                            style={{
                                                                width: '50rem',
                                                            }}
                                                            control={control}
                                                            as={ATMInput}
                                                            type="text"
                                                            name="subject"
                                                            render={setValue(
                                                                'subject',
                                                                data.subject
                                                            )}
                                                            className={
                                                                styles.passwordField
                                                            }
                                                            error={
                                                                errors.subject
                                                            }
                                                            onChange={([
                                                                _,
                                                                data,
                                                            ]) => {
                                                                handleChange(
                                                                    'subject',
                                                                    data.value
                                                                );
                                                                return data.value;
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: '10px 0px',
                                                                width: '88px',
                                                            }}
                                                        >
                                                            <label>
                                                                {
                                                                    Lang.LBL_MASS_MAIL_BODY
                                                                }
                                                            </label>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                width: '100%',
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                        >
                                                            <Button
                                                                as={'label'}
                                                                primary
                                                                type="button"
                                                                style={{
                                                                    margin: '2px 0px',
                                                                    width: 'auto',
                                                                }}
                                                                onClick={() => {
                                                                    handleTemplateModalOpen();
                                                                }}
                                                            >
                                                                <Button.Content
                                                                    visible
                                                                >
                                                                    {
                                                                        Lang.LBL_EMAIL_TEMPLATES
                                                                    }
                                                                </Button.Content>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            marginBottom:
                                                                '1rem',
                                                            marginLeft: '80px',
                                                        }}
                                                    >
                                                        <ATMField
                                                            style={{
                                                                width: '50rem',
                                                                minHeight:
                                                                    '6em',
                                                                height: '6em',
                                                                marginLeft: 7,
                                                            }}
                                                            control={control}
                                                            as={'textarea'}
                                                            value={data.body}
                                                            name="body"
                                                            className={
                                                                styles.passwordField
                                                            }
                                                            error={errors.body}
                                                            render={setValue(
                                                                'body',
                                                                data.body
                                                            )}
                                                            onChange={([e]) => {
                                                                handleChange(
                                                                    'body',
                                                                    e.target
                                                                        .value
                                                                );
                                                                return e.target
                                                                    .value;
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.fieldContainer
                                                        }
                                                    >
                                                        <label
                                                            style={{
                                                                width: '88px',
                                                            }}
                                                        ></label>

                                                        <Button
                                                            as={'label'}
                                                            secondary
                                                            htmlFor="file"
                                                            type="button"
                                                            style={{
                                                                width: 'auto',
                                                            }}
                                                        >
                                                            <Button.Content
                                                                visible
                                                            >
                                                                <i
                                                                    className="linkify icon"
                                                                    style={{
                                                                        color: '#009BDA',
                                                                    }}
                                                                ></i>
                                                                {
                                                                    Lang.LBL_MASS_MAIL_ATTACH_FILE
                                                                }
                                                            </Button.Content>
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            id="file"
                                                            value=""
                                                            hidden
                                                            multiple
                                                            onChange={
                                                                handleFileChange
                                                            }
                                                        />
                                                        {data.attachments
                                                            .length > 0 &&
                                                            data.attachments.map(
                                                                (
                                                                    item: any,
                                                                    index: any
                                                                ) => (
                                                                    <ATMLabel
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {`${item.fileName} (${item.size})`}
                                                                    </ATMLabel>
                                                                )
                                                            )}
                                                    </div>
                                                </>
                                            );
                                        }}
                                    </ATMForm>
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <Dialog
                        open={templateDialogOpen}
                        handleModalClose={handleTemplateModalClose}
                        handleModalDone={handleTemplateModalDoneCall}
                        handleTemplateOptionSelected={
                            handleTemplateOptionSelectedCall
                        }
                    />
                </>
            </ATMContainer>
        </>
    );
};

export default MassMailView;

import React, { useEffect } from 'react';
import {
    ATMButton,
    ATMContainer,
    ATMGrid,
    ATMIcon,
    ATMSelect,
} from 'shared-it-appmod-ui';
import style from '../report.module.scss';
import Lang from 'src/libraries/language';
import Instruction from 'src/components/atoms/instruction/instruction.component';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole, statusTypes } from 'src/constants/access.constant';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
const ReportHead: React.FC<any> = ({
    reportType,
    reload,
    subcontractorsOptions,
    handleSubcontractorChange,
    subcontractorsSelected,
    contractorsSelected,
    handleClearSubcontractors,
    contractsOptions,
    handleContractsChange,
    handleClearContracts,
    handleDownload,
    primeOptions,
    handlePrimesFilter,
    primesSelected,
    handleClearPrimes,
    handleSupplierDiversityFilter,
    supplierDiversitySelected,
    handleClearFilter,
    supplierDiversityOptions,
    handleStatusChange,
    selectedStatus,
    handlePrimesAdminFilter,
    utilityOptions,
    handleUtilityChange,
    selectedUtility,
    verificationFollowupList,
    handleChangeVerificationExpDate,
    verificationExpDates,
    handleClearVerificationExpDates,
    handleSpendAmountFilter,
    handleSupplierDiversityAdminFilter,
    handlePrimesAdminNeedFollowUpFilter,
    spendAmount,
    handleClearAdminVerificationFollowUp,
    handleClearPrimeChangestoSpendAmount,
    handleSpendDateRangeChange,
    spendDateRange,
    resetPagination,
    setResetPagination,
    currentApiYear, selectSupplierDiversity
}) => {
    const isPrime = AccessRole.PRIME === getUserType();
    const minDate = new Date(currentApiYear, 0, 1);
    const maxDate = new Date(currentApiYear, 11, 31);
    let primeOps = primeOptions || [];
    primeOps.sort((a, b) => a.text.localeCompare(b.text));
    primeOps.splice(0, 0, {
        key: 'favorites',
        value: 'favorites',
        text: 'Favorites',
    });
    if (primeOps.length > 0) {
        primeOps = primeOps.filter((element) => {
            if (Object.keys(element).length !== 0) {
                return true;
            }
            return false;
        });
    }
    const contractOps = contractsOptions || [];
    contractOps.sort((a, b) => a.text.localeCompare(b.text));

    const subcontractorOps = subcontractorsOptions || [];
    subcontractorOps.sort((a, b) => a.text.localeCompare(b.text));

    const subOPs = supplierDiversityOptions || [];
    subOPs.sort((a, b) => a.text.localeCompare(b.text));

    useEffect(() => {
        supplierDiversitySelected.map((diversitySelected) => {
            const diversityFound = subOPs.some(item => item.value === diversitySelected)
            if (diversityFound) {
                const updatedArray = supplierDiversitySelected.filter(item => item !== diversitySelected);
                selectSupplierDiversity(updatedArray);
            }
        })
    }, [subOPs])

    const verificationExpStatusOps =
        verificationFollowupList?.filters_data?.verificationExpirationDue || [];
    verificationExpStatusOps.sort((a, b) => a.text.localeCompare(b.text));

    const spendAmountOps =
        verificationFollowupList?.filters_data?.spendAmount || [];

    const statusOps = statusTypes || [];
    statusOps.sort((a, b) => a.text.localeCompare(b.text));

    const utilityOps = utilityOptions || [];
    utilityOps.sort((a, b) => a.text.localeCompare(b.text));
    return (
        <>
            {!!reportType && (
                <>
                    {reportType !== 1 && (
                        <ATMContainer fluid className={style.content}>
                            <Instruction
                                title={Lang.LBL_NOTE}
                                content={Lang.MSG_REPORTS_INSTRUCTION}
                            />
                        </ATMContainer>
                    )}
                    <ATMContainer fluid>
                        <ATMGrid>
                            <ATMGrid.Row className={style.headerRow}>
                                <ATMGrid.Row className={style.alignCenter}>
                                    <div style={{ marginRight: '2rem' }}>
                                        <span
                                            style={{
                                                fontSize: '13px',
                                                fontWeight: 700,
                                                fontFamily: 'Lato',
                                                width: '11rem',
                                            }}
                                        >
                                            Reporting Year:&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {currentApiYear}
                                        </span>
                                    </div>
                                    <div className={style.reportFiltersWrapper}>
                                        {reportType === 3 && !reload && (
                                            <>
                                                <span
                                                    style={{
                                                        fontSize: 13,
                                                        fontWeight: 'bold',
                                                        color: '#333333DE',
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    Utility
                                                </span>
                                                <ATMSelect
                                                    placeholder="All"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        maxWidth: '55rem',
                                                        minWidth: '9rem',
                                                    }}
                                                    value={selectedUtility}
                                                    options={utilityOps}
                                                    compact
                                                    onChange={(i, v) => {
                                                        handleUtilityChange(
                                                            v.value
                                                        );
                                                        setResetPagination(
                                                            true
                                                        );
                                                    }
                                                    }
                                                />
                                            </>
                                        )}
                                        &nbsp; &nbsp;

                                        {reportType === 4 && !reload && isPrime && (
                                            <>
                                                <span
                                                    style={{
                                                        fontSize: 13,
                                                        fontWeight: 'bold',
                                                        color: '#333333DE',
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    Utility
                                                </span>
                                                <ATMSelect
                                                    placeholder="All"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        maxWidth: '55rem',
                                                        minWidth: '9rem',
                                                    }}
                                                    value={selectedUtility}
                                                    options={utilityOps}
                                                    compact
                                                    onChange={(i, v) => {
                                                        handleUtilityChange(
                                                            v.value
                                                        );
                                                        setResetPagination(
                                                            true
                                                        );
                                                    }
                                                    }
                                                />
                                            </>
                                        )}
                                        &nbsp; &nbsp;
                                        {(reportType === 1 ||
                                            reportType === 3) &&
                                            !reload &&
                                            isPrime && (
                                                <>
                                                    <span
                                                        style={{
                                                            marginRight: 10,
                                                            fontSize: '13px',
                                                            fontWeight: 700,
                                                            fontFamily: 'Lato',
                                                            color: '#333333DE',
                                                        }}
                                                    >
                                                        Subcontractor
                                                    </span>
                                                    <ATMSelect
                                                        placeholder="All"
                                                        options={
                                                            subcontractorOps
                                                        }
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: '#606060',
                                                            maxWidth: '55rem',
                                                            minWidth: '12rem',
                                                        }}
                                                        compact
                                                        multiple
                                                        onChange={(i, v) =>
                                                            handleSubcontractorChange(
                                                                v.value
                                                            )
                                                        }
                                                    />
                                                    {reportType === 3 &&
                                                        !reload &&
                                                        isPrime && (
                                                            <>
                                                                &nbsp; &nbsp;
                                                                <span
                                                                    style={{
                                                                        marginRight: 10,
                                                                        fontSize:
                                                                            '13px',
                                                                        fontWeight: 700,
                                                                        fontFamily:
                                                                            'Lato',
                                                                        color: '#333333DE',
                                                                    }}
                                                                >
                                                                    Contracts
                                                                </span>
                                                                <ATMSelect
                                                                    placeholder="All"
                                                                    style={{
                                                                        fontWeight:
                                                                            'bold',
                                                                        maxWidth:
                                                                            '55rem',
                                                                        minWidth:
                                                                            '9rem',
                                                                    }}
                                                                    options={
                                                                        contractOps
                                                                    }
                                                                    compact
                                                                    multiple
                                                                    onChange={(
                                                                        i,
                                                                        v
                                                                    ) =>
                                                                        handleContractsChange(
                                                                            v.value
                                                                        )
                                                                    }
                                                                />
                                                                &nbsp; &nbsp;
                                                                <span
                                                                    style={{
                                                                        marginRight: 10,
                                                                        fontSize:
                                                                            '13px',
                                                                        fontWeight: 700,
                                                                        fontFamily:
                                                                            'Lato',
                                                                        color: '#333333DE',
                                                                    }}
                                                                >
                                                                    Date
                                                                </span>
                                                                <SemanticDatepicker
                                                                    pointing="top left"
                                                                    className={
                                                                        style.datepicker
                                                                    }
                                                                    datePickerOnly={
                                                                        true
                                                                    }
                                                                    type="range"
                                                                    minDate={
                                                                        minDate
                                                                    }
                                                                    maxDate={
                                                                        maxDate
                                                                    }
                                                                    placeholder="Select From and To Date"
                                                                    name="spend_date"
                                                                    format="MM/DD/YYYY"
                                                                    onChange={(
                                                                        event,
                                                                        data
                                                                    ) => {
                                                                        handleSpendDateRangeChange(
                                                                            data.value
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    &nbsp; &nbsp;
                                                    {(subcontractorsSelected.length >
                                                        0 ||
                                                        contractorsSelected.length >
                                                        0 ||
                                                        spendDateRange.length >
                                                        0) && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() =>
                                                                    reportType === 3
                                                                        ? handleClearPrimeChangestoSpendAmount()
                                                                        : handleClearSubcontractors()
                                                                }
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}

                                        {(reportType === 4 ||
                                            reportType === 5 ||
                                            reportType === 1 ||
                                            reportType === 3) &&
                                            !reload &&
                                            !isPrime && (
                                                <>
                                                    <span
                                                        style={{
                                                            marginRight: 10,
                                                            fontSize: '13px',
                                                            fontWeight: 700,
                                                            fontFamily: 'Lato',
                                                            color: '#333333DE',
                                                        }}
                                                    >
                                                        Prime
                                                    </span>
                                                    <ATMSelect
                                                        placeholder="All"
                                                        options={primeOps}
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            color: '#606060',
                                                            maxWidth: '55rem',
                                                            minWidth: '12rem',
                                                        }}
                                                        defaultSelected={'ALL'}
                                                        compact
                                                        multiple
                                                        onChange={(i, v) => {
                                                            handlePrimesFilter(
                                                                v.value
                                                            );
                                                            setResetPagination(
                                                                true
                                                            );
                                                        }

                                                        }
                                                    />
                                                    &nbsp; &nbsp;
                                                    {(subcontractorsSelected.length >
                                                        0 ||
                                                        contractorsSelected.length >
                                                        0 ||
                                                        primesSelected.length >
                                                        0) &&
                                                        reportType != 1 &&
                                                        reportType != 5 &&
                                                        reportType != 3 &&
                                                        reportType != 4 && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() => {
                                                                    handleClearSubcontractors();
                                                                    handleClearPrimes();
                                                                }}
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}

                                        {reportType === 2 && !reload && (
                                            <div
                                                className={style.verFollowupDiv}
                                            >
                                                {!isPrime && (
                                                    <>
                                                        <div
                                                            className={
                                                                style.verFollowupDivWrapper
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    style.verFollowupLabel
                                                                }
                                                            >
                                                                Prime
                                                            </span>
                                                            <ATMSelect
                                                                placeholder="All"
                                                                options={
                                                                    primeOps
                                                                }
                                                                className={
                                                                    style.verFollowupFields
                                                                }
                                                                compact
                                                                name="clear"
                                                                onChange={(
                                                                    i,
                                                                    v
                                                                ) => {
                                                                    handlePrimesAdminNeedFollowUpFilter(
                                                                        v.value
                                                                    );
                                                                    setResetPagination(
                                                                        true
                                                                    );
                                                                    return v.value;
                                                                }}
                                                                multiple
                                                            />
                                                        </div>
                                                        &nbsp; &nbsp;
                                                        <div
                                                            className={
                                                                style.verFollowupDivWrapper
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    style.verFollowupLabel
                                                                }
                                                            >
                                                                Supplier
                                                                Diversity
                                                                Contact/Contacts
                                                            </span>
                                                            <ATMSelect
                                                                placeholder="All"
                                                                options={subOPs}
                                                                className={
                                                                    style.verFollowupFields
                                                                }
                                                                multiple
                                                                compact
                                                                name="clear"
                                                                onChange={(
                                                                    i,
                                                                    v
                                                                ) => {
                                                                    handleSupplierDiversityAdminFilter(
                                                                        v.value
                                                                    );
                                                                    setResetPagination(
                                                                        true
                                                                    );
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                &nbsp; &nbsp;
                                                <div
                                                    className={
                                                        style.verFollowupDivWrapper
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            style.verFollowupLabel
                                                        }
                                                    >
                                                        Verification Expiration
                                                        Status
                                                    </span>
                                                    <ATMSelect
                                                        multiple
                                                        placeholder="All"
                                                        options={verificationExpStatusOps?.map(
                                                            (item) => ({
                                                                key: item.key,
                                                                value: item.key,
                                                                text: item.text,
                                                            })
                                                        )}
                                                        className={
                                                            style.verFollowupFields
                                                        }
                                                        compact
                                                        name="clear"
                                                        onChange={(i, v) => {
                                                            handleChangeVerificationExpDate(
                                                                v.value
                                                            )
                                                            setResetPagination(
                                                                true
                                                            );
                                                        }
                                                        }
                                                    />
                                                </div>
                                                &nbsp; &nbsp;
                                                {!isPrime && (
                                                    <>
                                                        <div
                                                            className={
                                                                style.verFollowupDivWrapper
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    style.verFollowupLabel
                                                                }
                                                            >
                                                                Spend Amounts
                                                            </span>
                                                            <ATMSelect
                                                                placeholder="All"
                                                                options={spendAmountOps?.map(
                                                                    (item) => ({
                                                                        key: item.id,
                                                                        value: item.id,
                                                                        text: item.name,
                                                                    })
                                                                )}
                                                                className={
                                                                    style.verFollowupFields
                                                                }
                                                                compact
                                                                name="clear"
                                                                onChange={(
                                                                    i,
                                                                    v
                                                                ) => {
                                                                    handleSpendAmountFilter(
                                                                        v.value
                                                                    );
                                                                    setResetPagination(
                                                                        true
                                                                    );
                                                                    return v.value;
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                &nbsp; &nbsp;
                                                {(verificationExpDates.length >
                                                    0 ||
                                                    primesSelected.length > 0 ||
                                                    supplierDiversitySelected.length >
                                                    0 ||
                                                    spendAmount == '1') && (
                                                        <ATMButton
                                                            color="blue"
                                                            secondary
                                                            onClick={() =>
                                                                !isPrime
                                                                    ? handleClearAdminVerificationFollowUp()
                                                                    : handleClearVerificationExpDates()
                                                            }
                                                        >
                                                            {Lang.LBL_CLEAR}
                                                        </ATMButton>
                                                    )}
                                            </div>
                                        )}

                                        {reportType === 5 &&
                                            !reload &&
                                            !isPrime && (
                                                <>
                                                    <span
                                                        style={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color: '#333333DE',
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        Contract
                                                    </span>
                                                    <ATMSelect
                                                        placeholder="All"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            maxWidth: '55rem',
                                                            minWidth: '9rem',
                                                        }}
                                                        options={contractOps}
                                                        compact
                                                        multiple
                                                        onChange={(i, v) => {
                                                            handleContractsChange(
                                                                v.value
                                                            );
                                                            setResetPagination(
                                                                true
                                                            );
                                                        }

                                                        }
                                                    />
                                                    &nbsp; &nbsp;
                                                    {contractorsSelected.length >
                                                        0 &&
                                                        reportType != 5 && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() =>
                                                                    handleClearContracts()
                                                                }
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}
                                        {reportType === 4 &&
                                            !reload &&
                                            isPrime && (
                                                <>
                                                    <span
                                                        style={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color: '#333333DE',
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        Contract
                                                    </span>
                                                    <ATMSelect
                                                        placeholder="All"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            maxWidth: '55rem',
                                                            minWidth: '9rem',
                                                        }}
                                                        options={contractOps}
                                                        compact
                                                        multiple
                                                        onChange={(i, v) => {
                                                            handleContractsChange(
                                                                v.value
                                                            );
                                                            setResetPagination(
                                                                true
                                                            );
                                                        }
                                                        }
                                                    />
                                                    &nbsp; &nbsp;
                                                    {contractorsSelected.length >
                                                        0 && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() =>
                                                                    handleClearContracts()
                                                                }
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}
                                        {(reportType === 5 || reportType === 4) &&
                                            !reload &&
                                            !isPrime && (
                                                <>
                                                    <span
                                                        style={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color: '#333333DE',
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        Utility
                                                    </span>
                                                    <ATMSelect
                                                        placeholder="All"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            maxWidth: '55rem',
                                                            minWidth: '9rem',
                                                        }}
                                                        value={selectedUtility}
                                                        options={utilityOps}
                                                        compact
                                                        onChange={(i, v) => {
                                                            handleUtilityChange(
                                                                v.value
                                                            );
                                                            setResetPagination(
                                                                true
                                                            );
                                                        }
                                                        }
                                                    />
                                                    &nbsp; &nbsp;
                                                    {contractorsSelected.length >
                                                        0 &&
                                                        reportType != 5 && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() =>
                                                                    handleClearContracts()
                                                                }
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}
                                        {(reportType === 5 ||
                                            reportType === 1 ||
                                            reportType === 3 ||
                                            reportType === 4) &&
                                            !reload &&
                                            !isPrime && (
                                                <>
                                                    <div>
                                                        <span
                                                            style={{
                                                                marginRight: 10,
                                                                marginLeft: 5,
                                                                fontSize:
                                                                    '13px',
                                                                fontWeight: 700,
                                                                fontFamily:
                                                                    'Lato',
                                                                color: '#333333DE',
                                                            }}
                                                        >
                                                            {`Supplier Diversity ${reportType === 1
                                                                ? 'Contact/Contacts'
                                                                : 'Contact/Contacts'
                                                                }`}
                                                        </span>
                                                        <ATMSelect
                                                            placeholder="All"
                                                            options={subOPs}
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#606060',
                                                                maxWidth:
                                                                    '30rem',
                                                                minWidth:
                                                                    '12rem',
                                                            }}
                                                            compact
                                                            multiple
                                                            name="clear"
                                                            onChange={(i, v) => {
                                                                handleSupplierDiversityFilter(
                                                                    v.value
                                                                );
                                                                setResetPagination(
                                                                    true
                                                                );
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    &nbsp; &nbsp;
                                                    {reportType === 1 && (
                                                        <>
                                                            <span
                                                                style={{
                                                                    marginRight: 10,
                                                                    fontSize:
                                                                        '13px',
                                                                    fontWeight: 700,
                                                                    fontFamily:
                                                                        'Lato',
                                                                    color: '#333333DE',
                                                                }}
                                                            >
                                                                Status
                                                            </span>
                                                            <ATMSelect
                                                                placeholder="All"
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    maxWidth:
                                                                        '55rem',
                                                                    minWidth:
                                                                        '9rem',
                                                                }}
                                                                value={
                                                                    selectedStatus
                                                                }
                                                                options={
                                                                    statusOps
                                                                }
                                                                compact
                                                                onChange={(
                                                                    i,
                                                                    v
                                                                ) => {
                                                                    handleStatusChange(
                                                                        v.value
                                                                    );
                                                                    setResetPagination(
                                                                        true
                                                                    );
                                                                }

                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    &nbsp; &nbsp;
                                                    {(supplierDiversitySelected?.length >
                                                        0 ||
                                                        selectedStatus ||
                                                        primesSelected.length >
                                                        0 ||
                                                        contractorsSelected.length >
                                                        0 ||
                                                        selectedUtility) && (
                                                            <ATMButton
                                                                color="blue"
                                                                secondary
                                                                onClick={() => {
                                                                    handleClearFilter();
                                                                }}
                                                            >
                                                                {Lang.LBL_CLEAR}
                                                            </ATMButton>
                                                        )}
                                                </>
                                            )}
                                    </div>
                                </ATMGrid.Row>
                                <ATMGrid.Row className={style.alignCenter}>
                                    <ATMButton
                                        secondary
                                        onClick={() =>
                                            handleDownload(reportType)
                                        }
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ATMIcon name="download" />
                                        {Lang.LBL_DOWNLOAD}
                                    </ATMButton>
                                </ATMGrid.Row>
                            </ATMGrid.Row>
                        </ATMGrid>
                        <ATMGrid style={{ height: '100%' }}></ATMGrid>
                    </ATMContainer>
                </>
            )}
        </>
    );
};

export default ReportHead;

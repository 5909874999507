import moment from 'moment-timezone';
import React from 'react';
import { ATMIcon, ATMModal, IORGDataTableColumn, IORGDataTableQueryState, ORGDataTable } from 'shared-it-appmod-ui';
import { concatinateArrayWithSpace } from 'src/components/pages/utils/ArrayUtils';
import Lang from 'src/libraries/language';

interface IProps {
    open: boolean;
    handleClose: () => void;
    loading: boolean;
    fetchData: (params: IORGDataTableQueryState) => void;
    totalRecord: number;
    data: any

}



const BlockSpendFileGenerationAuditLogView: React.FC<IProps> = ({ open,
    handleClose,
    loading,
    fetchData,
    totalRecord,
    data }) => {
    const columns: IORGDataTableColumn<any>[] = [
        {
            title: Lang.TTL_AUTOMATED_SPEND_FILE_GENERATION_STATUS,
            index: 'status',
            render: (_, value) => <span>{value.status ? Lang.TTL_AUTOMATED_SPEND_FILE_GENERATION_DISABLED : Lang.TTL_AUTOMATED_SPEND_FILE_GENERATION_ENABLED}</span>,
        },
        {
            title: Lang.TTL_DONE_BY,
            index: 'done_by',
            render: (_, value) => <span>{concatinateArrayWithSpace([value.firstName, value.lastName]) === '' ? '-' : concatinateArrayWithSpace([value.firstName, value.lastName])}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        }, {
            title: Lang.TTL_TIME_STAMP,
            index: 'timestamp',
            render: (_, value) => <span>{`${value?.loggedAt && value?.loggedAt != '-' ? moment.utc(value?.loggedAt).format('MM/DD/YYYY hh:mm A') : '-'}`}</span>,
            headerProps: {
                //className: Style.subcontractor,
            },
        },
    ];
    return (
        <ATMModal
            size="large"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                {Lang.TTL_AUTO_SPEND_FILE_AUDIT_LOGS}
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060', position: 'absolute', 'right': '0', top: '5px', cursor: 'pointer' }} />

            </ATMModal.Header>

            <ATMModal.Content>
                <ORGDataTable
                    celled={false}
                    columns={columns}
                    data={data}
                    loading={loading}
                    sortable
                    counter
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchData}
                    total={totalRecord}
                    key={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                />
            </ATMModal.Content>
        </ATMModal>
    )
}

export default BlockSpendFileGenerationAuditLogView
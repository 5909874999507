import React from 'react';
import { Container } from 'semantic-ui-react';
import {
    ORGDataTable,
    IORGDataTableColumn,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
    ATMGrid,
    ATMSelect,
    ATMInput,
    ATMField,
    ATMCheckbox,
    ATMButton,
    ATMResponsive,
    ATMForm
} from 'shared-it-appmod-ui';
import style from './primes-contact.module.scss';
import Lang from 'src/libraries/language';
import { IContactUser } from 'src/models/contactuser.model';
import { alphaNumericStartsWith, status } from 'src/constants';

export type IProps = {
    isAdmin: boolean;
    isVisible: {
        success: boolean;
        error: boolean;
    };
    data: IContactUser[];
    loading: boolean;
    fetchData: (params: IORGDataTableQueryState) => void;
    handleDismiss: () => void;
    handlePrimeClick: (vendorId: number) => void;
    params?: IORGDataTableQueryState;
    totalRecord: number;
    handleMassUpdateModalOpen: (state: boolean) => void;
};

const PrimesContactView: React.FC<IProps> = ({
    isAdmin,
    data,
    fetchData,
    loading,
    handlePrimeClick,
    totalRecord,
    handleMassUpdateModalOpen,
}) => {
    const columns: IORGDataTableColumn<IContactUser>[] = [
        {
            title: Lang.TTL_PRIME_CONTACT,
            index: 'name',
            render: (_, value) => {
                return !isAdmin ? (
                    <span className={style.rowData || '-'}>{value.name}</span>
                ) : (
                    <span className={style.rowData}>
                        <i
                            style={{
                                color: '#009BDA',
                                fontWeight: 'bold',
                                fontStyle: 'normal',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                handlePrimeClick(value.vendor_id || 0)
                            }
                        >
                            {value.name}
                        </i>
                    </span>
                );
            },
            headerProps: {
                className: style.prime,
            },
        },
        {
            title: (
                <span>
                    {Lang.TTL_PRIME_CONTACT_SUPPLIER_DIVERSITY} <br />{' '}
                    {Lang.TTL_PRIME_CONTACT_CONTACT} {` (${'SDG&E'})`}
                </span>
            ),
            index: 'sdge_adv_name',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.sdge_adv_name.trim().length > 0
                        ? value.sdge_adv_name
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.sdge,
            },
        },
        {
            title: (
                <span>
                    {Lang.TTL_PRIME_CONTACT_SUPPLIER_DIVERSITY} <br />{' '}
                    {Lang.TTL_PRIME_CONTACT_CONTACT}
                    {` (${'SoCalGas'})`}
                </span>
            ),
            //isVisible:isAdmin,
            index: 'socal_adv_name',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.socal_adv_name.trim().length > 0
                        ? value.socal_adv_name
                        : '-'}
                </span>
            ),
            headerProps: {
                className: style.sdge,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_1,
            index: 'contact1',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact1?.trim().length ? value.contact1 : '-'}
                </span>
            ),
            headerProps: {
                className: style.contact,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_2,
            index: 'contact2',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact2?.trim().length ? value.contact2 : '-'}
                </span>
            ),
            headerProps: {
                className: style.contact,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_3,
            index: 'contact3',
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact3?.trim().length ? value.contact3 : '-'}
                </span>
            ),
            headerProps: {
                className: style.contact,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_FAVOURITES,
            index: 'favourite',
            render: (_, value) => (
                <span
                    className={style.rowData}
                    style={{ position: 'relative', left: '-5px' }}
                >
                    {value.favourite === 1 ? (
                        <>
                            <i
                                className="star icon"
                                style={{ color: '#CDA000' }}
                            ></i>
                            <span>Favorite</span>
                        </>
                    ) : (
                        '-'
                    )}
                </span>
            ),
            headerProps: {
                className: style.favorite,
            },
        },
        {
            title: Lang.LBL_CONTACT_PRIMES_STATUS,
            index: 'active',
            render: (_, value) => (
                <span className={style.rowData}>
                    <span
                        className={
                            value.active === false
                                ? style.inactiveContract
                                : style.activeContract
                        }
                    ></span>
                    {`${value.active === false ? 'Inactive' : 'Active'}`}
                </span>
            ),
            headerProps: {
                className: style.status,
            },
        },
    ];
    return (
        <Container fluid className={style.wrapper}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                    {Lang.LBL_PRIME_HEADING}
                </ATMHeader>
                {isAdmin && (
                    <ATMButton
                        secondary
                        content={Lang.LBL_MASS_UPDATE}
                        onClick={() => {
                            handleMassUpdateModalOpen(true);
                        }}
                    />
                )}
            </div>
            <ATMSegment>
                <ORGDataTable
                    columns={columns}
                    data={data}
                    loading={!data?.length && loading}
                    sortable
                    toolbar
                    history
                    counter
                    filters={true}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    onChange={fetchData}
                    total={totalRecord}
                    rowsPerPageOptions={[10, 20, 25]}
                    filteredBy={(filterList) =>
                        (filterList || []).map(
                            (filter) =>
                                Lang.PRIME_ANDCONTACT_FILTER_LABEL[
                                filter.name
                                ] ?? filter.name
                        )
                    }
                >
                    {/* {() => ({
                        filters: ({ values, setValue }) => {
                            return (
                                <ATMGrid
                                    columns={5}
                                    className={style.filters}
                                    largeScreen="16"
                                    stretched
                                    doubling
                                    stackable
                                >
                                    <ATMGrid.Column width="3">
                                        <label>
                                            {
                                                Lang.LBL_CONTACT_PRIMES_FILTER_NAME_BEGIN_WITH
                                            }
                                        </label>
                                        <ATMSelect
                                            placeholder={
                                                Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                            }
                                            name="name_begin_with"
                                            value={values.name_begin_with}
                                            options={alphaNumericStartsWith.map(
                                                (type) => ({
                                                    key: type.key,
                                                    value: type.value,
                                                    text: type.text,
                                                })
                                            )}
                                            onChange={(_, val) => {
                                                setValue(
                                                    'name_begin_with',
                                                    val.value
                                                );
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column width="3">
                                        <label>
                                            {Lang.LBL_PRIME_NAME_CONTAINS}
                                        </label>
                                        <ATMInput
                                            value={values.name_contains}
                                            name="name_contains"
                                            placeholder={Lang.LBL_PRIME_ENTER}
                                            onChange={(_, val) =>
                                                setValue(
                                                    'name_contains',
                                                    val.value
                                                )
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        width="3"
                                        style={{
                                            paddingRight: '3px',
                                            marginRight: '13px',
                                        }}
                                    >
                                        <label>
                                            {
                                                Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG
                                            }
                                        </label>
                                        <ATMInput
                                            value={values.sdge_name}
                                            name="sdge_name"
                                            placeholder={Lang.LBL_PRIME_ENTER}
                                            onChange={(_, val) =>
                                                setValue('sdge_name', val.value)
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        width="3"
                                        className={style.sdgeSocal}
                                        style={{
                                            paddingRight: '3px',
                                            marginRight: '13px',
                                        }}
                                    >
                                        <label>
                                            {
                                                Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG
                                            }
                                        </label>
                                        <ATMInput
                                            value={values.socal_name}
                                            name="socal_name"
                                            placeholder={Lang.LBL_PRIME_ENTER}
                                            onChange={(_, val) =>
                                                setValue(
                                                    'socal_name',
                                                    val.value
                                                )
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column width="3">
                                        <label>{Lang.LBL_PRIME_STATUS}</label>
                                        <ATMSelect
                                            placeholder={Lang.LBL_PRIME_SELECT}
                                            name="is_active"
                                            value={+values.is_active}
                                            options={status.map((type) => ({
                                                key: type.key,
                                                value: type.value,
                                                text: type.text,
                                            }))}
                                            onChange={(_, val) => {
                                                setValue(
                                                    'is_active',
                                                    val.value
                                                );
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column width="3">
                                        <label>
                                            {Lang.LBL_CONTACT_NAME_CONTAINS}
                                        </label>
                                        <ATMInput
                                            placeholder={Lang.LBL_PRIME_ENTER}
                                            value={values.contact_name_contains}
                                            name="contact_name_contains"
                                            onChange={(_, val) =>
                                                setValue(
                                                    'contact_name_contains',
                                                    val.value
                                                )
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        width="3"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: '35px',
                                        }}
                                    >
                                        <ATMField
                                            style={{ width: '100%' }}
                                            name="favourite"
                                            as={ATMCheckbox}
                                            type="checkbox"
                                            checked={
                                                Number(values.favourite)
                                                    ? true
                                                    : false
                                            }
                                            onChange={() => {
                                                return setValue(
                                                    'favourite',
                                                    +values.favourite ? '' : 1
                                                );
                                            }}
                                        />
                                        <label
                                            style={{
                                                marginLeft: '-60%',
                                                width: '30%',
                                            }}
                                        >
                                            {Lang.LBL_CONTACT_PRIMES_FAVOURITES}
                                        </label>
                                    </ATMGrid.Column>
                                </ATMGrid>
                            );
                        },
                    })} */}


                    {() => ({ 
                        filters: {
                            name_begin_with: ({ value, setValue }) => (
                                <>
                                    <ATMResponsive greaterThan="mobile">
                                        {' '}
                                        <ATMForm.Field>
                                            <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FILTER_NAME_BEGIN_WITH}</span>
                                            <ATMSelect
                                                placeholder={
                                                    Lang.LBL_SUBCONTRACTOR_FILTER_SELECT
                                                }
                                                name="name_begin_with"
                                                value={value}
                                                options={alphaNumericStartsWith.map(
                                                    (type) => ({
                                                        key: type.key,
                                                        value: type.value,
                                                        text: type.text,
                                                    })
                                                )}
                                                onChange={(_, val) =>
                                                    setValue(val.value)
                                                }
                                            />
                                        </ATMForm.Field>
                                    </ATMResponsive>
                                </>
                            ),
                            name_contains: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={style.filterSection}>{ Lang.LBL_PRIME_NAME_CONTAINS}</span>
                                    <ATMInput
                                        value={value}
                                        name="name_contains"
                                        placeholder={Lang.LBL_PRIME_ENTER}
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            sdge_name: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={style.filterSection}>{ Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG}</span>
                                    <ATMInput
                                        value={value}
                                        name="sdge_name"
                                        placeholder={Lang.LBL_PRIME_ENTER}
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            socal_name: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={style.filterSection}>{ Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG}</span>
                                    <ATMInput
                                        value={value}
                                        name="socal_name"
                                        placeholder={Lang.LBL_PRIME_ENTER}
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                    
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            is_active: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={style.filterSection}>{ Lang.LBL_PRIME_STATUS}</span>
                                    <ATMSelect
                                        placeholder={Lang.LBL_PRIME_SELECT}
                                        name="is_active"
                                        value={value}
                                        options={status.map((type) => ({
                                            key: type.key,
                                            value: type.value,
                                            text: type.text,
                                        }))}
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            contact_name_contains: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    <span className={style.filterSection}>{ Lang.LBL_CONTACT_NAME_CONTAINS}</span>
                                    <ATMInput
                                        placeholder={Lang.LBL_PRIME_ENTER}
                                        value={value}
                                        name="contact_name_contains"
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                            favourite: ({ value, setValue }) => (
                            <>
                                <ATMResponsive greaterThan="mobile">
                                {' '}
                                <ATMForm.Field>
                                    {/* <ATMField
                                        style={{ width: '100%' }}
                                        name="favourite"
                                        as={ATMCheckbox}
                                        type="checkbox"
                                        checked={
                                            Number(value)
                                                ? true
                                                : false
                                        }
                                        onChange={(_, val) =>
                                            setValue(val.value)
                                        }
                                    />
                                    <span className={style.filterSection}>{ Lang.LBL_CONTACT_PRIMES_FAVOURITES}</span> */}
                                    
                                    <label
                                            style={{
                                                marginLeft: '-60%',
                                                width: '30%',
                                            }}
                                        >
                                        </label>
                                    <ATMCheckbox
                                        className={style.checkBocfilterSection}
                                        label={Lang.LBL_CONTACT_PRIMES_FAVOURITES}
                                        checked={
                                            Number(value)
                                                ? true
                                                : false
                                        }
                                        onChange={(_, val) => {
                                            console.log('', val.checked)
                                            setValue( val.checked
                                            ? 1
                                            : undefined);
                                        }}
                                        style={{ width: '100%' }}
                                        name="favourite"
                                    />
                                    {/* <ATMField
                                            style={{ width: '100%' }}
                                            name="favourite"
                                            as={ATMCheckbox}
                                            type="checkbox"
                                            checked={
                                                Number(value)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(_, val) =>
                                                setValue(val.value)
                                            }
                                            
                                        /> */}
                                    </ATMForm.Field>
                                </ATMResponsive>
                            </>
                            ),
                        },
                    })}
                </ORGDataTable>
            </ATMSegment>
        </Container>
    );
};

export default PrimesContactView;

import services from 'src/services/admin-console.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    ADMIN_CONSOLE_FETCH: 'ADMIN_CONSOLE_FETCH',
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    getAdminConsoleNotificationHeader: {
        type: actionTypes.ADMIN_CONSOLE_FETCH,
        service: services.getAdminConsoleNotificationHeader,
        status: {} as never,
    },

    getAdminConsoleNotificationFooter: {
        type: actionTypes.ADMIN_CONSOLE_FETCH,
        service: services.getAdminConsoleNotificationFooter,
        status: {} as never,
    },
};
export type IAdminConsoleAsync = typeof asyncActions;

export type IAdminConsoleReducerAction = IAsyncActions<IAdminConsoleAsync>;
export interface IAdminConsoleState
    extends ICommonState<IAdminConsoleReducerAction> {
    list: any;
}

export const defaultState: IAdminConsoleState = {
    status: {},
    list: { data: [] },
};

const AdminConsoleReducer = (
    state: IAdminConsoleState = defaultState,
    action: ICommonAction<IAdminConsoleReducerAction>
): IAdminConsoleState => {
    switch (action.type) {
        case actionTypes.ADMIN_CONSOLE_FETCH: {
            return {
                ...state,
                list: action?.payload || { data: [] },
            };
        }
        default: {
            return state;
        }
    }
};

export default AdminConsoleReducer;

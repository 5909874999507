import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ATMContainer, IORGDataTableQueryState } from 'shared-it-appmod-ui';
import SubcontractorListView from './subcontractor-list.view';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import {
    getSubcontractorStatus,
    getTableListParams,
} from 'src/selectors/subcontractor.selector';
import SubcontractorUpdateForm from '../subcontractor-update/subcontractor-update.component';
import { actionTypes } from 'src/ducks/subcontractor.duck';
import history from 'src/history';
import {
    ISubcontractorUpdateData,
    ISubcontractorForTesting,
    IToggleTier2And3SubcontractorUnderPrime
} from 'src/models/subcontractor.model';
import SubcontractorAdd from 'src/components/pages/subcontractor/subcontractor-add/subcontractor-add-view/subcontractor-add-view.component';
import Lang from 'src/libraries/language';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useLocation } from 'react-router-dom';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import {
    ToastSuccess,
    ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import { getUserID, isAdmin, isContact, isPrime } from 'src/libraries/users.library';

export type IProps = {
    verificationExpirationStatus: number;
};

const SubcontractorList: React.FC<IProps> = ({
    verificationExpirationStatus,
}) => {
    const isUserPrime = isPrime();
    const isUserAdmin = isAdmin();
    const isUserContact = isContact();
    const { state: SubcontractorState, actions } = useSubcontractorContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [inActive, setInActive] = useState(false);
    const [toastMsg] = useState('');
    const [updateConfirm, setUpdateConfirm] = useState(false);
    const [removeConfirm, setRemoveConfirm] = useState(false);
    const [refreshView, setRefreshView] = useState(false);
    const [editData, setEditData] = useState<ISubcontractorForTesting>();
    const [subcontractorId, setSubcontractorId] = useState(0);
    const dataDeleteRef = useRef<any>();
    const [btndisable, setBtndisable] = useState(true);
    const [removeBtnDisable, setRemoveBtnDisable] = useState(true);
    const [checkStatus, setCheckStatus] = useState(false);

    const tableParamsRef = useRef({ page: 0, limit: 0 });

    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const search = useLocation().search;
    const [queryParams, setQueryParams] = useState<IORGDataTableQueryState>({
        page: 1,
        limit: 20,
    });

    const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);
    const [filterData, setFilterData] = useState<any>([]);

    const [hidden, sethidden] = useState<number>(1);
    const formRef = useRef<HTMLFormElement>();
    const page = new URLSearchParams(search).get('page');
    const limit = new URLSearchParams(search).get('limit');

    const [isTier2, setIsTier2] = useState(false);

    const handleFilter = useCallback(
        async (params: IORGDataTableQueryState) => {
            fetchData(params);
        },
        [actions]
    );

    useEffect(() => {
        const resAddSelected = localStorage.getItem('resAddSelected');
        const resCreateSubcontractor = localStorage.getItem(
            'resCreateSubcontractor'
        );

        if (resAddSelected || resCreateSubcontractor) {
            ToastSuccess(Lang.MSG_SUBCONTRACTOR_ADDED_SUCCESSFULLY);
            setTimeout(() => {
                setIsVisible({ success: false, error: false });
            }, 5000);
        }


        checkTier();
        sethidden(1);
        localStorage.setItem('resAddSelected', '');
        localStorage.setItem('resCreateSubcontractor', '');
        if (page != null && limit != null) {
            setQueryParams({
                page: parseInt(page ?? '1'),
                limit: parseInt(limit ?? '20'),
            });
        }
    }, []);

    const checkTier = async () => {
        const userId = getUserID();
        const params = { vendor_id: userId };
        const data = await actions.primeContractorGet(params);
        if (data.payload.data.isToggleAllow) {
            setIsTier2(true)
        }
        setCheckStatus(true)
        return data;
    }

    const fetchData = useCallback(
        async (params: IORGDataTableQueryState) => {
            console.log("filter", params)
            if (params?.filters) {
                setFilterData(params.filters);
            }
            SubcontractorState.reasons?.data.length == 0 &&
                actions.Reason_getList();
            let user_id = localStorage.getItem('loginCredential'); //for prime user

            if (isUserContact || isUserAdmin) {
                //for contact or admin user
                user_id = localStorage.getItem('prime_id');
            }
            let queryData = {};
            const { page, limit, sort, order } = params;
            let data: any = {};
            if (inActive) {
                // params.page = parseInt(page ?? '1');
                // params.limit = parseInt(limit ?? '10');
            }

            if (params != undefined && params) {
                data = getTableListParams(params);
            }

            if ((isUserContact || isUserAdmin) && hidden === 0) {
                queryData = {
                    ...params,
                    vendor_id: user_id,
                    hidden: hidden,
                };
            } else {
                queryData = {
                    page: page,
                    limit: limit,
                    sort: sort || '',
                    order: order || '',
                    name_begin_with: data?.name_begin_with || '',
                    state: data?.state || '',
                    name_contains: data?.name_contains || '',
                    vendor_id: user_id,
                    verification_expiration_status: isUserPrime
                        ? verificationExpirationStatus
                        : data?.verification_expiration_status || '',
                };

            }

            if (inActive === true) {
                history.push(
                    `/primes/primecontractor?page=1&limit=${limit ?? '20'
                    }&tab=1`
                );
                setInActive(false);
            }
            await actions.listGET_Prime(queryData);

        },
        [actions, hidden, inActive, verificationExpirationStatus]
    );

    const AddSubContractor = useCallback(() => {
        history.push(`/subcontractors/add`);
    }, [setOpen]);

    const handleEdit = useCallback(
        async (data) => {
            setEditData(data);
            setSubcontractorId(data.subcontractor_id);
            setConfirm(true);
        },
        [setConfirm]
    );

    const handleRemove = useCallback(async () => {
        setOpen(true);
    }, [setOpen]);

    const handleDelete = useCallback(
        async (data) => {
            dataDeleteRef.current = data;
            setSubcontractorId(data.subcontractor_id);
            setRemoveConfirm(true);
            const today = new Date();
            const fiscalyear = today.getFullYear();
            if (data.isDisbale) {
                setRemoveBtnDisable(false);
            } else {
                setRemoveBtnDisable(true);
            }
        },
        [setOpen]
    );

    const changeHidden = useCallback(
        // for Contact user filter for Show active/Inactive
        (val) => {
            sethidden(val);
            setInActive(true);
        },
        [sethidden]
    );
    const handleClose = useCallback(() => {
        // close popup form view form
        setOpen(false);
    }, [setOpen]);
    const handleremoveClose = () => {
        setRemoveConfirm(false);
    };
    const handleClosePopUp = useCallback(() => {
        // close popup form for update form
        setConfirm(false);
        setBtndisable(true);
    }, [setConfirm, setBtndisable]);

    const handleSuccess = useCallback(
        async (params: IORGDataTableQueryState) => {
            handleSuccessSubmit(params);
        },
        [setOpen, setConfirm, setLoading]
    );
    const handleSuccessSubmit = async (params: IORGDataTableQueryState) => {
        setLoading(true);
        setConfirm(false);
        setOpen(false);
        await fetchData(params);
        setLoading(false);
    };
    /*
     * open confirm
     */

    const handleSubmit = () => {
        return;
    };

    const handleClick = useCallback(() => {
        if (formRef && formRef.current) {
            formRef.current.handleSubmit();
        }
        setUpdateConfirm(true);
        setConfirm(false);
    }, [setUpdateConfirm]);


    const handlerToggle = useCallback(
        async (Vendor_SubContractor_Id, vendor_id, Tier3SpendAllow, Tier2SpendAllow, Hidden, isToggleAllow) => {
            const params = {
                "tier2Toggle": Tier2SpendAllow ? 1 : 0,
                "tier3Toggle": Tier3SpendAllow ? 1 : 0,
                "vendorId": vendor_id,
                "VendorSubContractorId": Vendor_SubContractor_Id,
                "Hidden": Hidden,
                "isToggleAllow": isToggleAllow
            }
            const response = await actions.postToggleTier2And3SubcontractorUnderPrime(params);

            const queryData = {
                page: 1,
                limit: 20
            }
            const page = new URLSearchParams(search).get('page');
            const limit = new URLSearchParams(search).get('limit');
            const verificationExpiryStatus = new URLSearchParams(search).get('tab');
            // handleFilter({
            //     page: parseInt(page ?? '1'),
            //     limit: parseInt(limit ?? '20'),
            //     verification_expiration_status: new URLSearchParams(search).get('tab')
            //     // filters: filterData,
            // });
            //toggleTier22And3SubcontractorUnderPrime
            if (response?.payload?.message) {
                ToastSuccess(response?.payload.message);
                await fetchData({
                    page: parseInt(page ?? '1'),
                    limit: parseInt(limit ?? '20'), 
                    verification_expiration_status: verificationExpiryStatus, 
                    filters: filterData,
                });
            }

        }, [handleFilter, filterData, SubcontractorState]);


    const handleSubmitConfirm = useCallback(
        //for updating subcontractor when click on update btn
        async (params: IORGDataTableQueryState) => {
            if (editData != undefined) {
                const param: ISubcontractorUpdateData = {};
                if (editData.Hidden === true) {
                    param.hidden = 1;
                } else {
                    param.hidden = 0;
                }
                param.VSID = editData.Vendor_SubContractor_Id;
                param.reason_id = editData.Reason_id;
                if (param.hidden == 0) {
                    param.reason_id = 0;
                }
                await actions.updateSubcontractor(param);
                ToastSuccess(Lang.MSG_SUBCONTRACTOR_UPDATED_SUCCESSFULLY);
                setBtndisable(true);
                setUpdateConfirm(false);
                handleSuccessSubmit(params);
            }
        },
        [editData]
    );

    /**
     * handle view of subcontractor
     */
    const handleViewSubContractor = useCallback(
        async (id) => {
            await actions.DetailGET_Not_Prime(id);
            setOpen(true);
        },
        [setOpen]
    );

    const setValue = useCallback(
        (filedName, value) => {
            const originalData = SubcontractorState.list?.data.find(
                (lstData) => lstData.subcontractor_id == subcontractorId
            );
            if (filedName === 'Hidden') {
                editData!.Hidden = value;
                setEditData(editData);
                if (
                    editData?.Hidden == false &&
                    originalData?.Hidden === true
                ) {
                    setBtndisable(false);
                } else {
                    setBtndisable(true);
                }
            }
            if (filedName === 'Reason_id') {
                if (originalData?.Reason_id === value) {
                    setBtndisable(true);
                } else {
                    setBtndisable(false);
                }
                editData!.Reason_id = value;
                setEditData(editData);
            }
        },
        [editData, btndisable]
    );

    /**
     * function to handle message popup for few second
     */

    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: false });
    }, [setIsVisible]);

    const handleOnClose = async () => {
        await setRefreshView(true);
        setUpdateConfirm(false);
        await setRefreshView(false);
    };

    const handleRemoveConfirm = useCallback(
        async (params: IORGDataTableQueryState) => {
            const param = {
                subcontractor_id: dataDeleteRef.current.subcontractor_id,
                vendor_id: dataDeleteRef.current.vendor_id,
            };
            const response = await actions.subcontractorDelete(param);

            if (response?.payload) {
                setRemoveConfirm(false);
                ToastSuccess('Subcontractor has been remove successfully.');
                await fetchData(params);
            } else if (!response?.error?.status) {
                ToastError(response?.error?.message);
                setRemoveConfirm(false);
            }
        },
        []
    );


    return (
        <ATMContainer fluid>
            {checkStatus && (<SubcontractorListView
                handleEdit={handleEdit}
                isVisible={isVisible}
                toastMsg={toastMsg}
                isPrime={isUserPrime}
                isAdmin={isUserAdmin}
                isContact={isUserContact}
                hidden={hidden}
                sethidden={changeHidden}
                data={SubcontractorState?.list}
                totalRecord={SubcontractorState?.list?.total_rows || 0}
                fetchData={fetchData}
                AddSubContractor={AddSubContractor}
                handleRemove={handleRemove}
                handleDelete={handleDelete}
                handleViewSubContractor={handleViewSubContractor}
                handleDismiss={handleDismiss}
                handlerToggle={handlerToggle}
                loading={
                    loading ||
                    getSubcontractorStatus(
                        SubcontractorState,
                        actionTypes.SUBCONTRACTOR_LIST_PRIME_FETCH
                    ).fetching
                }
                isTier2={isTier2}
            />)}
            {!refreshView && (
                <SubcontractorUpdateForm
                    isOpen={confirm}
                    reasonList={SubcontractorState?.reasons ?? {}}
                    handleClosePopUp={handleClosePopUp}
                    fdata={SubcontractorState.list?.data.find(
                        (lstData) => lstData.subcontractor_id == subcontractorId
                    )}
                    editData={editData}
                    btndisable={btndisable}
                    handleClick={handleClick}
                    handleSuccess={handleSuccess}
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    setValue={setValue}
                />
            )}
            {!updateConfirm ? (
                <SubcontractorAdd
                    isOpen={open}
                    handleClose={handleClose}
                    data={
                        SubcontractorState?.notPrimeDetail?.data || { data: [] }
                    }
                    formRef={formRef}
                />
            ) : (
                <Confirm
                    open={updateConfirm}
                    size="tiny"
                    content={Lang.MSG_CONTRACTOR_CONFIRM_UPDATE}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() =>
                        handleSubmitConfirm({
                            page: parseInt(page ?? '1'),
                            limit: parseInt(limit ?? '20'),
                            filters: filterData
                        })
                    }
                />
            )}
            {removeConfirm && (
                <Confirm
                    open={removeConfirm}
                    size="tiny"
                    disabled={removeBtnDisable}
                    content={
                        removeBtnDisable == true
                            ? Lang.MSG_REMOVE_SUBCONTRACTOR_IF_ISDELETE
                            : Lang.MSG_REMOVE_SUBCONTRACTOR
                    }
                    shortContent={
                        removeBtnDisable == true
                            ? Lang.MSG_CONFIRM_REMOVE_SUBCONTRACTOR_IF_ISDELETE
                            : Lang.MSG_CONFIRM_REMOVE_SUBCONTRACTOR
                    }
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_REMOVAL}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleremoveClose}
                    isDelete={true}
                    onConfirm={() =>
                        handleRemoveConfirm({
                            page: parseInt(page ?? '1'),
                            limit: parseInt(limit ?? '20'),
                        })
                    }
                />
            )}
        </ATMContainer>
    );
};

export default SubcontractorList;

import yup from '../libraries/validator.library';

export const SubcontractorSchema = yup // for Prime User
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        subcontractor_id: yup.number().defined(),
                        Vendor_SubContractor_Id: yup.number().defined(),
                        name: yup.string().nullable().defined(),
                        city: yup.string().nullable().defined(),
                        state: yup.string().nullable().defined(),
                        isDisbale: yup.boolean(),
                        Tier2SpendAllow: yup.boolean(),
                        Tier3SpendAllow: yup.boolean(),
                        isToggleAllow: yup.boolean(),
                        vendor_id: yup.number(),
                        VerificationStatus: yup.object({
                            color: yup.string(),
                            label: yup.string(),
                        }),
                        Hidden: yup.boolean(),
                        Reason_id: yup.number().defined(),
                        Reason: yup.string().nullable().defined(),
                    })
                    .defined()
            )
            .defined(),
        total_pages: yup.number().nullable(),
        total_rows: yup.number().nullable(),
        filters_data: yup.object({
            verificationExpirationStatus: yup.array().of(
                yup.object({
                    name: yup.string().nullable().defined(),
                    value: yup.string().defined(),
                })
            ),
        }),
    })
    .defined();

export const SubcontractorSpendsSchema = yup // for Prime User
    .object({message: yup.string(),
        data: yup.array().of(
            yup.object({
                vendor_id: yup.number(),
                subcontractor_id: yup.number(),
                verification_number: yup.string().nullable(),
                prime: yup.string(),
                subcontractor_name: yup.string(),
                verification_expiration_date: yup.string().nullable(),
                vedate: yup.string().nullable(),
                updated: yup.boolean(),
                contactName: yup.string().nullable(),
                contactEmail: yup.string().nullable(),
                contactPhone: yup.string().nullable(),
                city: yup.string().nullable(),
                state: yup.string().nullable(),
                zip: yup.string().nullable(),
                address: yup.string().nullable(),
                in_sixty_days: yup.boolean(),
                verificationStatus: yup.object({
                    color: yup.string(),
                    label: yup.string().nullable(),
                }),
                spendAmount: yup.number().nullable(),
            })
        ),
        filters_data: yup.object({
            verificationExpirationDue: yup.array(),
        }),
        total_rows: yup.number(),
        total_pages: yup.number(),
    })
    .defined();

export const SubcontractorForTestingSchema = yup // for Prime User Unit Test Schema
    .object({
        vendor_id: yup.number().defined(),
        Vendor_SubContractor_Id: yup.number().defined(),
        subcontractor_id: yup.number().defined(),
        name: yup.string().nullable().defined(),
        city: yup.string().nullable().defined(),
        state: yup.string().nullable().defined(),
        VerificationStatus: yup
            .object({
                color: yup.string(),
                label: yup.string(),
            })
            .defined(),
        Hidden: yup.boolean(),
        Reason_id: yup.number().defined(),
    })
    .defined();

export const SubcontractorNotPrimeSchema = yup // for non prime schema shape
    .object({
        subcontractor_id: yup.number().defined(),
        name: yup.string().defined(),
        address: yup.string().defined(),
        city: yup.string().nullable().defined(),
        state: yup.string().nullable().defined(),
        VerificationStatus: yup
            .object({
                color: yup.string(),
                label: yup.string(),
            })
            .defined(),
        Hidden: yup.boolean(),
    })
    .defined();

export const SubcontractorNotPrimeListPayloadSchema = yup // for non prime user listing
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        subcontractor_id: yup.number().defined(),
                        name: yup.string().defined(),
                        address: yup.string().defined(),
                        city: yup.string().nullable().defined(),
                        state: yup.string().nullable().defined(),
                        VerificationStatus: yup
                            .object({
                                color: yup.string(),
                                label: yup.string(),
                            })
                            .defined(),
                        Hidden: yup.boolean(),
                    })
                    .defined()
            )
            .defined(),
        total_pages: yup.number().nullable(),
        total_rows: yup.number().nullable(),
        filters_data: yup.object({
            verificationExpirationStatus: yup.array().of(
                yup.object({
                    name: yup.string().nullable().defined(),
                    value: yup.string().defined(),
                })
            ),
        }),
    })
    .defined();

export const SubcontractorNotPrimeDetailSchema = yup //this is to get detail data when click on eye icon on List of Non Prime users (selected)
    .object({
        data: yup
            .object({
                subcontractor_id: yup.number(),
                SAP_id: yup.string().nullable(),
                name: yup.string().nullable(),
                address: yup.string().nullable(),
                city: yup.string().nullable(),
                state: yup.string().nullable(),
                zip: yup.string().nullable(),
                phone: yup.string().nullable(),
                ownerName: yup.string().nullable(),
                gender_id: yup.number().nullable(),
                nationality_id: yup.number().nullable(),
                contactName: yup.string().nullable(),
                contactPhone: yup.string().nullable(),
                contactEmail: yup.string().nullable(),
                verificationAgency_id: yup.number().nullable(),

                verificationNumber: yup.string().nullable(),
                verificationExpirationDate: yup.string().nullable(),
                URL: yup.string().nullable(),
                minority_code: yup.string().nullable(),
                updated: yup.boolean(),
                gender: yup.string().nullable(),
                Nationality: yup.string().nullable(),
                agency_name: yup.string().nullable(),
            })
            .defined(),
    })
    .defined();

export const SubcontractorUpdateSchema = yup //for view data in popup form
    .object({
        Vendor_SubContractor_Id: yup.number().defined(),
        name: yup.string().defined(),
        city: yup.string().defined(),
        state: yup.string().defined(),
        VerificationStatus: yup.object({
            color: yup.string().nullable().defined(),
            label: yup.string().nullable().defined(),
        }),
        sapId: yup.number(),
        Reason_id: yup
            .number()
            .min(1)
            .required('Inactivation Reason is required field.'),
        Hidden: yup.boolean(),
    })
    .defined();
export const SubcontractorUpdateDataSchema = yup //for updating data from popup form post request
    .object({
        hidden: yup.number(),
        VSID: yup.number(),
        reason_id: yup.number(),
    })
    .defined();

    export const ToggleTier2And3SubcontractorUnderPrime = yup //for updating data from toggle form post request
    .object({
        VendorSubContractorId: yup.number(),
        vendorId: yup.number(),
        tier2Toggle: yup.number(),
        tier3Toggle: yup.number(),
        Hidden: yup.boolean(),
        isToggleAllow: yup.boolean(),
    })
    .defined();
    export const ToggleTier2And3SubcontractorUnderPrimeResponse = yup //for updating data from toggle form post request
    .object({
        message: yup.string(),
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
    })
    .defined();

export const SubcontractorAddSelectedSchema = yup // add selected subcontractors during additionz
    .object({
        vendor_id: yup.number().defined(),
        subcontract_ids: yup.array().of(yup.number()),
        status: yup.boolean(),
    })
    .defined();

/**
 * subcontractor create schema
 */

//  const formatDate = (value, originalValue) => {
//   return moment(originalValue).format('DD-MM-YYYY');
// }

export const SubcontractorCreateVerificationSchema = yup.object({
    verification_number: yup.string().required(),
});

export const SubcontractorCreatePostSchema = yup //using for (form validation and lookup data populate) schema
    .object({
        vendor_id: yup.number(),
        name: yup.string(),
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string(),
        owner_name: yup.string(),
        owner_phone_number: yup.string(),
        contact_name: yup.string(),
        contact_phone_number: yup.string(),
        gender_id: yup.number(),
        nationality_id: yup.number(),
        verification_agency_id: yup.number(),
        verification_expiry_date: yup.string(),
        contact_email: yup.string().email(),
        updated: yup.boolean(),
        verification_number: yup.string(),
        categoryId: yup.number(),
    })
    .defined();

export const SubcontractorCreateFrmErrorObjSchema = yup
    .object({
        name: yup.string(),
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string(),
        phone: yup.string(),
        owner_name: yup.string(),
        gender: yup.string(),
        nationality: yup.string(),
        contact_name: yup.string(),
        contact_phone_number: yup.string(),
        contact_email: yup.string(),
        verification_agency: yup.string(),
        verification_number: yup.string(),
        verification_expiry_date: yup.string(),
        categoryId: yup.number(),
    })
    .defined();

export const SubcontractorCreateSchema = yup
    .object({
        subcontractor_id: yup.number(),
        manual_entry: yup.boolean(),

        name: yup.string().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string().required(),
        phone: yup.string().required(),
        owner_name: yup.string().required(),
        gender_id: yup.number().required(),
        nationality_id: yup.number().required(),
        contact_name: yup.string().required(),
        contact_phone_number: yup.string().required(),
        contact_email: yup.string().email().required(),
        verification_agency_id: yup.number().required(),
        verification_number: yup.string().required(),
        verification_expiry_date: yup.string().required(),
        categoryId: yup.number().required(),
    })
    .defined();

export const SubcontractorCreateListPayloadSchema = yup //using for (form validation and lookup data populate) schema
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
    })
    .defined();

export const lookupSchema = yup //using for enter  new subcontractor
    .object({
        verification_number: yup.string().defined(),
    })
    .defined();

export const ReasonShape = {
    Reason_Id: yup.number().nullable().defined(),
    Reason: yup.string().nullable().defined(),
};
export const SubcontractorReasonSchema = yup.object(ReasonShape).defined(); // for reason list

export const SubcontractorReasonGetSchema = yup
    .object({
        data: yup.array().of(SubcontractorReasonSchema).defined(),
    })
    .defined();

export const spendRecordSapIdZeroSchema = yup
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
        message: yup.string(),
    })
    .defined();

    

//GenderNationalityAgencyList
export const SubcontractorGenderNationalityAgencySchema = yup
    .object({
        data: yup
            .object({
                genders: yup.array().of(
                    yup.object({
                        gender_id: yup.number(),
                        gender: yup.string(),
                    })
                ),
                nationalities: yup.array().of(
                    yup.object({
                        Nationality_ID: yup.number(),
                        Nationality: yup.string(),
                    })
                ),
                verification_agencies: yup.array().of(
                    yup.object({
                        agency_id: yup.number(),
                        agency_name: yup.string(),
                    })
                ),
                categories: yup.array().of(
                    yup.object({
                        categoryId: yup.number(),
                        category: yup.string(),
                        categoryDescription: yup.string(),
                        hide: yup.boolean(),
                        value: yup.string(),
                    })
                ),
            })
            .defined(),
    })
    .defined();
// end GenderNationalityAgencyList
export const SubcontractorListPayloadSchema = yup
    .array()
    .of(SubcontractorSchema)
    .defined();
//export const SubcontractorLookupSchema = yup.array().of(SubcontractorCreateSchema).defined();
export const SubcontractorDelectSchema = yup //using for (form validation and lookup data populate) schema
    .object({
        data: yup.array().of(yup.mixed()).defined(),
        status: yup.boolean().nullable(),
        message: yup.string(),
    })
    .defined();
export const SubcontractorDelectPutSchema = yup //using for (form validation and lookup data populate) schema
    .object({
        subcontractor_id: yup.number(),
        vendor_id: yup.number(),
    })
    .defined();
export const AdminSubcontractorGetSchema = yup //using for (form validation and lookup data populate) schema
    .object({
        subcontractor_id: yup.number(),
        vendor_id: yup.number(),
    })
    .defined();

export const AdminSubcontractorSchema = yup // for Prime User
    .object({
        data: yup
            .array()
            .of(
                yup
                    .object({
                        name: yup.string().nullable(),
                        city: yup.string().nullable(),
                        state: yup.string().nullable(),
                        address: yup.string().nullable(),
                        SAP_id: yup.string().nullable().defined(),
                        contactEmail: yup.string().nullable(),
                        contactPhone: yup.string().nullable(),
                        contactName: yup.string().nullable(),
                        zip: yup.string().nullable(),
                        phone: yup.string().nullable(),
                        ownerName: yup.string().nullable(),
                        subcontractor_id: yup.number().defined(),
                        verificationNumber: yup.string().nullable().defined(),
                        verificationExpirationDate: yup.string(),
                        verificationAgency_id: yup.number().defined(),
                        agency_name: yup.string().nullable(),
                        gender: yup.string().nullable().defined(),
                        Nationality: yup.string().nullable().defined(),
                        VerificationStatus: yup.object({
                            color: yup.string(),
                            label: yup.string(),
                            isVerified: yup.boolean(),
                        }),
                    })
                    .defined()
            )
            .defined(),
        user_type: yup
            .array()
            .of(
                yup
                    .object({
                        userTypeID: yup.number().defined(),
                        userType: yup.string().nullable().defined(),
                    })
                    .defined()
            )
            .defined(),
        filters_data: yup.object({
            verificationExpirationStatus: yup.array().of(
                yup.object({
                    name: yup.string().nullable().defined(),
                    value: yup.string().defined(),
                })
            ),
        }),
        totalPages: yup.number().nullable(),
        totalRows: yup.number().nullable(),
    })
    .defined();

export const SubcontractorDeleteResponseSchema = yup.object({
    message: yup.string(),
    status: yup.boolean(),
});

export type AdminSubcontractorSchemaObject = yup.InferType<
    typeof AdminSubcontractorSchema
>;
export type SubcontractorDelectSchemaObject = yup.InferType<
    typeof SubcontractorDelectPutSchema
>;
export type ISubcontractorCreateFrmErrorObj = yup.InferType<
    typeof SubcontractorCreateFrmErrorObjSchema
>;
export type ISubcontractorCreatePost = yup.InferType<
    typeof SubcontractorCreatePostSchema
>;
export type ISubcontractorGenderNationalityAgency = yup.InferType<
    typeof SubcontractorGenderNationalityAgencySchema
>;
export type ISubcontractorReasonObject = yup.InferType<
    typeof SubcontractorReasonSchema
>;
export type ISubcontractorReasonGet = yup.InferType<
    typeof SubcontractorReasonGetSchema
>;
export type IspendRecordSapIdZeroSchema = yup.InferType<
    typeof spendRecordSapIdZeroSchema
>;
export type ISubcontractorLookup = yup.InferType<typeof lookupSchema>;
export type ISubcontractorUpdate = yup.InferType<
    typeof SubcontractorUpdateSchema
>;

export type ISubcontractorCreate = yup.InferType<
    typeof SubcontractorCreateSchema
>;
export type ISubcontractorLookupGetData = yup.InferType<
    typeof SubcontractorCreateListPayloadSchema
>;
export type ISubcontractor = yup.InferType<typeof SubcontractorSchema>;

export type ISubcontractorNotPrime = yup.InferType<
    typeof SubcontractorNotPrimeSchema
>;

export type ISubcontractorNotPrimeListPayload = yup.InferType<
    typeof SubcontractorNotPrimeListPayloadSchema
>;
export type ISubcontractorSelectedAdd = yup.InferType<
    typeof SubcontractorAddSelectedSchema
>;
export type ISubcontractorNotPrimeDetail = yup.InferType<
    typeof SubcontractorNotPrimeDetailSchema
>;
export type ISubcontractorUpdateData = yup.InferType<
    typeof SubcontractorUpdateDataSchema
>;
export type IToggleTier2And3SubcontractorUnderPrime = yup.InferType<
    typeof ToggleTier2And3SubcontractorUnderPrime
>;
export type ISubcontractorForTesting = yup.InferType<
    typeof SubcontractorForTestingSchema
>;
export type ISubcontractorSpendsObject = yup.InferType<
    typeof SubcontractorSpendsSchema
>;

import yup from '../libraries/validator.library';

export const DashboardStatusSchema = yup
    .object({
        count: yup.number().defined(),
        type: yup.string().defined()
    })
    .defined();

export const FetchDashboardStatusSchema = yup
    .object({
        data: yup.array().of(DashboardStatusSchema).defined(),
        status: yup.boolean().defined()
    })
    .defined();

export const PrimeSAPSchema = yup.object({
    sapId: yup.number().defined(),
    name: yup.string().defined()
});

export const UtilitySchema = yup.object({
    Sempra_Company_ID: yup.number().defined(),
    Sempra_Company_Name: yup.string().defined(),
    Sempra_Company_Code: yup.string().defined()
})
export const SubcontractingSpendReportDashboardSchema = yup.object({
    message: yup.string().defined(),
    data: yup.array().of(yup.object({
        compCode: yup.number(),
        totalPaymentYTD: yup.number(),
        overallGoal: yup.number(),
        goalSubcontracting: yup.number(),
        totalVerifiedSubcontracting: yup.number(),
        verifiedSubcontracting: yup.number(),
        subcontractingDiff: yup.number()
    })),
    status: yup.boolean().defined()
})


export const SubcontractingSpendReportDropdownSchema = yup.object({
    message: yup.string().defined(),
    data: yup.object({
        dropdownPrimelist: yup.array().of(PrimeSAPSchema),
        utility: yup.array().of(UtilitySchema)
    }),
    status: yup.boolean().defined()
})

export type IDashboardStatusSchema = yup.InferType<typeof DashboardStatusSchema>;
export type IFetchDashboardStatusSchema = yup.InferType<
    typeof FetchDashboardStatusSchema
>;

import React from 'react';
import PrimeInformationView from './prime-information.view';
const PrimeInformation: React.FC<any> = ({
    primeDetails,
    loading,
    onPrimeInfoPageChange,
}) => {
    return (
        <PrimeInformationView
            data={primeDetails?.data || []}
            totalRows={primeDetails?.total_rows || 0}
            loading={loading}
            onPrimeInfoPageChange={onPrimeInfoPageChange}
        />
    );
};

export default PrimeInformation;

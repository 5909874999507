import moment from 'moment';
import React, { useEffect } from 'react';
import { ATMTable, ORGDataTable } from 'shared-it-appmod-ui';
import style from '../report.module.scss';
import * as $ from 'jquery';
import Lang from 'src/libraries/language';

const titles = [
    {
        title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SUBCONTRACTOR,
        key: 'subcontractor_name',
    },
    {
        title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_VON,
        key: 'verification_number',
    },
    {
        title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_VERIFICAITON_EXP_DATE,
        key: 'verification_expiration_date',
    },
    {
        title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT_NAME,
        key: 'contactName',
    },
    {
        title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_EMAIL,
        key: 'contactEmail',
    },
    {
        title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_PHONE_NO,
        key: 'contactPhone',
    },
    { title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ADDRESS, key: 'address' },
    { title: Lang.LBL_MASS_MAIL_CITY, key: 'city' },
    { title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_STATE, key: 'state' },
    { title: Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_ZIP, key: 'zip' },
];

const VerificationFollowUp: React.FC<any> = ({
    data,
    onVerificationPageChange,
    total,
    loading,
}) => {
    useEffect(() => {
        $('.unUpdated').parent().css({
            backgroundColor: '#D0191908',
            fontWeight: 'bold',
            color: '#D01919',
        });
        $('.updated').parent().css({
            backgroundColor: '',
            fontWeight: '',
            color: '',
        });
    }, [data]);

    const columns = titles.map(({ title, key }, index) => {
        return {
            title,
            index: String(index),
            render: (_, record) => {
                const expDate = moment(record.verification_expiration_date);
                return (
                    <ATMTable.Cell
                        className={
                            record.in_sixty_days ? 'updated' : 'unUpdated'
                        }
                        style={{
                            border: 0,
                            padding: index !== 0 ? 0 : '',
                            maxHeight: index === 1 ? 50 : 0,
                        }}
                    >
                        {title === 'Verification Expiration Date'
                            ? expDate.isValid()
                                ? expDate.format('M/D/YYYY')
                                : '-'
                            : record[key]}
                    </ATMTable.Cell>
                );
            },
        };
    });

    return (
        <div style={{ marginTop: '10px' }}>
            <ORGDataTable
                celled={false}
                loading={loading}
                columns={columns}
                data={data || []}
                addParams={['keyword']}
                history
                total={total}
                showPagination={true}
                rowsPerPageOptions={[10, 20, 25]}
                className={style.rowTable}
                onChange={(i) => onVerificationPageChange(i)}
            />
        </div>
    );
};

export default VerificationFollowUp;

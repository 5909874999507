import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    ISubcontractorCreate,
    ISubcontractorCreatePost,
} from 'src/models/subcontractor.model';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import SubcontractorCreateView from './subcontractor-create.view';
import moment from 'moment';
import Lang from 'src/libraries/language';
import history from 'src/history';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { validateSubContractorCreateFrm } from 'src/selectors/subcontractor.selector';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { DEFAULT_PHONE_NO } from 'src/constants';

const SubcontractorCreate: React.FC = () => {
    const [manualEntry, setManualEntry] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [addConfirm, setAddConfirm] = useState(false);
    const [found_in_cpuc, setFound_in_cpuc] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [frmError, setFrmError] = useState(false);
    const [error, setError] = useState<Partial<ISubcontractorCreate>>();
    const [addBtnDisable, setAddBtnDisable] = useState(true);
    const [mannualEntryDisable, setMannualEntryDisable] = useState(true);
    const [timeLeft, setTimeLeft] = useState(0);

    const { state, actions } = useSubcontractorContext();

    const [frmData, setFrmData] = useState<Partial<ISubcontractorCreate>>({
        subcontractor_id: 0,
        manual_entry: false,
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        owner_name: '',
        gender_id: 0,
        nationality_id: 0,
        contact_name: '',
        contact_phone_number: '',
        contact_email: '',
        verification_agency_id: 0,
        verification_number: '',
        verification_expiry_date: '',
    });
    const formRef = useRef<HTMLFormElement>();
    const lookupFormRef = useRef<HTMLFormElement>();

    useEffect(() => {
        actions.GenderNationalityAgenctData();
        if (!timeLeft) return;

        if (timeLeft === 0) {
            setTimeLeft(0);
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, []);

    const autoFill = useCallback(async () => {
        if (frmData.verification_number == '') {
            await setFrmError(true);
        }
        setError(
            validateSubContractorCreateFrm(frmData, true, 'verification_number')
        );

        frmData.verification_number != '' &&
            actions
                .AutoFill({ verification_number: frmData?.verification_number })
                .then((res) => {
                    if (res.payload?.data.length == 0) {
                        ToastError(Lang.MSG_SUBCONTRACTOR_AUTOFILL_ERROR);
                        setTimeLeft(5);
                        setFrmData({
                            subcontractor_id: 0,
                            manual_entry: false,
                            name: '',
                            address: '',
                            city: '',
                            state: '',
                            zip: '',
                            phone: '',
                            owner_name: '',
                            gender_id: 0,
                            nationality_id: 0,
                            contact_name: '',
                            contact_phone_number: '',
                            contact_email: '',
                            verification_agency_id: 0,
                            verification_number: frmData.verification_number,
                            verification_expiry_date: '',
                            categoryId: 0,
                        });
                        setMannualEntryDisable(false);

                        setError(
                            validateSubContractorCreateFrm(
                                frmData,
                                false,
                                'verification_number'
                            )
                        );
                        return null;
                    } else {
                        const result = Object(res?.payload?.data[0]);
                        if (
                            result.flag === 'found_in_cpuc_file' &&
                            result?.verification_expiry_date != ''
                        ) {
                            setUpdated(true);
                        }

                        if (result.flag === 'found_in_cpuc_file') {
                            setMannualEntryDisable(true);
                            setFound_in_cpuc(true);
                            setAddBtnDisable(false);
                        } else {
                            setMannualEntryDisable(true);
                        }
                        if (
                            result?.verification_expiry_date === 'Invalid date'
                        ) {
                            result.verification_expiry_date = '';
                        }
                        setFrmData({...result, phone: result?.phone===''?DEFAULT_PHONE_NO:result?.phone, contact_phone_number: result?.contact_phone_number===''?DEFAULT_PHONE_NO:result?.contact_phone_number});
                        return res.payload?.data;
                    }
                });
    }, [found_in_cpuc, mannualEntryDisable, addBtnDisable, frmData]);

    const lookup = useCallback(() => {
        setConfirm(true);
    }, [confirm]);

    /**
     * function: goBack on subcontractor list page
     *
     */
    const goBack = useCallback(() => {
        history.push('/subcontractors/add');
    }, []);

    const validateField = useCallback(
        async (fieldName) => {
            const frmDt = { ...frmData };
            const retErr = validateSubContractorCreateFrm(
                frmDt,
                frmError,
                fieldName
            );
            const newErrObj = { ...error, ...retErr };

            setError(newErrObj);
        },
        [setError, error, manualEntry]
    );
    const setValue = useCallback(
        async (fieldName, val) => {
            const frmDt = { ...frmData };
            // console.log(fieldName);
            if (fieldName === 'manual_entry') {
                setManualEntry(val);
                frmDt[fieldName] = val;
                setFrmData(frmDt);
                if (val == false) {
                    setAddBtnDisable(true);
                    setFrmError(false);
                } else {
                    setAddBtnDisable(false);
                }
            } else if (fieldName === 'verification_number') {
                const frmDt = { ...frmData};
                frmDt[fieldName] = val;
                setFrmData(frmDt);

                const retErr = validateSubContractorCreateFrm(
                    frmDt,
                    frmError,
                    fieldName
                );
                const newErrObj = { ...error, ...retErr };
                setError(newErrObj);
            } else {
                if (fieldName === 'verification_expiry_date') {
                    frmDt[fieldName] =
                        val != null ? moment(val).format('MM/DD/YYYY') : '';
                } else {
                    frmDt[fieldName] = val;
                }

                setFrmData(frmDt);

                const retErr = validateSubContractorCreateFrm(
                    frmDt,
                    frmError,
                    fieldName
                );
                const newErrObj = { ...error, ...retErr };

                setError(newErrObj);
            }
        },
        [setError, error, formRef, manualEntry, frmData, setAddBtnDisable]
    );

    const handleSubmit = () => {
        return;
    };
    const add = useCallback(() => {
        const validateObj = clean(
            validateSubContractorCreateFrm(frmData, true, '')
        );

        if (Object.keys(validateObj).length > 0) {
            setError(validateObj);
        } else {
            setAddConfirm(true);
        }
    }, [addConfirm, manualEntry, frmData, setAddConfirm]);

    /**
     * function: CreateSubContractor
     * params: data (form data)
     */
    const CreateSubContractor = useCallback(async () => {
        const params: Partial<ISubcontractorCreatePost> = {};
        if (frmData.name == '') {
            setFrmError(true);
        }
        const validateObj = clean(
            validateSubContractorCreateFrm(frmData, true, '')
        );

        if (Object.keys(validateObj).length > 0) {
            setError(validateObj);
        } else {
            params.vendor_id = parseInt(
                localStorage.getItem('loginCredential') ?? ''
            );
            params.name = frmData.name ?? '';
            params.address = frmData.address;
            params.city = frmData?.city;
            params.state = frmData?.state;
            params.zip = frmData?.zip;
            params.owner_name = frmData?.owner_name;
            params.owner_phone_number = frmData?.phone;

            params.contact_name = frmData?.contact_name;
            params.contact_phone_number = frmData?.contact_phone_number;
            params.gender_id = frmData?.gender_id;
            // console.log("params.nationality_id===",params.nationality_id);
            if (frmData?.nationality_id === undefined){
                // console.log("INSIDE");
                params.nationality_id = 8;
            } else {
                params.nationality_id = frmData?.nationality_id;
            }
            params.verification_agency_id = frmData?.verification_agency_id;
            params.categoryId = frmData?.categoryId;
            if (
                frmData?.verification_expiry_date &&
                frmData.verification_expiry_date != ''
            ) {
                params.verification_expiry_date = moment(
                    frmData?.verification_expiry_date
                ).format('MM/DD/YYYY');
            } else {
                params.verification_expiry_date = '';
            }

            params.contact_email = frmData?.contact_email;
            params.updated = updated;
            params.verification_number = frmData?.verification_number;
            // console.log("PARAMS ADD SUBCONTRACTOR: ",params);
            actions
                .createSubcontractor_POST(params)
                .then((res) => {
                    if (res?.error?.status == false) {
                        setAddConfirm(false);
                        ToastError(res?.error?.message);
                    } else {
                        localStorage.setItem('resCreateSubcontractor', '1');
                        history.push('/subcontractors');
                    }
                    return null;
                })
                .catch(() => {
                    ToastError(Lang.MSG_SUBCONTRACTOR_AUTOFILL_ERROR);
                    return null;
                });

            setAddBtnDisable(Object.keys(params).every((k) => params[k] != ''));
        }
    }, [frmData, formRef, frmError, setFrmError]);

    const handleSubmitConfirm = useCallback(() => {
        window.open('https://sch.thesupplierclearinghouse.com/', '_blank');
        setConfirm(false);
    }, [confirm]);

    function clean(obj) {
        if (obj != undefined || obj != null) {
            const propNames = Object.getOwnPropertyNames(obj);
            for (let i = 0; i < propNames.length; i++) {
                const propName = propNames[i];
                if (
                    obj[propName] === null ||
                    obj[propName] === undefined ||
                    obj[propName] === ''
                ) {
                    delete obj[propName];
                }
            }
            return obj;
        }
    }

    const lookupText = (
        <p>
            <p>
                You will be taken out of this application to the Supplier
                Clearinghouse to select a subcontractor to add. When you have
                selected your sub, copy the Verification Order # in the 2nd
                column into the Verification Number field in the subcontracting
                portal and select the Auto-Fill button.
            </p>
            <p>
                {' '}
                Hold the Ctrl key down when clicking OK if you have a pop-up
                blocker.
            </p>
        </p>
    );
    return (
        <>
            <SubcontractorCreateView
                lookupData={state.lookupData?.data || []}
                defaultValues={frmData}
                GenderNationalityAgenctList={
                    state?.GenderNationalityAgenctList || {}
                }
                validateField={validateField}
                mannualEntryDisable={mannualEntryDisable}
                loading={false}
                manualEntry={manualEntry}
                formRef={formRef}
                lookupFormRef={lookupFormRef}
                autoFill={autoFill}
                lookup={lookup}
                goBack={goBack}
                setValue={setValue}
                frmError={frmError}
                error={clean(error)}
                addBtnDisable={addBtnDisable}
                add={add}
                handleSubmit={handleSubmit}
                found_in_cpuc={found_in_cpuc}
            />
            <Confirm
                open={confirm}
                size="tiny"
                content={lookupText}
                loading={false}
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={() => setConfirm(false)}
                onConfirm={handleSubmitConfirm}
            />
            <Confirm // add subcontractor confirm
                open={addConfirm}
                size="tiny"
                content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                loading={false}
                btn_text={Lang.LBL_CONFIRM_BTN}
                header={Lang.TTL_CONFIRMATION}
                onCancel={() => setAddConfirm(false)}
                onConfirm={CreateSubContractor}
            />
        </>
    );
};

export default SubcontractorCreate;

import React, { useEffect, useState } from 'react';
import {
    ATMDropdown,
    ATMPagination,
    ATMTable,
    ATMLoader,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import style from '../report.module.scss';
import '../report.scss';
import { formatter } from '../report.component';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const getCellData: any = (data, type) => {
    if (type === 'verified') {
        data.verifySpendTotal.subcontractor = 'Month Totals';
        return [...data.verifySpend, data.verifySpendTotal];
    } else if (type === 'unVerified') {
        data.unVerifySpendTotal.subcontractor = 'Month Totals';
        return [...data.UnVerifySpend, data.unVerifySpendTotal];
    }
    return [];
};

export const getAmount: any = (row, value) => {
    if (row.subcontractor === 'Month Totals') {
        return formatter.format(value);
    } else {
        return Number(value) !== 0 ? formatter.format(value) : '-';
    }
};


export const formatAmount: any = (value) => {
    return Number(value) !== 0 ? formatter.format(value) : '0';
};

const MonthSummaryReportAdminView: React.FC<any> = ({
    verifiedData,
    unVerifiedData,
    onMonthSummaryOrderAdminPageChange,
    monthSummaryAdminList,
    loading,
    reportType,
    setResetPagination,
    resetPagination,
    filterData,
    utilityID,
    utilityData
}) => {
    const [refresh, setRefresh] = useState(false);
    const [pageOption, selectPageOption] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    const verifiedDataCount = verifiedData?.filter(
        (ver) => ver.data.verifySpend.length >= 1
    ).length;
    const unVerifiedDataCount = unVerifiedData?.filter(
        (unVer) => unVer.data.UnVerifySpend.length >= 1
    ).length;

    useEffect(() => {
        setPageNumber(1);
        setResetPagination(false);
    }, [setResetPagination]);

    useEffect(() => {
        handleRefresh();
    }, [unVerifiedData, verifiedData]);

    useEffect(() => {
        handlePageChange();
    }, [pageOption, pageNumber]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window]);

    const handlePageChange = () => {
        onMonthSummaryOrderAdminPageChange({
            page: pageNumber,
            limit: pageOption,
        });
    };

    const handleRefresh = async () => {
        await setRefresh(true);
        await setRefresh(false);
    };

    const pageOptions = [10, 20, 25].map((i) => ({
        key: i,
        value: i,
        text: i,
    }));

    const titles = [
        'Prime',
        'Subcontractor',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Total',
    ];
    const headerTitles = [
        'Utility',
        'Verified/Non-Verified Spend',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Total',
    ]
    if (reportType === 4 || reportType === 5)
        titles.splice(1, 0, 'Supplier Diversity Contact/ Contacts');
    titles.splice(2, 0, 'Spend Type');
    return loading === true ? (
        <div style={{ paddingTop: '30px', paddingBottom: '20px' }}>
            <ATMLoader active inline="centered" size="large" />
        </div>
    ) : (
        <>
            {(reportType === 5 || reportType === 4) && (
                <ATMTable enableScroll className={style.purchaseOrderTable} style={{ marginTop: '15px' }}>
                    <ATMTable.Header>
                        <ATMTable.Row>
                            {headerTitles.map((i) => {
                                return (
                                    <ATMTable.HeaderCell key={i}>
                                        {i}
                                    </ATMTable.HeaderCell>
                                );
                            })}
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable.Body>
                        <ATMTable.Row>
                            <ATMTable.Cell style={{ width: '30px!important' }}
                                rowSpan={3}
                            >
                                {utilityID == 'ALL' && (
                                    <b>ALL</b>
                                )
                                }
                                {utilityID != 'ALL' && (
                                    <b >
                                        {utilityData.filter(x => x.Sempra_Company_ID == utilityID).map(x => x.Sempra_Company_Name)}
                                    </b>
                                )
                                }

                            </ATMTable.Cell>
                            <ATMTable.Cell
                            //rowSpan={}
                            >
                                <b style={{ color: 'green' }}>Total Verified Spend</b>
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.janTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.febTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.marTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.aprTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.mayTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.junTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.julTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.augTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.sepTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.octTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.novTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.decTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                <b>{formatAmount(filterData.total_verify_spend)}</b>
                            </ATMTable.Cell>

                        </ATMTable.Row>
                        <ATMTable.Row>
                            <ATMTable.Cell
                            //rowSpan={}
                            >
                                <b style={{ color: 'red' }}>Total Non-Verified Spend</b>
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_janTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_febTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_marTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_aprTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_mayTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_junTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_julTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_augTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_sepTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_octTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_novTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.unveify_decTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                <b>{formatAmount(filterData.total_unverify_spend)}</b>
                            </ATMTable.Cell>

                        </ATMTable.Row>

                        <ATMTable.Row className={style.monthTotals}>
                            <ATMTable.Cell
                            //rowSpan={}
                            >
                                Grand Total
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_janTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_febTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_marTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_aprTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_mayTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_junTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_julTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_augTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_sepTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_octTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_novTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_decTotal)}
                            </ATMTable.Cell>
                            <ATMTable.Cell
                                textAlign={'right'}
                            >
                                {formatAmount(filterData.grand_total_verify_n_nonverify)}
                            </ATMTable.Cell>

                        </ATMTable.Row>
                    </ATMTable.Body>
                </ATMTable>
            )}
            {verifiedDataCount >= 1 && !refresh && (
                <ATMTable enableScroll style={{ marginTop: '1rem' }}>
                    <ATMTable.Header>
                        <ATMTable.Row
                            id="head"
                            className={`${style.tabeheader} ${style.green}`}
                            style={{ fontWeight: 'bold' }}
                        >
                            <span>{Lang.LBL_SPEND_VERIFIED_SPEND}</span>
                        </ATMTable.Row>
                    </ATMTable.Header>
                    <ATMTable
                        className={`${style.purchaseOrderTable} ${style.adminMonthSummaryTable}`}
                        style={{ width: '100%' }}
                        id="abc"
                        enableScroll
                    >
                        <ATMTable.Header>
                            <ATMTable.Row>
                                {titles.map((i) => {
                                    return (
                                        <ATMTable.HeaderCell key={i}>
                                            {i}
                                        </ATMTable.HeaderCell>
                                    );
                                })}
                            </ATMTable.Row>
                        </ATMTable.Header>
                        <ATMTable.Body>
                            {verifiedData
                                .filter((j) => j.data.verifySpend.length >= 1)
                                .map((j) => {
                                    return (
                                        <>
                                            <ATMTable.Row key={j.primeName}>
                                                <ATMTable.Cell
                                                    className={
                                                        style.alignPrimeData
                                                    }
                                                    rowSpan={
                                                        j?.data?.verifySpend
                                                            ?.length + 2
                                                    }
                                                >
                                                    <p>
                                                        {' '}
                                                        <b> {j?.primeName}</b>
                                                    </p>
                                                    <p>
                                                        {' '}
                                                        <b>
                                                            {j?.contractNumber}
                                                        </b>
                                                    </p>
                                                    <p>
                                                        {j?.contractDescription}
                                                    </p>
                                                    <p>{j?.utility}</p>
                                                </ATMTable.Cell>
                                                {reportType === 4 && (
                                                    <ATMTable.Cell
                                                        className={
                                                            style.alignsdcName
                                                        }
                                                        rowSpan={
                                                            j?.data?.verifySpend
                                                                ?.length + 2
                                                        }
                                                        style={{
                                                            width: '200px',
                                                            innerWidth: '200px',
                                                            innerHeight: '20px',
                                                        }}
                                                    >
                                                        {j?.sdge_adv_name && (
                                                            <p>
                                                                {
                                                                    j?.sdge_adv_name
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.socal_adv_name && (
                                                            <p>
                                                                {
                                                                    j?.socal_adv_name
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.c1 && (
                                                            <p>{j?.c1}</p>
                                                        )}
                                                        {j?.c2 && (
                                                            <p>{j?.c2}</p>
                                                        )}
                                                        {j?.c3 && (
                                                            <p>{j?.c3}</p>
                                                        )}
                                                    </ATMTable.Cell>
                                                )}
                                                {reportType === 5 && (
                                                    <ATMTable.Cell
                                                        className={
                                                            style.alignsdcName
                                                        }
                                                        rowSpan={
                                                            j?.data?.verifySpend
                                                                ?.length + 2
                                                        }
                                                        style={{
                                                            width: '200px',
                                                            innerWidth: '200px',
                                                            innerHeight: '20px',
                                                        }}
                                                    >
                                                        {j?.sdgeAdvisorName && (
                                                            <p>
                                                                {
                                                                    j?.sdgeAdvisorName
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.socalAdvisorName && (
                                                            <p>
                                                                {
                                                                    j?.socalAdvisorName
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.c1 && (
                                                            <p>{j?.c1}</p>
                                                        )}
                                                        {j?.c2 && (
                                                            <p>{j?.c2}</p>
                                                        )}
                                                        {j?.c3 && (
                                                            <p>{j?.c3}</p>
                                                        )}
                                                    </ATMTable.Cell>
                                                )}
                                            </ATMTable.Row>
                                            {getCellData(
                                                j.data,
                                                'verified'
                                            ).map((k, i) => {
                                                return (
                                                    <ATMTable.Row
                                                        key={i}
                                                        className={
                                                            k.subcontractor ===
                                                                'Month Totals'
                                                                ? style.monthTotals
                                                                : ''
                                                        }
                                                    ><ATMTable.Cell
                                                        className={
                                                            style.algnSubcontractor
                                                        }
                                                        style={{ fontWeight: 'bold', backgroundColor: '#fff' }}
                                                    >
                                                            {k.subcontractor ===
                                                                'Month Totals' ? '' :
                                                                k.isTier3Spend ? "Tier III" : "Tier II"}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            className={
                                                                style.algnSubcontractor
                                                            }
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                            }}
                                                        >
                                                            {k.subcontractor !=
                                                                'Month Totals'
                                                                ? k.subcontractor
                                                                : k.subcontractor}
                                                        </ATMTable.Cell>

                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.jan
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.feb
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.mar
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.apr
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.may
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.jun
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.jul
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.aug
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.sep
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.oct
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.nov
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}>
                                                            {getAmount(
                                                                k,
                                                                k.dec
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell style={{
                                                            backgroundColor: k.subcontractor !==
                                                                'Month Totals' && k.isTier3Spend && '#F3FFEB'
                                                        }}
                                                            className={`${style.adminSummaryTotal} monthTotals`}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.total
                                                            )}
                                                        </ATMTable.Cell>
                                                    </ATMTable.Row>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                        </ATMTable.Body>
                    </ATMTable>
                </ATMTable>
            )}

            {unVerifiedDataCount >= 1 && !refresh && (
                <ATMTable
                    enableScroll
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                    <ATMTable.Header>
                        <ATMTable.Row
                            id="head"
                            className={`${style.tabeheader} ${style.red}`}
                            style={{ fontWeight: 'bold' }}
                        >
                            <span>
                                {Lang.LBL_SPEND_SUMMARY_UNVERIFIED_SPEND}
                            </span>
                        </ATMTable.Row>
                    </ATMTable.Header>

                    <ATMTable enableScroll className={style.purchaseOrderTable}>
                        <ATMTable.Header>
                            <ATMTable.Row>
                                {titles.map((i) => {
                                    return (
                                        <ATMTable.HeaderCell key={i}>
                                            {i}
                                        </ATMTable.HeaderCell>
                                    );
                                })}
                            </ATMTable.Row>
                        </ATMTable.Header>
                        <ATMTable.Body>
                            {unVerifiedData
                                .filter((j) => j.data.UnVerifySpend.length >= 1)
                                .map((j) => {
                                    return (
                                        <>
                                            <ATMTable.Row key={j.primeName}>
                                                <ATMTable.Cell
                                                    className={
                                                        style.alignPrimeData
                                                    }
                                                    rowSpan={
                                                        j.data.UnVerifySpend
                                                            .length + 2
                                                    }
                                                >
                                                    <b> {j.primeName}</b>
                                                    <p>
                                                        {' '}
                                                        <b>
                                                            {j?.contractNumber}
                                                        </b>
                                                    </p>
                                                    <p>
                                                        {j?.contractDescription}
                                                    </p>
                                                    <p>{j?.utility}</p>
                                                </ATMTable.Cell>
                                                {reportType === 4 && (
                                                    <ATMTable.Cell
                                                        rowSpan={
                                                            j.data.UnVerifySpend
                                                                .length + 2
                                                        }
                                                        className={
                                                            style.alignsdcName
                                                        }
                                                    >
                                                        {j?.sdge_adv_name && (
                                                            <p>
                                                                {
                                                                    j?.sdge_adv_name
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.socal_adv_name && (
                                                            <p>
                                                                {
                                                                    j?.socal_adv_name
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.c1 && (
                                                            <p>{j?.c1}</p>
                                                        )}
                                                        {j?.c2 && (
                                                            <p>{j?.c2}</p>
                                                        )}
                                                        {j?.c3 && (
                                                            <p>{j?.c3}</p>
                                                        )}
                                                    </ATMTable.Cell>
                                                )}
                                                {reportType === 5 && (
                                                    <ATMTable.Cell
                                                        rowSpan={
                                                            j.data.UnVerifySpend
                                                                .length + 2
                                                        }
                                                        className={
                                                            style.alignsdcName
                                                        }
                                                    >
                                                        {j?.sdgeAdvisorName && (
                                                            <p>
                                                                {
                                                                    j?.sdgeAdvisorName
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.socalAdvisorName && (
                                                            <p>
                                                                {
                                                                    j?.socalAdvisorName
                                                                }
                                                            </p>
                                                        )}
                                                        {j?.c1 && (
                                                            <p>{j?.c1}</p>
                                                        )}
                                                        {j?.c2 && (
                                                            <p>{j?.c2}</p>
                                                        )}
                                                        {j?.c3 && (
                                                            <p>{j?.c3}</p>
                                                        )}
                                                    </ATMTable.Cell>
                                                )}
                                            </ATMTable.Row>
                                            {getCellData(
                                                j.data,
                                                'unVerified'
                                            ).map((k, i) => {
                                                return (
                                                    <ATMTable.Row
                                                        key={i}
                                                        className={
                                                            k.subcontractor ===
                                                                'Month Totals'
                                                                ? style.monthTotals
                                                                : ''
                                                        }
                                                    ><ATMTable.Cell
                                                        className={
                                                            style.algnSubcontractor
                                                        }
                                                        style={{ fontWeight: 'bold', backgroundColor: '#fff' }}
                                                    >
                                                            {k.subcontractor ===
                                                                'Month Totals' ? '' :
                                                                k.isTier3Spend ? "Tier III" : "Tier II"}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            className={
                                                                style.algnSubcontractor
                                                            } style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                            }}
                                                        >
                                                            {k?.subcontractor}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.jan
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.feb
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.mar
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.apr
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.may
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.jun
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.jul
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.aug
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.sep
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.oct
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.nov
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.dec
                                                            )}
                                                        </ATMTable.Cell>
                                                        <ATMTable.Cell
                                                            style={{
                                                                backgroundColor: k.subcontractor !==
                                                                    'Month Totals' && k.isTier3Spend && '#F3FFEB',
                                                                color:
                                                                    k.subcontractor ===
                                                                        'Month Totals'
                                                                        ? '#000000DD'
                                                                        : '#D01919',
                                                            }}
                                                        >
                                                            {getAmount(
                                                                k,
                                                                k.total
                                                            )}
                                                        </ATMTable.Cell>
                                                    </ATMTable.Row>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                        </ATMTable.Body>
                    </ATMTable>
                </ATMTable>
            )}

            {verifiedDataCount === 0 && unVerifiedDataCount === 0 && (
                <div className={style.alignMessage}>
                    {Lang.MSG_NO_RECORDS_TO_DISPLAY}
                </div>
            )}

            <ATMTable.Footer
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <ATMTable.Row colSpan="15">
                    <span>
                        {Lang.LBL_ROW_PER_PAGE}&nbsp;
                        <ATMDropdown
                            compact
                            options={pageOptions}
                            value={pageOption}
                            onChange={(i, j) => {
                                setPageNumber(1);
                                selectPageOption(Number(j.value));
                            }}
                        />
                    </span>
                    <ATMTable.HeaderCell
                        style={{ marginLeft: windowDimensions.width }}
                    >
                        <ATMPagination
                            style={{
                                marginLeft:
                                    (windowDimensions.width - 200) / 2.5,
                            }}
                            defaultActivePage={pageNumber}
                            totalPages={Math.ceil(
                                monthSummaryAdminList?.total_rows /
                                Number(pageOption)
                            )}
                            size="tiny"
                            onPageChange={(i, j) => {
                                setPageNumber(Number(j.activePage));
                            }}
                        />
                    </ATMTable.HeaderCell>
                </ATMTable.Row>
            </ATMTable.Footer>
        </>
    );
};

export default MonthSummaryReportAdminView;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    ISubcontractorReducerAction,
    ISubcontractorState,
} from '../ducks/subcontractor.duck';
import { IStatus } from '../ducks';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
    ISubcontractorCreate,
    ISubcontractorCreateFrmErrorObj,
} from 'src/models/subcontractor.model';
import Lang from 'src/libraries/language';

export const getSubcontractorStatus = (
    state: ISubcontractorState,
    action: ISubcontractorReducerAction['type']
): IStatus =>
    state.status[action] ?? {
        fetching: false,
        error: null,
    };

export const getTableListParams = (
    params: IORGDataTableQueryState
): Partial<IORGDataTableQueryState> => {
    const { ...data } = params;
    const ret = {};
    if (data.filters) {
        for (const dataParam of data.filters) {
            ret[dataParam.name] = dataParam.value;
        }
    }
    delete data.filters;
    return { ...data, ...ret };
};

export const validateSubContractorCreateFrm = (
    data: Partial<ISubcontractorCreate>,
    frmError,
    fieldName
) => {
    const errorObj: ISubcontractorCreateFrmErrorObj = {};
    const nameRegex = /^[A-Za-z ]*$/;
    const subNameRegex = /^[A-Za-z0-9 ]*$/;
    const numberRegex = /^[0-9 ]*$/;
    const PHONE_NO_REGEX = /^\d{3}-\d{3}-\d{4}$/;
    const ALPHANUMERIC_REGEX = /^[A-Za-z0-9 ]*$/;
    const EMAIL_REGEX =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
        data.verification_number == '' &&
        (frmError || fieldName == 'verification_number')
    ) {
        errorObj.verification_number = String(
            Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Verification Number')
        );
    }
    const verificationLen = data.verification_number?.length ?? 0;
    if (verificationLen > 30) {
        errorObj.verification_number = String(
            Lang.formatString(
                Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                'Verification Number',
                '30'
            )
        );
    }
    if (
        data.verification_number != '' &&
        !ALPHANUMERIC_REGEX.test(data.verification_number ?? '')
    ) {
        errorObj.verification_number = String(
            Lang.formatString(
                Lang.ERROR.COMMON.ALPHANUMERIC,
                'Verification Number'
            )
        );
    }
    if (
        data.verification_number != '' &&
        ALPHANUMERIC_REGEX.test(data.verification_number ?? '') &&
        verificationLen < 30
    ) {
        errorObj.verification_number = '';
    }

    if (data.manual_entry) {
        //name

        if (data.name == '' && (frmError || fieldName == 'name')) {
            errorObj.name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.REQUIRED,
                    'Subcontractor Name'
                )
            );
        }
        if (data.name != '' && !subNameRegex.test(data.name ?? '')) {
            errorObj.name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.ONLY_LETTERS,
                    'Subcontractor Name'
                )
            );
        }

        const lenName = data.name?.length ?? 0;
        if (data.name != '' && lenName > 30) {
            errorObj.name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'Subcontractor Name',
                    '30'
                )
            );
        }
        if (
            data.name != '' &&
            lenName < 30 &&
            subNameRegex.test(data.name ?? '')
        ) {
            errorObj.name = '';
        }

        //address
        if (data.address == '' && (frmError || fieldName == 'address')) {
            errorObj.address = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Address')
            );
        }

        const lenAddress = data.address?.length ?? 0;
        if (data.address != '' && lenAddress > 30) {
            errorObj.address = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'Address',
                    '30'
                )
            );
        }

        if (
            data.address != '' &&
            !ALPHANUMERIC_REGEX.test(data.address ?? '')
        ) {
            errorObj.address = String(
                Lang.formatString(Lang.ERROR.COMMON.ALPHANUMERIC, 'Address')
            );
        }

        if (
            data.address != '' &&
            lenAddress < 30 &&
            ALPHANUMERIC_REGEX.test(data.address ?? '')
        ) {
            errorObj.address = '';
        }

        //city
        if (data.city == '' && (frmError || fieldName == 'city')) {
            errorObj.city = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'City')
            );
        }
        if (data.city != '' && !nameRegex.test(data.city ?? '')) {
            errorObj.city = String(
                Lang.formatString(Lang.ERROR.COMMON.ONLY_LETTERS, 'City')
            );
        }
        const lenCity = data.city?.length ?? 0;
        if (data.city != '' && lenCity > 20) {
            errorObj.city = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'City',
                    '20'
                )
            );
        }
        if (
            data.city != '' &&
            lenCity < 20 &&
            nameRegex.test(data.city ?? '')
        ) {
            errorObj.city = '';
        }
        //state
        if (data.state == '' && (frmError || fieldName == 'state')) {
            errorObj.state = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'State')
            );
        }
        if (data.state != '' && !nameRegex.test(data.state ?? '')) {
            errorObj.state = String(
                Lang.formatString(Lang.ERROR.COMMON.ONLY_LETTERS, 'State')
            );
        }
        const lenState = data.state?.length ?? 0;
        if (data.state != '' && lenState > 2) {
            errorObj.state = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'State',
                    '2'
                )
            );
        }
        if (data.state != '' && lenState < 2) {
            errorObj.state = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.MUST_BE_N_CHARACTERS,
                    'State',
                    '2'
                )
            );
        }
        if (
            data.state != '' &&
            lenState == 2 &&
            nameRegex.test(data.state ?? '')
        ) {
            errorObj.state = '';
        }

        //zip
        if (data.zip == '' && (frmError || fieldName == 'zip')) {
            errorObj.zip = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Zip')
            );
        }
        if (data.zip != '' && !numberRegex.test(data.zip ?? '')) {
            errorObj.zip = String(
                Lang.formatString(Lang.ERROR.COMMON.MUST_BE_N_DIGIT, 'Zip', '5')
            );
        }
        const lenZip = data.zip?.length ?? 0;
        if (data.zip != '' && (lenZip > 5 || lenZip < 5)) {
            errorObj.zip = String(
                Lang.formatString(Lang.ERROR.COMMON.MUST_BE_N_DIGIT, 'Zip', '5')
            );
        }
        if (
            data.zip != '' &&
            lenZip < 20 &&
            lenZip == 5 &&
            numberRegex.test(data.zip ?? '')
        ) {
            errorObj.zip = '';
        }
        //OwnerName
        if (data.owner_name == '' && (frmError || fieldName == 'owner_name')) {
            errorObj.owner_name = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Owner Name')
            );
        }
        if (data.owner_name != '' && !nameRegex.test(data.owner_name ?? '')) {
            errorObj.owner_name = String(
                Lang.formatString(Lang.ERROR.COMMON.ONLY_LETTERS, 'Owner Name')
            );
        }
        const lenowner_name = data.owner_name?.length ?? 0;
        if (data.owner_name != '' && lenowner_name > 30) {
            errorObj.owner_name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'Owner Name',
                    '30'
                )
            );
        }
        if (
            data.owner_name != '' &&
            lenowner_name < 30 &&
            nameRegex.test(data.owner_name ?? '')
        ) {
            errorObj.owner_name = '';
        }

        //phone Number
        if (data.phone == '' && (frmError || fieldName == 'phone')) {
            errorObj.phone = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Phone Number')
            );
        }
        if (data.phone != '' && !PHONE_NO_REGEX.test(data.phone ?? '')) {
            errorObj.phone = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.PHONE_FORMAT,
                    'Phone Number'
                )
            );
        }
        if (data.phone != '' && PHONE_NO_REGEX.test(data.phone ?? '')) {
            errorObj.phone = '';
        }
        // gender
        if (data.gender_id == 0 && (frmError || fieldName == 'gender')) {
            errorObj.gender = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Gender')
            );
        }
        if (data.gender_id != 0) {
            errorObj.gender = '';
        }
        //nationality
        if (
            data.nationality_id == 0 &&
            (frmError || fieldName == 'nationality')
        ) {
            errorObj.nationality = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Ethnicity')
            );
        }
        if (data.nationality_id != 0) {
            errorObj.nationality = '';
        }
        //verificationAgency_id
        if (
            data.verification_agency_id == 0 &&
            (frmError || fieldName == 'verification_agency')
        ) {
            errorObj.verification_agency = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.REQUIRED,
                    'Verification Agency'
                )
            );
        }
        if (data.verification_agency_id != 0) {
            errorObj.verification_agency = '';
        }
        //verificationExpirationDate
        // if (
        // (data.verification_expiry_date == '' ||
        // data.verification_expiry_date === null) &&
        // (frmError || fieldName == 'verification_expiry_date')
        // ) {
        // errorObj.verification_expiry_date = String(
        // Lang.formatString(
        // Lang.ERROR.COMMON.REQUIRED,
        // 'Verification Exp. Date'
        // )
        // );
        // }
        // if (data.verification_expiry_date != '') {
        // errorObj.verification_expiry_date = '';
        // }

        //ContactName
        if (
            data.contact_name == '' &&
            (frmError || fieldName == 'contact_name')
        ) {
            errorObj.contact_name = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Contact Name')
            );
        }
        if (
            data.contact_name != '' &&
            !nameRegex.test(data.contact_name ?? '')
        ) {
            errorObj.contact_name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.ONLY_LETTERS,
                    'Contact Name'
                )
            );
        }
        const lencontact_name = data.contact_name?.length ?? 0;
        if (data.contact_name != '' && lencontact_name > 30) {
            errorObj.contact_name = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.CAN_NOT_BE_LONGER_THAN,
                    'Contact Name',
                    '30'
                )
            );
        }
        if (
            data.contact_name != '' &&
            lencontact_name < 30 &&
            nameRegex.test(data.contact_name ?? '')
        ) {
            errorObj.contact_name = '';
        }

        //Contact phone Number
        if (
            data.contact_phone_number == '' &&
            (frmError || fieldName == 'contact_phone_number')
        ) {
            errorObj.contact_phone_number = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.REQUIRED,
                    'Contact Phone Number'
                )
            );
        }
        if (
            data.contact_phone_number != '' &&
            !PHONE_NO_REGEX.test(data.contact_phone_number ?? '')
        ) {
            errorObj.contact_phone_number = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.PHONE_FORMAT,
                    'Contact Phone Number'
                )
            );
        }
        if (
            data.contact_phone_number != '' &&
            PHONE_NO_REGEX.test(data.contact_phone_number ?? '')
        ) {
            errorObj.contact_phone_number = '';
        }

        //email
        if (
            data.contact_email == '' &&
            (frmError || fieldName == 'contact_email')
        ) {
            errorObj.contact_email = String(
                Lang.formatString(Lang.ERROR.COMMON.REQUIRED, 'Contact Email')
            );
        }
        if (
            data.contact_email != '' &&
            !EMAIL_REGEX.test(data.contact_email ?? '')
        ) {
            errorObj.contact_email = String(
                Lang.formatString(
                    Lang.ERROR.COMMON.VALID_EMAIL,
                    'Contact Email'
                )
            );
        }
        if (
            data.contact_email != '' &&
            EMAIL_REGEX.test(data.contact_email ?? '')
        ) {
            errorObj.contact_email = '';
        }
    }
    return errorObj;
};

import React, { useEffect, useState, useCallback } from 'react';
import { ATMPopover } from 'shared-it-appmod-ui';
import { isAdmin, isContact, isPrime } from 'src/libraries/users.library';
import { Link, useHistory } from 'react-router-dom';
import { useDashboardContext } from 'src/contexts/dashboard.context';
import { forEach } from 'lodash';

interface ITilesProps {
    value: string;
    title: string;
    border: boolean;
    infoIcon: string;
    link: string;
    status: string;
    color: string;
}

const DashboardSummary: React.FC = () => {
    const { state, actions } = useDashboardContext();
    const isUserAdmin = isAdmin();
    const isUserPrime = isPrime();
    const isUserContact = isContact();
    const [currentApiYear, setCurrentApiYear] = useState(Number(localStorage.getItem('reportYear')));
    useEffect(() => {
        
        fetchYear();
    }, []);

    const fetchData = useCallback(async (currentyear) => {
        let user_id = localStorage.getItem('loginCredential'); //for prime user
        
        //const currentyear = new Date().getFullYear();
        if (isUserContact || isUserAdmin) {
            user_id = '';
        }
        const params = { vendor_id: user_id, spendYear : currentyear};
        await actions?.getDashboardStatus(params);
    }, [actions, currentApiYear]);

    const handleSetStatus = (val)=>{
        localStorage.setItem('subContractorVerificationStatus', val);
    };

    const fetchYear = async () => {
        const data = await actions.reportingyearsGet();
        localStorage.setItem('reportYearUpdate',data.payload.data[0].report_year)
        fetchData(data.payload.data[0].report_year);

        if(localStorage.getItem('reportYear') != localStorage.getItem('reportYearUpdate')) {
            localStorage.setItem('reportYear',data.payload.data[0].report_year)
            setCurrentApiYear(data.payload.data[0].report_year);
            setTimeout(() => {
                window.location.reload();
              }, 100);
        }
    }


    const getFilteredData = (key)=>{
        let res = "0";
        if(state?.dashboardStatus?.data) {
            state?.dashboardStatus?.data.forEach((val, _k) => {
                if(val.type == key) {
                    res = val.count.toString();
                }

            });
        }
        return res;
    }

    const Tile: React.FC<ITilesProps> = ({
        value,
        title,
        border,
        infoIcon,
        link,
        status,
        color
    }) => {
        return (
            <div
                style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    justifyContent: 'flex-end',
                    // alignItems: 'center',
                    maxWidth: 380,
                    minWidth: 240,
                    width: 'auto',
                    borderRight: border ? '3px solid #ccc' : '',
                }}
                // className={[dFlex, fCol, a, center]}
            >
                <div
                    style={{
                        fontSize: 32,
                        marginBottom: 10,
                        color: 'rgb(33, 133, 208)',
                    }}
                >
                    <Link
                        to={link}
                        onClick={() => handleSetStatus(status)}
                        style={{color:color}}
                    >
                        {value}
                    </Link>
                </div>
                <div style={{ fontSize: 13,fontWeight: 'bold' }}>
                    <span dangerouslySetInnerHTML={{ __html: title }}></span>
                    
                    {infoIcon != '' && (
                        <span style={{ position: 'relative' }}>
                            <ATMPopover
                                trigger={
                                    <i
                                        className={`info circle icon`}
                                        style={{
                                            color: '#848080',
                                            position: 'absolute',
                                            right: '-21px',
                                        }}
                                    ></i>
                                }
                                content={infoIcon}
                                size="mini"
                                position="top center"
                                flowing={true}
                                style={{ marginLeft: '0px', marginTop: '10px' }}
                            />
                        </span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {(isUserAdmin || isUserContact) && (
                <div style={{ display: 'contents' }}>
                    <div>
                        <Tile
                            link="/dashboard/subcontractorspendlist"
                            value={getFilteredData(4)}
                            title="Not found<br /> in SCH"
                            border={true}
                            infoIcon="Not found in SCH"
                            status="4"
                            color="rgb(102, 102, 102)"
                        />
                    </div>
                    <div style={{ minWidth: '280px'}}>
                        <Tile
                            link="/dashboard/subcontractorspendlist"
                            value={getFilteredData(3)}
                            title="Verification Expiration <br /> More Than 180 Days Past Due"
                            border={true}
                            infoIcon="Verification Expiration More Than 180 Days Past Due"
                            status="3"
                            color="rgb(237, 92, 87)"
                        />
                    </div>
                    <div>
                        <Tile
                            link="/dashboard/subcontractorspendlist"
                            value={getFilteredData(2)}
                            title="Verification Expiration <br /> 1-180 Days Past Due"
                            border={true}
                            infoIcon="Verification Expiration 1-180 Days Past Due"
                            status="2"
                            color="rgb(243, 144, 29)"
                        />
                    </div>
                    <div>
                        <Tile
                            link="/dashboard/subcontractorspendlist"
                            value={getFilteredData(1)}
                            title="Verification Expiration <br /> Next 60 Days"
                            border={false}
                            infoIcon="Verification Expiration Next 60 Days"
                            status="1"
                            color="rgb(245 212 39)"
                        />
                    </div>
                </div>
            )}
            {isUserPrime && (
                <div style={{ display: 'contents' }}>
                    <div>
                        <Tile
                            link="/primedashboard/subcontractorspendlist"
                            value={getFilteredData(4)}
                            title="Not found<br /> in SCH"
                            border={true}
                            infoIcon=""
                            status="4"
                            color="rgb(102, 102, 102)"
                        />
                    </div>
                    <div>
                        <Tile
                            link="/primedashboard/subcontractorspendlist"
                            value={getFilteredData(3)}
                            title="Verification Expiration <br /> More Than 180 Days Past Due"
                            border={true}
                            infoIcon=""
                            status="3"
                            color="rgb(237, 92, 87)"
                        />
                    </div>
                    <div>
                        <Tile
                            link="/primedashboard/subcontractorspendlist"
                            value={getFilteredData(2)}
                            title="Verification Expiration <br />1-180 Days Past Due"
                            border={true}
                            infoIcon=""
                            status="2"
                            color="rgb(243, 144, 29)"
                        />
                    </div>
                    <div>
                        <Tile
                            link="/primedashboard/subcontractorspendlist"
                            value={getFilteredData(1)}
                            title="Verification Expiration <br /> Next 60 Days"
                            border={false}
                            infoIcon=""
                            status="1"
                            color="rgb(245 212 39)"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardSummary;

import { httpClient } from '../libraries/http.library';
import {
    IForgotPassword,
    ForgotPasswordRedPayloadSchema,
} from '../models/forgot-password.model';

const client = httpClient();

export const services = {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    resetPasswordPost: async (data: IForgotPassword) => {
        return client.post(
            '/changePassword',
            {
                ...data,
            },
            ForgotPasswordRedPayloadSchema
        );
    },
};

export default services;

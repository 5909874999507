import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lang from 'src/libraries/language';
import {
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useContractorPageContext } from 'src/contexts/contractorpage.context';
import { IInvalidData } from 'src/models/contractorpage.model';

import InvalidContractsModalView from './invalid-contracts-edit-modal.view';

type Iprops = {
    open: boolean;
    data: Partial<IInvalidData | undefined>;
    handleOnDiscard: () => void;
    handleSuccess: (msg) => void;
};

const InvalidContractsModalComponent: React.FC<Iprops> = ({
    open,
    data,
    handleOnDiscard,
    handleSuccess
}) => {
    const { state, actions } = useContractorPageContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const [reasonsDisabled, setReasonsDisabled] = useState(true);
    const [updateConfirm, setUpdateConfirm] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const dataRef = useRef<any>();
    const dataReason = useRef<any>();
    const dataIsReason = useRef<any>();
    const dialogRef = useRef<string>();
    const discardStatusRef = useRef<string>();
    const [vonError, setVonError] = useState(false);

    const loginCredential = localStorage.getItem('loginCredential');
    const handleClose = () => {
        setConfirm(false);
        dialogRef.current = 'close';
    };

    const handleOnClose = () => {
        setUpdateConfirm(false);
        dialogRef.current = 'close';
    };
    // useEffect(() => {
        
    // }, [data]);

    const handleCancel = useCallback(() => {
        setConfirm(false);
        setButtonDisable(true);
        handleOnDiscard();
    }, [data, dataRef]);


    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return year + '-' + month + '-' + day;
    }

    const handleSubmitConfirm = async () => {
        if (discardStatusRef.current === 'open') {
            if(data?.spendAllowedAdmin) {            
                const params = {
                    stopExceptionReason: dataRef.current.reason,
                    contract_id: data?.contract_id,
                    userId: loginCredential,
                    contractAdminId:data?.contractAdminId
                };
                const response = await actions.invalidContractAddPUT(
                    params
                );
    
                if (response?.payload) {
                    handleSuccess(
                        response?.payload?.message
                    );
                } else if (!response?.error?.status) {
                    ToastError(response?.error?.message);
                }
            } else {
                const endDate = getFormattedDate(dataRef.current.endDate);
            
                const params = {
                    endDate: endDate,
                    reason: dataRef.current.reason,
                    contract_id: data?.contract_id,
                    userId: loginCredential,
                };
                const response = await actions.invalidContractAddPOST(
                    params
                );
    
                if (response?.payload) {
                    handleSuccess(
                        response?.payload?.message
                    );
                } else if (!response?.error?.status) {
                    ToastError(response?.error?.message);
                }
            }
            
        } else {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        }
    };

    

    const handleSubmit = () => {
        discardStatusRef.current = 'open';
        // setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_UPDATE);
        // setConfirm(true);
        handleSubmitConfirm()
    };

    const handleChange = (key, value: any) => {
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const handleClick = useCallback(() => {
        handleSubmit();
        // if (dataReason.current && !dataIsReason.current) {
        //     ToastError('Please choose any reason!');
        // } else {
        //     dialogRef.current = 'open';
        //     if (formRef && formRef.current) {
        //         handleSubmit();
        //     }
        //     // setUpdateConfirm(true);
        //     // setConfirm(true);
        // }
    }, [setUpdateConfirm]);
    return (
        <>
            <InvalidContractsModalView
                data={data}
                open={open}
                handleClose={handleCancel}
                handleClick={handleClick}
                handleSubmit={handleSubmit}
                formRef={formRef}
                buttonDisable={buttonDisable}
                reasonsDisabled={reasonsDisabled}
                vonError={vonError}
                handleChange={handleChange}
            />
        </>
    );
};

export default InvalidContractsModalComponent;

import { AccessType, AccessPermission, AccessRole } from 'src/constants';

let isAdmin = false;
let isSuperAdmin = false;

export const setIsAdmin = (value = false, superAdmin = false): void => {
    if (superAdmin) {
        isSuperAdmin = value;
    }

    isAdmin = value;
};

export const getIsAdmin = (isSuper = false): boolean =>
    isSuper ? isSuperAdmin : isAdmin;

export const getUserType = (): number => {
    const client_id = localStorage.getItem('loginCredential');
    const user = localStorage.getItem(`${client_id}_user_data`) ?? '';
    const userObj = user != '' && JSON.parse(user);
    return userObj.user_group;
};

export type IHasAccessType = AccessType | AccessType[];

const flattenList = (type: IHasAccessType) => {
    return (Array.isArray(type) ? type : [type]).reduce(
        (a: AccessType[], b) =>
            Array.isArray(b) ? [...a, ...flattenList(b)] : [...a, b],
        []
    );
};

export const hasAccess = (type: IHasAccessType): boolean => {
    const list = flattenList(type);

    const user = getUserType();

    // This will only checks access type designated only for super admin
    if (
        list.every(
            (value) =>
                Array.isArray(AccessPermission[AccessRole.ADMIN]) &&
                AccessPermission[AccessRole.ADMIN].includes(value)
        )
    ) {
        return user === AccessRole.ADMIN;
    }    

    return list.every(
        (value) =>
            Array.isArray(AccessPermission[user]) &&
            AccessPermission[user]?.includes(value)
    );
};

export const checkVisibility = (user_groupArr: AccessType[])  : boolean => {
    // checking page access
    const client_id = localStorage.getItem('loginCredential');

    const user = localStorage.getItem(`${client_id}_user_data`) ?? '';

    const userObj = user != '' && JSON.parse(user);

    if (
        user_groupArr[0] == 0 ||
        user_groupArr?.includes(parseInt(userObj.user_group))
    ) {
        // check for guest user also
        return true;
    } else {
        return false;
    }
};

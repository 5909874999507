import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import ContactUserReducer, {
    defaultState,
    asyncActions,
} from 'src/ducks/contactuser.duck';
// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
    return useReducerHook(ContactUserReducer, defaultState, asyncActions);
};

type IContactUserContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const ContactUserContext = createContext<Partial<IContactUserContext>>({
    state: defaultState,
}) as React.Context<IContactUserContext>;

const ContactUserProvider: React.FC = ({ children }) => {
    const reducer = useReducer();    
    return (
        <ContactUserContext.Provider value={{ ...reducer }}>
            {children}
        </ContactUserContext.Provider>
    );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useContactUserContext: () => IContactUserContext = () => useContext(ContactUserContext);
export type IUseContactUserContext = ReturnType<typeof useContactUserContext>;

export { ContactUserContext, ContactUserProvider };

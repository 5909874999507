import services from 'src/services/contractorpage.service';
import { IContractorPage, IInvalidContractAuditLogResponse } from 'src/models/contractorpage.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';

export const actionTypes = {
    CONTRACTOR_LIST_FETCH: 'CONTRACTOR_LIST_FETCH',
    CONTRACTOR_LIST_ADD: 'CONTRACTOR_LIST_ADD',
    CONTRACTOR_LIST_EDIT: 'CONTRACTOR_LIST_EDIT',
    CONTRACTOR_VERIFY: 'CONTRACTOR_VERIFY',
    UTILITY_LIST_FETCH: 'UTILITY_LIST_FETCH',
    INVALID_CONTRACTS_LIST: 'INVALID_CONTRACTS_LIST',
    INVALID_CONTRACTS_ADD: 'INVALID_CONTRACTS_ADD',
    INVALID_CONTRACTS_UPDATE: 'INVALID_CONTRACTS_UPDATE',
    DASHBOARD_CONTRACT_SUMMARY: 'DASHBOARD_CONTRACT_SUMMARY',
    INVALID_CONTRACTS_AUDIT_LOG: 'INVALID_CONTRACTS_AUDIT_LOG',
    GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS: 'GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS',
    GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS_PRIME:'GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS_PRIME'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET: {
        type: actionTypes.CONTRACTOR_LIST_FETCH,
        service: services.listGET,
        status: {} as never,
    },

    replacePUT: {
        type: actionTypes.CONTRACTOR_LIST_EDIT,
        service: services.replacePUT,
        status: {} as never,
    },

    addPOST: {
        type: actionTypes.CONTRACTOR_LIST_ADD,
        service: services.addPOST,
        status: {} as never,
    },

    verifyPOST: {
        type: actionTypes.CONTRACTOR_VERIFY,
        service: services.verifyPOST,
        status: {} as never,
    },

    ultilityListGET: {
        type: actionTypes.UTILITY_LIST_FETCH,
        service: services.utilityListGET,
        status: {} as never,
    },

    invalidContractListGET: {
        type: actionTypes.INVALID_CONTRACTS_LIST,
        service: services.invalidContractListGET,
        status: {} as never,
    },
    invalidContractAddPOST: {
        type: actionTypes.INVALID_CONTRACTS_ADD,
        service: services.invalidContractAddPOST,
        status: {} as never,
    },

    invalidContractAddPUT: {
        type: actionTypes.INVALID_CONTRACTS_UPDATE,
        service: services.invalidContractAddPUT,
        status: {} as never,
    },
    dashboardContractSummaryGET: {
        type: actionTypes.DASHBOARD_CONTRACT_SUMMARY,
        service: services.getDashboardContractSummary,
        status: {} as never,
    },
    getInvalidContractAuditLog: {
        type: actionTypes.INVALID_CONTRACTS_AUDIT_LOG,
        service: services.getInvalidContractAuditLog,
        status: {} as never,
    },
    getSubcontractingIndividualPODetails: {
        type: actionTypes.GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS,
        service: services.getSubcontractingIndividualPODetails,
        status: {} as never,
    },
    getSubcontractingIndividualContractDetailsPrime: {
        type: actionTypes.GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS_PRIME,
        service: services.getSubcontractingIndividualContractDetailsPrime,
        status: {} as never,
    }
};
export type IContractorpageAsync = typeof asyncActions;

export type IContractorpagePageReducerAction = IAsyncActions<
    IContractorpageAsync
>;
export interface IContractorpageState
    extends ICommonState<IContractorpagePageReducerAction> {
    list: IContractorPage;
    response: any;
    verifyStatus: any;
    utilityList: any;
    invalidContractList: any;
    invalidContractAuditLog: any;
    subcontractingIndividualPODetails: any;
}

export const defaultState: IContractorpageState = {
    status: {},
    list: {},
    response: {},
    verifyStatus: {},
    utilityList: [],
    invalidContractList: {},
    invalidContractAuditLog: {},
    subcontractingIndividualPODetails: {}
};

const ContractorPageReducer = (
    state: IContractorpageState = defaultState,
    action: ICommonAction<IContractorpagePageReducerAction>
): IContractorpageState => {
    switch (action.type) {
        case actionTypes.CONTRACTOR_LIST_FETCH: {
            return {
                ...state,
                list: action.payload ?? {},
            };
        }
        case actionTypes.DASHBOARD_CONTRACT_SUMMARY: {
            return {
                ...state,
                list: action.payload ?? {},
            };
        }
        case actionTypes.CONTRACTOR_LIST_ADD: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        case actionTypes.CONTRACTOR_VERIFY: {
            return {
                ...state,
                verifyStatus: action.payload ?? { 'a': 1 },
            };
        }
        case actionTypes.CONTRACTOR_LIST_EDIT: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        case actionTypes.UTILITY_LIST_FETCH: {
            return {
                ...state,
                utilityList: action.payload ?? [],
            };
        }
        case actionTypes.INVALID_CONTRACTS_LIST: {
            return {
                ...state,
                invalidContractList: action.payload ?? [],
            };
        }
        case actionTypes.INVALID_CONTRACTS_ADD: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        case actionTypes.INVALID_CONTRACTS_UPDATE: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        case actionTypes.INVALID_CONTRACTS_AUDIT_LOG: {
            return {
                ...state,
                invalidContractAuditLog: action.payload ?? {},
            };
        }
        case actionTypes.GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        case actionTypes.GET_SUBCONTRACTING_INDIVIDUAL_PO_DETAILS_PRIME: {
            return {
                ...state,
                response: action.payload ?? {},
            };
        }
        default: {
            return state;
        }
    }
};

export default ContractorPageReducer;

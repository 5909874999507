import yup from '../libraries/validator.library';

export const SpendSchema = yup
    .object({
        contracts: yup
            .array()
            .of(
                yup.object({
                    contract_id: yup.number(),
                    contract_number: yup.string(),
                    vendor_id: yup.number(),
                    description: yup.string().nullable(),
                })
            )
            .defined(),
        contracts_last_page: yup.number().defined(),
        subcontractors: yup
            .array()
            .of(
                yup.object({
                    name: yup.string(),
                    subcontractor_id: yup.number(),
                    is_verified: yup.boolean(),
                })
            )
            .defined(),
        data: yup.array().of(
            yup.mixed()
            // yup.object({
            //     subcontractor_id: yup.number(),
            //     VSID: yup.number(),
            //     SubContractor_Name: yup.string(),
            //     VED: yup.string(),
            //     updated: yup.boolean(),

            // })
        ),
        UtilityData: yup.array().of(
            yup.object({
                Sempra_Company_ID: yup.number(),
                Sempra_Company_Name: yup.string(),
            })
        ),
        spendAmountFilter: yup.array().of(
            yup.object({
                id: yup.number(),
                name: yup.string(),
            })
        ),
    })
    .defined();

export const subcontractorsSchema = yup
    .object({
        data: yup.array().of(
            yup.object({
                name: yup.string(),
                subcontractor_id: yup.number(),
            })
        ),
    })
    .defined();

export const updateSchema = yup.object({
    common: yup.object(),
    updateData: yup.object(),
});

export const sub = yup.object({
    name: yup.string(),
    subcontractor_id: yup.number(),
});

export const contractsSchema = yup
    .object({
        data: yup.array().of(
            yup.object({
                contract_id: yup.number(),
                contract_number: yup.string(),
                company: yup.string(),
                vendor_id: yup.number(),
                isHideForSpend: yup.boolean(),
            })
        ),
    })
    .defined();
    export const primeContractsSchema = yup
    .object({
        data: yup.array().of(
            yup.object({
                vendor_id: yup.number(),
                SAP_ID: yup.number(),
                contract_number: yup.string(),
                comp_code: yup.number(),
                Sempra_Company_ID: yup.number(),
            })
        ),
        UtilityData:  yup.array().of(
            yup.object({
                Sempra_Company_ID: yup.number(),
                Sempra_Company_Name: yup.string(),
            })
        ),
    })
    .defined();


const updateResponseSchema = yup.object({
    message: yup.string(),
    data: yup.string(),
    status: yup.boolean(),
});

export const BlockAndUnblockExternalUserGetSchema = yup
    .object({
        // data: yup.array().of(yup.object({
        //     isBlockedExternalUser: yup.boolean()
        // }).defined()).defined(),
        data: yup.array().of(yup.mixed()).defined(),
        message: yup.string().nullable().defined(),
        status: yup.boolean()
    })
    .defined();
export const ReportingyearsGetSchema = yup
.object({
    data: yup.array().of(yup.mixed()).defined(),
    status: yup.boolean().nullable(),
    message: yup.string(),
})
.defined();


export type IReportingyearsGetSchema = yup.InferType<
typeof ReportingyearsGetSchema
>;
export const SpendListPayloadSchema = SpendSchema;
export const UpdateListPayloadSchema = updateResponseSchema;
export type ISpend = yup.InferType<typeof SpendSchema>;
export type IContracts = yup.InferType<typeof contractsSchema>;
export type IPrimeContracts = yup.InferType<typeof primeContractsSchema>;
export type ISubContracts = yup.InferType<typeof subcontractorsSchema>;
export type IBlockAndUnblockExternalUserGetSchema = yup.InferType<typeof BlockAndUnblockExternalUserGetSchema>;
export type ISub = yup.InferType<typeof sub>;

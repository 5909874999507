import yup from '../libraries/validator.library';

export const ResetPasswordPostSchema = yup //using for post schema
    .object({
        email: yup.string(),
    });

export const ResetPasswordResponseSchema = yup.object({
    message: yup.string(),
    data: yup.array(),
    status: yup.boolean(),
});


export type IResetPassword = yup.InferType<typeof ResetPasswordPostSchema>;
export type IResetPasswordResponse = yup.InferType<typeof ResetPasswordResponseSchema>;

import moment from 'moment';
import React, { useEffect } from 'react';
import * as $ from 'jquery';
import { ATMPopover, ATMTable, ORGDataTable } from 'shared-it-appmod-ui';
import style from '../report.module.scss';
import { formatter } from '../report.component';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants';
import Lang from 'src/libraries/language';

export const Popover: any = () => {
    return (
        <ATMPopover
            trigger={
                <i
                    className={`info circle icon`}
                    style={{
                        color: '#848080',
                    }}
                ></i>
            }
            content={
                'Spend change between previously input and updated amount.'
            }
            size="small"
            position="bottom left"
            className={style.reportTooltip}
            style={{
                marginLeft: '-10px',
                marginTop: '10px',
                color: '#FFFFFFCC',
                background: '#535353',
            }}
        />
    );
};

export const RecordCell: any = ({ record, value, style }) => {
    return (
        <ATMTable.Cell
            className={record.is_verified ? 'updated' : 'unUpdated'}
            style={style ? style : { padding: 0, border: 0 }}
        >
            {value}
        </ATMTable.Cell>
    );
};

export const columns = (isPrime: boolean) => [{
    title: Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY,
    index: 'utility',
    render: (_, record): any => (
        <RecordCell record={record} value={record.utility} />
    ),
},
{
    title: Lang.TTL_PRIME_CONTACT,
    index: 'prime',
    isVisible: !isPrime,
    render: (_, record): any => (
        <RecordCell record={record} value={record.primeName} />
    ),
},
{
    title: Lang.TTL_UPDATE_SUBCONTRACTORS_VONS_SUBCONTRACTOR,
    index: 'subcontractor',
    render: (_, record): any => (
        <RecordCell record={record} value={record.sub} />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT,
    index: 'contract',
    render: (_, record): any => (
        <RecordCell record={record} value={record.contract_number} />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_CONTRACT_NAME,
    index: 'contactName',
    render: (_, record): any => (
        <RecordCell record={record} value={record.contactName} />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_DATE_OF_CHANGE,
    index: 'dateOfChange',
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={moment(record?.changeDate).format('M/D/YYYY @ LT')}
        />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_PREVIOUS_AMOUNT,
    index: 'prevAmount',
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={formatter.format(record.originalSpendAmt)}
        />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_NEW_AMOUNT,
    index: 'newAmount',
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={formatter.format(record.newSpendAmt)}
        />
    ),
},
{
    title: (
        <span
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <span> {Lang.LBL_CHANGE_TO_SPEND_AMOUNT_DIFFERENCE}</span>
            <Popover />
        </span>
    ),
    index: 'difference',
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={
                record.diff > 0
                    ? formatter.format(record.diff)
                    : `(${formatter.format(record.absDiff)})`
            }
        />
    ),
},
{
    title: Lang.TTL_CHANGE_TO_SPEND_AMOUNT_MON_YR,
    index: 'yearPerMonth',
    sortable: false,
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={`${record?.spendMonth?.trim()}/${record?.spendYear?.trim()}`}
        />
    ),
},
{
    title: 'Spend Type',
    index: 'isTier3Spend',
    sortable: false,
    render: (_, record): any => (
        <RecordCell
            record={record}
            value={
                record.isTier3Spend 
                    ? 'Tier III'
                    : 'Tier II'
            }
        />
    ),
},
];
const ChangesToSpendAmountView: React.FC<any> = ({
    data,
    onChangesPageChange,
    total,
    loading,
}) => {
    useEffect(() => {
        // $('.unUpdated').parent().css({
        //     backgroundColor: '#D0191908',
        //     fontWeight: 'bold',
        //     color: '#D01919',
        // });
        const unUpdated = document.querySelectorAll('.unUpdated');
        unUpdated.forEach((unUpdatedItem) => {
            unUpdatedItem?.parentElement?.parentElement?.classList.remove(
                style.updatedParent
            );
            unUpdatedItem?.parentElement?.parentElement?.classList.add(
                style.unUpdatedParent
            );
        });

        const updated = document.querySelectorAll('.updated');
        updated.forEach((updatedItem) => {
            updatedItem?.parentElement?.parentElement?.classList.remove(
                style.unUpdatedParent
            );
            updatedItem?.parentElement?.parentElement?.classList.add(
                style.updatedParent
            );
        });

        // $('.updated').parent().css({
        //     backgroundColor: '#fff',
        //     fontWeight: 400,
        //     color: 'rgba(0, 0, 0, 0.87)',
        // });
    }, [data]);
    const isPrime = AccessRole.PRIME === getUserType();
    return (
        <div style={{ marginTop: '10px' }}>
            <ORGDataTable
                celled={false}
                loading={loading}
                columns={columns(isPrime)}
                data={data || []}
                addParams={['keyword']}
                total={total}
                onChange={(i) => onChangesPageChange(i)}
                // history
                key={total}
                showPagination={true}
                rowsPerPageOptions={[10, 20, 25]}
                className={style.rowTable1}
                sortable
            />
        </div>
    );
};

export default ChangesToSpendAmountView;

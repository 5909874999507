import React from 'react';
import style from './subcontractor.module.scss';
import { Container } from 'semantic-ui-react';
import { isAdmin, isContact, isPrime } from 'src/libraries/users.library';
import {
    ORGDataTable,
    ATMButton,
    ATMPopover,
    ATMLabel,
    ATMGrid,
    IORGDataTableQueryState,
    ATMHeader,
    ATMSegment,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Instruction from 'src/components/atoms/instruction/instruction.component';

export type IProps = {
    tabs: any;
    AddSubContractor: () => void;
};

const SubcontractorView: React.FC<IProps> = ({ tabs, AddSubContractor }) => {
    const isUserPrime = isPrime();
    const isUserAdmin = isAdmin();
    const isUserContact = isContact();
    return (
        <>
            <Container fluid className={style.wrapper}>
                {isUserPrime && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                                {Lang.LBL_SUBCONTRACTORS}
                            </ATMHeader>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingTop: '13px',
                                    paddingBottom: '13px',
                                }}
                            >
                                <ATMButton
                                    className="ui secondary button"
                                    onClick={() => AddSubContractor()}
                                >
                                    <i
                                        className="plus icon"
                                        style={{
                                            background: 'transparent',
                                        }}
                                    ></i>
                                    {Lang.TTL_ADD_SUBCONTRACTOR}
                                </ATMButton>
                            </div>
                        </div>
                        <Instruction
                            title={Lang.LBL_NOTE}
                            content={Lang.MSG_SUBCONTRACTOR_LIST_INSTRUCTION}
                        />
                        {tabs}
                    </>
                )}
            </Container>
        </>
    );
};

export default SubcontractorView;

import React, { useEffect, useState, useCallback } from 'react';
import { ATMButton, ATMGrid, ATMHeader, ATMSelect } from 'shared-it-appmod-ui';

import Lang from 'src/libraries/language';
import style from './update-spend.module.scss';

interface Iprops {
    spendStage: string;
    fillText: any;
    isUserPrime: boolean;
    utilsList: any;
    datastate: any;
    handleUtilityDataChange: (value) => void;
    disabled: boolean;
}
const UtilityView: React.FC<Iprops> = ({
    spendStage,
    fillText,
    isUserPrime,
    utilsList,
    datastate,
    handleUtilityDataChange,
    disabled
}) => {


    return (

        <>
            <ATMGrid.Column
                className={
                    spendStage == '' ? style.utilListHide : (isUserPrime
                        ? style.utilList
                        : style.normalContract)
                }
            >

                <ATMGrid.Column
                    className={style.lableFieldAlign}
                >
                    <span style={fillText}>Select Utility</span>
                    <ATMSelect
                        className="ddDefault"
                        placeholder={'Select Utility'}
                        defaultValue={''}
                        selection
                        search

                        disabled={disabled ? true :
                            datastate?.list.UtilityData?.length
                                ? false
                                : true
                        }
                        options={
                            // !isUserPrime
                            //     ? utilsList
                            //     : 
                            datastate?.list?.UtilityData?.map(
                                    (v) => ({
                                        key: v?.Sempra_Company_ID,
                                        value: v?.Sempra_Company_ID,
                                        text: v?.Sempra_Company_Name,
                                    })
                                )
                        }
                        onChange={(i, v) => {
                            console.log('Start T:', new Date().toJSON())
                            handleUtilityDataChange(v)
                            // filterUtils(
                            //     v,
                            //     setUtilsFilter,
                            //     handleUtilsChange,
                            //     //handlePOChange,
                            //     handleSearch
                            // );
                        }}
                        style={{
                            marginBottom: 5,
                            marginRight: 15,
                            height: 'fit-content',
                        }}
                    />
                </ATMGrid.Column>
            </ATMGrid.Column>
        </>
    );


}

export default UtilityView;
import React from 'react';
import SubcontractorAddView from './subcontractor-add-view.view';

export type IProps = {
    isOpen: boolean;
    data: Record<string, unknown>;
    handleClose: () => void;

    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
};
const SubcontractorAdd: React.FC<IProps> = ({
    formRef,
    handleClose,
    data,
    ...props
}) => {
    console.log('SMR', data)
    return (
        <>
            <SubcontractorAddView
                {...props}
                data={data}
                loading={false}
                formRef={formRef}
                handleClose={handleClose}
            />
        </>
    );
};

export default SubcontractorAdd;

import React, { useCallback, useEffect, useState } from 'react';
import { ATMSegment } from 'shared-it-appmod-ui';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import { useContractorContext } from 'src/contexts/contractor.context';
import history from 'src/history';
import Lang from 'src/libraries/language';
import SubcontractorList from './subcontractor-list/subcontractor-list.component';
import style from './subcontractor.module.scss';
import SubcontractorView from './subcontractor.view';
import { Card } from 'semantic-ui-react';
import { FunctionalityDisableText } from '../constant/message-constant.component';
import { usePOGuardrailsContext } from 'src/contexts/po-guardrails.context';

const createPane = (menuName: string, Component: any, value: number) => ({
    menuItem: menuName,
    render: () => (
        <ATMSegment>
            <Component verificationExpirationStatus={value} />
        </ATMSegment>
    ),
});

const Subcontractor: React.FC = () => {
    const [open, setOpen] = useState(false);
    const { state: schFileState, actions: schFileActions } = useContractorContext();
  const { state: poState, actions: poActions } = usePOGuardrailsContext();

  const [isRollBackNOverTriggered, setRollBackNOverTriggered] = useState(false);
  const [isSCHFileRunning, setSCHFileRunnig] = useState(false);
  const [isPOValidationBatchJobRunning, setPOValidationBatchJobRunnig] = useState(false);
  const [isDisableFunctionality, setDisableFunctionality] = useState(false);

  const checkSCHFileStatus = async () => {
    const response = await schFileActions.schFileStatusGet();
    if (response.payload?.status) {
      setSCHFileRunnig(response.payload?.data.isInProgress)
    }
  };

  const checkPOValidationBatchJobStatus = async () => {
    const response = await poActions.getPOValidationBatchJobStatus();
    if (response.payload?.status) {
      setPOValidationBatchJobRunnig(response.payload?.data.isInProgress);
      setRollBackNOverTriggered(response.payload?.data.isRollBackNOverTriggered)
    }
  };

  useEffect(() => {
    if (isSCHFileRunning || (isPOValidationBatchJobRunning && isRollBackNOverTriggered)) {
        setDisableFunctionality(true)
    } else {
      setDisableFunctionality(false)
    }
  }, [isSCHFileRunning, isPOValidationBatchJobRunning, isRollBackNOverTriggered])




    useEffect(() => {
        checkSCHFileStatus();
        checkPOValidationBatchJobStatus();
    }, [])

    const AddSubContractor = useCallback(() => {
        history.push(`/subcontractors/add`);
    }, [setOpen]);

    const panes = [
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_ALL,
            SubcontractorList,
            0
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_NEXT_60_DAYS,
            SubcontractorList,
            1
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_1_180_DAYS_PAST_DUE,
            SubcontractorList,
            2
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_LIST_MORE_THAN_180_DAYS_PAST_DUE,
            SubcontractorList,
            3
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_LIST_NOT_FOUND_IN_SCH,
            SubcontractorList,
            4
        ),
        createPane(
            Lang.LBL_SUBCONTRACTOR_ADD_SELECTED_VERIFIED,
            SubcontractorList,
            5
        ),
    ];
    return isDisableFunctionality ? (<Card style={{
        display: 'flex',
        marginTop: '100px',
        width: '100%',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center'
    }
    } >
        <FunctionalityDisableText />
    </Card >) : (<SubcontractorView
        tabs={
            <Tabs
                menu={{
                    fluid: false,
                    vertical: false,
                    pointing: true,
                    compact: true,
                    size: 'small',
                }}
                panes={panes}
                segment="basic"
            />
        }
        AddSubContractor={AddSubContractor}
    />)
        ;
};

export default Subcontractor;

import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';
import {
    ATMGrid,
    ATMForm,
    ATMField,
    ATMButton,
    ATMHeader,
    ATMInput,
    ATMCheckbox,
    ATMContainer,
    ATMSelect,
    ATMRadio,
    ATMLabel,
    ATMMessage,
    ORGDataTable,
    ATMTable,
    ATMPagination,
} from 'shared-it-appmod-ui';

import Lang from 'src/libraries/language';
import { MassMailFormSchema } from 'src/models/mass-mail.model';
import styles from '../information/manage-notification.module.scss';

interface showType {
    by_supplier?: boolean;
    primes_located_in?: boolean;
    primes_with?: boolean;
    all_primes?: boolean;
}

export type IProps = {
    isSending: boolean;
    loading: boolean;
    handleClick: () => void;
    handleSubmit: () => void;
    handleChange: (id: string, value: string) => void;
    showTo: showType | any;
    mailSent: boolean;
    states: [] | any;
    cities: [] | any;
    dbeList: [] | any;
    data: any;
    handleFileChange: (e: any) => void;
    resetForm: () => void;

    setValue: (val: boolean) => void;

    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
};

const ManageNotificationView: React.FC<IProps> = ({
    isSending,
    formRef,
    mailSent,
    handleSubmit,
    handleChange,
    loading,
    showTo,
    states,
    cities,
    data,
    handleFileChange,
    resetForm,
    dbeList,
}) => {

    const information = [
        {
            notification: "Prime contract update required",
            action_required: "Yes",
            sent_date: "6/30/2021",
            sent_to: "All Primes",
            duration: "2 Days"
        },
        {
            notification: "Contact update required",
            action_required: "Yes",
            sent_date: "1/30/2021",
            sent_to: "All Primes",
            duration: "1 Week"
        },
        {
            notification: "Prime contract update required",
            action_required: "Yes",
            sent_date: "6/30/2021",
            sent_to: "All Primes",
            duration: "2 Days"
        },
        {
            notification: "Prime contract update required",
            action_required: "Yes",
            sent_date: "6/30/2021",
            sent_to: "All Primes",
            duration: "2 Days"
        },
        {
            notification: "Prime contract update required",
            action_required: "Yes",
            sent_date: "6/30/2021",
            sent_to: "All Primes",
            duration: "2 Days"
        },
    ];

    const columns = [
        {
            title: 'Notification',
            index: 'notification',
            sortable: false,
        },
        {
            title: 'Action Required',
            index: 'action_required',
            sortable: false,
        },
        {
            title: 'Sent Date',
            index: 'sent_date',
            sortable: false,
        },
        {
            title: 'Sent To',
            index: 'sent_to',
            sortable: false,
        },
        {
            title: 'Duration',
            index: 'duration',
            sortable: false,
        },
        {
            title: 'Actions',
            index: 'actions',
            sortable: false,
            render: (a: boolean, value) => (
                <>
                    <ATMButton
                        icon="refresh"
                        borderless
                        onClick={()=>alert('clicked')}
                    />    
                    <ATMButton
                        icon="trash alternate"
                        borderless
                        onClick={()=>alert('clicked')}
                    />
                </>
            ),
        },
    ];



    return (
        <>
            <ATMContainer
                fluid
                className={styles.resetInner}
                style={{ marginBottom: '20px' }}
            >
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <ATMHeader as="h1">{Lang.TTL_MANAGE_NOTIFICATIONS}</ATMHeader>
                            <ATMMessage
                                info
                                width
                                header="Note"
                                content={Lang.ADMIN_MANAGE_NOTIFICATION_NOTE}
                            />
                        </div>
                    </div>

                    <div style={{    
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        marginTop: "2rem", 
                    }}>
                        <ATMButton
                            primary
                            onClick={()=>alert("notification button clicked")}
                        >
                            {Lang.LBL_SEND_NEW_NOTIFICATION}
                        </ATMButton>
                    </div>

                    <ATMGrid
                        // style={{ marginTop: '2rem' }}
                    >
                        <ATMGrid.Row className={styles.loginrow}>
                            <ATMGrid.Column>
                                {/* <ATMTable>
                                    <ATMTable.Header>
                                        <ATMTable.Row>
                                            {
                                                columns?.map((item) => (
                                                    <ATMTable.HeaderCell key={item.title}>{item.title}</ATMTable.HeaderCell>
                                                ))
                                            }
                                        </ATMTable.Row>
                                    </ATMTable.Header>

                                    <ATMTable.Body>
                                        {
                                            information?.map((item, index) => (
                                                <ATMTable.Row key={index}>
                                                    <ATMTable.Cell>{item.notification}</ATMTable.Cell>
                                                    <ATMTable.Cell>{item.action_required}</ATMTable.Cell>
                                                    <ATMTable.Cell>{item.sent_date}</ATMTable.Cell>
                                                    <ATMTable.Cell>{item.sent_to}</ATMTable.Cell>
                                                    <ATMTable.Cell>{item.duration}</ATMTable.Cell>
                                                    <ATMTable.Cell>
                                                        <ATMButton
                                                            icon="refresh"
                                                            borderless
                                                            onClick={()=>alert('clicked')}
                                                        />    
                                                        <ATMButton
                                                            icon="trash alternate"
                                                            borderless
                                                            onClick={()=>alert('clicked')}
                                                        />    
                                                    </ATMTable.Cell>
                                                </ATMTable.Row>
                                            ))
                                        }
                                    </ATMTable.Body>
                                </ATMTable> */}
                                {/* <div style={{
                                    margin: "3rem",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <ATMPagination
                                        defaultActivePage={5}
                                        totalPages={10}
                                    />
                                </div> */}

                                <div>
                                    <ORGDataTable
                                        celled={false}
                                        counter={true}
                                        data={information}
                                        columns={columns}
                                        onChange={(val) => {
                                            console.log('Table state:', val); 
                                        }}
                                        rowsPerPageOptions={[10, 20, 30]}
                                        className={styles.customTable}
                                    />
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </>
            </ATMContainer>
        </>
    );
};

export default ManageNotificationView;

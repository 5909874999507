import React, { useEffect, useState } from 'react';
import {
    ATMDropdown,
    ATMPagination,
    ATMTable,
    ATMLoader,
} from 'shared-it-appmod-ui';
import { formatter } from '../report.component';
import style from '../report.module.scss';
import Lang from 'src/libraries/language';

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Total',
];

export const unVerifiedSpendMonths = [
    'unverifiedJanSpend',
    'unverifiedFebSpend',
    'unverifiedMarSpend',
    'unverifiedAprSpend',
    'unverifiedMaySpend',
    'unverifiedJunSpend',
    'unverifiedJulSpend',
    'unverifiedAugSpend',
    'unverifiedSepSpend',
    'unverifiedOctSpend',
    'unverifiedNovSpend',
    'unverifiedDecSpend',
    'unverifiedTotal',
];

export const totalMonths = [
    'janTotal',
    'febTotal',
    'marchTotal',
    'aprilTotal',
    'mayTotal',
    'junTotal',
    'julytotal',
    'augTotal',
    'septTotal',
    'octTotal',
    'novTotal',
    'decTotal',
    'totalAmount',
];

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const ByPurchaseOrder: any = ({
    listByPurchaseOrder,
    onPurchaseOrderPageChange,
    loading,
}) => {
    const [pageOption, selectPageOption] = useState(20);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const [pageNumber, setPageNumber] = useState(1);

    const spendMonths = months.map((i) => i.toLowerCase() + 'Spend');

    useEffect(() => {
        handlePageChange();
    }, [pageOption, pageNumber]);



    const renderMonthsValue = (j, k) => {
        return (
            <ATMTable.Cell>{j[k] ? formatter.format(j[k]) : '-'}</ATMTable.Cell>
        );
    };

    const sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            const x = a[key];
            const y = b[key];
            return x > y ? -1 : x < y ? 1 : 0;
        });
    };

    const pageOptions = [10, 20, 25].map((i) => ({
        key: i,
        value: i,
        text: i,
    }));

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window]);

    const handlePageChange = () => {
        onPurchaseOrderPageChange({
            page: pageNumber,
            limit: pageOption,
        });
    };

    const arrangeData = (list: any) => {
        return list.data;
    };

    return (
        <div style={{ marginTop: '10px' }}>
            <ATMTable celled className={style.purchaseOrderTable}>
                <ATMTable.Header>
                    <ATMTable.Row>
                        <ATMTable.HeaderCell>{Lang.LBL_ADMIN_USER_CONFIG_UTILITY}</ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>{Lang.LBL_REPORT_BY_PURCHASE_ORDER_CONTRACT}</ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>Spend Type </ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>Subcontractor </ATMTable.HeaderCell>
                        {months.map((i) => {
                            return (
                                <ATMTable.HeaderCell key={i}>
                                    {i}
                                </ATMTable.HeaderCell>
                            );
                        })}
                    </ATMTable.Row>
                </ATMTable.Header>
                <ATMTable.Body>
                    {loading === true ? (
                        <tr>
                            <td colSpan={15}>
                                <ATMLoader
                                    active
                                    inline="centered"
                                    size="large"
                                />
                            </td>
                        </tr>
                    ) : (
                        arrangeData(listByPurchaseOrder)?.map((i) => {
                            return (
                                <>
                                    <ATMTable.Row><ATMTable.Cell
                                        style={{ marginRight: 1 }}
                                        rowSpan={
                                            i?.subcontractors?.length + 2
                                        }
                                    >{i?.info?.utility}</ATMTable.Cell>
                                        <ATMTable.Cell
                                            style={{ marginRight: 1 }}
                                            rowSpan={
                                                i?.subcontractors?.length + 2
                                            }
                                        >
                                            <b>{i.info.contract_number}</b>
                                            <br />
                                            <>{i.info.description}</>
                                            <br />
                                        </ATMTable.Cell>
                                    </ATMTable.Row>

                                    {sortByKey(
                                        i.subcontractors,
                                        'isVerified'
                                    ).map((j) => {
                                        if (j.isVerified) {
                                            // return true;
                                            return (
                                                <ATMTable.Row key=""
                                                //  style={{
                                                //     backgroundColor: j.isTier3Spend && '#F3FFEB',
                                                // }}
                                                >
                                                    <ATMTable.Cell>
                                                        <b>{j.isTier3Spend ? 'Tier III' : 'Tier II'}</b>
                                                    </ATMTable.Cell>
                                                    <ATMTable.Cell>
                                                        {j.subcontractor}
                                                    </ATMTable.Cell>

                                                    {spendMonths.map((k) => {
                                                        return renderMonthsValue(
                                                            j,
                                                            k
                                                        );
                                                    })}
                                                </ATMTable.Row>
                                            );
                                        } else if (!j.isVerified) {
                                            // return true;
                                            return (
                                                <ATMTable.Row key=""
                                                    className={`${style.red} `}
                                                    // style={{
                                                    //     backgroundColor: j.isTier3Spend && '#F3FFEB',
                                                    // }}
                                                >
                                                <ATMTable.Cell>
                                                    <b>{j.isTier3Spend ? 'Tier III' : 'Tier II'}</b>
                                                </ATMTable.Cell>
                                                    <ATMTable.Cell>
                                                        {j.subcontractor.toUpperCase()}
                                                    </ATMTable.Cell>
                                                    {unVerifiedSpendMonths.map(
                                                        (k) => {
                                                            return renderMonthsValue(
                                                                j,
                                                                k
                                                            );
                                                        }
                                                    )}
                                                </ATMTable.Row>
                                            );
                                        } else {
                                            // return true;
                                            return (
                                                <ATMTable.Row key=""></ATMTable.Row>
                                            );
                                        }
                                    })}
                                    <ATMTable.Row className={style.totals}>
                                        <ATMTable.Cell>

                                        </ATMTable.Cell>
                                        <ATMTable.Cell>
                                            {'Contract Month Totals'}
                                        </ATMTable.Cell>
                                        {totalMonths.map((k, index) => {
                                            return (
                                                <ATMTable.Cell key={index}>
                                                    {i.poMonthTotal[k] !=
                                                        undefined
                                                        ? formatter.format(
                                                            i.poMonthTotal[k]
                                                        )
                                                        : 0}
                                                </ATMTable.Cell>
                                            );
                                        })}
                                    </ATMTable.Row>
                                </>
                            );
                        })
                    )}
                </ATMTable.Body>
            </ATMTable>
            <ATMTable.Footer
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <ATMTable.Row colSpan="15">
                    <span>
                        {Lang.LBL_ROW_PER_PAGE}&nbsp;
                        <ATMDropdown
                            compact
                            options={pageOptions}
                            value={pageOption}
                            onChange={(i, j) =>
                                selectPageOption(Number(j.value))
                            }
                        />
                    </span>
                    <ATMTable.HeaderCell
                        style={{ marginLeft: windowDimensions.width }}
                    >
                        <ATMPagination
                            style={{
                                marginLeft:
                                    (windowDimensions.width - 200) / 2.5,
                            }}
                            defaultActivePage={1}
                            totalPages={Math.ceil(
                                listByPurchaseOrder?.total_rows /
                                Number(pageOption)
                            )}
                            size="tiny"
                            onPageChange={(i, j) => {
                                setPageNumber(Number(j.activePage));
                            }}
                        />
                    </ATMTable.HeaderCell>
                </ATMTable.Row>
            </ATMTable.Footer>
        </div>
    );
};

export default ByPurchaseOrder;

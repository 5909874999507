import React from 'react';
import MonthSummaryReportAdminTierIIIView from './month-summary-report-admin-tier-III.view';

const MonthSummaryReportAdminTierIIIComponent: React.FC<any> = (
    {
    subcontractors,
    structureAdminData,
    monthSummaryAdminList,
    onMonthSummaryOrderAdminPageChange,
    loading,
    reportType,
    setResetPagination,
    resetPagination,
}
) => {
    console.log("data",monthSummaryAdminList?.data)
    return (
        <>
            <MonthSummaryReportAdminTierIIIView
                setResetPagination={setResetPagination}
                resetPagination={resetPagination}
                verifiedData={structureAdminData(
                    monthSummaryAdminList?.data,
                    'verified',
                    subcontractors
                )}
                unVerifiedData={structureAdminData(
                    monthSummaryAdminList?.data,
                    'unverified',
                    subcontractors
                )}
                filterData={monthSummaryAdminList?.filters_data?.totalVerifyAndNonverifySpend}
                utilityData={monthSummaryAdminList?.filters_data?.UtilityData}
                utilityID={monthSummaryAdminList?.filters_data?.utilityID}
                onMonthSummaryOrderAdminPageChange={
                    onMonthSummaryOrderAdminPageChange
                }
                monthSummaryAdminList={monthSummaryAdminList}
                loading={loading}
                reportType={reportType}
            />
        </>
    );
};

export default MonthSummaryReportAdminTierIIIComponent;

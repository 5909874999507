import React from 'react';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants';
import Lang from 'src/libraries/language';
import style from './prime-information.module.css';
import { Container } from 'semantic-ui-react';
import { ORGDataTable, ATMSegment } from 'shared-it-appmod-ui';
import { formatter } from '../report.component';

const isPrime = AccessRole.PRIME === getUserType();

const PrimeInformationView: React.FC<any> = ({
    data,
    loading,
    onPrimeInfoPageChange,
    totalRows,
}) => {
    const columns = [
        {
            title: Lang.TTL_REPORT_PRIME_INFO_PRIME_INFORMATION,
            index: 'prime',
            isVisible: !isPrime,
            render: (_, record): any => (
                <>
                    <span className={style.rowData}>
                        <>
                            <p>
                                <b> {record?.name}</b>
                            </p>
                            <p> {record?.contactName ?? '-'} </p>
                            <p> {record?.contactPhone} </p>
                            <p> {record?.contactEmail} </p>
                            <p> {record?.address} </p>
                            <p>
                                {' '}
                                {`${record?.city}, ${record?.state}  ${record?.zip}`}{' '}
                            </p>
                        </>
                    </span>
                </>
            ),
            cellProps: {
                style: {
                    borderRight: 1,
                    borderRightStyle: 'solid',
                    borderRightColor: '#e3e4e6',
                },
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_SAPID,
            index: 'vendorId',
            isVisible: !isPrime,
            render: (_, record): any => (
                <span className={style.rowData}>{record.sapId ?? '-'}</span>
            ),
            headerProps: {
                className: style.vendorId,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_SUPPLIER_CODE,
            index: 'supplierCode',
            sortable: false,
            isVisible: !isPrime,
            render: (_, record): any => (
                <span className={style.rowData}>
                    {record.supplierCode ?? '-'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_STATUS,
            index: 'status',
            sortable: false,
            isVisible: !isPrime,
            render: (_, record): any => (
                <span className={style.rowData}>
                    {record.active ? 'Active' : 'Inactive'}
                </span>
            ),
            headerProps: {
                className: style.vendorId,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_AGREEMENTS_ALLOWED,
            index: 'allowAgreements',
            sortable: false,
            isVisible: !isPrime,
            render: (_, record): any => (
                <span className={style.rowData}>
                    {record.allowAgreements ? 'Yes' : 'No'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_CURRENT_SPEND,
            index: 'spend',
            sortable: false,
            isVisible: !isPrime,
            render: (_, record): any => (
                <span className={style.rowData}>
                    {record.spend ? `${formatter.format(record.spend)}` : '-'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: (
                <>
                    {Lang.TTL_PRIME_CONTACT_SUPPLIER_DIVERSITY} <br />{' '}
                    {Lang.TTL_PRIME_CONTACT_CONTACT}
                    {` (${'SDG&E'})`}
                </>
            ),
            index: 'sdgAdvisor',
            sortable: false,
            render: (_, value) => (
                <span className={style.rowData}>{value.sdgAdvisor ?? '-'}</span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: (
                <>
                    {Lang.TTL_PRIME_CONTACT_SUPPLIER_DIVERSITY} <br />{' '}
                    {Lang.TTL_PRIME_CONTACT_CONTACT}
                    {` (${'SoCalGas'})`}
                </>
            ),
            //isVisible:isAdmin,
            index: 'socalGasAdvisor',
            sortable: false,
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.socalGasAdvisor ?? '-'}
                </span>
            ),
            headerProps: {
                className: style.socal,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_1,
            index: 'contact1',
            sortable: false,
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact1?.trim().length ? value.contact1 : '-'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_2,
            index: 'contact2',
            sortable: false,
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact2?.trim().length ? value.contact2 : '-'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTACT_3,
            index: 'contact3',
            sortable: false,
            render: (_, value) => (
                <span className={style.rowData}>
                    {value.contact3?.trim().length ? value.contact3 : '-'}
                </span>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_SECONDARY_CONTACT,
            index: 'secondary_contact',
            sortable: false,
            render: (_, value) => (
                <>
                    <p> {value?.contactName2 ?? '-'} </p>
                    <p> {value?.contactPhone2 ?? '-'} </p>
                    <p> {value?.contactEmail2 ?? '-'} </p>
                </>
            ),
            headerProps: {
                className: style.header,
            },
        },
        {
            title: Lang.TTL_REPORT_PRIME_INFO_OPTIONAL_CONTACT,
            index: 'optional_contact',
            sortable: false,
            render: (_, value) => (
                <>
                    <p> {value?.contactName3 ?? '-'} </p>
                    <p> {value?.contactPhone3 ?? '-'} </p>
                    <p> {value?.contactEmail3 ?? '-'} </p>
                </>
            ),
            headerProps: {
                className: style.header,
            },
        },
    ];

    return (
        <Container fluid className={style.wrapper}>
            <ATMSegment style={{ marginTop: '20px' }}>
                <ORGDataTable
                    key={totalRows}
                    sortable
                    celled={false}
                    columns={columns}
                    data={data}
                    enableScroll
                    fixedColumns={'first'}
                    loading={loading}
                    toolbar
                    // history
                    filters={true}
                    showPagination={true}
                    noDataText={Lang.MSG_NO_RECORDS_TO_DISPLAY}
                    total={totalRows}
                    rowsPerPageOptions={[10, 20, 25]}
                    onChange={(i) => onPrimeInfoPageChange(i)}
                />
            </ATMSegment>
        </Container>
    );
};

export default PrimeInformationView;

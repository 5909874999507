import yup from '../libraries/validator.library';
const passREGEX = /(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}/;
export const ForgotPasswordSchema = yup
    .object({
        old_password: yup.string().required('Old Password is required.'),
        password: yup.string()
        .matches(passREGEX, 'Password must meet the password requirements.')
        .required('Password is required.'),
        repeat_password: yup
            .string()
            .matches(passREGEX, 'Password must meet the password requirements.')
            .test('passwords-match', 'The Password and Confirm password do not match.', function (value) {
                return this.parent.password === value;
            }),

    })
    .defined();

export const ForgotPasswordPayloadSchema = yup.object({
    data: yup
        .object({
            change_password: yup.string(),
            token: yup.string(),
        })
        .defined(),
});

export const ForgotPasswordRedPayloadSchema = yup.object({
    message: yup.string(),
    status: yup.boolean(),
}).defined();

export type IForgotPassword = yup.InferType<typeof ForgotPasswordSchema>;
export type IForgotPasswordPayload = yup.InferType<
    typeof ForgotPasswordPayloadSchema
>;

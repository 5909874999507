import React from 'react'
import { ATMGrid, ATMIcon, ATMModal } from 'shared-it-appmod-ui';
import { concatinateArrayWithSpace } from 'src/components/pages/utils/ArrayUtils';
import Lang from 'src/libraries/language';


interface IProps {
    data: any; open: boolean; handleClose: () => void
}

const MassMailAuditLogEmailView: React.FC<IProps> = ({ data, open, handleClose }) => {
    const formattedText = "<p>" + data?.body.replace(/\n\s*/g, "</p><p>") + "</p>";
    
    return (
        <ATMModal
            size="small"
            open={open}
            onClose={handleClose}
            closeOnDimmerClick={false}
        >
            <ATMModal.Header as="h5">
                {Lang.TTL_REPORT_VERIFICATION_FOLLOW_UP_EMAIL}
                <ATMIcon name="close" onClick={handleClose} style={{ color: '#606060', position: 'absolute', 'right': '0', top: '5px', cursor: 'pointer' }} />

            </ATMModal.Header>

            <ATMModal.Content>
                <ATMGrid columns={2}>
                <ATMGrid.Row >
                        <ATMGrid.Column style={{ width: '10%', textAlign: 'left', paddingTop: '15px', paddingBottom: '10px' }}><b>{Lang.LBL_TO}:</b><div style={{paddingTop:'10px'}}><b>{Lang.LBL_SUBJECT}:</b></div></ATMGrid.Column>
                        <ATMGrid.Column style={{ width: '90%', textAlign: 'left', paddingTop: '15px', paddingBottom: '10px' }}>{concatinateArrayWithSpace([data.emailTo,data.sdcName])}<div style={{paddingTop:'10px'}}>{data.subject}</div></ATMGrid.Column>
                    </ATMGrid.Row>
                    {/* <ATMGrid.Row >
                        <ATMGrid.Column style={{ width: '10%', textAlign: 'left', paddingBottom: '10px' }}><b>{Lang.LBL_SUBJECT}:</b></ATMGrid.Column>
                        <ATMGrid.Column style={{ width: '90%', textAlign: 'left', paddingBottom: '10px' }}>{data.subject}</ATMGrid.Column>
                    </ATMGrid.Row> */}
                </ATMGrid>
                <hr />
                <ATMGrid columns={1}>
                    <ATMGrid.Row >
                        <ATMGrid.Column style={{ width: '100%', textAlign: 'left', paddingTop: '25px', paddingBottom: '25px' }}>
                            <div dangerouslySetInnerHTML={{ __html: formattedText }} />
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </ATMModal.Content>
        </ATMModal>
    )
}

export default MassMailAuditLogEmailView
import services from 'src/services/spend.service';
import { 
    ISpend,
    IContracts, 
    ISubContracts, 
    IPrimeContracts, 
    IBlockAndUnblockExternalUserGetSchema,
    IReportingyearsGetSchema
 } from 'src/models/spend.model';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import {
    IContractor
} from 'src/models/contractor.model';
export const actionTypes = {
    SPEND_LIST_FETCH: 'SPEND_LIST_FETCH',
    SPEND_LIST_UPDATE: 'SPEND_LIST_UPDATE',
    SUBCONTRACTS_LIST: 'SUBCONTRACTS_LIST',
    CONTRACTS_LIST: 'CONTRACTS_LIST',
    PRIMECONTRACTS: 'PRIMECONTRACTS',
    CONTRACTS_LIST_ALL: 'CONTRACTS_LIST_ALL',
    SUBCONTRACTORS_LIST: 'SUBCONTRACTORS_LIST',
    NOT_SPEND_MONTH_UPDATE: 'NOT_SPEND_MONTH_UPDATE',
    BLOCK_UNBLOCK_EXTERNAL_USERS_GET: 'BLOCK_UNBLOCK_EXTERNAL_USERS_GET',    
    REPORTING_YEAR: 'REPORTING_YEAR',
    PRIME_CONTRACTOR: 'PRIME_CONTRACTOR'
} as const;

// This is where we put the actions that have promise/async payload like HTTP request
export const asyncActions = {
    listGET: {
        type: actionTypes.SPEND_LIST_FETCH,
        service: services.listGET,
        status: {} as never,
    },
    listUPDATE: {
        type: actionTypes.SPEND_LIST_UPDATE,
        service: services.listUPDATE,
        status: {} as never,
    },
    listSUBCONTRACTS: {
        type: actionTypes.SUBCONTRACTS_LIST,
        service: services.listSUBCONTRACTS,
        status: {} as never,
    },
    listCONTRACTS: {
        type: actionTypes.CONTRACTS_LIST,
        service: services.listCONTRACTS,
        status: {} as never,
    },
    listPRIMECONTRACTS: {
        type: actionTypes.PRIMECONTRACTS,
        service: services.listPRIMECONTRACTS,
        status: {} as never,
    },
    listCONTRACTS_ALL: {
        type: actionTypes.CONTRACTS_LIST_ALL,
        service: services.listCONTRACTS,
        status: {} as never,
    },
    listDistinctSUBCONTRACTORS: {
        type: actionTypes.SUBCONTRACTORS_LIST,
        service: services.listDistSUBCONTRACTORS,
        status: {} as never,
    },
    notSpendMonthUPDATE: {
        type: actionTypes.NOT_SPEND_MONTH_UPDATE,
        service: services.notSpendMonthUPDATE,
        status: {} as never,
    },
    blockAndUnblockExternalUserGet: {
        type: actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS_GET,
        service: services.blockAndUnblockExternalUserGet,
        status: {} as never,
    }, 
    reportingyearsGet: {
        type: actionTypes.REPORTING_YEAR,
        service: services.reportingyearsGet,
        status: {} as never,
    } ,
    primeContractorGet:{
        type: actionTypes.PRIME_CONTRACTOR,
        service: services.primeContractor,
        status: {} as never,
    }
    
};
export type ISpendAsync = typeof asyncActions;

export type ISpendReducerAction = IAsyncActions<ISpendAsync>;
export interface ISpendState extends ICommonState<ISpendReducerAction> {
    status: any;
    list: ISpend;
    subContracts: ISubContracts;
    contracts: IContracts;
    contractsAll: IContracts;
    primeContracts: IPrimeContracts;
    blockAndUnblockExternalUserGet?: Partial<IBlockAndUnblockExternalUserGetSchema>;
    reportingyearsGet?: Partial<IReportingyearsGetSchema>;
    primeContractorGet?: Partial<IContractor>;
}

export const defaultState: ISpendState = {
    status: {},
    list: {
        contracts: [],
        contracts_last_page: 0,
        subcontractors: [],
        data: []
    },
    contracts: {},
    contractsAll: {},
    subContracts: {},
    primeContracts: {},
    blockAndUnblockExternalUserGet: {},
    reportingyearsGet: {},
    primeContractorGet: {}
};

const SpendReducer = (
    state: ISpendState = defaultState,
    action: ICommonAction<ISpendReducerAction>
): ISpendState => {
    switch (action.type) {
        case actionTypes.SPEND_LIST_FETCH: {
            return {
                ...state,
                list: action?.payload ?? {
                    contracts: [],
                    contracts_last_page: 0,
                    subcontractors: [],
                    data: [],
                },
            };
        }
        case actionTypes.SPEND_LIST_UPDATE: {
            return {
                ...state,
                // list: action?.payload ?? [],
            };
        }
        case actionTypes.SUBCONTRACTS_LIST: {
            return {
                ...state,
                subContracts: action?.payload ?? {},
            };
        }
        case actionTypes.SUBCONTRACTORS_LIST: {
            return {
                ...state,
                subContracts: action?.payload ?? {},
            };
        }        
        case actionTypes.CONTRACTS_LIST: {
            return {
                ...state,
                contracts: action?.payload ?? {},
            };
        }        
        case actionTypes.PRIMECONTRACTS: {
            return {
                ...state,
                primeContracts: action?.payload ?? {},
            };
        }
        
        case actionTypes.CONTRACTS_LIST_ALL: {
            return {
                ...state,
                contractsAll: action?.payload ?? {},
            };
        }
        case actionTypes.NOT_SPEND_MONTH_UPDATE: {
            return {
                ...state,
            };
        }
        
        case actionTypes.BLOCK_UNBLOCK_EXTERNAL_USERS_GET: {
            return {
                ...state,
                blockAndUnblockExternalUserGet: action.payload.data || {},
            };
        }
        
        case actionTypes.REPORTING_YEAR: {
            return {
                ...state,
                reportingyearsGet: action.payload || {},
            };
        }
        case actionTypes.PRIME_CONTRACTOR: {
            return {
                ...state,
                primeContractorGet: action.payload || {},
            };
        }
        default: {
            return state;
        }
    }
};

export default SpendReducer;

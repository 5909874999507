import React, { useCallback, useState, useEffect } from 'react';
import PrimeLoginView from './prime-login.view';
import { AccessRole } from '../../../../constants/access.constant';
import {
    PRIME_LOGIN_REDIRECT_URL,
    CONTACT_LOGIN_REDIRECT_URL,
} from 'src/constants';
import { useLoginContext } from 'src/contexts/login.context';
import { getLoginStatus } from 'src/selectors/login.selector';
import { actionTypes } from 'src/ducks/login.duck';
import { adLogin, getToken } from 'src/libraries/auth.library';
import history from 'src/history';
import { useLocation } from 'react-router-dom';

const PrimeLogin: React.FC = () => {
    const loginUrl = localStorage.getItem('loginUrl');
    if (loginUrl) {
        localStorage.removeItem('loginUrl');
    }
    localStorage.setItem('loginUrl', '/primelogin');

    const [loading] = useState(false);
    const [ADloading] = useState(false);

    const [isVisible, setIsVisible] = useState({
        success: false,
        error: false,
    });
    const [toastMsg, setToastMsg] = useState<any>('');
    const [proxyURL, setProxyURL] = useState('');
    const { state, actions } = useLoginContext();
    const search = useLocation().search;

    useEffect(() => {
        // for CDC login
        setProxyURL(window.location.pathname);
        if (window.location.pathname === '/success') {
            const responseId = new URLSearchParams(search).get('responseId');
            const nameId = new URLSearchParams(search).get('nameId');
            const cdcUID = new URLSearchParams(search).get('cdcUID');
            const sessionIndex = new URLSearchParams(search).get(
                'sessionIndex'
            );
            const params = {
                responseId: responseId,
                nameId: nameId,
                cdcUID: cdcUID,
                sessionIndex: sessionIndex,
            };
            actions
                .CDCAccessTokenPost(params)
                .then((res) => {
                    if (res.error) {
                        setToastMsg(res?.error?.message);
                        setIsVisible({ success: false, error: true });
                        setTimeout(() => {
                            setIsVisible({ success: false, error: false });
                        }, 30000);
                    } else {
                        const responseData = res?.payload;
                        setUnserInfo(responseData);
                    }

                    return null;
                })
                .catch((error) => {
                    setToastMsg(error?.message);
                    setIsVisible({ success: false, error: true });
                    setTimeout(() => {
                        setIsVisible({ success: false, error: false });
                    }, 30000);
                    return null;
                });
        }
        if (window.location.pathname === '/error') {
            const errorJson = new URLSearchParams(search).get('error') ?? '';
            const errorJsonParesed = JSON.parse(errorJson);
            setIsVisible({ success: false, error: true });
            setToastMsg(errorJsonParesed?.errorMessage);
            setTimeout(() => {
                setIsVisible({ success: false, error: false });
            }, 30000);
        }
    }, []);

    /**
     * handle CDC login for external users
     *
     */
    const handleCDCLogin = useCallback(() => {
        actions
            .CDCLoginGetSAPLoginURLGet()
            .then((response) => {
                window.location.href = response.payload.message;
                return;
            })
            .catch((error) => {
                console.error('response', error);
            });
    }, []);

    const handleSubmit = useCallback(() => {
        return;
    }, [setIsVisible]);

    const handleDismiss = useCallback(() => {
        setIsVisible({ success: false, error: false });
    }, [setIsVisible]);

    /**
     * function for handling AD login (Employee login)
     */
    const handleADLogin = useCallback(async () => {
        if (await adLogin(setIsVisible, setToastMsg)) {
            const token = getToken();
            actions
                .loginGet({ msal_access_token: token })
                .then((response) => {
                    const errorResponse = response?.error;
                    const responseData = response?.payload;

                    if (errorResponse && errorResponse.status === false) {
                        if (errorResponse.errorCode === 1002) {
                            console.error(
                                'errorResponse',
                                errorResponse.errorCode
                            );
                            const errorMsg = (
                                <span>
                                    <b>Access Denied</b>
                                    <br />
                                    You are not authorized to access the{' '}
                                    <b>
                                        Subcontracting Spend Reporting Portal.
                                    </b>{' '}
                                    We apologize for the inconvenience.
                                    <br />
                                    If you need assistance, please contact the{' '}
                                    <b>Administrator.</b>
                                </span>
                            );
                            setToastMsg(errorMsg);
                        } else {
                            setToastMsg(errorResponse?.message);
                        }
                        setIsVisible({ success: false, error: true });
                        setTimeout(() => {
                            setIsVisible({ success: false, error: false });
                        }, 30000);
                        return;
                    }
                    setUnserInfo(responseData);
                    return null;
                })
                .catch((error) => {
                    console.error(error.message);
                });
        }
    }, []);

    /**
     * common function for CDC and AD for handling session data
     * @param responseData: user object info
     */
    const setUnserInfo = (responseData) => {
        localStorage.setItem('user_token', responseData.data.token);
        localStorage.setItem('loginCredential', responseData.data.user_id);
        const userInfo = {
            email: responseData.data.user_email,
            prime_id: responseData.data.user_id,
            name: responseData.data.full_name,
            user_group: Number(responseData.data.user_group_id),
            first_name: responseData.data.first_name,
            last_name: responseData.data.last_name,
            username: responseData.data.username,
        };

        localStorage.setItem(
            `${responseData.data.user_id}_user_data`,
            JSON.stringify(userInfo)
        );

        const redirect_url =
            userInfo.user_group === AccessRole.PRIME
                ? PRIME_LOGIN_REDIRECT_URL
                : CONTACT_LOGIN_REDIRECT_URL;
        history.push(redirect_url);
    };

    return (
        <>
            {
                <PrimeLoginView
                    isVisible={isVisible}
                    handleCDCLogin={handleCDCLogin}
                    handleSubmit={handleSubmit}
                    handleDismiss={handleDismiss}
                    toastMsg={toastMsg}
                    handleADLogin={handleADLogin}
                    loading={
                        loading ||
                        getLoginStatus(state, actionTypes.LOGIN_FETCH).fetching
                    }
                    ADloading={
                        ADloading ||
                        getLoginStatus(state, actionTypes.LOGIN_AD_FETCH)
                            .fetching
                    }
                />
            }
        </>
    );
};

export default PrimeLogin;

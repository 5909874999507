import { ISpendReducerAction, ISpendState } from '../ducks/spend.duck';
import { IStatus } from '../ducks';

export const getSpendStatus = (
    state: ISpendState,
    action: ISpendReducerAction['type']
): IStatus =>
    state.status[action] ?? {
        fetching: false,
        error: null,
    };

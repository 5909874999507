import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import SubcontractorReducer, {
  defaultState,
  asyncActions,
} from 'src/ducks/subcontractor.duck';

// We need to do this, to be able to get the typings of the Reducer

const useReducer = () => {
  return useReducerHook(SubcontractorReducer, defaultState, asyncActions);
};

type ISubcontractorContext = ReturnType<typeof useReducer>;

// Having trouble setting initial values, that's why it is cast to the return type of the reducer
const SubcontractorContext = createContext<Partial<ISubcontractorContext>>({
  state: defaultState,
}) as React.Context<ISubcontractorContext>;

const SubcontractorProvider: React.FC = ({ children }) => {
  const reducer = useReducer();
  return (
    <SubcontractorContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </SubcontractorContext.Provider>
  );
};

// We create context hook here so that we can able to unit test
// container components that uses this context
export const useSubcontractorContext: () => ISubcontractorContext = () => useContext(SubcontractorContext);
export type IUseSubcontractorContext = ReturnType<typeof useSubcontractorContext>;

export { SubcontractorContext, SubcontractorProvider };

import React, { Fragment } from 'react';
import {
    ATMGrid,
    ATMForm,
    ATMModal,
    ATMButton,
    ATMField,
    ATMInput,
    ATMCheckbox,
    ATMToggle,
    ATMSelect,
    ATMSegment,
    ATMHeader,
} from 'shared-it-appmod-ui';
import { Container } from 'semantic-ui-react';
import { Divider } from 'semantic-ui-react';
import {
    IContractor,
    IAdminPrime,
    IContractorForm,
    ContractorUpdateSchema,
    AdminPrimeUpdateSchema,
} from 'src/models/contractor.model';
import Lang from 'src/libraries/language';
import { isPrime } from 'src/libraries/users.library';
import styles from './contractor-update.module.scss';
import { blankSelectOption } from 'src/constants';
import { states } from 'src/constants/common.constant';

export type IContractorHandleSubmit = (data: IContractorForm) => void;

type IProps = {
    isAdmin: boolean;
    isNewPrime: boolean;
    advisorOptions: any;
    contactOptions: any;
    isOpen: boolean;
    loading: boolean;
    btnDisable: boolean;
    toastMsg: string;
    isVisible: {
        success: boolean;
        error: boolean;
    };
    handleDismiss: () => void;
    data: Partial<IAdminPrime>;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleClose: () => void;
    handleClick: () => void;
    handleSubmit: (data: IContractor | IAdminPrime) => void;
    handleChange: (fieldName, val) => void;
    setCompanyStatus: (status) => void;
    companyStatus: boolean;
    socalContactOptions: any;
};

type Fprops = {
    isAdmin: boolean;
    isNewPrime: boolean;
    data: Partial<IAdminPrime>;
    formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
    handleSubmit: (data: IContractor | IAdminPrime) => void;
    handleChange: (fieldName, val) => void;
    advisorOptions: any;
    contactOptions: any;
    setCompanyStatus: (status) => void;
    companyStatus: boolean;
    socalContactOptions: any;
};

const RequiredLabel = ({ children, ...props }) => (
    <label {...props} className={styles.required}>
        {children}
    </label>
);
const UpdateFormView: React.FC<IProps> = ({
    isAdmin,
    isNewPrime,
    isOpen,
    loading,
    data,
    btnDisable,
    formRef,
    handleClose,
    handleClick,
    handleSubmit,
    handleChange,
    advisorOptions,
    contactOptions,
    setCompanyStatus,
    companyStatus,
    socalContactOptions,
}) => {
    const formProps = {
        isAdmin,
        data,
        formRef,
        handleSubmit,
        handleChange,
        advisorOptions,
        contactOptions,
        isNewPrime,
        setCompanyStatus,
        companyStatus,
        socalContactOptions,
    };

    return (
        data && (
            <ATMModal
                open={isOpen}
                onClose={handleClose}
                closeOnDimmerClick={false}
                size="large"
            >
                <ATMModal.Header as="h5" className={styles.modelHeader}>
                    <div>
                        {isAdmin == true
                            ? Lang.TTL_CONTRACTOR_EDIT_UPDATE_PRIME_INFORMATION
                            : Lang.TTL_CONTRACTOR_EDIT_PRIME_INFORMATION}
                    </div>
                    <div className={styles.mandatory}>
                        (<span className={styles.required}></span>
                        {Lang.LBL_MONDATORY_FIELD})
                    </div>
                </ATMModal.Header>
                <div className={styles.modelHeader}></div>

                <ATMModal.Content className={styles.wrapper}>
                    <FormUpdate {...formProps} />
                </ATMModal.Content>
                <ATMModal.Actions>
                    <ATMButton basic color="blue" onClick={handleClose}>
                        {Lang.LBL_CANCEL}
                    </ATMButton>
                    <ATMButton
                        primary
                        onClick={handleClick}
                        loading={loading}
                        disabled={loading || btnDisable}
                    >
                        {Lang.LBL_UPDATE}
                    </ATMButton>
                </ATMModal.Actions>
            </ATMModal>
        )
    );
};

function UpdateInner({ handleClose, handleClick, ...props }: IProps) {
    return (
        <Container fluid className={styles.innerPagecontainer}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <ATMHeader as="h1" style={{ marginTop: '20px' }}>
                    {Lang.LBL_ADD_PRIME}
                </ATMHeader>
                <div>
                    <ATMButton
                        style={{ marginRight: '15px' }}
                        secondary
                        onClick={handleClose}
                        content="Back"
                    />
                    <ATMButton primary content="Save" onClick={handleClick} />
                </div>
            </div>
            <ATMSegment>
                <FormUpdate {...props} />
            </ATMSegment>
        </Container>
    );
}

export default function UpdateModelForm({
    ...props
}: IProps): React.ReactElement {
    if (props.isAdmin && props.isNewPrime) {
        return <UpdateInner {...props} />;
    } else {
        return <UpdateFormView {...props} />;
    }
}

const FormUpdate: React.FC<Fprops> = ({
    isAdmin,
    isNewPrime,
    data,
    formRef,
    handleSubmit,
    handleChange,
    advisorOptions,
    contactOptions,
    setCompanyStatus,
    companyStatus,
    socalContactOptions,
}) => {
    const isUserPrime = isPrime();
    const socalContactOptionsModified: any = socalContactOptions
        ? [blankSelectOption, ...socalContactOptions]
        : [];

    socalContactOptionsModified.sort((a, b) => a.text.localeCompare(b.text));

    const advisorOptionsModified: any = advisorOptions
        ? [blankSelectOption, ...advisorOptions]
        : [];
    advisorOptionsModified.sort((a, b) => a.text.localeCompare(b.text));

    const contactOptionsModified: any = contactOptions
        ? [blankSelectOption, ...contactOptions]
        : [];
    contactOptionsModified.sort((a, b) => a.text.localeCompare(b.text));

    const stateObj = states.map((state) => {
        return {
            key: state,
            text: state,
            value: state,
        };
    });
    console.log('smm', data)
    return (
        <ATMForm
            ref={formRef}
            validationSchema={
                isAdmin ? AdminPrimeUpdateSchema : ContractorUpdateSchema
            }
            mode="onChange"
            defaultValues={data}
            onSubmit={handleSubmit}
            style={{
                marginBottom: '3rem',
            }}
        >
            {({ control, errors }) => (
                <>
                    <ATMGrid columns={3}>
                        <ATMGrid.Row
                            style={{ paddingBottom: '0px !important' }}
                        >
                            <div
                                className={styles.contractorHeader}
                                style={{ paddingLeft: 14 }}
                            >
                                {Lang.TTL_PRIME_CONTRACTOR_COMPANY_DETAILS}
                            </div>
                        </ATMGrid.Row>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                {!isAdmin ? (
                                    <label>
                                        {Lang.LBL_CONTRACTOR_COMPANY_NAME}
                                    </label>
                                ) : (
                                    <RequiredLabel>
                                        {Lang.LBL_CONTRACTOR_PRIME_NAME}
                                    </RequiredLabel>
                                )}
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="company_name"
                                    error={errors.company_name}
                                    required
                                    disabled={!isAdmin}
                                    className={
                                        !isAdmin ? styles.inputDisabled : {}
                                    }
                                    onChange={([_, val]) => {
                                        handleChange('company_name', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <RequiredLabel>
                                    {Lang.LBL_CONTRACTOR_ADDRESS}
                                </RequiredLabel>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="vendoradd"
                                    // maxLength="50"
                                    error={errors.vendoradd}
                                    className={styles.fieldname}
                                    rows={1}
                                    required
                                    onChange={([_, val]) => {
                                        handleChange('vendoradd', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>

                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <RequiredLabel>
                                    {Lang.LBL_CONTRACTOR_ZIP}
                                </RequiredLabel>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    // maxLength="5"
                                    name="zip"
                                    error={errors.zip}
                                    className={styles.fieldname}
                                    required
                                    onChange={([_, val]) => {
                                        handleChange('zip', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                {!isAdmin ? (
                                    <label>
                                        {isUserPrime
                                            ? Lang.LBL_CONTRACTOR_LOGIN_ID
                                            : Lang.LBL_CONTRACTOR_SAP_ID}
                                    </label>
                                ) : (
                                    <label
                                        className={
                                            !isAdmin ? '' : styles.required
                                        }
                                    >
                                        {isUserPrime
                                            ? Lang.LBL_CONTRACTOR_LOGIN_ID
                                            : Lang.LBL_CONTRACTOR_SAP_ID}
                                    </label>
                                )}
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="sapId"
                                    error={errors.sapId}
                                    required
                                    onChange={([_, val]) => {
                                        handleChange('sapId', val.value);
                                        return val.value;
                                    }}                                    
                                    disabled={!isNewPrime}
                                    //disabled={true}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <RequiredLabel>
                                    {Lang.LBL_CONTRACTOR_CITY}
                                </RequiredLabel>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="city"
                                    error={errors.city}
                                    className={styles.fieldname}
                                    required
                                    onChange={([_, val]) => {
                                        console.log('city', val.value);
                                        
                                        handleChange('city', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            {!isAdmin ? (
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {
                                            Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG
                                        }
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMInput}
                                        name="supplier_diversity_contact"
                                        error={errors.supplier_diversity_portal}
                                        className={styles.inputDisabled}
                                        disabled
                                    />
                                </ATMGrid.Column>
                            ) : (
                                <ATMGrid.Column className={styles.fieldWrapper}>
                                    <label>
                                        {Lang.LBL_ADMIN_ALLOW_AGREEMENTS}
                                    </label>
                                    <ATMField
                                        control={control}
                                        as={ATMCheckbox}
                                        name="allow_agreements"
                                        error={errors.allowAgreements}
                                        onChange={([_, val]) => {
                                            handleChange(
                                                'allow_agreements',
                                                val.checked
                                            );
                                            return val.checked;
                                        }}
                                    />
                                </ATMGrid.Column>
                            )}
                        </ATMGrid.Row>
                    </ATMGrid>
                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                {!isAdmin ? (
                                    <label>
                                        {Lang.LBL_CONTRACTOR_SUPPLIER_CODE}
                                    </label>
                                ) : (
                                    <RequiredLabel>
                                        {Lang.LBL_CONTRACTOR_SUPPLIER_CODE}
                                    </RequiredLabel>
                                )}
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="supplier_code"
                                    error={errors.supplier_code}
                                    disabled={!isAdmin}
                                    required
                                    className={
                                        !isAdmin ? styles.inputDisabled : {}
                                    }
                                    onChange={([_, val]) => {
                                        handleChange(
                                            'supplier_code',
                                            val.value
                                        );
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <RequiredLabel>
                                    {Lang.LBL_CONTRACTOR_STATE}
                                </RequiredLabel>
                                <ATMField
                                    control={control}
                                    as={ATMSelect}
                                    name="state"
                                    maxLength="2"
                                    error={errors.state}
                                    className={styles.fieldname}
                                    options={stateObj}
                                    placeholder={'Select'}
                                    onChange={([_, val]) => {
                                        handleChange('state', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                {isAdmin && (
                                    <Fragment>
                                        <label>
                                            {Lang.LBL_ADMIN_COMPANY_STATUS}
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMToggle}
                                            name="active"
                                            defaultChecked={
                                                control.defaultValuesRef.current
                                                    .active
                                            }
                                            error={errors.active}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'active',
                                                    val.checked
                                                );
                                                setCompanyStatus(val.checked);
                                                return val.checked;
                                            }}
                                        />
                                        <label style={{ marginLeft: 8 }}>
                                            {companyStatus
                                                ? 'Active'
                                                : 'Inactive'}
                                        </label>
                                    </Fragment>
                                )}
                                {isUserPrime && (
                                    <Fragment>
                                        <label>
                                            {
                                                Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG
                                            }
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMInput}
                                            name="supplier_diversity_contact_socal"
                                            error={
                                                errors.supplier_diversity_portal
                                            }
                                            className={styles.inputDisabled}
                                            disabled
                                        />
                                    </Fragment>
                                )}
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>

                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                
                                <label>
                                    { Lang.LBL_CONTRACTOR_TIER_III_ENABLED} 
                                </label>

                                
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="isToggleText"
                                    error={errors.isToggleText}
                                    disabled
                                    className={styles.inputDisabled}
                                    // defaultValue={''}
                                    // onChange={([_, val]) => {
                                        
                                        
                                    //     handleChange('isToggleText', val.value);
                                    //     return val.value;
                                    // }}
                                />
                            </ATMGrid.Column>
                            
                        </ATMGrid.Row>
                    </ATMGrid>
                    {isAdmin && (
                        <Fragment>
                            <Divider />
                            <ATMGrid columns={3}>
                                <ATMGrid.Row
                                    style={{ paddingBottom: '0px !important' }}
                                >
                                    <div
                                        className={styles.contractorHeader}
                                        style={{ paddingLeft: 14 }}
                                    >
                                        {
                                            Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_DETAILS
                                        }
                                    </div>
                                </ATMGrid.Row>
                                <ATMGrid.Row className={styles.rowWrapper}>
                                    <ATMGrid.Column
                                        className={styles.fieldWrapper}
                                    >
                                        <label style={{ marginTop: 0 }}>
                                            {
                                                Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SDG
                                            }
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMSelect}
                                            placeholder={'Select'}
                                            name="dbeAdvisor"
                                            error={errors.dbeAdvisor}
                                            className={styles.fieldname}
                                            options={advisorOptionsModified}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'dbeAdvisor',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                            required
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={styles.fieldWrapper}
                                    >
                                        <label
                                            style={{
                                                marginTop: 0,
                                                width: '10em',
                                            }}
                                        >
                                            {
                                                Lang.LBL_ADMIN_SUPPLIER_DIVERSITY_CONTACT_SCG
                                            }
                                        </label>
                                        <ATMField
                                            control={control}
                                            as={ATMSelect}
                                            placeholder={'Select'}
                                            name="socalContact"
                                            error={errors.socalContact}
                                            className={styles.fieldname}
                                            options={
                                                socalContactOptionsModified
                                            }
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'socalContact',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                            required
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                            <ATMGrid columns={3}>
                                <ATMGrid.Row className={styles.rowWrapper}>
                                    <ATMGrid.Column
                                        className={styles.fieldWrapper}
                                    >
                                        <label>{Lang.LBL_ADMIN_CONTACT1}</label>

                                        <ATMField
                                            control={control}
                                            placeholder={'Select'}
                                            as={ATMSelect}
                                            name="contact1"
                                            error={errors.contact1}
                                            className={styles.fieldname}
                                            options={contactOptionsModified}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'contact1',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={styles.fieldWrapper}
                                    >
                                        <label>{Lang.LBL_ADMIN_CONTACT2}</label>
                                        <ATMField
                                            control={control}
                                            placeholder={'Select'}
                                            as={ATMSelect}
                                            name="contact2"
                                            error={errors.contact2}
                                            className={styles.fieldname}
                                            options={contactOptionsModified}
                                            required
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'contact2',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        className={styles.fieldWrapper}
                                    >
                                        <label>{Lang.LBL_ADMIN_CONTACT3}</label>
                                        <ATMField
                                            control={control}
                                            placeholder={'Select'}
                                            as={ATMSelect}
                                            name="contact3"
                                            error={errors.contact3}
                                            className={styles.fieldname}
                                            options={contactOptionsModified}
                                            onChange={([_, val]) => {
                                                handleChange(
                                                    'contact3',
                                                    val.value
                                                );
                                                return val.value;
                                            }}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            </ATMGrid>
                        </Fragment>
                    )}
                    <Divider />
                    <ATMGrid columns={3}>
                        <ATMGrid.Row
                            style={{ paddingBottom: '0px !important' }}
                        >
                            <ATMGrid.Column
                                className={styles.fieldWrapper}
                                style={{ paddingLeft: 0 }}
                            >
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {
                                        Lang.TTL_CONTRACTOR_PRIMARY_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                            <ATMGrid.Column
                                className={styles.fieldWrapper}
                                style={{ paddingLeft: 0 }}
                            >
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {
                                        Lang.TTL_CONTRACTOR_SECONDARY_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                            <ATMGrid.Column
                                className={styles.fieldWrapper}
                                style={{ paddingLeft: 0 }}
                            >
                                <div
                                    className={styles.contractorHeader}
                                    style={{ paddingLeft: 14 }}
                                >
                                    {
                                        Lang.TTL_CONTRACTOR_OPIONAL_CONTACT_DETAILS
                                    }
                                </div>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label
                                    className={
                                        isUserPrime ? '' : styles.required
                                    }
                                >
                                    {Lang.LBL_CONTRACTOR_PRIMARY_NAME}
                                </label>

                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactName"
                                    error={errors.contactName}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange('contactName', val.value);
                                        return val.value;
                                    }}
                                    // disabled={isUserPrime}
                                    required
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ALTERNAME_NAME}
                                </label>

                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactName2"
                                    error={errors.contactName2}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange('contactName2', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ADDITIONAL_NAME}
                                </label>

                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactName3"
                                    error={errors.contactName3}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange('contactName3', val.value);
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label
                                    className={
                                        isUserPrime == true
                                            ? ''
                                            : styles.required
                                    }
                                >
                                    {Lang.LBL_CONTRACTOR_PRIMARY_PHONE}
                                </label>

                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactPhone"
                                    error={errors.contactPhone}
                                    className={styles.fieldname}
                                    required
                                    onChange={([_, val]) => {
                                        handleChange('contactPhone', val.value);
                                        return val.value;
                                    }}
                                    // disabled={isUserPrime}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ALTERNAME_PHONE}
                                </label>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactPhone2"
                                    error={errors.contactPhone2}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange(
                                            'contactPhone2',
                                            val.value
                                        );
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ADDITIONAL_PHONE}
                                </label>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactPhone3"
                                    error={errors.contactPhone3}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange(
                                            'contactPhone3',
                                            val.value
                                        );
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                    <ATMGrid columns={3}>
                        <ATMGrid.Row className={styles.rowWrapper}>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label
                                    className={
                                        isUserPrime ? '' : styles.required
                                    }
                                >
                                    {Lang.LBL_CONTRACTOR_PRIMARY_EMAIL}
                                </label>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactEmail"
                                    error={errors.contactEmail}
                                    className={styles.fieldname}
                                    required
                                    onChange={([_, val]) => {
                                        handleChange('contactEmail', val.value);
                                        return val.value;
                                    }}
                                    // disabled={isUserPrime}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ALTERNAME_EMAIL}
                                </label>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactEmail2"
                                    error={errors.contactEmail2}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange(
                                            'contactEmail2',
                                            val.value
                                        );
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                            <ATMGrid.Column className={styles.fieldWrapper}>
                                <label>
                                    {Lang.LBL_CONTRACTOR_ADDITIONAL_EMAIL}
                                </label>
                                <ATMField
                                    control={control}
                                    as={ATMInput}
                                    name="contactEmail3"
                                    error={errors.contactEmail3}
                                    className={styles.fieldname}
                                    onChange={([_, val]) => {
                                        handleChange(
                                            'contactEmail3',
                                            val.value
                                        );
                                        return val.value;
                                    }}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </>
            )}
        </ATMForm>
    );
};

import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lang from 'src/libraries/language';
import {
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import UpdateSpendReportModalView from './update-spend-report-modal.view';
import {
    month
} from '../../../../constants/common.constant';
import { useSpendContext } from 'src/contexts/spend.context';
import {
    getUserID
} from 'src/libraries/users.library';
type Iprops = {
    open: boolean;
    data: any;
    handleOnDiscard: () => void;
};

const UpdateSpendReportModalComponent: React.FC<Iprops> = ({
    open,
    data,
    handleOnDiscard
}) => {
    const [confirm, setConfirm] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const dialogRef = useRef<string>();
    const [btnEnable, setBtnEnable] = useState(true);
    const [currentMonth, setCurrentMonth] = useState(1);
    const dataRef = useRef<any>();
    const { state, actions } = useSpendContext();
    
    const handleClose = () => {
        setConfirm(false);
        handleOnDiscard();
        dialogRef.current = 'close';
    };

    const handleUpdate = () => {
        if(dataRef.current?.notspendmonth) {
            handleUpdateNew(dataRef)
        }
    };


    const handleUpdateNew = useCallback(
        async (obj) => {
            // const userId = getUserID();
            // if (obj.length > 0) {
                const userId = getUserID();
                const updateObj = {
                    vendorId: userId,
                    notSpendMonths: JSON.stringify(dataRef.current.notspendmonth),
                };
                console.log(updateObj)
                const response: any = await actions.notSpendMonthUPDATE(updateObj);
                console.log(response)
                if (response?.payload?.status) {
                    ToastSuccess('Successfully updated months for reporting no spend');
                    handleClose();
                } else {
                    ToastError('Data not saved.');
                }
            
        },
        [actions,dataRef]
    );

    

    useEffect(() => {
        const date  = new Date();
        setCurrentMonth(date.getMonth() + 1)
        month.splice(date.getMonth() + 1, (12-date.getMonth() + 1));
    });

    const handleChange = (key, value: any) => {
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
        if(dataRef.current?.notspendmonth) {
            if(dataRef.current?.notspendmonth.length > 0) {
                setBtnEnable(false);
            } else {
                setBtnEnable(true);
            }
        }
    };
    
    return (
        <>
            <UpdateSpendReportModalView
                data={data}
                open={open}
                handleClose={handleClose}
                handleUpdate={handleUpdate}
                formRef={formRef}
                currentMonth = {currentMonth}
                month={month}
                handleChange={handleChange}
                btnEnable={btnEnable}
                dataRef ={dataRef.current}
            />
        </>
    );
};

export default UpdateSpendReportModalComponent;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpClient } from '../libraries/http.library';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import {
    SubcontractorSchema,
    ISubcontractor,
    SubcontractorNotPrimeListPayloadSchema,
    SubcontractorAddSelectedSchema,
    ISubcontractorSelectedAdd,
    SubcontractorNotPrimeDetailSchema,
    ISubcontractorUpdateData,
    SubcontractorUpdateDataSchema,
    SubcontractorReasonGetSchema,
    spendRecordSapIdZeroSchema,
    SubcontractorGenderNationalityAgencySchema,
    SubcontractorCreateListPayloadSchema,
    SubcontractorCreatePostSchema,
    ISubcontractorCreatePost,
    SubcontractorDelectSchema,
    SubcontractorDelectSchemaObject,
    SubcontractorDelectPutSchema,
    AdminSubcontractorSchema,
    SubcontractorDeleteResponseSchema,
    SubcontractorSpendsSchema,
    ToggleTier2And3SubcontractorUnderPrime,
    IToggleTier2And3SubcontractorUnderPrime,
    ToggleTier2And3SubcontractorUnderPrimeResponse
} from '../models/subcontractor.model';
import {
    ContractorSchema
} from '../models/contractor.model';
import {
    ReportingyearsGetSchema
} from '../models/manage-subcontractor.model';
const client = httpClient();

export const services = {
    listGET_Prime: async (data: Partial<IORGDataTableQueryState> = {}) => {
        const params: Record<string, any> = {
            ...data,
        };
        return client.get('/subcontractors', params, SubcontractorSchema);
    },
    AutoFill: async (params) => {
        const ret = client.get(
            '/subcontractor/lookupVerificationNumber',
            params,
            SubcontractorCreateListPayloadSchema
        );
        return ret;
    },
   
    listGET_Not_Prime: async (data: Partial<IORGDataTableQueryState> = {}) => {
        const params: Record<string, any> = {
            ...data,
        };
        return client.get(
            '/availableSubcontractorPrime',
            params,
            SubcontractorNotPrimeListPayloadSchema
        );
    },
    DetailGET_Not_Prime: async (id) => {
        return client.get(
            `/subcontractorUnderPrime/ById/${id}`,
            {},
            SubcontractorNotPrimeDetailSchema
        );
    },
    ReasonList: async () => {
        return client.get(
            `/reasonsForInactivity`,
            {},
            SubcontractorReasonGetSchema
        );
    },
    spendRecordSapIdZero: async (param) => {
        return client.get(
            `/spendRecordSapIdZero`,
            param,
            spendRecordSapIdZeroSchema
        );
    },
    GenderNationalityAgencyList: async () => {
        return client.get(
            `/addNewSubcontractor/formData`,
            {},
            SubcontractorGenderNationalityAgencySchema
        );
    },

    updateSubcontractor: async (
        // add selected subcontractors who are not prime
        data: ISubcontractorUpdateData
    ) => {
        return client.post(
            '/subcontractorUnderPrime/updateStatus',
            {
                ...data,
            },
            SubcontractorUpdateDataSchema
        );
    },
    toggleTier2And3SubcontractorUnderPrime: async (
        data: IToggleTier2And3SubcontractorUnderPrime
    ) => {
        // ?tier2Toggle=${data.tier2Toggle}&tier3Toggle=${data.tier3Toggle}&vendorId=${data.vendorId}&VendorSubContractorId=${data.VendorSubContractorId}
        return client.post(
            `toggleTier2And3SubcontractorUnderPrime`,
           data,
           ToggleTier2And3SubcontractorUnderPrimeResponse
        );
    },
    updateSubcontractorStatusAdmin: async (
        // add selected subcontractors who are not prime
        data: ISubcontractorUpdateData
    ) => {
        return client.post(
            '/changeSubcontractorStatusAdmin',
            {
                ...data,
            },
            SubcontractorUpdateDataSchema
        );
    },

    AddSelectedSub_POST: async (
        // add selected subcontractors who are not prime
        data: ISubcontractorSelectedAdd
    ) => {
        return client.post(
            '/addSubcontractorUnderPrime',
            {
                ...data,
            },
            SubcontractorAddSelectedSchema
        );
    },

    createSubcontractor_POST: async (
        // add selected subcontractors who are not prime
        data: ISubcontractorCreatePost
    ) => {
        return client.post(
            '/addNewSubcontractor',
            {
                ...data,
            },
            SubcontractorCreatePostSchema
        );
    },


    

    replacePUT: async (data: ISubcontractor) => {
        return client.put(
            '/SubcontractorUnderPrime',
            {
                data,
            },
            SubcontractorSchema
        );
    },
    subcontractorDelete: async (data: SubcontractorDelectSchemaObject) => {
        return client.put(
            '/subcontractors',
            { ...data },
            SubcontractorDelectPutSchema
        );
    },
    adminSubcontractors: async (params) => {
        return client.get(
            '/subcontractorUnderAdmin',
            params,
            AdminSubcontractorSchema
        );
    },
    deleteSubcontractor: async (id,isDeleteFromVonPage,reasonForDeletion) => {
        return client.delete(
            `/inSubcontractorWithoutVons?subcontractorId=${id}&isDeleteFromVonPage=${isDeleteFromVonPage}&reasonForDeletion=${reasonForDeletion}`,
            {},
            SubcontractorDeleteResponseSchema
        );
    },

    getSubcontractorSpendList: async (params) => {
        return client.get(
            '/dashboardNeedSubcontractorVerification',
            params,
            SubcontractorSpendsSchema
        );
    },
    primeContractor: async (params) => {
        return client.get('/primeContractor', params, ContractorSchema);
    },
    reportingyearsGet: async () => {
        return client.get('/reportingYears', {}, ReportingyearsGetSchema);
    },
};
export default services;

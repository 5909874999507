import yup from '../libraries/validator.library';

const SpendSchema = yup.object({
    isTier3Spend:yup.boolean(),
    subcontractor: yup.string(),
    jan: yup.number(),
    feb: yup.number(),
    mar: yup.number(),
    apr: yup.number(),
    may: yup.number(),
    jun: yup.number(),
    jul: yup.number(),
    aug: yup.number(),
    sep: yup.number(),
    oct: yup.number(),
    nov: yup.number(),
    dec: yup.number(),
    total: yup.number(),
});

const TotalSchema = yup.object({
    jan: yup.number(),
    feb: yup.number(),
    mar: yup.number(),
    apr: yup.number(),
    may: yup.number(),
    jun: yup.number(),
    jul: yup.number(),
    aug: yup.number(),
    sep: yup.number(),
    oct: yup.number(),
    nov: yup.number(),
    dec: yup.number(),
    total: yup.number(),
});

const primeInfoSchemaData = yup.object({
    active: yup.boolean(),
    allowAgreements: yup.boolean().nullable(),
    address: yup.string(),
    contact1: yup.string().nullable(),
    contact2: yup.string().nullable(),
    contact3: yup.string().nullable(),
    contactPhone: yup.string().nullable(),
    contactPhone2: yup.string().nullable(),
    contactPhone3: yup.string().nullable(),
    contactName: yup.string().nullable(),
    contactName2: yup.string().nullable(),
    contactName3: yup.string().nullable(),
    contactEmail: yup.string().nullable(),
    contactEmail2: yup.string().nullable(),
    contactEmail3: yup.string().nullable(),
    name: yup.string(),
    state: yup.string(),
    supplierCode: yup.string().nullable(),
    vendorId: yup.number(),
    zip: yup.string(),
    spend: yup.string().nullable(),
    city: yup.string(),
    sdgAdvisor: yup.string().nullable(),
    socalGasAdvisor: yup.string().nullable(),
    sapId: yup.number(),
});

export const subByMonthReportPayloadSchema = yup.object({
    message: yup.string(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    data: yup.array().of(
        yup.object({
            primeName: yup.string(),
            data: yup.object({
                verifySpend: yup.array().of(SpendSchema),
                verifySpendTotal: TotalSchema,
                UnVerifySpend: yup.array().of(SpendSchema),
                unVerifySpendTotal: TotalSchema,
            }),
        })
    ),
    filters_data: yup.object({
        subcontractors: yup.array(),
    }),
});

export const subByMonthReportAdminPayloadSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(
        yup.object({
            primeName: yup.string(),
            sdge_adv_name: yup.string(),
            socal_adv_name: yup.string(),
            c1: yup.string(),
            c2: yup.string(),
            c3: yup.string(),
            data: yup.object({
                verifySpend: yup.array().of(SpendSchema),
                verifySpendTotal: TotalSchema,
                UnVerifySpend: yup.array().of(SpendSchema),
                unVerifySpendTotal: TotalSchema,
            }),
        })
    ),
    total_rows: yup.number(),
    total_pages: yup.number(),
    filters_data: yup.object({
        primes: yup.array().of(
            yup.object({
                vendor_id: yup.number(),
                prime: yup.string(),
            })
        ),
        UtilityData: yup.array().of(
            yup.object({
                Sempra_Company_ID: yup.number(),
                Sempra_Company_Name: yup.string(),
            })
        ),
        totalVerifyAndNonverifySpend: yup.object({
            aprTotal: yup.number(),
            augTotal: yup.number(),
            decTotal: yup.number(),
            febTotal: yup.number(),
            grand_aprTotal: yup.number(),
            grand_augTotal: yup.number(),
            grand_decTotal: yup.number(),
            grand_febTotal: yup.number(),
            grand_janTotal: yup.number(),
            grand_julTotal: yup.number(),
            grand_junTotal: yup.number(),
            grand_marTotal: yup.number(),
            grand_mayTotal: yup.number(),
            grand_novTotal: yup.number(),
            grand_octTotal: yup.number(),
            grand_sepTotal: yup.number(),
            grand_total_verify_n_nonverify: yup.number(),
            janTotal: yup.number(),
            julTotal: yup.number(),
            junTotal: yup.number(),
            marTotal: yup.number(),
            mayTotal: yup.number(),
            novTotal: yup.number(),
            octTotal: yup.number(),
            sepTotal: yup.number(),
            total_unverify_spend: yup.number(),
            total_verify_spend: yup.number(),
            unveify_aprTotal: yup.number(),
            unveify_augTotal: yup.number(),
            unveify_decTotal: yup.number(),
            unveify_febTotal: yup.number(),
            unveify_janTotal: yup.number(),
            unveify_julTotal: yup.number(),
            unveify_junTotal: yup.number(),
            unveify_marTotal: yup.number(),
            unveify_mayTotal: yup.number(),
            unveify_novTotal: yup.number(),
            unveify_octTotal: yup.number(),
            unveify_sepTotal: yup.number(),
        }),
        utilityID: yup.string(),
    }),
});

export const subByPrimeContractReportSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(
        yup.object({
            prime_name: yup.string(),
            vendor_id: yup.string(),
            contract_number: yup.string(),
            contract_description: yup.string(),
            sdge_adv_name: yup.string().nullable(),
            socal_adv_name: yup.string().nullable(),
            c1: yup.string().nullable(),
            c2: yup.string().nullable(),
            c3: yup.string().nullable(),
            utility: yup.string(),
            spend_data: yup.object({
                verify_spend: yup.array().of(SpendSchema),
                unVerify_spend: yup.array().of(SpendSchema),
                verify_spend_total: TotalSchema,
                unVerify_spend_total: TotalSchema,
            }),
        })
    ),
    filters_data: yup.object({
        AllUniquePrimes: yup.array().of(
            yup.object({
                vendor_id: yup.number(),
                prime_name: yup.string(),
            })
        ),
        AllUniqueContracts: yup.array(yup.string()),
        UtilityData: yup.array().of(
            yup.object({
                Sempra_Company_ID: yup.number(),
                Sempra_Company_Name: yup.string(),
            })
        ),
        totalVerifyAndNonverifySpend: yup.object({
            aprTotal: yup.number(),
            augTotal: yup.number(),
            decTotal: yup.number(),
            febTotal: yup.number(),
            grand_aprTotal: yup.number(),
            grand_augTotal: yup.number(),
            grand_decTotal: yup.number(),
            grand_febTotal: yup.number(),
            grand_janTotal: yup.number(),
            grand_julTotal: yup.number(),
            grand_junTotal: yup.number(),
            grand_marTotal: yup.number(),
            grand_mayTotal: yup.number(),
            grand_novTotal: yup.number(),
            grand_octTotal: yup.number(),
            grand_sepTotal: yup.number(),
            grand_total_verify_n_nonverify: yup.number(),
            janTotal: yup.number(),
            julTotal: yup.number(),
            junTotal: yup.number(),
            marTotal: yup.number(),
            mayTotal: yup.number(),
            novTotal: yup.number(),
            octTotal: yup.number(),
            sepTotal: yup.number(),
            total_unverify_spend: yup.number(),
            total_verify_spend: yup.number(),
            unveify_aprTotal: yup.number(),
            unveify_augTotal: yup.number(),
            unveify_decTotal: yup.number(),
            unveify_febTotal: yup.number(),
            unveify_janTotal: yup.number(),
            unveify_julTotal: yup.number(),
            unveify_junTotal: yup.number(),
            unveify_marTotal: yup.number(),
            unveify_mayTotal: yup.number(),
            unveify_novTotal: yup.number(),
            unveify_octTotal: yup.number(),
            unveify_sepTotal: yup.number(),
        }),
        utilityID: yup.string()
    }),
    total_rows: yup.number(),
    total_pages: yup.number(),
});

export const ChangeSpendAmountReportPayloadSchema = yup.object({
    message: yup.string(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    data: yup.array().of(
        yup.object({
            primeName: yup.string(),
            is_verified: yup.boolean(),
            contract_number: yup.string(),
            changeDate: yup.string(),
            contactName: yup.string(),
            originalSpendAmt: yup.number(),
            newSpendAmt: yup.number(),
            spendMonth: yup.string(),
            spendYear: yup.string(),
            sub: yup.string(),
            diff: yup.number(),
            absDiff: yup.number(),
            utility:yup.string(),
            isTier3Spend: yup.boolean(),
        })
    ),
    filters_data: yup.object({
        subcontractors: yup.array(),
        contracts: yup.array(),
        utilityData: yup.array()
    }),
});

export const AdminChangesSpendAmountReportPayloadSchema = yup.object({
    message: yup.string(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    data: yup.array().of(
        yup.object({
            primeName: yup.string(),
            is_verified: yup.boolean(),
            contract_number: yup.string(),
            changeDate: yup.string(),
            contactName: yup.string(),
            originalSpendAmt: yup.number(),
            newSpendAmt: yup.number(),
            spendMonth: yup.string(),
            spendYear: yup.string(),
            sub: yup.string(),
            diff: yup.number(),
            absDiff: yup.number(),
            utility:yup.string(),
            isTier3Spend: yup.boolean(),
        })
    ),
    filtersData: yup.object({
        primes: yup.array(),
        utilityData: yup.array()
    }),
});

export const PurchaseOrderReportPayloadSchema = yup.object({
    message: yup.string(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    poNumbers: yup.array(),
    data: yup.array().of(
        yup.object({
            info: yup.object({
                utility: yup.string(),
                description: yup.string(),
                contract_number: yup.string(),
                isVerified: yup.boolean(),
            }),
            subcontractors: yup.array().of(
                yup.object({
                    subcontractor: yup.string(),
                    prime: yup.string(),
                    spendYear: yup.string(),
                    isVerified: yup.boolean(),
                    contract_number: yup.string(),
                    isTier3Spend:yup.boolean(),
                    description: yup.string(),
                    janSpend: yup.number().nullable(),
                    unverifiedJanSpend: yup.number().nullable(),
                    febSpend: yup.number().nullable(),
                    unverifiedFebSpend: yup.number().nullable(),
                    marSpend: yup.number().nullable(),
                    unverifiedMarSpend: yup.number().nullable(),
                    aprSpend: yup.number().nullable(),
                    unverifiedAprSpend: yup.number().nullable(),
                    maySpend: yup.number().nullable(),
                    unverifiedMaySpend: yup.number().nullable(),
                    junSpend: yup.number().nullable(),
                    unverifiedJunSpend: yup.number().nullable(),
                    julSpend: yup.number().nullable(),
                    unverifiedJulSpend: yup.number().nullable(),
                    augSpend: yup.number().nullable(),
                    unverifiedAugSpend: yup.number().nullable(),
                    sepSpend: yup.number().nullable(),
                    unverifiedSepSpend: yup.number().nullable(),
                    octSpend: yup.number().nullable(),
                    unverifiedOctSpend: yup.number().nullable(),
                    novSpend: yup.number().nullable(),
                    unverifiedNovSpend: yup.number().nullable(),
                    decSpend: yup.number().nullable(),
                    unverifiedDecSpend: yup.number().nullable(),
                    totalSpend: yup.number().nullable(),
                    unverifiedTotal: yup.number().nullable(),
                    totalRows: yup.number().nullable(),
                })
            ),
            poMonthTotal: yup.object({
                janTotal: yup.number(),
                febTotal: yup.number(),
                marchTotal: yup.number(),
                aprilTotal: yup.number(),
                mayTotal: yup.number(),
                junTotal: yup.number(),
                julytotal: yup.number(),
                augTotal: yup.number(),
                septTotal: yup.number(),
                octTotal: yup.number(),
                novTotal: yup.number(),
                decTotal: yup.number(),
                totalAmount: yup.number(),
            }),
        })
    ),
    utilityData: yup.array(),
});

export const verificationFollowUpPayloadSchema = yup.object({
    message: yup.string(),
    data: yup.array().of(
        yup.object({
            vendor_id: yup.number(),
            subcontractor_id: yup.number(),
            verification_number: yup.string().nullable(),
            prime: yup.string(),
            subcontractor_name: yup.string(),
            verification_expiration_date: yup.string().nullable(),
            vedate: yup.string().nullable(),
            updated: yup.boolean(),
            contactName: yup.string().nullable(),
            contactEmail: yup.string().nullable(),
            contactPhone: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            zip: yup.string().nullable(),
            address: yup.string().nullable(),
            in_sixty_days: yup.boolean(),
            verificationStatus: yup.object({
                color: yup.string(),
                label: yup.string().nullable(),
            }),
            spendAmount: yup.number().nullable(),
        })
    ),
    filters_data: yup.object({
        verificationExpirationDue: yup.array(),
    }),
    total_rows: yup.number(),
    total_pages: yup.number(),
});

export const verificationFollowUpPayloadSchemaAdmin = yup.object({
    message: yup.string(),
    data: yup.array().of(
        yup.object({
            primeInformation: yup.object({
                primeName: yup.string().nullable(),
                email: yup.string().nullable(),
                phone: yup.string().nullable(),
                state: yup.string().nullable(),
                zip: yup.string().nullable(),
                city: yup.string().nullable(),
                address: yup.string().nullable(),
                contactName: yup.string().nullable(),
            }),
            sdcName: yup.object({
                contact1: yup.string(),
                contact2: yup.string(),
                contact3: yup.string(),
                sdgeName: yup.string(),
                socalName: yup.string(),
            }),
            subcontractorsList: yup.array().of(
                yup.object({
                    subContractorId: yup.number().nullable(),
                    subContractorName: yup.string().nullable(),
                    verificationNumber: yup.string().nullable(),
                    verificationExpirationDate: yup.string().nullable(),
                    totalSpendAmount: yup.number().nullable(),
                    spendYear: yup.string().nullable(),
                    verificationStatus: yup.object({
                        color: yup.string().nullable(),
                        label: yup.string().nullable(),
                    }),
                    contactName: yup.string().nullable(),
                    contactPhone: yup.string().nullable(),
                    contactEmail: yup.string().nullable(),
                    gender: yup.string().nullable(),
                    nationality: yup.string().nullable(),
                })
            ),
        })
    ),
    filters_data: yup.object({
        prime: yup.array(),
        spendAmount: yup.array(),
        verificationExpirationDue: yup.array(),
    }),
    total_rows: yup.number(),
    total_pages: yup.number(),
});

export const ReportDataSchems = yup.array().of(
    yup.object({
        primeName: yup.string(),
        data: yup.object({
            verifySpend: yup.array().of(SpendSchema),
            verifySpendTotal: TotalSchema,
            UnVerifySpend: yup.array().of(SpendSchema),
            unVerifySpendTotal: TotalSchema,
        }),
    })
);

export const primeInformationReportSchema = yup.object({
    filters_data: yup
        .object({
            primes: yup.array().of(primeInfoSchemaData),
        })
        .defined(),
    message: yup.string(),
    total_rows: yup.number(),
    total_pages: yup.number(),
    data: yup.array().of(primeInfoSchemaData),
});

export type IReportMonth = yup.InferType<typeof subByMonthReportPayloadSchema>;
export type IReportAdminMonth = yup.InferType<
    typeof subByMonthReportAdminPayloadSchema
>;
export type IReportByPrimeContract = yup.InferType<
    typeof subByPrimeContractReportSchema
>;
export type IChangeSpend = yup.InferType<
    typeof ChangeSpendAmountReportPayloadSchema
>;
export type AdminIChangeSpend = yup.InferType<
    typeof AdminChangesSpendAmountReportPayloadSchema
>;
export type IPurchaseOrder = yup.InferType<
    typeof PurchaseOrderReportPayloadSchema
>;
export type IVerification = yup.InferType<
    typeof verificationFollowUpPayloadSchema
>;

export type IVerificationAdmin = yup.InferType<
    typeof verificationFollowUpPayloadSchemaAdmin
>;
export type IReportData = yup.InferType<typeof ReportDataSchems>;

export type IPrimeInfoData = yup.InferType<typeof primeInformationReportSchema>;

import services from 'src/services/announcement.service';
import { IAsyncActions } from 'src/libraries/thunk.library';
import { ICommonAction, ICommonState } from '.';
import {
    IAnnouncementDropdownListSchema,
    IFetchAnnouncementSchema,
} from 'src/models/announcement.model';

export const actionTypes = {
    ANNOUNCEMENT_LIST_FETCH: 'ANNOUNCEMENT_LIST_FETCH',
    ANNOUNCEMENT_DROPDOWN_LIST_FETCH: 'ANNOUNCEMENT_DROPDOWN_LIST_FETCH',
    ANNOUNCEMENT_SEND: 'ANNOUNCEMENT_SEND',
    ANNOUNCEMENT_DELETE: 'ANNOUNCEMENT_DELETE',
} as const;

export const asyncActions = {
    getAnnouncementList: {
        type: actionTypes.ANNOUNCEMENT_LIST_FETCH,
        service: services.getAnnouncementList,
        status: {} as never,
    },
    getAnnouncementDropdownList: {
        type: actionTypes.ANNOUNCEMENT_DROPDOWN_LIST_FETCH,
        service: services.getAnnouncementDropdownList,
        status: {} as never,
    },
    sendAnnouncement: {
        type: actionTypes.ANNOUNCEMENT_SEND,
        service: services.sendAnnouncement,
        status: {} as never,
    },
    deleteAnnouncement: {
        type: actionTypes.ANNOUNCEMENT_DELETE,
        service: services.deleteAnnouncement,
        status: {} as never,
    },
};

export type IAnnouncementAsync = typeof asyncActions;

export type IAnnouncementReducerAction = IAsyncActions<IAnnouncementAsync>;

export interface IAnnouncementState
    extends ICommonState<IAnnouncementReducerAction> {
    announcementList: Partial<IFetchAnnouncementSchema>;
    announcementDropdownList: Partial<IAnnouncementDropdownListSchema>;
}

export const defaultState: IAnnouncementState = {
    status: {},
    announcementList: {},
    announcementDropdownList: {},
};

const AnnouncementReducer = (
    state: IAnnouncementState = defaultState,
    action: ICommonAction<IAnnouncementReducerAction>
): IAnnouncementState => {
    switch (action.type) {
        case actionTypes.ANNOUNCEMENT_LIST_FETCH: {
            return {
                ...state,
                announcementList: action.payload || {},
            };
        }
        case actionTypes.ANNOUNCEMENT_DROPDOWN_LIST_FETCH: {
            return {
                ...state,
                announcementDropdownList: action.payload || {},
            };
        }

        default: {
            return state;
        }
    }
};

export default AnnouncementReducer;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import Lang from 'src/libraries/language';
import {
    ToastError,
    ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { useSubcontractorContext } from 'src/contexts/subcontractor.context';
import { ISubList } from 'src/models/manage-subcontractor.model';

import SubcontractorStatusEditModalView from './subcontractor-status-edit-modal.view';

type Iprops = {
    open: boolean;
    data: Partial<ISubList | undefined>;
    handleOnDiscard: () => void;
    handleSuccess: (msg, bool) => void;
    reasonList: any;
};

const UpdateSubcontrctorComponent: React.FC<Iprops> = ({
    open,
    data,
    handleOnDiscard,
    handleSuccess,
    reasonList,
}) => {
    const { state, actions } = useSubcontractorContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);
    const [reasonsDisabled, setReasonsDisabled] = useState(true);
    const [updateConfirm, setUpdateConfirm] = useState(false);
    const formRef = useRef<HTMLFormElement>();
    const dataRef = useRef<any>();
    const dataRefOld = useRef<any>();
    const dataReason = useRef<any>();
    const dataIsReason = useRef<any>();
    const dialogRef = useRef<string>();
    const discardStatusRef = useRef<string>();
    const [vonError, setVonError] = useState(false);

    const handleClose = () => {
        setConfirm(false);
        dialogRef.current = 'close';
    };

    const handleOnClose = () => {
        setUpdateConfirm(false);
        dialogRef.current = 'close';
    };
    useEffect(() => {
        if (data) {
            dataRef.current = data;
            dataRefOld.current = data;
            if (dataRef.current.reason > 0) {
                setReasonsDisabled(false);
                dataReason.current = true;
                dataIsReason.current = true;
            } else {
                dataReason.current = false;
                dataIsReason.current = false;
            }
        }
    }, [data]);

    const handleCancel = useCallback(() => {
        setConfirm(false);
        setButtonDisable(true);
        handleOnDiscard();
    }, [data, dataRef]);

    const handleSubmitConfirm = async () => {
        if (discardStatusRef.current === 'open') {
            let hidden = dataRef.current.hidden ? 1 : 0;
            if (dataRef.current.reason == 0) {
                hidden = 0;
                dataRef.current.hidden = false
            } else {
                hidden = 1;
                dataRef.current.hidden = true
            }

            let ifIsHiddenChange = 0;
            if(dataRefOld.current.hidden != dataRef.current.hidden) {
                ifIsHiddenChange = 1;
            }
            const params = {
                hidden: hidden,
                subcontractorId: dataRef.current.subcontractor_id,
                reasonId: dataRef.current.reason,
                isHiddenChange: ifIsHiddenChange
            };

            const response = await actions.updateSubcontractorStatusAdmin(
                params
            );

            if (response?.payload) {
                handleSuccess(
                    'Subcontractor Information has been updated successfully',
                    true
                );
                setButtonDisable(true);
                setConfirm(false);
            } else if (!response?.error?.status) {
                ToastError(response?.error?.message);
                setConfirm(false);
            }
        } else {
            setConfirm(false);
            setButtonDisable(true);
            handleOnDiscard();
        }
    };

    const handleChange = (key, value: any) => {
        dataIsReason.current = true;
        const newData = { ...dataRef.current, ...{ [key]: value } };
        dataRef.current = newData;
    };

    const handleReasonChange = (value: any) => {
        if (value.checked) {
            setReasonsDisabled(false);
            setButtonDisable(false);
            dataReason.current = true;
        } else {
            setReasonsDisabled(true);
            setButtonDisable(true);
            dataReason.current = false;
            const newData = { ...dataRef.current, ...{ ['reason']: '0' } };
            // setCurrentReasonId(0);
            dataRef.current = newData;
        }
    };

    const handleSubmit = () => {
        discardStatusRef.current = 'open';
        setConfirmMsg(Lang.MSG_CONTRACTOR_CONFIRM_UPDATE);
        setConfirm(true);
    };

    const handleClick = useCallback(() => {
        if (dataReason.current && !dataIsReason.current) {
            ToastError('Please choose any reason!');
        } else {
            dialogRef.current = 'open';
            if (formRef && formRef.current) {
                handleSubmit();
            }
            setUpdateConfirm(true);
            setConfirm(true);
        }
    }, [setUpdateConfirm]);
    return (
        <>
            <SubcontractorStatusEditModalView
                data={data}
                open={open}
                reasonList={reasonList.data}
                handleClose={handleCancel}
                handleClick={handleClick}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formRef={formRef}
                buttonDisable={buttonDisable}
                reasonsDisabled={reasonsDisabled}
                handleReasonChange={handleReasonChange}
                vonError={vonError}
            />

            {confirm && (
                <Confirm
                    open={confirm}
                    size="tiny"
                    content={confirmmsg}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleClose}
                    onConfirm={handleSubmitConfirm}
                />
            )}
            {updateConfirm && (
                <Confirm
                    open={updateConfirm}
                    size="tiny"
                    content={Lang.MSG_CONFIRM_YOUR_CHANGES}
                    loading={false}
                    btn_text={Lang.LBL_CONFIRM_BTN}
                    header={Lang.TTL_CONFIRMATION}
                    onCancel={handleOnClose}
                    onConfirm={() => handleSubmitConfirm()}
                />
            )}
        </>
    );
};

export default UpdateSubcontrctorComponent;

import React, { useCallback, useEffect, useState } from 'react';
import { useDashboardContext } from 'src/contexts/dashboard.context';
import Lang from 'src/libraries/language';
import AdminDashboardView from './admin-dashboard.view';

const AdminDashboard = (): React.ReactElement => {
    const [graph, setGraph] = useState(false);
    const { state, actions } = useDashboardContext();
    const [primeFilter, setPrimeFilter] = useState('ALL');
    const primeArray: any = [];
    const [utilityDropdownOptions, setUtilityDropdownOption] = useState([]);
    const [primeDropdownOptions, setPrimeDropdownOptions] = useState<any>([]);
    const [sapId, setSapId] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [isDashboardHeaderLoading, setDashboardHeaderLoading] = useState(true);
    const [isDashboardSubcontractorSummaryLoading, setDashboardSubcontractorSummaryLoading] = useState(true);
    const [selectedPrimeName, setSelectedPrimeName] = useState(Lang.LBL_ALL_CAPS);
    const [selectedUtilityName, setSelectedUtilityName] = useState('');
    const [socalSubcontractingReport, setSocalSubcontractingReport] = useState({
        totalPaymentYTD: '-',
        totalVerifiedSubcontracting: '-',
        verifiedSubcontracting: '-',
        overallGoal: '-',
        goalSubcontracting: '-',
        subcontractingDiff: '-'
    });
    const [sdgeSubcontractingReport, setSdgeSubcontractingReport] = useState({
        totalPaymentYTD: '-',
        totalVerifiedSubcontracting: '-',
        verifiedSubcontracting: '-',
        overallGoal: '-',
        goalSubcontracting: '-',
        subcontractingDiff: '-'
    });

    const handleChangePrimeOptions = (value) => {
        setPrimeFilter(value);
        setSapId('');
    }

    const handleSelectPrime = (value) => {
        setSapId(value)
    }

    const handleSelectUtility = (value) => {
        setCompanyCode(value)
    }

    useEffect(() => {
        if (primeFilter === 'Individual' && sapId === '' || companyCode === '') {

        } else {
            fetchSubcontractingSpendReport();
        }
    }, [sapId, companyCode, primeFilter]);

    const fetchSubcontractingSpendReport = useCallback(async () => {
        if (primeFilter === 'ALL') {
            setSelectedPrimeName(Lang.LBL_ALL_CAPS);
        } else if (primeFilter === 'Individual') {
            const selectedPrime = primeDropdownOptions.filter((prime) => prime?.value === sapId);
            setSelectedPrimeName(selectedPrime.length > 0 && selectedPrime[0].text);
        }
        if(companyCode === '2100'){
            setSelectedUtilityName(Lang.LBL_SDGNE)
        }else if(companyCode === '2200'){
            setSelectedUtilityName(Lang.LBL_SOCALGAS)
        }else{
            setSelectedUtilityName(Lang.LBL_ALL_CAPS)
        }
        setDashboardSubcontractorSummaryLoading(true);
        const queryParam: {
            primefilter: string;
            compCodes: string;
            primeSapId?: string;
        } = {
            primefilter: primeFilter,
            compCodes: companyCode,
            primeSapId: sapId
        };

        if (primeFilter === 'ALL') {
            delete queryParam.primeSapId;
        }
        const response = await actions.getSubcontractingSpendReportDashboard(queryParam);
        const summaryData = response?.payload?.data;

        const socalData = summaryData && summaryData?.length > 0 && summaryData.filter((data) => data.compCode === 2200);
        socalData && socalData.length > 0 ? setSocalSubcontractingReport({
            totalPaymentYTD: socalData[0]?.totalPaymentYTD >= 0 ? socalData[0].totalPaymentYTD.toLocaleString() : `(${Math.abs(socalData[0].totalPaymentYTD).toLocaleString()})`,
            totalVerifiedSubcontracting: socalData[0]?.totalVerifiedSubcontracting >= 0 ? socalData[0].totalVerifiedSubcontracting.toLocaleString() : `(${Math.abs(socalData[0].totalVerifiedSubcontracting).toLocaleString()})`,
            verifiedSubcontracting: socalData[0]?.verifiedSubcontracting >= 0 ? `${socalData[0].verifiedSubcontracting}%` : `(${Math.abs(socalData[0].verifiedSubcontracting)})%`,
            overallGoal: socalData[0]?.overallGoal >= 0 ? `${socalData[0].overallGoal}%` : `(${Math.abs(socalData[0].overallGoal)})%`,
            goalSubcontracting: socalData[0]?.goalSubcontracting >= 0 ? socalData[0].goalSubcontracting.toLocaleString() : `(${Math.abs(socalData[0].goalSubcontracting).toLocaleString()})`,
            subcontractingDiff: socalData[0]?.subcontractingDiff >= 0 ? socalData[0].subcontractingDiff.toLocaleString() : `(${Math.abs(socalData[0].subcontractingDiff).toLocaleString()})`
        }) : setSocalSubcontractingReport({
            totalPaymentYTD: '-',
            totalVerifiedSubcontracting: '-',
            verifiedSubcontracting: '-',
            overallGoal: '-',
            goalSubcontracting: '-',
            subcontractingDiff: '-'
        });


        const sdgeData = summaryData && summaryData?.length > 0 && summaryData.filter((data) => data.compCode === 2100);
        sdgeData && sdgeData.length > 0 ? setSdgeSubcontractingReport({
            totalPaymentYTD: sdgeData[0]?.totalPaymentYTD >= 0 ? sdgeData[0].totalPaymentYTD.toLocaleString() : `(${Math.abs(sdgeData[0].totalPaymentYTD).toLocaleString()})`,
            totalVerifiedSubcontracting: sdgeData[0]?.totalVerifiedSubcontracting >= 0 ? sdgeData[0].totalVerifiedSubcontracting.toLocaleString() : `(${Math.abs(sdgeData[0].totalVerifiedSubcontracting).toLocaleString()})`,
            verifiedSubcontracting: sdgeData[0]?.verifiedSubcontracting >= 0 ? `${sdgeData[0].verifiedSubcontracting}%` : `(${Math.abs(sdgeData[0].verifiedSubcontracting)})%`,
            overallGoal: sdgeData[0]?.overallGoal >= 0 ? `${sdgeData[0].overallGoal}%` : `(${Math.abs(sdgeData[0].overallGoal)})%`,
            goalSubcontracting: sdgeData[0]?.goalSubcontracting >= 0 ? sdgeData[0].goalSubcontracting.toLocaleString() : `(${Math.abs(sdgeData[0].goalSubcontracting).toLocaleString()})`,
            subcontractingDiff: sdgeData[0]?.subcontractingDiff >= 0 ? sdgeData[0].subcontractingDiff.toLocaleString() : `(${Math.abs(sdgeData[0].subcontractingDiff).toLocaleString()})`
        }) : setSdgeSubcontractingReport({
            totalPaymentYTD: '-',
            totalVerifiedSubcontracting: '-',
            verifiedSubcontracting: '-',
            overallGoal: '-',
            goalSubcontracting: '-',
            subcontractingDiff: '-'
        });
        setDashboardSubcontractorSummaryLoading(false);
    }, [actions, sapId, companyCode, primeFilter])



    const fetchSubcontractingSpendReportDropdown = useCallback(async () => {
        setDashboardHeaderLoading(true);
        const response = await actions.getSubcontractingSpendReportDropdown();
        const primes = response?.payload?.data?.dropdownPrimelist;
        const utilities = response?.payload?.data?.utility;

        primes && primes.length > 0 && primes.map((prime) => {
            primeArray.push({
                key: prime.sapId,
                value: prime.sapId,
                text: prime.name
            })
        })

        setPrimeDropdownOptions(primeArray)

        const allCodes = utilities && utilities.length > 0 && utilities.map(utilityOps => utilityOps.Sempra_Company_Code).join(',');

        const utilityArray: any = [{
            key: allCodes,
            value: allCodes,
            text: 'ALL'
        }];

        if (utilities && utilities.length > 0) {
            utilities.map((utilityOps) => {
                let utilityText = '';
                switch (utilityOps.Sempra_Company_Code) {
                    case '2100':
                        utilityText = Lang.LBL_SDGNE;
                        break;
                    case '2200':
                        utilityText = Lang.LBL_SOCALGAS;
                        break;
                    default:
                        utilityText = 'Unknown Company';
                }

                utilityArray.push({
                    key: utilityOps.Sempra_Company_Code,
                    value: utilityOps.Sempra_Company_Code,
                    text: utilityText
                });
            });

            setUtilityDropdownOption(utilityArray);
        }

        setCompanyCode(allCodes);
        setDashboardHeaderLoading(false);
    }, [actions]);
    useEffect(() => {
        fetchSubcontractingSpendReportDropdown();
    }, [actions]);

    const setValue = useCallback(
        (name, value) => {
            if (name === 'graph') setGraph(value);
        },
        [graph]
    );

    return <AdminDashboardView
        isDashboardHeaderLoading={isDashboardHeaderLoading}
        isDashboardSubcontractorSummaryLoading={isDashboardSubcontractorSummaryLoading}
        sapId={sapId}
        companyCode={companyCode}
        graph={graph}
        setValue={setValue}
        primeFilter={primeFilter}
        utilityDropdownOptions={utilityDropdownOptions}
        primeDropdownOptions={primeDropdownOptions}
        handleChangePrimeOptions={handleChangePrimeOptions}
        handleSelectPrime={handleSelectPrime}
        handleSelectUtility={handleSelectUtility}
        socalSubcontractingReport={socalSubcontractingReport}
        sdgeSubcontractingReport={sdgeSubcontractingReport}
        selectedUtilityName={selectedUtilityName}
        selectedPrimeName={selectedPrimeName}
    />;

};

export default AdminDashboard;

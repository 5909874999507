import { httpClient } from '../libraries/http.library';
import {
    NotificationHeaderSchema,
    NotificationFooterSchema,
} from '../models/admin-console.model';

const client = httpClient();

export const services = {
    getAdminConsoleNotificationHeader: async (params) => {
        return client.get('/notifications', params, NotificationHeaderSchema);
    },
    getAdminConsoleNotificationFooter: async (params) => {
        return client.get('/footers', params, NotificationFooterSchema);
    },
};

export default services;

export default {
    MSG_NUMBERS_ONLY: 'Please enter number only',
    MSG_ACTION_IRREVERSIBLE: 'This action cannot be undone.',
    MSG_CLICK_HOME: 'Click here to return to home',
    MSG_CONFIRM_DELETE: 'Are you sure you want to delete {0}?',
    MSG_CONFIRM_SEND: 'Are you sure you want to send out this email now?',
    MSG_EMAIL_SENT: 'Your email has been sent.',
    MSG_ERROR: 'We caught an error while processing your request.',
    MSG_ERROR_REQUIRED: 'This field is required',
    MSG_ERROR_REQUIRED_CUSTOM: '{0} is a required field',
    MSG_NO_RECORDS_TO_DISPLAY:
        'No records/data found for the selected criteria',
    MSG_UNDER_CONSTRUCTION:
        "Please bear with us as we're still constructing this part. Thank you.",
    MSG_REQUIRED: 'Indicates mandatory field',
    MSG_ADMIN_SUCCESS_DELETE: 'Admin access was successfully removed.',
    MSG_ADMIN_SUCCESS_CREATE: 'New admin was successfully created.',

    MSG_AUTHORIZATION_ERROR:
        'Unable to authenticate credentials, please try reloading this page.',

    MSG_REMINDER_CUSTOM_EMAIL_SUCCESS_UPDATE:
        'Custom email successfully saved.',
    MSG_REMINDER_EMAIL_SUCCESS_UPDATE:
        'Reminder email template successfully saved.',
    MSG_HTTP_ERROR_BAD_REQUEST: 'Request failed with status code {0}',
    MSG_LOGIN_EMAIL_REQUIRED: 'Email Address is a required field',
    MSG_LOGIN_PASSWORD_REQUIRED: 'Password is a required field',
    MSG_LOGIN_INFO_CERTIFY_REQUIRED: 'Please accept the checkbox',
    MSG_LOGIN_PLEASE_CHECK_CREDENTIAL:
        'Please enter correct email and password.',
    MSG_SPEND_SUMMARY_TOOLTIP: '* amount of spend dollars added today.',
    MSG_CONTRACTOR_PRIME_INFORMATION_UPDATED_SUCCESSFULLY:
        'Prime information has been updated successfully.',
    MSG_CONTRACTOR_PRIME_INFORMATION_ADDED_SUCCESSFULLY:
        'Prime has been added successfully.',
    MSG_CONTRACTOR_PROFILE_UPDATE_ERROR:
        'Profile has not updated. Please contact administrator!',
    MSG_CONTRACTOR_CONFIRM_UPDATE: 'Please confirm your changes.',
    MSG_CONTRACTOR_CONFIRM_DISCARD: 'Please confirm your changes.',
    MSG_CONFIRM_YOUR_CHANGES: 'Please confirm your changes.',
    MSG_DELETE_ANNOUNCEMENT: 'Are you sure to delete the announcement',
    MSG_SUBCONTRACTOR_ADDED_SUCCESSFULLY:
        'Subcontractor has been added successfully.',
    MSG_SUBCONTRACTOR_UPDATED_SUCCESSFULLY:
        'Subcontractor has been updated successfully.',
    MSG_SUBCONTRACTOR_CREATE_INSTRUCTION:
        'You must try to locate your subcontractor in the Supplier Clearinghouse before being allowed to input manually. Adding a subcontractor manually should only be attempted upon approval from the subcontracting portal administrator as it is likely there is a valid reason why a subcontractor cannot be found in the Supplier Clearinghouse to add via Verification Number "Lookup" option. To manually add a subcontractor, click the "Enable Manual Entry" button to "On" to edit the fields. All fields are required. You are responsible for getting your subcontractor verified with the Supplier Clearinghouse before your spend with them will count towards your DBE goals.',
    MSG_SUBCONTRACTOR_ADD_SELECTED_INSTRUCTION:
        'Select subcontractors that you would like to add. Subcontractors whose verification expiration dates are more than 180 days past due do not show in this listing. Select view {0} to confirm that this is the correct subcontractor.',
    MSG_SUBCONTRACTOR_AUTOFILL_ERROR:
        'The Verification Number was not found. Please re-enter the Verification Number and try again.',
    MSG_REPORTS_INSTRUCTION:
        'Subcontracting spend with the following subcontractor(s) will not count towards your DBE subcontracting goals once verification has expired. If verification is with an agency other than the Supplier Clearinghouse (for minorities, women, LGBTBE or SB8(a) owned businesses) or the OSDC (for service disabled Veteran-owned businesses), the subcontracting spend will not count until they become verified through the applicable agency above.',
    MSG_NO_REPORT_SELECTED:
        'Please select the report name from the dropdown to view the report.',
    MSG_SUBCONTRACTOR_LIST_INSTRUCTION:
        'Subcontractors must be verified by CPUC-approved verification agencies and verification must be current to count towards supplier diversity subcontracting goals. Minority-, Women-, LGBT- or SBA 8(a)-owned businesses must be verified through the Supplier Clearinghouse. Service-Disabled Veteran-owned businesses must be verified through the California Department of General Services.',
    MSG_SUBCONTRACTOR_LOOKUP_CONFIRM:
        '<p><p>You will be taken out of this application to the Supplier Clearinghouse to select a subcontractor to add. When you have selected your sub, copy the Verification Order # in the 2nd column into the Verification Number field in the subcontracting portal and select the Auto-Fill button.</p><p> Hold the Ctrl key down when clicking OK if you have a pop-up blocker.</p></p>',
    MSG_FORGOT_PASSOWORD_SUCCESS:
        'Password has been sent to your registered email id.',
    MSG_RESET_PASSWORD:
        'A temporary password will be sent to selected emails.They will be required to change their password when they login.',
    MSG_REMOVE_SUBCONTRACTOR:
        'A subcontractor cannot be removed if subcontracting spend was entered in the reporting year. Spend must be deleted before permanently removing a subcontractor.',
    MSG_REMOVE_SUBCONTRACTOR_IF_ISDELETE:
        'Spend has been reported for  this Subcontractor for the current reporting year.To enable removal of this Subcontractor, please delete the spend first and then reselect the delete icon.',

    ERROR: {
        COMMON: {
            REQUIRED: '{0} is required field',
            ONLY_LETTERS: '{0} must contain only letters',
            CAN_NOT_BE_LONGER_THAN: "{0} can't be longer than {1} characters",
            PHONE_FORMAT: 'Phone number must be in ###-###-#### format',
            VALID_EMAIL: 'Please enter vaild email',
            ALPHANUMERIC: '{0} must be alphanumeric',
            MUST_BE_N_DIGIT: '{0} must be {1} digit number',
            MUST_BE_N_CHARACTERS: '{0} must be {1} characters',
        },
        PRIME_CONTRACTOR: {},
        SUBCONTRACTOR_ENTER: {},
        SUBCONTRACTOR: {
            INACTIVATION_REASON_REQUIRED: 'Please Select Reason',
        },
    },

    MSG_PRIMES_FAVOURITE_UPDATED_SUCCESSFULLY:
        'Prime details has been updated successfully.',
    MSG_PRIMES_TIER_3_SPEND_UPDATED_SUCCESSFULLY:
        'Prime User Tier III status has been updated successfully.',
    MSG_MAIL_SENT_SUCCESSFULLY: 'Email has been sent successfully.',
    MSG_MAXIMUM_FILE_SIZE: 'The maximum file size is 5MB.',
    MSG_FILE_FORMAT: 'The file should be in PDF format.',
    MSG_TOKEN_GENERATED_SUCCESS: 'Refresh token generated successfully',
    MSG_JWT_TOKEN_EXPIRED: 'jwt token expired',
    MSG_FILE_UPLOAD_CSV_FILE_ONLY:
        'File format not supported. Please upload CSV File only',
    MSG_UPDATED_SPEND_UTILITY_SELECTED:
        'Please select the Utility from the dropdown to record the data',
    MSG_SUBCONTRACTOR_DATABASE_FILE_UPLOAD_CSV_FILE_ONLY:
        'File format not supported. Please upload the "supplierclearinghouse.csv" file only',
    MSG_EXTERNAL_DATA_FILE_UPLOAD_CSV_FILE_ONLY:
        'Incorrect file name or format. Please upload supplierclearinghouse.csv file only',
    MSG_USER_DELETE_RECORD_CONFIRM:
        'Please confirm that you want to delete this contact.',
    MSG_ACTION_CONFIRM: 'Please confirm your changes.',
    MSG_USER_DELETE_SUCCESFUL: 'User has been deleted successfully',
    MSG_CONFIRM_REMOVE_SUBCONTRACTOR: 'Please confirm to remove subcontractor',
    MSG_CONFIRM_REMOVE_SUBCONTRACTOR_IF_ISDELETE:
        'Click on the "Confirm Removal" button to delete the Subcontractor.',
    MSG_DISABLE_SPEND_AUTO_GENERATION_CONFIRM: 'Are you sure you want to disable auto generation of the spend file?',
    MSG_ENABLE_SPEND_AUTO_GENERATION_CONFIRM: 'Are you sure you want to enable the auto generation of the spend file?',
    MSG_FUNCTIONALITY_DISABLED:'This tab/functionality cannot be accessed at this time as the portal is performing required updates. Please try again later.'
};

export default {
  NTE_PAGE_ACCESS_DENIED:
    'You do not appear to be an authorized user for this application. If you  believe you have received this message in error, please contact the administrator via email: {0}',

  NTE_ADMIN_REMOVE_ACCESS_TEXT_CONFIRM:
    'Are you sure you would like to remove Administrator to {0}?',
  NTE_ADMIN_REMOVE_ACCESS_TEXT_GENERAL: 'This will make {0} a general user.',
  ADMIN_MANAGE_NOTIFICATION_NOTE: 'The records highlighted in red are expired notifications. To send new notification click the \"Send New Notification\" button.',
  NTE_ADMIN_REMOVE_ACCESS_TEXT_GENERAL_INFO:
  'General users only have the ability to view content and approve requirements that are associated with.',

  NTE_SUBCONTRACTOR_ADD_SUBCONTRACTOR_NOTE:'Select subcontractors that you would like to add.Subcontractors whose verification expiration dates are more than 180 days past due do not show in this listing.',
  NTE_SUBCONTRACTOR_ADD_SUBCONTRACTOR_CONFIRM:'to confirm that this is the correct subcontractor.'

};

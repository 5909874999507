import React, { useCallback, useEffect, useState } from 'react';
import { useContractorContext } from 'src/contexts/contractor.context';
import { getUserID } from 'src/libraries/users.library';


const PrimeDashboardHead: React.FC = () => {
    const { state, actions } = useContractorContext();
    const [primedata, setPrimeData] = useState();
    useEffect(() => {
        fetchData();
    }, [actions]);

    const fetchData = useCallback(async () => {
        const userId = getUserID();
        const params = { vendor_id: userId };
        const response = await actions.listGET(params);
        setPrimeData(response.payload?.data?.company_name);
    }, [actions, setPrimeData]);

    return (
        <>
            {false && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: 16,
                                position: 'absolute',
                                width: '100%',
                                left: 'auto',
                                right: 0
                            }}
                        >
                            Coming Soon
                        </div>

                    </div>
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        margin: '10px 30px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <i
                        className="icon building outline"
                        style={{
                            color: 'white',
                            fontSize: 'x-large',
                            textAlign: 'inherit',
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            background: '#2185D0',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />
                </div>
                <div
                    style={{
                        flexGrow: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 10,
                        justifyContent: 'center',
                        alignItems: 'start',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                        }}
                    >
                        {primedata}
                    </div>
                    {/* <div>
                            <i
                                className="icon calendar alternate outline"
                                style={{ width: 15, height: 13 }}
                            />
                            Wednesday 19th May 2021
                        </div> */}
                </div>
            </div>
        </>
    );
};

export default PrimeDashboardHead;
